import clsx from 'clsx'
import {useFormik} from 'formik'
import {cloneDeep, concat, find, flatMap, isEmpty, isEqual, omit, size, update} from 'lodash'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Container, Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import {Button, SelectFormik, ValidationErrorModal} from '../../../../../_gori/partials/widgets'
import {InputCheckboxFormik} from '../../../../../_gori/partials/widgets/form/InputCheckboxFormik'
import HttpStatusCode from '../../../../api/HttpStatusCode'
import {ButtonTemplateMapping, FileErrorModal, SaveTemplateMappingModal} from '../../../batches'
import {BATCH_UPLOAD_MAPPING} from '../../core/_const'
import BatchService from '../../core/_requests'

const checkRequiredValues = (object, mappingArray) => {
  const values = Object.values(object).filter((value) => value !== '')
  const requiredValues = flatMap(mappingArray, 'options')
    .filter((item) => item.required)
    .map((item) => item.value)
  const missingValues = requiredValues.filter((value) => !values.includes(value))

  const valueCounts: any = values.reduce((acc: any, value: any) => {
    acc[value] = (acc[value] || 0) + 1
    return acc
  }, {})
  const duplicateValues = Object.keys(valueCounts).filter((value) => valueCounts[value] > 1)

  return {missingValues, duplicateValues}
}

type Props = {
  showModal: boolean
  handleClose: () => void
  data: {fileUpload: any; columnsCSV: any}
  handleUploadSuccess: any
}

const BatchUploadMappingModal: React.FC<Props> = ({
  showModal,
  handleClose,
  data,
  handleUploadSuccess,
}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const [errors, setErrors] = useState<{
    validationErrors: any
    upload: any
    missing: any
    duplicate: any
  }>({
    validationErrors: null,
    upload: null,
    missing: null,
    duplicate: null,
  })
  const [loading, setLoading] = useState<{
    setAsDefault: boolean
    getTemplate: boolean
    uploadFile: boolean
  }>({
    setAsDefault: false,
    getTemplate: false,
    uploadFile: false,
  })
  const [show, setShow] = useState<{
    upload: boolean
    errors: boolean
    saveTemplate: boolean
    hasUpload: boolean
  }>({
    upload: false,
    errors: false,
    saveTemplate: false,
    hasUpload: false,
  })
  const [mappingTemplate, setMappingTemplate] = useState<{options: any; value: any}>({
    options: [],
    value: undefined,
  })
  const [selectedOption, setSelectedOption] = useState<Array<any>>([])
  const selectedOptionRef = useRef<any>(null)
  const templateCurrent = useRef<any>(null)
  const modalBodyRef = useRef<any>(null)

  const handleCloseModal = () => {
    templateCurrent.current = null
    handleClose()
  }

  const getMappingTemplate = useCallback(
    async ({applyDefaultTemplate}) => {
      try {
        const config = {
          cancelToken: newCancelToken(),
        }
        setLoading((prev) => ({...prev, getTemplate: true}))
        const {batch_mappings} = await BatchService.getMappingTemplate(config)
        if (batch_mappings) {
          setMappingTemplate((prev) => ({
            ...prev,
            options: batch_mappings,
          }))
          const defaultTemplate = find(batch_mappings, {default: true})
          if (defaultTemplate && applyDefaultTemplate) {
            handleClickTemplate(defaultTemplate)
          }
          const foundTemplate = find(batch_mappings, {id: templateCurrent.current?.id})
          if (foundTemplate && !applyDefaultTemplate) {
            handleClickTemplate(foundTemplate)
          }
        }
      } catch (error) {
        if (isCancel(error)) return
      } finally {
        setLoading((prev) => ({...prev, getTemplate: false}))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCancel, newCancelToken, templateCurrent.current]
  )

  useEffect(() => {
    getMappingTemplate({applyDefaultTemplate: true})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initialValues = useCallback(() => {
    if (!data?.columnsCSV) return {}
    const initValues = data.columnsCSV.reduce((columns, col, index) => {
      columns[`col_${index}`] = ''
      return columns
    }, {})
    return initValues
  }, [data.columnsCSV])

  const mapByColumnName = useCallback(
    (international = false) => {
      if (!data?.columnsCSV) return {}
      const mapping = international
        ? [...BATCH_UPLOAD_MAPPING.DEFAULT, ...BATCH_UPLOAD_MAPPING.CUSTOM]
        : BATCH_UPLOAD_MAPPING.DEFAULT
      const initValues = data.columnsCSV.reduce((columns, col, index) => {
        const existingValue = Object.values(columns).includes(col.value)
        columns[`col_${index}`] =
          find(mapping, {value: col.value}) && !existingValue ? col.value : ''
        return columns
      }, {})
      return initValues
    },
    [data.columnsCSV]
  )

  const formik = useFormik({
    initialValues: {...initialValues(), international: false},
    enableReinitialize: true,
    onSubmit: async () => {},
  })

  const renderOptionsMapping = useCallback(
    (international = false) => {
      const renderOptions = (mapping) =>
        mapping.map((field) => {
          return {
            ...field,
            label: `${intl.formatMessage({id: field.label})}${field.required ? '*' : ''}`,
            isDisabled:
              !isEmpty(selectedOption) && !!selectedOption.find((col) => col === field.value),
          }
        })

      return [
        {
          label: '',
          options: renderOptions(BATCH_UPLOAD_MAPPING.DEFAULT),
        },
        {
          label: intl.formatMessage({id: 'CUSTOM'}),
          options: international
            ? renderOptions(BATCH_UPLOAD_MAPPING.CUSTOM)
            : [{isDisabled: true}],
        },
      ]
    },
    [intl, selectedOption]
  )

  const optionsMapping = useMemo(() => {
    const _optionsMapping = flatMap(renderOptionsMapping(formik.values.international), 'options')
    if (formik?.values) {
      const checkMapping = Object.values(omit(formik?.values, ['international'])).reduce(
        (columns, col, index) => {
          columns[`col_${index}`] = find(cloneDeep(_optionsMapping), {value: col}) ? col : ''
          return columns
        },
        {}
      )
      selectedOptionRef.current = Object.values(checkMapping)
      if (formik.values.international && !isEqual(selectedOptionRef.current, selectedOption)) {
        setSelectedOption(Object.values(checkMapping))
      }
      formik.setValues({
        international: formik.values.international,
        ...checkMapping,
      })
    }

    return renderOptionsMapping(formik.values.international)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderOptionsMapping, formik.values.international])

  const disableSaveCurrentMapping = useMemo(() => {
    const templateValues = omit(formik.values, ['international'])
    return isEqual(templateCurrent?.current?.value, templateValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, templateCurrent?.current])

  const handleUploadFile = async () => {
    const formData = new FormData()
    formData.append('file', data.fileUpload)
    formData.append('international', formik.values.international)
    formData.append(
      'mappings',
      JSON.stringify(Object.values(omit(formik.values, ['international'])))
    )

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
      cancelToken: newCancelToken(),
    }

    setLoading((prev) => ({...prev, uploadFile: true}))
    try {
      const response = await BatchService.upload(formData, config)
      if (response) {
        handleUploadSuccess()
        handleCloseModal()
      }
    } catch (error: any) {
      if (isCancel(error)) return
      if (error !== null) {
        let errorResponse = error?.response?.data.error
        if (error?.response?.status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
          setErrors((prev) => ({...prev, upload: errorResponse?.fields}))
          setShow((prev) => ({...prev, errors: true}))
        } else if (!isEmpty(errorResponse?.fields?.missing_fields)) {
          setErrors((prev) => ({...prev, missing: errorResponse?.fields?.missing_fields}))
        } else {
          setErrors((prev) => ({...prev, validationErrors: error?.response}))
        }
      }
    } finally {
      setLoading((prev) => ({...prev, uploadFile: false}))
    }
  }

  const handleUpload = (hasUpload) => {
    const {missingValues, duplicateValues} = checkRequiredValues(formik.values, optionsMapping)
    if (isEmpty(missingValues) && isEmpty(duplicateValues)) {
      setErrors((prev) => ({...prev, missing: null, duplicate: null}))
      if (disableSaveCurrentMapping) {
        handleUploadFile()
      } else {
        setShow((prev) => ({...prev, saveTemplate: true, hasUpload: hasUpload}))
      }
    } else {
      if (modalBodyRef.current) {
        modalBodyRef.current.scrollTop = 0
      }
      setErrors((prev) => ({...prev, missing: missingValues, duplicate: duplicateValues}))
    }
  }

  const handleSetAsDefault = async () => {
    try {
      const config = {
        cancelToken: newCancelToken(),
      }

      const payload = {
        batch_mapping_id: templateCurrent?.current?.id,
        default: true,
      }

      setLoading((prev) => ({...prev, setAsDefault: true}))
      const res = await BatchService.setDefaultTemplate(payload, config)
      if (res) {
        getMappingTemplate({applyDefaultTemplate: false})
        toast.success(intl.formatMessage({id: 'THE_TEMPLATE_HAS_BEEN_SUCCESSFULLY_SET_AS_DEFAULT'}))
      }
    } catch (error) {
      if (isCancel(error)) return
    } finally {
      setLoading((prev) => ({...prev, setAsDefault: false}))
    }
  }

  const btnSetAsDefault = useMemo(() => {
    const disabled =
      loading.setAsDefault ||
      templateCurrent.current?.default ||
      isEmpty(mappingTemplate.value) ||
      size(mappingTemplate?.options) < 1
    const active =
      !templateCurrent.current?.default &&
      !loading.setAsDefault &&
      size(mappingTemplate?.options) > 0 &&
      !isEmpty(mappingTemplate.value)

    return {disabled: disabled, active: active}
  }, [loading.setAsDefault, mappingTemplate?.options, mappingTemplate.value])

  const handleClickTemplate = (dataField) => {
    const requiredLength = size(data.columnsCSV)
    let _templateOptions = dataField.value?.slice(0, requiredLength) || []
    if (size(_templateOptions) < requiredLength) {
      _templateOptions = concat(
        _templateOptions,
        Array(requiredLength - size(_templateOptions)).fill('')
      )
    }

    const _optionsMapping = flatMap(renderOptionsMapping(dataField.international), 'options')
    const templateOptions = _templateOptions.reduce((acc, item, index) => {
      acc[`col_${index}`] = find(_optionsMapping, {value: item}) ? item : ''
      return acc
    }, {})
    selectedOptionRef.current = Object.values(_optionsMapping)
    setSelectedOption(Object.values(templateOptions))
    formik.setValues({
      international: dataField.international,
      ...templateOptions,
    })
    templateCurrent.current = {...dataField, value: templateOptions}
    setMappingTemplate((prev) => ({...prev, value: dataField}))
  }

  const handleDeleteFieldSuccess = ({deleteThis}) => {
    if (deleteThis) {
      templateCurrent.current = undefined
      setMappingTemplate((prev) => ({...prev, value: undefined}))
    }
    getMappingTemplate({applyDefaultTemplate: false})
  }

  const handleMapByColumnName = () => {
    formik.setValues({
      international: formik.values.international,
      ...mapByColumnName(formik.values.international),
    })
    const {missingValues, duplicateValues} = checkRequiredValues(
      mapByColumnName(formik.values.international),
      optionsMapping
    )
    selectedOptionRef.current = Object.values(mapByColumnName(formik.values.international))
    setSelectedOption(Object.values(mapByColumnName(formik.values.international)))
    setErrors((prev) => ({...prev, missing: missingValues, duplicate: duplicateValues}))
  }

  const handleClickOptionsCol = (option, key) => {
    if (!!selectedOption.find((col) => col === option.value)) return
    const valueUpdate = update(cloneDeep(selectedOption), key, () => option.value)
    selectedOptionRef.current = valueUpdate
    setSelectedOption(valueUpdate)
  }

  const formatGroupLabel = useCallback(
    (data) =>
      data.label && (
        <div
          onClick={() => formik.setFieldValue('international', !formik.values.international)}
          className='bg-light bg-hover-light-primary text-hover-primary p-3 mx-n3 cursor-pointer'
        >
          <span className='fs-7 text-dark fw-bolder text-capitalize'>{data.label}</span>
        </div>
      ),
    [formik]
  )

  return (
    <>
      {errors.validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setErrors((prev) => ({...prev, validationErrors: undefined}))
          }}
          response={errors.validationErrors}
        />
      )}
      {show.errors && (
        <FileErrorModal
          show={show.errors}
          handleClose={() => setShow((prev) => ({...prev, errors: false}))}
          errors={errors.upload}
        />
      )}
      {show.saveTemplate && (
        <SaveTemplateMappingModal
          show={show.saveTemplate}
          hasUpload={show.hasUpload}
          handleClose={() => {
            setShow((prev) => ({...prev, saveTemplate: false, hasUpload: false}))
          }}
          data={{
            templateCurrent: templateCurrent,
            templateValues: formik.values,
            optionsName: isEmpty(mappingTemplate?.options)
              ? []
              : mappingTemplate?.options.map((option) => option.label),
          }}
          handleUploadFile={handleUploadFile}
          handleGetTemplate={() => getMappingTemplate({applyDefaultTemplate: false})}
        />
      )}
      <Modal
        id='gori_modal_batch_upload_mapping'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-1000px h-auto'
        show={showModal}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              <h2>{intl.formatMessage({id: 'MAP_YOUR_CSV_TO_SHIPBAE'})}</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            className={clsx('vh-75 scroll-y', {'cursor-no-drop': loading.getTemplate})}
            ref={modalBodyRef}
          >
            <Container>
              <div className={clsx({'pe-none opacity-75': loading.getTemplate})}>
                <div className='d-flex mb-8'>
                  <ButtonTemplateMapping
                    loadingParent={{getTemplate: loading.getTemplate}}
                    mappingTemplate={mappingTemplate}
                    handleClickTemplate={handleClickTemplate}
                    handleDeleteFieldSuccess={handleDeleteFieldSuccess}
                  />
                  <div
                    className={clsx(
                      'text-primary bg-hover-light px-2 rounded-2 ms-4 d-flex align-items-center justify-content-center cursor-pointer fw-bolder col-3',
                      {
                        'cursor-no-drop text-gray-600': btnSetAsDefault.disabled,
                      }
                    )}
                    onClick={() => btnSetAsDefault.active && handleSetAsDefault()}
                  >
                    {intl.formatMessage({id: 'SET_THIS_TEMPLATE_AS_DEFAULT'})}
                    {loading.setAsDefault && (
                      <span className='spinner-border spinner-border-sm align-middle ms-2' />
                    )}
                  </div>
                </div>
                <div className='border rounded-2 px-10 py-8'>
                  <div className='d-flex'>
                    <div
                      className='d-flex align-items-center bg-light rounded-2 cursor-pointer bg-hover-light-primary text-hover-primary'
                      onClick={handleMapByColumnName}
                    >
                      <span className='px-3 fw-bold'>
                        {intl.formatMessage({id: 'MAP_BY_COLUMN_NAME'})}
                      </span>
                    </div>
                    <div
                      className={clsx(
                        'text-primary bg-hover-light p-2 rounded-2 ms-3 d-flex align-items-center cursor-pointer fw-bolder',
                        {'cursor-no-drop text-gray-600': disableSaveCurrentMapping}
                      )}
                      onClick={() => {
                        if (!disableSaveCurrentMapping) {
                          handleUpload(false)
                        }
                      }}
                    >
                      {intl.formatMessage({id: 'SAVE_CURRENT_MAPPING'})}
                    </div>
                  </div>
                  {(!isEmpty(errors.missing) || !isEmpty(errors.duplicate)) && (
                    <div className='text-danger my-4'>
                      {!isEmpty(errors.missing) && (
                        <div className='my-2'>
                          {intl.formatMessage(
                            {id: 'REQUIRED_FIELD_IS_MISSING_ERRORS'},
                            {
                              errors: errors.missing
                                .map((error) => intl.formatMessage({id: error.toUpperCase()}))
                                .join(', '),
                            }
                          )}
                        </div>
                      )}
                      {!isEmpty(errors.duplicate) && (
                        <div className='my-2'>
                          {intl.formatMessage(
                            {
                              id: 'YOU_SELECTED_THE_SAME_VALUE_MORE_THAN_ONCE_IN_THE_DROP_DOWN_MENUS_PLEASE_CHECK_YOUR_DROP_DOWN_MENU_SELECTIONS_AND_ENSURE_THAT_YOU_ARE_ONLY_USING_THE_HEADINGS_ONCE_EACH_THE_DUPLICATES_ARE_ERRORS',
                            },
                            {
                              errors: errors.duplicate
                                .map((error) => intl.formatMessage({id: error.toUpperCase()}))
                                .join(', '),
                            }
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  <div className='d-flex mt-10'>
                    <div className='col-6 fw-bold fs-5'>
                      {intl.formatMessage({id: 'COLUMNS_IN_YOUR_CSV'})}
                    </div>
                    <div className='col-6 d-flex justify-content-between fw-bold fs-5'>
                      <span>{intl.formatMessage({id: 'SHIPBAE_FIELDS'})}</span>
                      <InputCheckboxFormik
                        type='checkbox'
                        formik={formik}
                        name='international'
                        label={intl.formatMessage({id: 'INTERNATIONAL'})}
                        labelClassName={'fw-bold fs-5 ms-2'}
                      />
                    </div>
                  </div>
                  <hr className='bg-gray-600' />
                  {!isEmpty(data.columnsCSV) &&
                    data.columnsCSV?.map((col, key) => {
                      return (
                        <div key={key}>
                          <div className='d-flex' key={key}>
                            <SelectFormik
                              className='col-6'
                              labelClassName='col-6 col-form-label fw-bolder fs-5'
                              label={col.label}
                              emptyDefault={false}
                              options={optionsMapping}
                              name={`col_${key}`}
                              formik={formik}
                              checkFormik={false}
                              placeholder={intl.formatMessage({id: 'DO_NOT_MAP'})}
                              onChange={(option) => handleClickOptionsCol(option, key)}
                              formatGroupLabel={formatGroupLabel}
                            />
                          </div>
                          <hr className='bg-gray-600' />
                        </div>
                      )
                    })}
                </div>
                <div className='fw-bold fs-5 mt-6 text-center'>
                  {intl.formatMessage({
                    id: 'IF_NO_UNIT_IS_SELECTED_FOR_WEIGHT_AND_DIMENSIONS_THE_DEFAULT_UNITS_ARE_OUNCES_FOR_WEIGHT_AND_INCHES_FOR_DIMENSIONS',
                  })}
                </div>
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'UPLOAD'})}
                loadingText={intl.formatMessage({id: 'UPLOAD'})}
                event={() => handleUpload(true)}
                loading={loading.uploadFile}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {BatchUploadMappingModal}
