import clsx from 'clsx'
import {cloneDeep, find, isEmpty} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSearchParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {CSSTransition} from 'react-transition-group'
import {RemoveFromBatchModal} from '..'
import {blockPopUp} from '../../../../_gori/helpers/BrowserHelpers'
import {useBatchesProvider} from '../../../../_gori/helpers/components/BatchesProvider'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import UseRoutesPermission from '../../../../_gori/hooks/UseRoutesPermission'
import {
  Button,
  ConfirmActionSwal,
  SelectNoFormik,
  TableHeader,
  ValidationErrorModal,
} from '../../../../_gori/partials/widgets'
import displayConfig from '../../../../displayconfig.json'
import {ActionAllModal} from '../../common'
import {SET_UP_MODAL_ALL} from '../../common/core/_const'
import {
  AssignUserModal,
  CancelOrderModal,
  CheapestServiceModal,
  CombineOrderModal,
  HoldOrderModal,
  MassUpdateModal,
  NoticePrintPackingSlips,
  PresetModal,
  ProcessCheapestServiceModal,
  ProcessEstimatedServiceModal,
  RemoveHoldOrderModal,
  RestoreOrderModal,
  SplitOrderModal,
} from '../../orders'
import {OrderStatus, OrdersConfig} from '../../orders/core/_const'
import {BatchesStatus} from '../core/_const'
import BatchService from '../core/_requests'
import {ShipmentRefund} from '../../shipments/core/_const'

type Props = {
  selectedList?: any
  reloadTable: any
  statistics: any
  dataSettings: any
}
const BatchesDetailFilter: React.FC<Props> = ({
  statistics,
  selectedList,
  reloadTable,
  dataSettings,
}) => {
  const intl = useIntl()
  const {routes} = UseRoutesPermission()
  const {batch} = useBatchesProvider()
  const {newCancelToken, isCancel} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [searchParams] = useSearchParams()

  const [loading, setLoading] = useState<any>({
    loadingPrintLabel: false,
    print_all: false,
    select_print_ids: false,
  })
  const [showModal, setShowModal] = useState<any>({
    create_label: false,
    create_all: false,
    preset: false,
    mass_update: false,
    remove_from_batch: false,
    split_shipments: false,
    combine_shipments: false,
    hold: false,
    remove_hold: false,
    cancel: false,
    restore: false,
    assign_to: false,
    cheapest_service: false,
    process_cheapest_service: false,
    estimated_service: false,
    print_packing_slip: false,
    notice_print_packing_slip: false,
  })
  const [dataModal, setDataModal] = useState<any>({
    create_order_ids: [],
    mass_update: {ids: [], name: undefined},
    print_packing_slip: [],
    notice_print_packing_slip: {},
  })

  useEffect(() => {
    if (!selectedList.selectedMaster) {
      setSelectAll(false)
    }
  }, [selectedList.selected, selectedList.selectedMaster])

  const getIdsByStatus = useCallback(
    (nameStatus) => {
      if (isEmpty(statistics)) return []
      const status: any = cloneDeep(statistics).find((item: any) => item.value === nameStatus)

      let orders: any
      if (!status) {
        orders = cloneDeep(statistics)
          .flatMap((item: any) => item.orders || [])
          .map((order: any) => ({original: order}))
      } else {
        orders = (status?.orders || []).map((order: any) => ({original: order}))
      }

      return orders
    },
    [statistics]
  )

  const filterOrdersByStatus = useCallback(
    (statusList) => {
      if (selectAll) {
        const currentStatus = searchParams.get('status')
        if (!currentStatus) {
          return statusList.flatMap((status) => getIdsByStatus(status))
        } else if (statusList.includes(currentStatus)) {
          return getIdsByStatus(currentStatus)
        }
      } else {
        return cloneDeep(selectedList.selected).flatMap((row) =>
          statusList.includes(row.original.status) ? [row] : []
        )
      }
    },
    [getIdsByStatus, searchParams, selectAll, selectedList.selected]
  )

  const orderIds = useMemo(() => {
    const convertDataRefund = (data) => {
      const dataRefund = data?.map((row) => ({
        ...row,
        original: {...row.original, id: row.original.latest_shipment?.gori_shipment_id},
      }))
      return dataRefund || []
    }

    return {
      create_label: filterOrdersByStatus([OrderStatus.OPEN]),
      print_label: filterOrdersByStatus([OrderStatus.SHIPPED]),
      print_return_label: filterOrdersByStatus([OrderStatus.SHIPPED]),
      print_packing_slip: selectedList.selected ?? [],
      create_all_label:
        find(statistics, {value: OrderStatus.OPEN})?.orders?.map((item) => item.id) ?? [],
      print_all_label:
        find(statistics, {value: OrderStatus.SHIPPED})?.orders?.map((item) => item.id) ?? [],
      mass_update: filterOrdersByStatus([OrderStatus.OPEN, OrderStatus.ON_HOLD]),
      // BEGIN: Other Action
      remove_from_batch: selectedList.selected ?? [],
      split_combine: filterOrdersByStatus([OrderStatus.OPEN]),
      hold: filterOrdersByStatus([OrderStatus.OPEN]),
      remove_hold: filterOrdersByStatus([OrderStatus.ON_HOLD]),
      cancel: filterOrdersByStatus([OrderStatus.OPEN, OrderStatus.ON_HOLD]),
      restore: filterOrdersByStatus([OrderStatus.CANCELLED]),
      assign_to: selectedList.selected ?? [],
      request_refund: convertDataRefund(filterOrdersByStatus([OrderStatus.SHIPPED])),
      // END: Other Action
      cheapest_service: filterOrdersByStatus([OrderStatus.OPEN]),
      estimated_service: filterOrdersByStatus([OrderStatus.OPEN]),
    }
  }, [filterOrdersByStatus, selectedList.selected, statistics])

  const otherActionsOptions: any = useMemo(() => {
    let otherActions: any = []
    otherActions.push(
      {value: 'print_packing_slip', label: intl.formatMessage({id: 'PRINT_PACKING_SLIPS'})},
      {value: 'remove_from_batch', label: intl.formatMessage({id: 'REMOVE_FROM_BATCH'})},
      {value: 'hold', label: intl.formatMessage({id: 'HOLD'})},
      {value: 'remove_hold', label: intl.formatMessage({id: 'REMOVE_HOLD'})},
      {value: 'cancel', label: intl.formatMessage({id: 'CANCEL'})},
      {value: 'restore', label: intl.formatMessage({id: 'RESTORE'})},
      {value: 'request_refund', label: intl.formatMessage({id: 'LABEL_REQUEST_REFUND'})},
      {value: 'assign_to', label: intl.formatMessage({id: 'ASSIGN_TO'})}
    )

    if (displayConfig.items.orders.splitCombine) {
      otherActions.push(
        {value: 'split_shipments', label: intl.formatMessage({id: 'SPLIT_SHIPMENTS'})},
        {value: 'combine_shipments', label: intl.formatMessage({id: 'COMBINE_SHIPMENTS'})}
      )
    }

    const status = selectedList.selected.filter((row) => row.original.status !== OrderStatus.OPEN)
    const split = selectedList.selected.filter((row) => row.original.split_from !== null)
    const combined = selectedList.selected.filter((row) => row.original.children.length > 0)

    otherActions = otherActions.map((item: any) => {
      switch (item.value) {
        case 'print_packing_slip':
          item.disabled = isEmpty(orderIds.print_packing_slip) || selectAll
          if (!routes.SHIPMENTS_PACKING_SLIP.hasPermission) {
            return null
          }
          break
        case 'remove_from_batch':
          item.disabled = isEmpty(orderIds.remove_from_batch) || selectAll
          if (!routes.BATCHES_UPDATE.hasPermission) {
            return null
          }
          break
        case 'split_shipments':
          item.disabled =
            selectedList.selected.length > 1 ||
            (selectedList.selected.length === 1 &&
              selectedList.selected?.at(0)?.original?.order_items.length === 0) ||
            (selectedList.selected.length === 1 &&
              selectedList.selected?.at(0)?.original?.order_items.length === 1 &&
              selectedList.selected?.at(0)?.original?.order_items?.at(0)?.quantity === 1) ||
            status.length !== 0 ||
            split.length !== 0 ||
            combined.length !== 0 ||
            selectAll
          if (!routes.ORDERS_UPDATE.hasPermission) {
            return null
          }
          break
        case 'combine_shipments':
          item.disabled =
            selectedList.selected.length < 2 ||
            status.length !== 0 ||
            combined.length !== 0 ||
            split.length !== 0 ||
            selectAll
          if (!routes.ORDERS_UPDATE.hasPermission) {
            return null
          }
          break
        case 'hold':
          item.disabled = isEmpty(orderIds.hold) || selectAll
          if (!routes.ORDERS_HOLD.hasPermission) {
            return null
          }
          break
        case 'remove_hold':
          item.disabled = isEmpty(orderIds.remove_hold) || selectAll
          if (!routes.ORDERS_HOLD.hasPermission) {
            return null
          }
          break
        case 'cancel':
          item.disabled = isEmpty(orderIds.cancel) || selectAll
          if (!routes.ORDERS_CANCEL.hasPermission) {
            return null
          }
          break
        case 'restore':
          item.disabled = isEmpty(orderIds.restore) || selectAll
          if (!routes.ORDERS_CANCEL.hasPermission) {
            return null
          }
          break
        case 'request_refund':
          item.disabled = isEmpty(orderIds.request_refund) || selectAll
          if (!routes.SHIPMENTS_REFUND.hasPermission) {
            return null
          }
          break
        case 'assign_to':
          item.disabled = selectAll
          if (!routes.ORDERS_ASSIGN.hasPermission) {
            return null
          }
          break
        default:
          return null
      }

      if (batch?.status === BatchesStatus.ARCHIVED) {
        item.disabled = true
      }
      return item
    })

    return otherActions.filter((item) => item !== null)
  }, [intl, batch?.status, orderIds, routes, selectAll, selectedList.selected])

  const handlePrintBatchLabel = useCallback(
    async (selected: any) => {
      let orderIds: any = []
      let refundOrderIds: any = []
      selected.forEach((row: any) => {
        if (
          row.original?.latest_shipment &&
          (row.original.latest_shipment.refund?.status === ShipmentRefund.SUBMITTED ||
            row.original.latest_shipment.refund?.status === ShipmentRefund.REFUNDED)
        ) {
          refundOrderIds.push(row.original.id)
        }
        orderIds.push(row.original.id)
      })
      if (orderIds.length === refundOrderIds.length) {
        toast.warning(
          intl.formatMessage({
            id: 'PRINT_LABELS_REFUNDED',
          })
        )
        return
      }
      if (orderIds.length > 0) {
        setLoading((prev) => ({...prev, loadingPrintLabel: true}))
        await BatchService.print(
          {
            order_ids: orderIds,
          },
          {
            cancelToken: newCancelToken(),
            params: {
              batch: batch.name,
            },
          }
        )
          .then((response) => {
            if (blockPopUp(response.label_url)) {
              toast.warning(
                intl.formatMessage({
                  id: 'POP_UP_IS_BLOCKED',
                })
              )
            }
          })
          .catch((error) => {
            if (isCancel(error)) return
          })
          .finally(() => {
            setLoading((prev) => ({...prev, loadingPrintLabel: false}))
          })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl, isCancel, newCancelToken]
  )

  const handleMassUpdate = useCallback(
    (name) => {
      if (name === 'preset') {
        setShowModal((prev) => ({...prev, preset: true}))
      } else {
        setShowModal((prev) => ({...prev, mass_update: true}))
        setDataModal((prev) => ({
          ...prev,
          mass_update: {
            ids: orderIds.mass_update.map((item: any) => {
              return item.original.id
            }),
            name: name,
          },
        }))
      }
    },
    [orderIds.mass_update]
  )

  const handleRequestRefundAll = useCallback(() => {
    if (isEmpty(orderIds?.request_refund)) return
    setShowModal((prev) => ({...prev, request_refund: true}))
  }, [orderIds.request_refund])

  const handlePrintPackingSlips = () => {
    const {
      packing_slip: {disable},
    } = dataSettings
    if (disable) {
      const configNoticePackingSlip = {
        title: 'PRINT_PACKING_SLIPS',
        content: 'DISABLE_PACKING_SLIPS_MESSAGE',
      }
      setDataModal((prev) => ({
        ...prev,
        notice_print_packing_slip: configNoticePackingSlip,
      }))
      setShowModal((prev) => ({...prev, notice_print_packing_slip: true}))
      return
    }

    setDataModal((prev) => ({
      ...prev,
      print_packing_slip: orderIds.print_packing_slip,
    }))
    setShowModal((prev) => ({...prev, print_packing_slip: true}))
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      {dataModal.action_swal &&
        Object.entries(dataModal.action_swal).map(([key, value]: [any, any], index) => {
          return (
            <ConfirmActionSwal
              key={index}
              show={value.status}
              title={intl.formatMessage({id: 'BATCHES'})}
              message={intl.formatMessage({id: 'ARE_YOU_SURE'})}
              messageCancel={intl.formatMessage({id: 'NO'})}
              handleCallBack={value.callback}
              handleClose={() =>
                setDataModal((prev) => ({
                  ...prev,
                  action_swal: {
                    ...prev.action_swal,
                    [key]: {...prev.action_swal[key], status: false},
                  },
                }))
              }
            />
          )
        })}
      {showModal.create_label && (
        <ActionAllModal
          show={showModal.create_label}
          data={orderIds?.create_label}
          setUpModal={SET_UP_MODAL_ALL.CREATE_LABELS}
          handleClose={() => {
            reloadTable()
            setShowModal((prev) => ({...prev, create_label: false}))
          }}
        />
      )}
      {showModal.print_packing_slip && (
        <ActionAllModal
          show={showModal.print_packing_slip}
          data={dataModal.print_packing_slip}
          setUpModal={SET_UP_MODAL_ALL.PACKING_SLIPS}
          handleClose={() => {
            setShowModal((prev) => ({...prev, print_packing_slip: false}))
          }}
          isSelectAll={selectAll}
        />
      )}
      {/* BEGIN: Mass Update */}
      {showModal.preset && (
        <PresetModal
          show={showModal.preset}
          handleClose={() => setShowModal((prev) => ({...prev, preset: false}))}
          orders={orderIds.mass_update}
          reloadTable={() => reloadTable()}
        />
      )}
      {showModal.mass_update && (
        <MassUpdateModal
          show={showModal.mass_update}
          handleClose={() => setShowModal((prev) => ({...prev, mass_update: false}))}
          data={dataModal.mass_update}
          reloadTable={() => reloadTable()}
        />
      )}
      {/* END: Mass Update */}

      {/* BEGIN: Other Action */}
      {showModal.remove_from_batch && (
        <RemoveFromBatchModal
          show={showModal.remove_from_batch}
          handleClose={() => setShowModal((prev) => ({...prev, remove_from_batch: false}))}
          batch={batch}
          orders={orderIds.remove_from_batch}
          reloadTable={() => reloadTable()}
        />
      )}
      {showModal.assign_to && (
        <AssignUserModal
          show={showModal.assign_to}
          handleClose={() => setShowModal((prev) => ({...prev, assign_to: false}))}
          orders={orderIds.assign_to}
          reloadTable={() => reloadTable()}
        />
      )}
      {showModal.split_shipments && (
        <SplitOrderModal
          show={showModal.split_shipments}
          handleClose={() => setShowModal((prev) => ({...prev, split_shipments: false}))}
          orders={orderIds.split_combine}
          reloadTable={() => reloadTable()}
        />
      )}
      {showModal.combine_shipments && (
        <CombineOrderModal
          show={showModal.combine_shipments}
          handleClose={() => setShowModal((prev) => ({...prev, combine_shipments: false}))}
          orders={orderIds.split_combine}
          reloadTable={() => reloadTable()}
        />
      )}
      {showModal.hold && (
        <HoldOrderModal
          show={showModal.hold}
          handleClose={() => setShowModal((prev) => ({...prev, hold: false}))}
          orders={orderIds.hold}
          reloadTable={() => reloadTable()}
        />
      )}
      {showModal.remove_hold && (
        <RemoveHoldOrderModal
          show={showModal.remove_hold}
          handleClose={() => setShowModal((prev) => ({...prev, remove_hold: false}))}
          orders={orderIds.remove_hold}
          reloadTable={() => reloadTable()}
        />
      )}
      {showModal.cancel && (
        <CancelOrderModal
          show={showModal.cancel}
          handleClose={() => setShowModal((prev) => ({...prev, cancel: false}))}
          orders={orderIds.cancel}
          reloadTable={() => reloadTable()}
        />
      )}
      {showModal.restore && (
        <RestoreOrderModal
          show={showModal.restore}
          handleClose={() => setShowModal((prev) => ({...prev, restore: false}))}
          orders={orderIds.restore}
          reloadTable={() => reloadTable()}
        />
      )}
      {/* END: Other Action */}

      {showModal.cheapest_service && (
        <CheapestServiceModal
          show={showModal.cheapest_service}
          handleClose={() => setShowModal((prev) => ({...prev, cheapest_service: false}))}
          orders={orderIds.cheapest_service}
          confirm={() => setShowModal((prev) => ({...prev, process_cheapest_service: true}))}
        />
      )}
      {showModal.process_cheapest_service && (
        <ProcessCheapestServiceModal
          isSelectAll={selectAll}
          show={showModal.process_cheapest_service}
          data={orderIds.cheapest_service}
          handleClose={() => {
            reloadTable()
            setShowModal((prev) => ({...prev, process_cheapest_service: false}))
          }}
        />
      )}
      {showModal.estimated_service && (
        <ProcessEstimatedServiceModal
          isSelectAll={selectAll}
          show={showModal.estimated_service}
          data={orderIds.estimated_service}
          handleClose={() => {
            reloadTable()
            setShowModal((prev) => ({...prev, estimated_service: false}))
          }}
        />
      )}
      {showModal.request_refund && (
        <ActionAllModal
          show={showModal.request_refund}
          data={orderIds?.request_refund}
          setUpModal={SET_UP_MODAL_ALL.REFUND_REQUEST}
          handleClose={() => {
            reloadTable()
            setShowModal((prev) => ({...prev, request_refund: false}))
          }}
        />
      )}
      {showModal.notice_print_packing_slip && (
        <NoticePrintPackingSlips
          show={showModal.notice_print_packing_slip}
          handleClose={() => setShowModal((prev) => ({...prev, notice_print_packing_slip: false}))}
          configModal={dataModal.notice_print_packing_slip}
        />
      )}

      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <TableHeader className='min-h-1px my-5 d-flex flex-column'>
          <div className='d-flex flex-column gap-3'>
            <div className='d-flex justify-content-between align-items-center'>
              <div
                className={clsx('d-flex justify-content-end align-items-center flex-wrap gap-3', {
                  'd-none': selectedList.selected.length <= 0,
                })}
              >
                <div className='fw-bolder me-5'>
                  <span className='me-2'>
                    {selectAll ? selectedList.total : selectedList.selected.length}
                  </span>
                  {intl.formatMessage({id: 'SELECTED'})}
                </div>
                {!selectAll && selectedList.selectedMaster && (
                  <div
                    onClick={() => setSelectAll(true)}
                    className='fw-bolder me-5 link-blue text-decoration-underline cursor-pointer'
                  >
                    {intl.formatMessage({id: 'SELECT_ALL'})} {selectedList.total}
                  </div>
                )}
                {selectAll && (
                  <div
                    onClick={() => setSelectAll(false)}
                    className='fw-bolder me-5 link-blue text-decoration-underline cursor-pointer'
                  >
                    {intl.formatMessage({id: 'CLEAR_SELECTION'})}
                  </div>
                )}
                {!isEmpty(orderIds.create_label) && routes.SHIPMENTS_CREATE.hasPermission && (
                  <Button
                    className='btn btn-light-primary border border-primary'
                    label={intl.formatMessage({id: 'CREATE_LABEL'})}
                    loadingText={intl.formatMessage({id: 'CREATE_LABEL'})}
                    event={() => setShowModal((prev) => ({...prev, create_label: true}))}
                  />
                )}
                {!isEmpty(orderIds.print_label) && (
                  <Button
                    className='btn btn-light-blue border border-blue'
                    label={intl.formatMessage({id: 'PRINT_LABELS'})}
                    loading={loading.loadingPrintLabel}
                    loadingText={intl.formatMessage({id: 'PRINT_LABELS'})}
                    event={() => handlePrintBatchLabel(orderIds.print_label)}
                    disabled={loading.loadingPrintLabel}
                  />
                )}
                {!isEmpty(orderIds.mass_update) && routes.ORDERS_UPDATE.hasPermission && (
                  <SelectNoFormik
                    emptyDefault={false}
                    className={'w-200px fw-bold fs-5'}
                    value={{label: intl.formatMessage({id: 'MASS_UPDATE'}), value: ''}}
                    options={OrdersConfig.MASS_UPDATE}
                    onChange={(e) => handleMassUpdate(e.value)}
                    hasUseIntl={true}
                  />
                )}
                {!isEmpty(otherActionsOptions) && (
                  <SelectNoFormik
                    emptyDefault={false}
                    className={'w-200px fw-bold fs-5'}
                    value={{label: intl.formatMessage({id: 'OTHER_ACTIONS'}), value: ''}}
                    options={otherActionsOptions}
                    onChange={(e) => {
                      switch (e.value) {
                        case 'print_packing_slip':
                          handlePrintPackingSlips()
                          break
                        case 'request_refund':
                          setDataModal((prev) => ({
                            ...prev,
                            action_swal: {
                              ...prev.action_swal,
                              [e.value]: {
                                status: true,
                                message:
                                  'A_REFUND_WILL_BE_REQUESTED_FOR_THE_LATEST_SHIPMENT_IN_THE_ORDER_WOULD_YOU_LIKE_TO_PROCEED',
                                callback: () => handleRequestRefundAll(),
                              },
                            },
                          }))
                          break
                        default:
                          setShowModal((prev) => ({...prev, [e.value]: true}))
                          break
                      }
                    }}
                  />
                )}
                {!isEmpty(orderIds.cheapest_service) && routes.ORDERS_UPDATE.hasPermission && (
                  <div>
                    <Button
                      className='btn btn-light-warning'
                      label={intl.formatMessage({id: 'CHEAPEST_SERVICE'})}
                      loadingText={intl.formatMessage({id: 'CHEAPEST_SERVICE'})}
                      event={() => setShowModal((prev) => ({...prev, cheapest_service: true}))}
                    />
                  </div>
                )}
                {!isEmpty(orderIds.estimated_service) && routes.ORDERS_UPDATE.hasPermission && (
                  <div>
                    <Button
                      className='btn btn-light-info'
                      label={intl.formatMessage({id: 'ESTIMATE_SERVICE'})}
                      loadingText={intl.formatMessage({id: 'ESTIMATE_SERVICE'})}
                      event={() => setShowModal((prev) => ({...prev, estimated_service: true}))}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </TableHeader>
      </CSSTransition>
    </>
  )
}

export {BatchesDetailFilter}
