export const DEFAULT_PAGE = '1'
export const DEFAULT_PAGE_SIZE = '10'
export const DEFAULT_PAGE_SIZE_BATCH = '25'
export const PAGE_SIZE_MAX = '200'
export const VALID_COLOR = '#14B8A6'
export const INVALID_COLOR = '#f1416c'
export const DEFAULT_COLOR = '#e4e6ef'
export const TABLE_KEY_COLUMN = {
  CAN_CHECK: 'canCheck',
  ACTIONS: 'actions',
  SUB_ACTIONS: 'sub-actions',
}
// Value must be unique
export const CUSTOM_COLUMN_NAME = {
  ORDER_PAGE: {
    ALL: 'ORDER_PAGE_ALL',
    OPEN: 'ORDER_PAGE_OPEN',
    SHIPPED: 'ORDER_PAGE_SHIPPED',
    AWAITING_PAYMENT: 'ORDER_PAGE_AWAITING_PAYMENT',
    ON_HOLD: 'ORDER_PAGE_ON_HOLD',
    CANCELLED: 'ORDER_PAGE_CANCELLED',
  },
  BATCHES_DETAIL_PAGE: {
    ALL: 'BATCHES_DETAIL_PAGE_ALL',
    OPEN: 'BATCHES_DETAIL_PAGE_OPEN',
    SHIPPED: 'BATCHES_DETAIL_PAGE_SHIPPED',
    ON_HOLD: 'BATCHES_DETAIL_PAGE_ON_HOLD',
    CANCELLED: 'BATCHES_DETAIL_PAGE_CANCELLED',
  },
  REPORT: {
    ENDING_BALANCES: 'REPORT_ENDING_BALANCES',
    REBATE: 'REPORT_REBATE',
    SHIPMENT: 'REPORT_SHIPMENT',
    SHIPMENTS_RETURN: 'SHIPMENTS_RETURN',
    TRANSACTIONS: 'REPORT_TRANSACTIONS',
  },
  SHIPMENT_PAGE: 'SHIPMENT_PAGE',
}
export const DEFAULT_DATE_FILTER = {
  ORDERS: 29,
  SHIPMENTS: 29,
  BATCHES: 29,
  CLAIMS: 364,
}

export const CARRIERS = {
  FEDEX: 'fedex',
}

export const SERVICES = {
  DHL_EXPRESS: 'dhl_express',
  UPS_MI: 'ups_mi_expedited',
}
