import React, {useContext, useState} from 'react'
import {OnboardProviderModel} from '../../../app/modules/onboard/core/_models'

const initBatchContextPropsState = {
  onboardActive: undefined,
  setOnboardActive: () => {},
  errors: {label_creation: false},
  setErrors: () => {},
}

const OnboardContext = React.createContext<OnboardProviderModel>(initBatchContextPropsState)
const OnboardProvider: React.FC = ({children}) => {
  const [onboardActive, setOnboardActive] = useState<any>(initBatchContextPropsState)
  const [errors, setErrors] = useState<{label_creation: boolean}>({label_creation: false})

  return (
    <OnboardContext.Provider value={{onboardActive, setOnboardActive, errors, setErrors}}>
      {children}
    </OnboardContext.Provider>
  )
}

function useOnboardProvider() {
  return useContext(OnboardContext)
}

export {OnboardProvider, useOnboardProvider}
