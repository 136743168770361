import {useFormik} from 'formik'
import {cloneDeep, size} from 'lodash'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {KTSVG} from '../../../../_gori/helpers'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../_gori/hooks/UseYupValidate'
import {
  Button,
  ConfirmActionSwal,
  InputTextArea,
  InputTextFormik,
  SelectFormik,
  ValidationErrorModal,
} from '../../../../_gori/partials/widgets'
import {UploadMultipleFile} from '../../claims'
import {
  ClaimsConfig,
  OPTION_CLAIMS_REASON,
  OPTION_ITEM_TYPE,
  OPTION_CLAIMS_REASON_FEDEX,
} from '../core/_const'
import ClaimService from '../core/_requests'
import ShipmentService from '../../shipments/core/_requests'
import UseCancelTokenHasKey from '../../../../_gori/hooks/UseCancelTokenHasKey'
import {CARRIERS} from '../../../../_gori/constants'

type Props = {
  show: boolean
  dataInit?: any
  handleClose: () => void
  handleCreateClaimsSuccess?: any
}

const CreateClaimsModal: React.FC<Props> = ({
  show,
  handleClose,
  dataInit,
  handleCreateClaimsSuccess,
}) => {
  const intl = useIntl()
  let key = useRef<number>(0)
  const {newCancelToken, isCancel} = useCancelToken()
  const {newCancelTokenHasKey, isCancelHasKey} = UseCancelTokenHasKey()
  const {stringYup, arrayRequiredYup, infoYup, claimYup, numberYup} = UseYupValidate()
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const [validationErrors, setValidationErrors] = useState<any>()
  const [dataForm, setDataForm] = useState<any>()
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false)
  const [isGetTrackingNumber, setIsGetTrackingNumber] = useState<boolean>(false)
  const previousCarrierRef = useRef(null)

  const initValidateSchema = useMemo(() => {
    return {
      tracking_number: stringYup(50, 'TRACKING_NUMBER'),
      claim_reason: stringYup(50, 'CLAIM_REASON'),
      items: Yup.object().shape({
        [key.current]: Yup.object().shape({
          claim_item_name: stringYup(70, 'ITEM_NAME'),
          claim_item_type: stringYup(50, 'ITEM_TYPE'),
          claim_item_specific_item_type: claimYup.specificItemType,
          claim_item_price: numberYup.unitPrice(),
          claim_item_qty: numberYup.quantity(),
          claim_item_desc: stringYup(2000, 'ITEM_DESCRIPTION'),
          photos: arrayRequiredYup('PHOTO_OF_ITEM'),
        }),
      }),
      claim_invoice: arrayRequiredYup('PROOF_OF_VALUE_INVOICE'),
      claim_recipient_phone: infoYup.phone("RECIPIENT'S_PHONE"),
      claim_recipient_email: infoYup.email("RECIPIENT'S_EMAIL", false),
      claim_is_replace_package: Yup.boolean(),
      tracking_number_replace: claimYup.replacementTrackingNumber,
    }
  }, [
    stringYup,
    claimYup.specificItemType,
    claimYup.replacementTrackingNumber,
    numberYup,
    arrayRequiredYup,
    infoYup,
  ])
  const [optionClaimsReason, setOptionClaimsReason] = useState(OPTION_CLAIMS_REASON)
  const [validateSchema, setValidateSchema] = useState<any>(initValidateSchema)

  const handleCloseModal = () => {
    handleClose()
    key.current = 0
    formik.resetForm()
    setOptionClaimsReason(OPTION_CLAIMS_REASON)
    previousCarrierRef.current = null
  }

  const validationSchema = Yup.object().shape(validateSchema)
  const formik = useFormik({
    initialValues: {
      tracking_number: '',
      claim_reason: '',
      items: {
        0: {
          show: true,
          claim_item_name: '',
          claim_item_specific_item_type: '',
          claim_item_type: '',
          claim_item_price: '',
          claim_item_qty: '',
          claim_item_desc: '',
          photos: [],
        },
      },
      claim_invoice: [],
      claim_is_replace_package: false,
      claim_is_plan_replace_package: false,
      tracking_number_replace: '',
      claim_recipient_phone: '',
      claim_recipient_email: '',
      ...dataInit,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setDataForm(values)
      setShowConfirmModal(true)
    },
  })

  const onSubmitPhotoOfItem = useCallback(
    (key, values) => {
      formik.setFieldValue(formik.getFieldProps(`items.${key}.photos`).name, values)
    },
    [formik]
  )

  const onSubmitProofOfValue = useCallback(
    (values) => {
      formik.setFieldValue(formik.getFieldProps('claim_invoice').name, values)
    },
    [formik]
  )

  const handleAddNewItem = () => {
    const newValidate = Yup.object().shape({
      ...validateSchema.items.fields,
      [key.current + 1]: cloneDeep(initValidateSchema.items.fields[key.current]),
    })

    setValidateSchema((prev) => {
      return {
        ...prev,
        items: newValidate,
      }
    })

    const newItem = {
      [key.current + 1]: {
        show: true,
        claim_item_name: '',
        claim_item_specific_item_type: '',
        claim_item_type: '',
        claim_item_price: '',
        claim_item_qty: '',
        claim_item_desc: '',
        photos: [],
      },
    }
    formik.setFieldValue(formik.getFieldProps('items').name, {
      ...formik.getFieldProps('items').value,
      ...newItem,
    })
    key.current += 1
  }

  const deleteItemObj = (obj, keyDelete) => {
    const newObject = Object.entries(obj).reduce((initValue, [keyItem, item]) => {
      if (keyItem !== keyDelete) {
        initValue[keyItem] = item
      }
      return initValue
    }, {})

    return newObject
  }

  const handleDeleteItem = (keyDelete) => {
    const objDelete = deleteItemObj(formik.getFieldProps('items').value, keyDelete)
    formik.setFieldValue(formik.getFieldProps('items').name, objDelete)

    const objValidate = deleteItemObj(validateSchema.items.fields, keyDelete)
    setValidateSchema((prev) => {
      return {
        ...prev,
        items: Yup.object().shape(objValidate),
      }
    })
    formik.setTouched({}, false)
  }

  const handleSubmitForm = async () => {
    setIsLoadingForm(true)
    const _dataForm = cloneDeep(dataForm)
    const items = Object.values(_dataForm.items).map((value: any) => {
      if (value.claim_item_type !== ClaimsConfig.ITEM_TYPE_OTHER) {
        value.claim_item_specific_item_type = ''
      }
      return value
    })
    _dataForm.items = items

    try {
      const res = await ClaimService.createClaims(
        {
          ..._dataForm,
          claim_is_replace_package: _dataForm.claim_is_replace_package ? 1 : 0,
          claim_is_plan_replace_package: _dataForm.claim_is_plan_replace_package ? 1 : 0,
        },
        {cancelToken: newCancelToken()}
      )
      if (res) {
        toast.success(intl.formatMessage({id: 'CREATED_SUCCESSFULLY'}))
        handleCloseModal()
        handleCreateClaimsSuccess()
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setIsLoadingForm(false)
    }
  }

  useEffect(() => {
    if (!formik.getFieldProps('claim_is_replace_package').value) {
      formik.setFieldValue(formik.getFieldProps('tracking_number_replace').name, '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.getFieldProps('claim_is_replace_package').value])

  const handleGetShipment = useCallback(async () => {
    try {
      setIsGetTrackingNumber(true)
      const config = {cancelToken: newCancelTokenHasKey('getShipment')}
      const {shipment} = await ShipmentService.get(
        formik.values?.tracking_number.toString(),
        config
      )
      const currentCarrier = shipment?.carrier
      const previousCarrier = previousCarrierRef.current
      if (
        (currentCarrier !== CARRIERS.FEDEX && previousCarrier === CARRIERS.FEDEX) ||
        (currentCarrier === CARRIERS.FEDEX && previousCarrier !== CARRIERS.FEDEX) ||
        previousCarrier === null
      ) {
        formik.setFieldValue('claim_reason', '')
        setOptionClaimsReason(
          currentCarrier === CARRIERS.FEDEX
            ? [...OPTION_CLAIMS_REASON, ...OPTION_CLAIMS_REASON_FEDEX]
            : OPTION_CLAIMS_REASON
        )
      }
      previousCarrierRef.current = currentCarrier
      setIsGetTrackingNumber(false)
    } catch (error: any) {
      if (isCancelHasKey(error)) {
        return
      }
      setIsGetTrackingNumber(false)
      console.error('Error get shipment:', error)
    }
  }, [formik.values, newCancelToken])

  const previousTrackingRef = useRef(formik.values.tracking_number)

  useEffect(() => {
    const currentTrackingNumber = formik.values.tracking_number
    let timer
    if (currentTrackingNumber !== previousTrackingRef.current) {
      const delayedHandleGetShipment = () => {
        timer = setTimeout(() => {
          if (currentTrackingNumber !== '') {
            handleGetShipment()
          }
        }, 1000)
      }
      delayedHandleGetShipment()
      previousTrackingRef.current = currentTrackingNumber
    }

    return () => clearTimeout(timer)
  }, [formik.values, handleGetShipment])

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <ConfirmActionSwal
        show={showConfirmModal}
        title={intl.formatMessage({id: 'START_A_NEW_CLAIM'})}
        message={intl.formatMessage({id: 'ARE_YOU_SURE'})}
        messageCancel={intl.formatMessage({id: 'NO'})}
        handleCallBack={handleSubmitForm}
        handleClose={() => setShowConfirmModal(false)}
      />
      <Modal
        id='gori_modal_create_claim'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-1000px h-auto'
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'CLAIM_FORM'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            <Container>
              {/* Title */}
              <div className='mb-13 text-center'>
                <h1 className='mb-3'>{intl.formatMessage({id: 'FILE_A_CLAIM'})}</h1>
                <div className='text-muted fw-semibold fs-5'>
                  {intl.formatMessage({
                    id: 'TRY_YOUR_BEST_TO_FILL_OUT_THE_INFORMATION_FOR_THE_BEST_RESULT',
                  })}
                </div>
              </div>
              <div className='row mb-8'>
                <div className='col-md-6 mb-1'>
                  <div className='d-flex'>
                    <InputTextFormik
                      className='col-8'
                      labelClassName='col-4 col-form-label'
                      required
                      label={intl.formatMessage({id: 'TRACKING_NUMBER'})}
                      formik={formik}
                      name='tracking_number'
                    />
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='d-flex'>
                    <SelectFormik
                      className='col-8'
                      labelClassName='col-4 col-form-label'
                      label={intl.formatMessage({id: 'CLAIM_REASON'})}
                      placeholder={intl.formatMessage(
                        {id: 'SELECT_A_INPUT'},
                        {input: intl.formatMessage({id: 'CLAIM_REASON'})}
                      )}
                      emptyDefault={false}
                      required
                      options={optionClaimsReason}
                      name='claim_reason'
                      formik={formik}
                      hasUseIntl={true}
                      disabled={isGetTrackingNumber}
                    />
                  </div>
                </div>
              </div>
              {/* Items Detail */}
              <div className='mt-15 mb-5'>
                <h3>{intl.formatMessage({id: 'ITEM_DETAILS'})}</h3>
                {/* <hr /> */}
              </div>
              {Object.entries(formik.getFieldProps('items').value).map(([key, item], index) => {
                return (
                  <div key={key} className='bg-light px-10 pt-5 pb-2 mb-4 rounded'>
                    <div className='d-flex align-items-center justify-content-between mb-5'>
                      <span className='fs-6 fw-bolder'>
                        {intl.formatMessage({id: 'ITEM'})} {index + 1}
                      </span>
                      <div className='d-flex align-items-center'>
                        {size(formik.getFieldProps('items').value) >
                          ClaimsConfig.SHOW_DELETE_ITEM && (
                          <div
                            className='pe-3 text-danger fw-bold cursor-pointer text-decoration-underline'
                            onClick={() => handleDeleteItem(key)}
                          >
                            {intl.formatMessage({id: 'DELETE_ITEM'})}
                          </div>
                        )}
                        <div
                          className='btn btn-sm btn-icon btn-white btn-active-light opacity-75 cursor-pointer rounded-circle'
                          onClick={() =>
                            formik.setFieldValue(
                              formik.getFieldProps(`items.${key}.show`).name,
                              !formik.getFieldProps(`items.${key}.show`).value
                            )
                          }
                        >
                          {formik.getFieldProps(`items.${key}.show`).value ? (
                            <KTSVG path='/media/gori/claims/down.svg' className='svg-icon-2' />
                          ) : (
                            <KTSVG path='/media/gori/claims/left.svg' className='svg-icon-2' />
                          )}
                        </div>
                      </div>
                    </div>
                    {formik.getFieldProps(`items.${key}.show`).value && (
                      <>
                        <div className='row mb-5'>
                          <div className='col-md-6'>
                            <div className='d-flex mb-5'>
                              <InputTextFormik
                                className='col-8'
                                labelClassName='col-4 col-form-label'
                                required
                                label={intl.formatMessage({id: 'ITEM_NAME'})}
                                formik={formik}
                                name={`items.${key}.claim_item_name`}
                              />
                            </div>
                            <div className='d-flex mb-5'>
                              <InputTextFormik
                                type={'number'}
                                min={0}
                                placeholder='$'
                                className='col-8'
                                labelClassName='col-4 col-form-label'
                                required
                                label={intl.formatMessage({id: 'UNIT_PRICE'})}
                                formik={formik}
                                name={`items.${key}.claim_item_price`}
                                isCurrency
                              />
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='d-flex mb-5'>
                              <SelectFormik
                                className='col-8'
                                labelClassName='col-4 col-form-label'
                                label={intl.formatMessage({id: 'ITEM_TYPE'})}
                                placeholder={intl.formatMessage(
                                  {id: 'SELECT_A_INPUT'},
                                  {input: intl.formatMessage({id: 'ITEM_TYPE'})}
                                )}
                                emptyDefault={false}
                                required
                                options={OPTION_ITEM_TYPE}
                                formik={formik}
                                name={`items.${key}.claim_item_type`}
                                hasUseIntl={true}
                              />
                            </div>
                            {formik.getFieldProps(`items.${key}.claim_item_type`).value ===
                              ClaimsConfig.ITEM_TYPE_OTHER && (
                              <div className='d-flex mb-5'>
                                <InputTextFormik
                                  className='col-8'
                                  labelClassName='col-4 col-form-label'
                                  required
                                  label={intl.formatMessage({id: 'SPECIFIC_ITEM_TYPE'})}
                                  formik={formik}
                                  name={`items.${key}.claim_item_specific_item_type`}
                                />
                              </div>
                            )}

                            <div className='d-flex'>
                              <InputTextFormik
                                type={'number'}
                                min={0}
                                isInteger
                                className='col-8'
                                labelClassName='col-4 col-form-label'
                                required
                                label={intl.formatMessage({id: 'QUANTITY'})}
                                formik={formik}
                                name={`items.${key}.claim_item_qty`}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='row mb-5'>
                          <InputTextArea
                            className='col-12'
                            labelClassName='col-form-label'
                            label={intl.formatMessage({id: 'ITEM_DESCRIPTION'})}
                            formik={formik}
                            name={`items.${key}.claim_item_desc`}
                            required
                          />
                        </div>
                        <div className='row mb-5'>
                          <UploadMultipleFile
                            label={intl.formatMessage({
                              id:
                                formik.getFieldProps('claim_reason').value === 'damage'
                                  ? 'PHOTO_OF_DAMAGED_ITEM'
                                  : 'PHOTO_OF_ITEM',
                            })}
                            required
                            onSubmit={(value) => onSubmitPhotoOfItem(key, value)}
                            formik={formik}
                            name={`items.${key}.photos`}
                          />
                        </div>
                      </>
                    )}
                  </div>
                )
              })}
              {size(formik.getFieldProps('items').value) < ClaimsConfig.SHOW_ADD_NEW_ITEM && (
                <div
                  className='btn btn-light d-grid d-flex justify-content-center'
                  onClick={handleAddNewItem}
                >
                  <KTSVG path={'/media/gori/claims/add-item.svg'} className='svg-icon-3' />
                  {intl.formatMessage({id: 'ADD_NEW_ITEM'})}
                </div>
              )}
              {/* Purchase Information */}
              <div className='mt-15 mb-5'>
                <h3>{intl.formatMessage({id: 'PURCHASE_INFORMATION'})}</h3>
                {/* <hr /> */}
              </div>
              <div className='row mb-10'>
                <UploadMultipleFile
                  label={intl.formatMessage({id: 'PROOF_OF_VALUE_INVOICE'})}
                  required
                  onSubmit={onSubmitProofOfValue}
                  formik={formik}
                  name={'claim_invoice'}
                />
              </div>
              {/* Replacement Package Information */}
              <div className='mt-15 mb-5'>
                <h3>{intl.formatMessage({id: 'REPLACEMENT_PACKAGE_INFORMATION'})}</h3>
                {/* <hr /> */}
              </div>
              <div className='d-flex align-items-center mb-5'>
                <span className='fs-6 fw-semibold text-gray-800 d-block'>
                  {intl.formatMessage({id: 'HAS_A_REPLACEMENT_PACKAGE_BEEN_SHIPPED'})}
                </span>
                <label className='form-check form-switch form-check-custom form-check-solid px-5'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    checked={formik.getFieldProps('claim_is_replace_package').value}
                    value={formik.getFieldProps('claim_is_replace_package').value}
                    onChange={formik.handleChange}
                    name='claim_is_replace_package'
                  />
                </label>
              </div>
              {formik.getFieldProps('claim_is_replace_package').value ? (
                <div className='d-flex'>
                  <InputTextFormik
                    className='col-9'
                    labelClassName='col-3 col-form-label'
                    label={intl.formatMessage({id: 'REPLACEMENT_TRACKING_NUMBER'})}
                    required
                    formik={formik}
                    name={'tracking_number_replace'}
                  />
                </div>
              ) : (
                <div className='d-flex align-items-center mb-5'>
                  <span className='fs-6 fw-semibold text-gray-800 d-block'>
                    {intl.formatMessage({id: 'DO_YOU_PLAN_ON_SHIPPING_A_RE_PLACEMENT_PACKAGE'})}
                  </span>
                  <label className='form-check form-switch form-check-custom form-check-solid px-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={formik.getFieldProps('claim_is_plan_replace_package').value}
                      value={formik.getFieldProps('claim_is_plan_replace_package').value}
                      onChange={formik.handleChange}
                      name='claim_is_plan_replace_package'
                    />
                  </label>
                </div>
              )}
              {/* Recipient Contacts */}
              <div className='mt-15 mb-5'>
                <h3>{intl.formatMessage({id: 'RECIPIENT_CONTACTS'})}</h3>
                {/* <hr /> */}
              </div>
              <div className='row mb-5'>
                <div className='col-md-6 mb-1'>
                  <div className='d-flex'>
                    <InputTextFormik
                      className='col-8'
                      labelClassName='col-4 col-form-label'
                      required
                      label={intl.formatMessage({id: "RECIPIENT'S_PHONE"})}
                      formik={formik}
                      name='claim_recipient_phone'
                    />
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='d-flex'>
                    <InputTextFormik
                      className='col-8'
                      labelClassName='col-4 col-form-label'
                      label={intl.formatMessage({id: "RECIPIENT'S_EMAIL"})}
                      formik={formik}
                      name='claim_recipient_email'
                    />
                  </div>
                </div>
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'CREATE'})}
                loadingText={intl.formatMessage({id: 'CREATE'})}
                disabled={isLoadingForm}
                event={formik.submitForm}
                loading={isLoadingForm}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {CreateClaimsModal}
