import clsx from 'clsx'
import {useFormik} from 'formik'
import {isEqual} from 'lodash'
import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../../../_gori/hooks/UseYupValidate'
import {
  Button,
  InputEmailFormik,
  InputTextFormik,
  ValidationErrorModal,
} from '../../../../../../_gori/partials/widgets'
import {InputCheckboxFormik} from '../../../../../../_gori/partials/widgets/form/InputCheckboxFormik'
import {ConfigGeneral} from '../../../core/_const'
import SettingsService from '../../../core/_requests'
import {InputInternationalPhoneFormik} from '../../../../../../_gori/partials/widgets/form/InputInternationalPhoneFormik'

type Props = {
  show: boolean
  handleClose: () => void
  data?: any
  handleReload: any
}

const LowBalanceAlertModal: React.FC<Props> = ({show, handleClose, data, handleReload}) => {
  const intl = useIntl()
  const {infoYup, numberYup} = UseYupValidate()
  const {newCancelToken} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()

  const validationSchema = Yup.object().shape({
    status: Yup.boolean(),
    threshold: Yup.number().when('status', {
      is: (val) => val === true,
      then: numberYup.amount('THRESHOLD'),
    }),
    // phone: Yup.string().when('status', {
    //   is: (val) => val,
    //   then: infoYup.phone('PHONE'),
    // }),
    emails: Yup.array().when('status', {
      is: (val) => val,
      then: Yup.array()
        .min(
          1,
          intl.formatMessage({id: 'INPUT_IS_REQUIRED'}, {input: intl.formatMessage({id: 'EMAIL'})})
        )
        .max(
          ConfigGeneral.MAX_EMAIL_NUMBER,
          intl.formatMessage(
            {id: 'ONLY_UP_TO_INPUT_EMAIL_ADDRESSES_ARE_ALLOWED'},
            {input: ConfigGeneral.MAX_EMAIL_NUMBER}
          )
        ),
    }),
    email: Yup.string().when('status', {
      is: (val) => val,
      then: infoYup.email('EMAIL', false).test({
        name: 'unique',
        test: function (value) {
          const emails = this.parent.emails || []
          return !emails?.map((item) => item?.toLowerCase()).includes(value?.toLowerCase())
        },
        message: intl.formatMessage(
          {id: 'INPUT_ALREADY_EXISTS'},
          {input: intl.formatMessage({id: 'EMAIL'})}
        ),
      }),
    }),
  })

  const formik = useFormik({
    initialValues: {
      threshold: data?.value?.threshold ?? 0,
      status: data?.status ?? false,
      emails: data?.receiver?.email?.filter((item) => item) ?? [],
      email: '',
      // phone: data?.receiver?.phone ?? '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      try {
        setSubmitting(true)
        const arrCurrentEmail = values.emails
        const initEmail = data?.receiver?.email
        const config = {cancelToken: newCancelToken()}
        const payload = {
          category: data?.category ?? ConfigGeneral.NOTIFICATION_CATEGORY.METER,
          type: data?.type ?? ConfigGeneral.NOTIFICATION_TYPE.EMAIL,
          status: values.status,
          receiver: {
            // phone: !values.status
            //   ? data?.receiver?.phone
            //   : values.phone.startsWith('+')
            //   ? values.phone
            //   : '+' + values.phone,
            email:
              !isEqual(initEmail, arrCurrentEmail) && !values.status
                ? data?.receiver?.email
                : arrCurrentEmail,
          },
          value: {
            threshold: values.threshold,
          },
        }
        const res = data?.id
          ? await SettingsService.updateNotification(data?.id, payload, config)
          : await SettingsService.createNotification(payload, config)
        if (res) {
          toast.success(intl.formatMessage({id: res.message}))
          handleReload()
          handleCloseModal()
        }
      } catch (error: any) {
        setValidationErrors(error?.response)
      } finally {
        setSubmitting(false)
      }
    },
  })

  const handleCloseModal = () => {
    handleClose()
    formik.resetForm()
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}

      <Modal
        id='gori_modal_change_password'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-600px h-auto'
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'LOW_BALANCE_ALERTS'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            <div className='d-flex justify-content-between'>
              <p className='form-label'>{intl.formatMessage({id: 'ALERT'})}</p>
              <InputCheckboxFormik
                formik={formik}
                className='form-switch ms-9 mt-1'
                type='checkbox'
                name='status'
                disabled={formik.isSubmitting}
              />
            </div>
            {formik.values.status && (
              <>
                <div className={clsx('my-8')}>
                  <InputTextFormik
                    labelClassName={'form-label'}
                    label={intl.formatMessage({id: 'THRESHOLD'})}
                    className='col-12'
                    formik={formik}
                    name='threshold'
                    type='number'
                    isInteger
                    placeholder='1000'
                    disabled={formik.isSubmitting}
                    max={10000000}
                    min={0}
                    required
                    checkFormik={formik.values.status}
                    configAppend={{
                      name: '$',
                      position: 'left',
                      classInput: 'ps-8',
                    }}
                  />
                </div>
                <InputEmailFormik
                  label={intl.formatMessage({id: 'EMAIL_TO'})}
                  required={true}
                  formik={formik}
                  maxEmailNumber={ConfigGeneral.MAX_EMAIL_NUMBER}
                  configAppend={{
                    name: intl.formatMessage({id: 'ADD'}),
                    position: 'right',
                    classInput: 'pe-25',
                    hasEvent: true,
                  }}
                />
                {/*<InputInternationalPhoneFormik*/}
                {/*  labelClassName={'form-label mt-4 pe-25'}*/}
                {/*  className='col-8'*/}
                {/*  required={true}*/}
                {/*  label={intl.formatMessage({id: 'PHONE'})}*/}
                {/*  formik={formik}*/}
                {/*  checkFormik={formik.values.status}*/}
                {/*  name='phone'*/}
                {/*/>*/}
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
                disabled={formik.isSubmitting}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'SAVE'})}
                loadingText={intl.formatMessage({id: 'SAVE'})}
                disabled={formik.isSubmitting}
                event={formik.submitForm}
                loading={formik.isSubmitting}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {LowBalanceAlertModal}
