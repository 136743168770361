import {useCallback, useEffect, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import {LoadingPage} from '../../../_gori/helpers/components/LoadingPage'
import useCancelToken from '../../../_gori/hooks/UseCancelToken'
import AuthService from '../../modules/auth/core/_requests'
import {useAuth} from '../../modules/auth'

export function RetoolLogin() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const {newCancelToken, isCancel} = useCancelToken()
  const {saveAuth, handleSaveCurrentUser} = useAuth()
  const [loading, setLoading] = useState(false)

  const handleSaveCache = useCallback(async () => {
    try {
      setLoading(true)
      const config = {cancelToken: newCancelToken()}
      const payload = {token: token}

      const retoolLogin = await AuthService.retoolLogin(payload, config)
      if (retoolLogin) {
        saveAuth(retoolLogin?.token)
        handleSaveCurrentUser(retoolLogin?.users)
        navigate('/dashboard')
      }
    } catch (e) {
      if (isCancel(e)) return
      navigate('/logout')
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (token) {
      handleSaveCache()
    } else {
      navigate('/logout')
    }
  }, [handleSaveCache, navigate, token])

  return (
    <>
      <CSSTransition appear in timeout={100} classNames='fade' unmountOnExit>
        <>{loading && <LoadingPage />}</>
      </CSSTransition>
    </>
  )
}
