import {useFormik} from 'formik'
import {find, isEmpty} from 'lodash'
import React, {useEffect, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_gori/helpers'
import {useStatesGlobal} from '../../../../../../_gori/helpers/components/StatesGlobalProvider'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import {Button, SelectFormik, ValidationErrorModal} from '../../../../../../_gori/partials/widgets'
import {SettingsConfig} from '../../../core/_const'
import SettingsService from '../../../core/_requests'

type Props = {
  show: boolean
  handleClose: () => void
  reloadTable: any
  dataPresetDefault?: any
}

const DefaultPresetModal: React.FC<Props> = ({
  show,
  handleClose,
  dataPresetDefault,
  reloadTable,
}) => {
  const intl = useIntl()
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false)
  const {newCancelToken, isCancel} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()
  const {statesGlobal, getPresets} = useStatesGlobal()
  const presets = statesGlobal.presets

  const handleCloseModal = () => {
    handleClose()
  }

  useEffect(() => {
    getPresets()

    return () => {}
  }, [getPresets])

  const optionsPresets = useMemo(() => {
    if (isEmpty(presets)) return []
    return presets.map((preset) => {
      let option: any = {}
      option.label = preset.name
      option.value = preset.id
      return option
    })
  }, [presets])

  const initValue = useMemo(() => {
    if (isEmpty(dataPresetDefault)) {
      return Object.values(SettingsConfig.DEFAULT_PRESETS).reduce((_initValue, field) => {
        _initValue[field.value] = null
        return _initValue
      }, {})
    } else {
      return dataPresetDefault?.reduce((defaultPreset: any, {order_type, preset_id}) => {
        defaultPreset[order_type] = find(presets, {id: preset_id})?.id || null
        return defaultPreset
      }, {})
    }
  }, [dataPresetDefault, presets])

  const formik = useFormik({
    initialValues: initValue,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const payload = Object.entries(values).reduce((payload: any, [key, value]) => {
        if (value) {
          payload.push({
            order_type: key,
            preset_id: value,
          })
        }
        return payload
      }, [])

      try {
        setIsLoadingForm(true)
        const res = await SettingsService.setDefaultPreset(payload, {
          cancelToken: newCancelToken(),
        })
        if (res) {
          toast.success(intl.formatMessage({id: 'UPDATED_SUCCESSFULLY'}))
          handleCloseModal()
          reloadTable()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setIsLoadingForm(false)
      }
    },
  })

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <Modal
        id='gori_modal_cancel_order'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-800px h-auto'
        show={show}
        backdrop='static'
        onHide={() => {
          handleClose()
        }}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'DEFAULT_PRESETS'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='mx-10 my-5 d-flex flex-column justify-content-center fs-4'>
              <div className='mb-4 d-flex'>
                <div className='col-3'></div>
                <div className='col-9 text-gray-600 fw-bold text-uppercase ms-2'>
                  {intl.formatMessage({id: 'DEFAULT_PRESET'})}
                </div>
              </div>
              {Object.values(SettingsConfig.DEFAULT_PRESETS).map((field) => (
                <div className='mb-8 d-flex'>
                  <SelectFormik
                    className='col-9'
                    placeholder={intl.formatMessage({id: 'CHOOSE_PRESET'})}
                    labelClassName='col-3 col-form-label'
                    label={
                      <div>
                        <img
                          alt={field.label}
                          src={toAbsoluteUrl(`/media/gori/settings/${field.value}.png`)}
                          className={'h-15px me-2'}
                        />
                        {field.label}
                      </div>
                    }
                    options={optionsPresets}
                    formik={formik}
                    name={field.value}
                    isClearValue
                    checkFormik={false}
                  />
                </div>
              ))}
              <div className='fs-6 d-flex align-items-center'>
                <KTSVG path='/media/gori/settings/errorsPreset.svg' className='me-3' />
                {intl.formatMessage({
                  id: 'THE_DEFAULT_PRESET_WILL_OVERWRITE_THE_SHIPPING_INFORMATION',
                })}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
                disabled={isLoadingForm}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'SAVE'})}
                loadingText={intl.formatMessage({id: 'SAVE'})}
                event={formik.handleSubmit}
                loading={isLoadingForm}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {DefaultPresetModal}
