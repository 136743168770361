import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {cloneDeep, isEmpty} from 'lodash'
import {Button, ValidationErrorModal} from '../../../../../_gori/partials/widgets'
import {KTSVG} from '../../../../../_gori/helpers'
import {CreateNewTagModal, DeleteTagModal, EditTagModal} from '../../../orders'

type Props = {
  show: boolean
  dataInit: any
  handleClose: () => void
  reloadOptionsTag: any
}

const ManageTagModal: React.FC<Props> = ({show, dataInit = [], handleClose, reloadOptionsTag}) => {
  const intl = useIntl()
  const [validationErrors, setValidationErrors] = useState<any>()

  const [showModal, setShowModal] = useState<{
    create: boolean
    edit: boolean
    delete: boolean
  }>({
    create: false,
    edit: false,
    delete: false,
  })
  const [dataModal, setDataModal] = useState<{
    edit: any
    delete: any
  }>({
    edit: null,
    delete: null,
  })
  const [data, setData] = useState(dataInit)

  const handleCloseModal = () => {
    reloadOptionsTag(data)
    handleClose()
  }

  const handleActionTag = (actionName, item?: any) => {
    setShowModal((prev) => ({...prev, [actionName]: true}))
    switch (actionName) {
      case 'edit':
        setDataModal((prev) => ({...prev, [actionName]: item}))
        break
      case 'delete':
        setDataModal((prev) => ({...prev, [actionName]: item}))
        break
    }
  }

  const handleActionSuccess = (key, newValue) => {
    let _data = cloneDeep(data)

    switch (key) {
      case 'create':
        _data = [newValue, ...data]
        break
      case 'edit':
        _data = data.map((item) => {
          if (item.id === newValue.id) {
            item['name'] = newValue.name
            item['color'] = newValue.color
          }
          return item
        })
        break
      case 'delete':
        _data = data.filter((item) => item.id !== newValue)
        break
    }
    setData(_data?.sort((a, b) => b - a))
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      {showModal.create && (
        <CreateNewTagModal
          show={showModal.create}
          dataInit={data}
          handleClose={() => setShowModal((prev) => ({...prev, create: false}))}
          handleActionSuccess={handleActionSuccess}
        />
      )}
      {showModal.edit && (
        <EditTagModal
          show={showModal.edit}
          dataInit={data}
          dataModal={dataModal.edit}
          handleClose={() => setShowModal((prev) => ({...prev, edit: false}))}
          handleActionSuccess={handleActionSuccess}
        />
      )}
      {showModal.delete && (
        <DeleteTagModal
          show={showModal.delete}
          dataModal={dataModal.delete}
          handleClose={() => setShowModal((prev) => ({...prev, delete: false}))}
          handleActionSuccess={handleActionSuccess}
        />
      )}

      {!showModal.create && !showModal.edit && !showModal.delete && (
        <Modal
          id='gori_modal_manage_tags'
          tabIndex={-1}
          aria-hidden='true'
          centered
          dialogClassName='mw-650px h-auto'
          show={show}
          backdrop='static'
          onHide={handleCloseModal}
        >
          <div className='modal-content'>
            <Modal.Header closeButton>
              <Modal.Title bsPrefix={'fw-bolder fs-1'}>
                {intl.formatMessage({id: 'MANAGE_TAGS'})}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className='vh-75 scroll-y'>
              <Container className='d-flex h-100 flex-column justify-content-between'>
                <span className='fs-5 text-gray-600 fw-bold'>
                  {intl.formatMessage({id: 'TAG_LIST'})}
                </span>
                <div>
                  {isEmpty(data) ? (
                    <div className='text-center fs-3 text-gray-600'>No Tag</div>
                  ) : (
                    data.map((item, index) => {
                      return (
                        <div key={index} className='d-flex align-items-center my-2'>
                          <div className='border border-2 rounded-2 flex-fill p-3'>
                            <KTSVG
                              path='/media/gori/orders/tag.svg'
                              className='svg-icon-2 me-2'
                              style={{color: item.color}}
                            />
                            {item.name}
                          </div>
                          <div className='d-flex align-items-center'>
                            <div
                              className={clsx('text-hover-primary cursor-pointer me-2 ms-4')}
                              onClick={() => handleActionTag('edit', item)}
                            >
                              <KTSVG
                                path='/media/gori/orders/edit.svg'
                                className='svg-icon-2'
                                small={false}
                              />
                            </div>
                            <div
                              className={clsx('text-hover-danger cursor-pointer')}
                              onClick={() => handleActionTag('delete', item)}
                            >
                              <KTSVG
                                path='/media/gori/orders/empty.svg'
                                className='svg-icon-2'
                                small={false}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    })
                  )}
                </div>
                <div className='d-flex justify-content-center mt-2'>
                  <Button
                    className='w-100 btn btn-light-primary'
                    label={`+ ${intl.formatMessage({id: 'CREATE_NEW_TAG'})}`}
                    loadingText={`+ ${intl.formatMessage({id: 'CREATE_NEW_TAG'})}`}
                    event={() => handleActionTag('create')}
                  />
                </div>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <div className='d-flex justify-content-end'>
                <Button
                  className='btn btn-secondary me-2'
                  label={intl.formatMessage({id: 'CLOSE'})}
                  loadingText={intl.formatMessage({id: 'CLOSE'})}
                  event={handleCloseModal}
                />
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      )}
    </>
  )
}

export {ManageTagModal}
