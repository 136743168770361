import axios from 'axios'
import {Dispatch, FC, createContext, useContext, useEffect, useRef, useState} from 'react'
import {SwapperComponent} from '../../../../_gori/assets/ts/components'
import {StorageHelpers} from '../../../../_gori/helpers'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import {useLanguage} from '../../../../_gori/i18n/Metronici18n'
import {LayoutSplashScreen} from '../../../../_gori/layout/core'
import UserService from '../../users/core/_requests'
import * as authHelper from './AuthHelpers'
import {AccountModel, AuthModel, CompanyModel, UserModel} from './_models'

const SIGNATURE_ACCESS_KEY = process.env.REACT_APP_SIGNATURE_ACCESS_KEY
const CURRENT_COMPANY_STORAGE =
  process.env.REACT_APP_CURRENT_COMPANY_STORAGE || 'CURRENT_COMPANY_STORAGE'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: (currentUser: UserModel | undefined) => void
  currentCompany: CompanyModel | undefined
  setCurrentCompany: (currentCompany: CompanyModel | undefined) => void
  currentAccount: AccountModel | undefined
  setCurrentAccount: (currentAccount: AccountModel | undefined) => void
  logout: () => void
  loadingSwitch: boolean
  setLoadingSwitch: Dispatch<boolean>
  handleSaveCurrentUser: (currentUser: UserModel | undefined) => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  currentCompany: undefined,
  setCurrentCompany: () => {},
  currentAccount: undefined,
  setCurrentAccount: () => {},
  logout: () => {},
  loadingSwitch: false,
  setLoadingSwitch: () => {},
  handleSaveCurrentUser: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC = ({children}) => {
  const {setLanguage} = useLanguage()
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [loadingSwitch, setLoadingSwitch] = useState(false)
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>(undefined)
  const [currentCompany, setCurrentCompany] = useState<CompanyModel | undefined>(undefined)
  const [currentAccount, setCurrentAccount] = useState<AccountModel | undefined>(undefined)

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const handleSaveCurrentUser = (currentUser: UserModel | undefined) => {
    if (!currentUser) return
    axios.defaults.headers.common[`${SIGNATURE_ACCESS_KEY}`] =
      currentUser?.current_account?.encrypt_key
    StorageHelpers.setItemLocalStorage(CURRENT_COMPANY_STORAGE, currentUser?.current_account)
    setLanguage(currentUser?.user_profiles?.general?.language)
    setCurrentUser(currentUser)
    setCurrentCompany(currentUser?.company)
    setCurrentAccount(currentUser?.current_account)
  }

  const logout = () => {
    SwapperComponent.removeInstances() // fix title header
    saveAuth(undefined)
    setLoadingSwitch(false)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        currentCompany,
        setCurrentCompany,
        currentAccount,
        setCurrentAccount,
        logout,
        loadingSwitch,
        setLoadingSwitch,
        handleSaveCurrentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC = ({children}) => {
  const {auth, logout, handleSaveCurrentUser} = useAuth()
  const {newCancelToken, isCancel} = useCancelToken()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const config = {cancelToken: newCancelToken()}

    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const currentAccount = StorageHelpers.getItemLocalStorage(CURRENT_COMPANY_STORAGE)
          if (currentAccount) {
            axios.defaults.headers.common[`${SIGNATURE_ACCESS_KEY}`] = currentAccount?.encrypt_key
          }
          const user = await UserService.getUser(config)
          if (user) {
            handleSaveCurrentUser(user)
          }
        }
      } catch (error) {
        if (isCancel(error)) return
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.access_token) {
      requestUser()
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthInit, AuthProvider, useAuth}
