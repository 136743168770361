import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {toAbsoluteUrl} from '../../../../../../_gori/helpers'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import {Button, ValidationErrorModal} from '../../../../../../_gori/partials/widgets'
import SettingsService from '../../../core/_requests'

type Props = {
  show: boolean
  handleClose: () => void
  data: any
  reloadTable: () => {}
}

const RemoveStoreModal: React.FC<Props> = ({show, handleClose, data, reloadTable}) => {
  const intl = useIntl()
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false)
  const {newCancelToken, isCancel} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()

  const handleCloseModal = () => {
    handleClose()
  }

  const handleRemoveStore = async () => {
    setIsLoadingForm(true)
    try {
      const {message} = await SettingsService.removeStore({
        params: {
          store_type: data.store_type,
          store_id: data.id,
        },
        cancelToken: newCancelToken(),
      })
      if (message) {
        toast.success(intl.formatMessage({id: message}))
        handleCloseModal()
        reloadTable()
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setIsLoadingForm(false)
    }
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <Modal
        id='gori_modal_cancel_order'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-600px h-auto'
        show={show}
        backdrop='static'
        onHide={() => {
          handleClose()
        }}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'REMOVE_STORE'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='mx-10 my-5 d-flex flex-column justify-content-center align-items-center fs-4'>
              <div className='mb-5'>
                <img src={toAbsoluteUrl('/media/gori/dashboard/error_balance.svg')} alt='img' />
              </div>
              <div>
                <span>{intl.formatMessage({id: 'DO_YOU_WANT_TO_REMOVE'})}</span>{' '}
                <span className='fw-bolder'>{data.store_name} ?</span>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
                disabled={isLoadingForm}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'REMOVE'})}
                loadingText={intl.formatMessage({id: 'REMOVE'})}
                event={handleRemoveStore}
                loading={isLoadingForm}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {RemoveStoreModal}
