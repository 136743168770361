import {IntegrationsPageLayout} from '../../../_gori/layout/components/IntegrationsPagesLayout'

export function ShipStationIntegration() {
  return (
    <>
      <IntegrationsPageLayout
        pageClass='shipstation-integration'
        heading='SHIPSTATION_HEADER'
        headingSubtext='SHIPSTATION_SUBTEXT'
        integrationLogo='/media/gori/integrations/shipstation/shipstation-logo.svg'
        integrationLogoAlt='shipstation-logo'
        integrateImg='/media/gori/integrations/shipstation/integrate.webp'
        card1Copy='SHIPSTATION_CARD_1'
        card2Copy='SHIPSTATION_CARD_2'
        card3Copy='SHIPSTATION_CARD_3'
        card4Copy='SHIPSTATION_CARD_4'
      />
    </>
  )
}
