import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_gori/helpers'
import {useIntl} from 'react-intl'

export function WhatIsShipbae() {
  const intl = useIntl()

  return (
    <section className='what-is-shipbae overflow-x-hidden'>
      <div className='container' style={{maxWidth: '1027px'}}>
        <h2 className='h2 text-left mb-8 text-center'>
          {intl.formatMessage({id: 'WHAT_IS'})}
          <img
            className='d-inline mx-3'
            src={toAbsoluteUrl('/media/gori/integrations/shipbae-logo.svg')}
            alt='Shipbae'
          />
          ?
        </h2>
        <p className='mb-10 text-center'>{intl.formatMessage({id: 'WHAT_IS_DESC'})}</p>
        <Link
          to='/auth/registration'
          className='btn btn-outline btn-primary hover-orange rounded-pill text-center d-block w-fit-content mx-auto px-9 py-6'
        >
          <img
            className='me-3'
            src={toAbsoluteUrl('/media/gori/landing/store.svg')}
            alt='store-icon'
          />
          {intl.formatMessage({id: 'LANDING_BUTTON_1'})}
        </Link>
      </div>
    </section>
  )
}
