import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_gori/helpers'
import {useIntl} from 'react-intl'

export function CalculateLabels() {
  const intl = useIntl()

  return (
    <section className='calculate-labels overflow-x-hidden'>
      <div className='container'>
        <h2 className='h2 text-left mb-12'>{intl.formatMessage({id: 'CALC_LABELS_H2'})}</h2>
        <div className='d-flex flex-column flex-md-row justify-content-center align-items-center w-100 gap-19'>
          <img
            className='mw-100 mw-md-50'
            src={toAbsoluteUrl('/media/gori/landing/calculate-labels-graphic.webp')}
            alt='calculate-labels-graphic'
          />
          <div>
            <p
              className='mb-9'
              dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'CALC_LABELS_COPY'})}}
            ></p>
            <Link
              to='/auth'
              className='btn btn-outline btn-primary hover-orange rounded-pill text-center d-block w-fit-content'
            >
              <img
                className='me-3'
                src={toAbsoluteUrl('/media/gori/landing/store.svg')}
                alt='store-icon'
              />
              {intl.formatMessage({id: 'CALC_LABELS_BUTTON'})}
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
