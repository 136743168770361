import React, {useEffect, useState} from 'react'
import {Welcome} from './Welcome'
import {AddFund} from './step4/AddFund'
import {LabelCreation} from './step5/LabelCreation'
import {Stepper} from './Stepper'
import {toAbsoluteUrl} from '../../../../_gori/helpers'
import OnboardService from '../core/_requests'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import {useAuth} from '../../auth'
import {STEPS} from '../core/_const'
import {General, Hazmat, Profile, Shipping} from '../../onboard'
import axios from 'axios'

const OnboardPage: React.FC = () => {
  const {newCancelToken, isCancel} = useCancelToken()
  const {currentUser} = useAuth()
  const [activeStep, setActiveStep] = useState<number>(0)
  const [onboard, setOnboard] = useState<any>([])
  const [errorValidate, setErrorValidate] = useState<boolean>(false)
  const SIGNATURE_ACCESS_KEY = process.env.REACT_APP_SIGNATURE_ACCESS_KEY

  useEffect(() => {
    try {
      const fetchData = async () => {
        axios.defaults.headers.common[`${SIGNATURE_ACCESS_KEY}`] =
          currentUser?.current_account?.encrypt_key
        const data = await OnboardService.get({cancelToken: newCancelToken()})
        setOnboard(data)
      }
      let step = STEPS[`${currentUser?.onboard_step}`] ?? STEPS.welcome
      setActiveStep(step)
      fetchData()
    } catch (error: any) {
      if (isCancel(error)) return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getSectionComponent = () => {
    switch (activeStep) {
      case STEPS.welcome:
        return <Welcome setActiveStep={setActiveStep} />
      case STEPS.profile:
        return (
          <Profile
            setActiveStep={setActiveStep}
            onboard={onboard}
            setOnboard={setOnboard}
            setErrorValidate={setErrorValidate}
          />
        )
      case STEPS.shipping:
        return (
          <Shipping
            setActiveStep={setActiveStep}
            onboard={onboard}
            setOnboard={setOnboard}
            setErrorValidate={setErrorValidate}
          />
        )
      case STEPS.ob_general:
        return (
          <General
            setActiveStep={setActiveStep}
            onboard={onboard}
            setOnboard={setOnboard}
            setErrorValidate={setErrorValidate}
          />
        )
      case STEPS.add_fund:
        return <AddFund setActiveStep={setActiveStep} />
      case STEPS.label_creation:
        return <LabelCreation setActiveStep={setActiveStep} />
      default:
        return null
    }
  }

  return (
    <div id='onboard' className='onboard'>
      <div className='onboard-logo'>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/logos/big-shipbae-logo.png')}
          className='h-45px'
        />
      </div>
      {activeStep === STEPS.hazmat ? (
        <Hazmat
          setActiveStep={setActiveStep}
          onboard={onboard}
          setOnboard={setOnboard}
          setErrorValidate={setErrorValidate}
        />
      ) : (
        <div className='container'>
          {activeStep !== STEPS.welcome && activeStep !== STEPS.hazmat && (
            <Stepper
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              errorValidate={errorValidate}
            />
          )}
          {getSectionComponent()}
        </div>
      )}
    </div>
  )
}

export {OnboardPage}
