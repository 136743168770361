import clsx from 'clsx'
import {useFormik} from 'formik'
import html2canvas from 'html2canvas'
import {omit} from 'lodash'
import moment from 'moment'
import {useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import SignatureCanvas from 'react-signature-canvas'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {toAbsoluteUrl} from '../../../../_gori/helpers'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../_gori/hooks/UseYupValidate'
import {
  Button,
  InputDate,
  InputTextFormik,
  SelectFormik,
  ValidationErrorModal,
} from '../../../../_gori/partials/widgets'
import {useAuth} from '../../auth'
import {OPTIONS_SIGNATURE} from '../../onboard/core/_const'
import AgreementService from '../core/_requests'
import UserService from '../../users/core/_requests'
import {useNavigate} from 'react-router-dom'
import {Agreement} from './Agreement'
import {ButtonLabelCreation} from '../../../../_gori/partials/widgets/form/ButtonLabelCreation'

const ClientSignature: React.FC = () => {
  const intl = useIntl()
  const sigCanvas = useRef<any>(null)
  const myRef = useRef<any>(null)
  const navigate = useNavigate()
  const {currentAccount, setCurrentUser} = useAuth()
  const {currentCompany} = useAuth()
  const {regexYup, stringYup, dateYup} = UseYupValidate()
  const [validationErrors, setValidationErrors] = useState<any>()
  const {newCancelToken, isCancel} = useCancelToken()
  const [loading, setLoading] = useState<boolean>(false)
  const [errorSignature, setErrorSignature] = useState<boolean>(false)

  const initialValues = {
    effective_date: moment(),
    company_name: currentCompany?.name,
    client_title: '',
    client_first_name: '',
    client_last_name: '',
    client_signature: '',
    client_option_signature: 'drawing',
  }

  const mndaValidate = Yup.object().shape({
    effective_date: dateYup('EFFECTIVE_DATE'),
    company_name: stringYup(255, 'COMPANY_NAME'),
    client_title: regexYup.inputText('TITLE'),
    client_first_name: regexYup.inputText('FIRST_NAME'),
    client_last_name: regexYup.inputText('LAST_NAME'),
    client_signature: Yup.string().when('client_option_signature', {
      is: (val) => val === 'write',
      then: regexYup.inputText('E_SIGNATURE'),
    }),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: mndaValidate,
    onSubmit: async (values) => {
      setLoading(true)
      let signatureDraw: any = null
      if (values.client_option_signature === 'write') {
        if (myRef.current) {
          const canvas = await html2canvas(myRef.current, {
            backgroundColor: 'transparent',
          })
          signatureDraw = canvas.toDataURL('image/png')
        }
      } else {
        signatureDraw = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
      }

      const _payload = {
        ...omit(values, ['client_option_signature']),
        account_id: currentAccount?.id,
        client_signature: signatureDraw,
        effective_date: values?.effective_date.format(),
      }
      const config = {cancelToken: newCancelToken()}

      try {
        const res = await AgreementService.storeMNDA(_payload, config)
        if (res) {
          toast.success(intl.formatMessage({id: 'SIGNED_SUCCESSFULLY'}))
          const userAuth = await UserService.getUser(config)
          if (userAuth) {
            setCurrentUser(userAuth)
            navigate('/your-info')
          }
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading(false)
      }
    },
  })

  const checkSignature = () => {
    if (formik.values[`client_option_signature`] === 'drawing' && sigCanvas?.current?.isEmpty()) {
      setErrorSignature(true)
      return true
    } else {
      setErrorSignature(false)
      return false
    }
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/gori/login/gori_bg_rocket_1.png')})`,
        }}
      >
        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/big-shipbae-logo.png')}
            className='h-60px'
          />
          <div className='w-50 fs-3'>
            <Agreement formik={formik} />
            <div className='my-10'>
              <div className='row my-4'>
                <div className='col-md-3'>
                  <InputDate
                    labelClassName='fw-bolder'
                    label={intl.formatMessage({id: 'EFFECTIVE_DATE'})}
                    formik={formik}
                    name={'effective_date'}
                    required
                    className='flex-fill'
                    disabled
                  />
                </div>
                <div className='col-md-3'></div>
                <div className='col-md-6'>
                  <label className='fw-bolder form-label required'>
                    {intl.formatMessage({id: 'PRINTED_NAME'})}
                  </label>
                  <div className='d-flex'>
                    <InputTextFormik
                      className='me-3'
                      labelClassName='fw-bolder'
                      required
                      placeholder={intl.formatMessage({id: 'FIRST'})}
                      formik={formik}
                      name={'client_first_name'}
                      disabled={loading}
                    />
                    <InputTextFormik
                      className='ms-3'
                      labelClassName='fw-bolder'
                      required
                      placeholder={intl.formatMessage({id: 'LAST'})}
                      formik={formik}
                      name={'client_last_name'}
                      disabled={loading}
                    />
                  </div>
                </div>
              </div>
              <div className='row my-4'>
                <div className='col-md-6'>
                  <InputTextFormik
                    className='flex-fill'
                    labelClassName='fw-bolder'
                    required
                    label={intl.formatMessage({id: 'COMPANY_NAME'})}
                    formik={formik}
                    name={'company_name'}
                    disabled={true}
                  />
                </div>
                <div className='col-md-6'>
                  <InputTextFormik
                    className='flex-fill'
                    labelClassName='fw-bolder'
                    required
                    label={intl.formatMessage({id: 'TITLE'})}
                    formik={formik}
                    name={'client_title'}
                  />
                </div>
              </div>
              <div className='row d-flex my-4'>
                <div className='col-md-6'>
                  <div className='mb-4'>
                    <SelectFormik
                      label={intl.formatMessage({id: 'E_SIGNATURE'})}
                      labelClassName='fw-bolder'
                      required
                      emptyDefault={false}
                      options={OPTIONS_SIGNATURE}
                      name='client_option_signature'
                      formik={formik}
                      placeholder={intl.formatMessage({id: 'PLEASE_CHOOSE'})}
                      disabled={loading}
                      hasUseIntl={true}
                    />
                  </div>
                  {formik?.values?.client_option_signature === 'drawing' ? (
                    <div className='col-md-6'>
                      <div
                        className='btn btn-secondary mt-0'
                        onClick={() => sigCanvas.current.clear()}
                      >
                        {intl.formatMessage({id: 'CLEAR'})}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <InputTextFormik
                        className='flex-fill'
                        labelClassName='fw-bolder'
                        required
                        formik={formik}
                        name={'client_signature'}
                        maxlength={20}
                        disabled={loading}
                      />
                    </div>
                  )}
                </div>
                {formik?.values?.client_option_signature === 'write' && (
                  <div className='col-md-6 d-flex justify-content-center'>
                    <div className='d-flex bg-white flex-fill rounded-2 border'>
                      <span className='signature p-2' ref={myRef}>
                        {formik?.values?.client_signature}
                      </span>
                    </div>
                  </div>
                )}
                {formik?.values?.client_option_signature === 'drawing' && (
                  <div className='col-md-6 d-flex flex-column'>
                    <div
                      className={clsx('d-flex bg-white flex-fill rounded-2 border', {
                        'border-danger': errorSignature,
                      })}
                    >
                      <SignatureCanvas
                        penColor='black'
                        canvasProps={{className: 'w-100', style: {backgroundColor: 'transparent'}}}
                        ref={sigCanvas}
                        onBegin={() => {
                          setErrorSignature(false)
                        }}
                      />
                    </div>
                    {errorSignature && (
                      <span role='alert' className='fs-6 mt-1 text-danger'>
                        {intl.formatMessage({id: 'E_SIGNATURE_IS_REQUIRED'})}
                      </span>
                    )}
                  </div>
                )}
              </div>
              <div className='d-flex justify-content-center mt-10'>
                <Button
                  className='btn btn-primary'
                  label={intl.formatMessage({id: 'SUBMIT'})}
                  loadingText={intl.formatMessage({id: 'SUBMIT'})}
                  disabled={loading}
                  loading={loading}
                  event={() => {
                    if (!checkSignature() || formik.values[`option_signature`] !== 'drawing') {
                      formik.submitForm()
                    }
                  }}
                />
              </div>
              <div className='d-flex justify-content-end mt-10'>
                <ButtonLabelCreation
                  className='btn btn-light'
                  label={intl.formatMessage({id: 'EXIT'})}
                  loadingText={intl.formatMessage({id: 'EXIT'})}
                  event={() => navigate('/logout')}
                ></ButtonLabelCreation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {ClientSignature}
