import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {FormikProps, getIn} from 'formik'
import {isEmpty} from 'lodash'
import 'moment/min/locales'
import moment from 'moment/moment'
import {FC, useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {LANGUAGE_KEY} from '../../../config/LanguageConfig'
import {useLanguage} from '../../../i18n/Metronici18n'

type Props = {
  className?: string
  labelClassName?: string | null
  label?: string | null
  required?: boolean
  formik: FormikProps<any>
  name: string
  append?: string
  disabled?: boolean
  min?: any
}

const InputDate: FC<Props> = ({
  className,
  labelClassName = null,
  label = null,
  formik,
  name,
  required = false,
  disabled = false,
  min = moment('2000-01-01'),
}) => {
  const intl = useIntl()
  let fieldProps = formik.getFieldProps(name)
  let formikErrors = getIn(formik.errors, name)
  let formikTouched = getIn(formik.touched, name)
  formik.values[name] = isEmpty(formik.values[name]) ? undefined : moment(formik.values[name])
  const {language} = useLanguage()

  const localeValue = useMemo(() => {
    let lang = language
    switch (language) {
      case LANGUAGE_KEY.ZH:
        lang = 'zh-cn'
        break
    }
    return lang
  }, [language])

  useEffect(() => {
    const div = document.querySelector('.date-picker')
    if (div) {
      const input = div.querySelector('input')
      if (input) {
        input.setAttribute('name', name)
      }
    }
  }, [name])

  return (
    <>
      {label && (
        <label className={`form-label ${labelClassName} ${required ? 'required' : ''}`}>
          {label}
        </label>
      )}
      <div className={className}>
        <div
          className={`input-group ${formikTouched && formikErrors ? 'error-date' : ''}`}
          onBlur={() => {
            formik.setFieldTouched(name, true)
          }}
        >
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            dateLibInstance={moment}
            adapterLocale={localeValue}
          >
            <DatePicker
              className={'date-picker'}
              sx={{
                backgroundColor: (disabled && '#eff2f5') || '#ffffff',
              }}
              minDate={min}
              disabled={disabled}
              defaultValue={formik.values[name]}
              dayOfWeekFormatter={(weekday) => intl.formatMessage({id: weekday.toUpperCase()})}
              onChange={(value) => {
                let event = {
                  target: {
                    name: fieldProps.name,
                    value: moment(value).format(),
                  },
                }
                formik.handleChange(event)
              }}
            />
          </LocalizationProvider>
        </div>
        {formikTouched && formikErrors && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block text-danger'>
              <span role='alert'>{formikErrors}</span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export {InputDate}
