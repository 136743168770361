import {AxiosRequestConfig} from 'axios'
import RequestBase from '../../../api/RequestBase'

const URL = '/onboard'

// onboard
const store = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(URL, payload, config)
}

const get = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(URL, config)
}

const OnboardService = {
  store,
  get,
}

export default OnboardService
