import {useEffect} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import {LoadingPage} from '../../../_gori/helpers/components/LoadingPage'

export function TikTokVerifyPage() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    let queryString: string[] = []
    searchParams.forEach((value, key) => {
      let param = key + '=' + value
      queryString.push(param.toString())
    })

    navigate('/settings/connections/tiktok/verify?' + queryString.join('&'))
  }, [navigate, searchParams])

  return (
    <CSSTransition appear in timeout={100} classNames='fade' unmountOnExit>
      <LoadingPage />
    </CSSTransition>
  )
}
