import {useFormik} from 'formik'
import {omit} from 'lodash'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../_gori/hooks/UseYupValidate'
import {
  Button,
  InputDate,
  InputTextFormik,
  SelectFormik,
  ValidationErrorModal,
} from '../../../../_gori/partials/widgets'
import SignatureCanvas from 'react-signature-canvas'
import {OPTIONS_SIGNATURE} from '../../onboard/core/_const'
import clsx from 'clsx'
import html2canvas from 'html2canvas'
import moment from 'moment/moment'
import {useAuth} from '../../auth'
import {UploadUspsClaims} from './UploadUspsClaims'
import AgreementService from '../../agreements/core/_requests'

type Props = {
  show: boolean
  reloadTable?: any
  handleClose: () => void
}

const UploadUspsClaimsAgreementModal: React.FC<Props> = ({show, reloadTable, handleClose}) => {
  const intl = useIntl()
  let key = useRef<number>(0)
  const {newCancelToken, isCancel} = useCancelToken()
  const {stringYup} = UseYupValidate()
  const [validationErrors, setValidationErrors] = useState<any>()
  const sigCanvas = useRef<any>(null)
  const {currentCompany} = useAuth()
  const {regexYup, dateYup} = UseYupValidate()
  const [loading, setLoading] = useState<boolean>(false)
  const myRef = useRef<any>(null)
  const [errorSignature, setErrorSignature] = useState<boolean>(false)
  const [showModalUploadUsps, setShowModalUploadUsps] = useState<boolean>(true)
  const [signature, setSignature] = useState<boolean>(true)
  const [disableButton, setDisableButton] = useState<boolean>(true)
  const [checked, setChecked] = useState<boolean>(false)

  const initialValues = {
    effective_date: moment(),
    company_name: currentCompany?.name,
    client_title: '',
    client_first_name: '',
    client_last_name: '',
    client_signature: '',
    client_option_signature: 'drawing',
  }

  const signatureValidate = Yup.object().shape({
    effective_date: dateYup('EFFECTIVE_DATE'),
    company_name: stringYup(255, 'COMPANY_NAME'),
    client_title: regexYup.inputText('TITLE'),
    client_first_name: regexYup.inputText('FIRST_NAME'),
    client_last_name: regexYup.inputText('LAST_NAME'),
    client_signature: Yup.string().when('client_option_signature', {
      is: (val) => val === 'write',
      then: regexYup.inputText('E_SIGNATURE'),
    }),
  })

  const handleCloseModal = () => {
    handleClose()
    key.current = 0
    formik.resetForm()
    setShowModalUploadUsps(true)
    setDisableButton(true)
    setChecked(false)
    setErrorSignature(false)
    reloadTable()
  }

  const formik = useFormik({
    initialValues,
    validationSchema: signatureValidate,
    onSubmit: async (values) => {
      setLoading(true)
      let signatureDraw: any = null
      if (values.client_option_signature === 'write') {
        if (myRef.current) {
          const canvas = await html2canvas(myRef.current, {
            backgroundColor: 'transparent',
          })
          signatureDraw = canvas.toDataURL('image/png')
        }
      } else {
        signatureDraw = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
      }

      const _payload = {
        ...omit(values, ['client_option_signature']),
        company_id: currentCompany?.id,
        client_signature: signatureDraw,
        effective_date: values?.effective_date.format(),
      }
      const config = {cancelToken: newCancelToken()}

      try {
        const res = await AgreementService.storeClaim(_payload, config)
        if (res.success) {
          toast.success(intl.formatMessage({id: 'SIGNED_SUCCESSFULLY'}))
          setSignature(true)
          setShowModalUploadUsps(true)
          setLoading(false)
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading(false)
      }
    },
  })

  const getClaimAgreement = useCallback(async () => {
    try {
      const response = await AgreementService.getClaim()
      setSignature(response.is_signature)
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    }
  }, [])

  useEffect(() => {
    getClaimAgreement()
  }, [getClaimAgreement])

  const checkSignature = () => {
    if (formik.values[`client_option_signature`] === 'drawing' && sigCanvas?.current?.isEmpty()) {
      setErrorSignature(true)
      return true
    } else {
      setErrorSignature(false)
      return false
    }
  }

  const handleClick = () => {
    setDisableButton(!disableButton)
    setChecked(true)
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <Modal
        id='gori_modal_create_claim'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-1000px h-auto'
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title bsPrefix={'fw-bolder fs-1'}>
            {intl.formatMessage({id: 'UPLOAD_USPS_CLAIMS'})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='vh-75 scroll-y fs-5'>
          {showModalUploadUsps ? (
            <Container>
              {/* Title */}
              <div className={'fw-bolder fs-2'}>{intl.formatMessage({id: 'USPS_CLAIM_TITLE'})}</div>
              <div className={'fw-bold mt-lg-4'}>
                {intl.formatMessage(
                  {id: 'USPS_CLAIM_AGREEMENT'},
                  {name: initialValues?.company_name}
                )}
                .
              </div>
              <div className={'mt-5 fw-bold'}>
                {intl.formatMessage({id: 'USPS_CLAIM_CUSTOMER_AGREEMENT'})}
              </div>
              <div className={'mt-5 fw-bold'}>
                {intl.formatMessage({id: 'BULK_USPS_CLAIM_CONTENT_1'})}
              </div>
              <div className={'mt-5 fw-bold'}>
                {intl.formatMessage({id: 'BULK_USPS_CLAIM_CONTENT_2'})}
              </div>
              <div className={'mt-5 fw-bold'}>
                {intl.formatMessage({id: 'BULK_USPS_CLAIM_CONTENT_3'})}
              </div>
              <div className={'mt-3 fw-bold'} style={{marginLeft: '1.5rem'}}>
                <b className={'fw-bolder'}>
                  {intl.formatMessage({id: 'BULK_USPS_CLAIM_PROOF_OF_VALUE'})}
                </b>{' '}
                <br />
                {intl.formatMessage({id: 'BULK_USPS_CLAIM_PROOF_OF_VALUE_CONTENT'})}
              </div>
              <div className={'mt-3 fw-bold'} style={{marginLeft: '1.5rem'}}>
                <b>{intl.formatMessage({id: 'BULK_USPS_CLAIM_PROOF_OF_DAMAGE'})} </b>
                <br />
                {intl.formatMessage({id: 'BULK_USPS_CLAIM_PROOF_OF_DAMAGE_CONTENT'})}
              </div>
              <div className={'mt-3 fw-bold'} style={{marginLeft: '1.5rem'}}>
                <b>{intl.formatMessage({id: 'BULK_USPS_CLAIM_PROOF_OF_CUSTOMER_CLAIM'})}</b>
                <br />
                {intl.formatMessage({id: 'BULK_USPS_CLAIM_PROOF_OF_CUSTOMER_CLAIM_CONTENT'})}
              </div>
              <div className={'mt-3 fw-bold'} style={{marginLeft: '1.5rem'}}>
                <b>{intl.formatMessage({id: 'BULK_USPS_CLAIM_PROOF_OF_REIMBURSENMENT'})}</b>
                <br />
                {intl.formatMessage({id: 'BULK_USPS_CLAIM_PROOF_OF_REIMBURSENMENT_CONTENT'})}
              </div>
              <div className={'mt-5 fw-bold'}>
                {' '}
                {intl.formatMessage({id: 'BULK_USPS_CLAIM_CONTENT_4'})}{' '}
              </div>
              <div className={'mt-5 fw-bold'}>
                {' '}
                {intl.formatMessage({id: 'BULK_USPS_CLAIM_CONTENT_5'})}{' '}
              </div>
              <div className={'mt-5 fw-bold'}>
                {' '}
                {intl.formatMessage({id: 'BULK_USPS_CLAIM_CONTENT_FLOW'})}
                <ul className={'mt-2 fw-bolder'}>
                  <li>{intl.formatMessage({id: 'BULK_USPS_CLAIM_CONTENT_FLOW_UL_1'})}</li>
                  <li>{intl.formatMessage({id: 'BULK_USPS_CLAIM_CONTENT_FLOW_UL_2'})}</li>
                  <li>{intl.formatMessage({id: 'BULK_USPS_CLAIM_CONTENT_FLOW_UL_3'})}</li>
                </ul>
              </div>
              {!signature ? (
                <div className='my-10'>
                  <div className='row my-4'>
                    <div className='col-md-3'>
                      <InputDate
                        labelClassName='fw-bolder'
                        label={intl.formatMessage({id: 'EFFECTIVE_DATE'})}
                        formik={formik}
                        name={'effective_date'}
                        required
                        disabled={true}
                        className='flex-fill'
                      />
                    </div>
                    <div className='col-md-3'></div>
                    <div className='col-md-6'>
                      <label className='fw-bolder form-label required'>
                        {intl.formatMessage({id: 'PRINTED_NAME'})}
                      </label>
                      <div className='d-flex'>
                        <InputTextFormik
                          className='me-3'
                          labelClassName='fw-bolder'
                          required
                          placeholder={intl.formatMessage({id: 'FIRST'})}
                          formik={formik}
                          name={'client_first_name'}
                          disabled={loading}
                        />
                        <InputTextFormik
                          className='ms-3'
                          labelClassName='fw-bolder'
                          required
                          placeholder={intl.formatMessage({id: 'LAST'})}
                          formik={formik}
                          name={'client_last_name'}
                          disabled={loading}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row my-4'>
                    <div className='col-md-6'>
                      <InputTextFormik
                        className='flex-fill'
                        labelClassName='fw-bolder'
                        required
                        label={intl.formatMessage({id: 'COMPANY_NAME'})}
                        formik={formik}
                        name={'company_name'}
                        disabled={true}
                      />
                    </div>
                    <div className='col-md-6'>
                      <InputTextFormik
                        className='flex-fill'
                        labelClassName='fw-bolder'
                        required
                        label={intl.formatMessage({id: 'TITLE'})}
                        formik={formik}
                        name={'client_title'}
                      />
                    </div>
                  </div>
                  <div className='row d-flex my-4'>
                    <div className='col-md-6'>
                      <div className='mb-4'>
                        <SelectFormik
                          label={intl.formatMessage({id: 'E_SIGNATURE'})}
                          labelClassName='fw-bolder'
                          required
                          emptyDefault={false}
                          options={OPTIONS_SIGNATURE}
                          name='client_option_signature'
                          formik={formik}
                          placeholder={intl.formatMessage({id: 'PLEASE_CHOOSE'})}
                          disabled={loading}
                          hasUseIntl={true}
                        />
                      </div>
                      {formik?.values?.client_option_signature === 'drawing' ? (
                        <div className='col-md-6'>
                          <div
                            className='btn btn-secondary mt-0'
                            onClick={() => sigCanvas.current.clear()}
                          >
                            {intl.formatMessage({id: 'CLEAR'})}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <InputTextFormik
                            className='flex-fill'
                            labelClassName='fw-bolder'
                            required
                            formik={formik}
                            name={'client_signature'}
                            maxlength={20}
                            disabled={loading}
                          />
                        </div>
                      )}
                    </div>
                    {formik?.values?.client_option_signature === 'write' && (
                      <div className='col-md-6 d-flex justify-content-center'>
                        <div
                          className='d-flex flex-fill rounded-2 border'
                          style={{backgroundColor: '#eff2f5'}}
                        >
                          <span className='signature p-2' ref={myRef}>
                            {formik?.values?.client_signature}
                          </span>
                        </div>
                      </div>
                    )}
                    {formik?.values?.client_option_signature === 'drawing' && (
                      <div className='col-md-6 d-flex flex-column'>
                        <div
                          className={clsx('d-flex flex-fill rounded-2 border', {
                            'border-danger': errorSignature,
                          })}
                          style={{backgroundColor: '#eff2f5'}}
                        >
                          <SignatureCanvas
                            penColor='black'
                            canvasProps={{
                              className: 'w-100',
                              style: {backgroundColor: 'transparent'},
                            }}
                            ref={sigCanvas}
                            onBegin={() => {
                              setErrorSignature(false)
                            }}
                          />
                        </div>
                        {errorSignature && (
                          <span role='alert' className='fs-6 mt-1 text-danger'>
                            {intl.formatMessage({id: 'E_SIGNATURE_IS_REQUIRED'})}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className='my-10'>
                  <div className='form-check form-check-custom form-check-solid form-check-inline'>
                    <input
                      className={'form-check-input shadow-none'}
                      type='checkbox'
                      name={'accept_terms'}
                      onClick={() => handleClick()}
                      checked={!disableButton}
                    />
                    <div
                      className={`form-check-label fw-bold text-gray-700 `}
                      onClick={() => handleClick()}
                    >
                      {intl.formatMessage({id: 'BULK_ACCEPT_TERMS'})}
                      <span style={{color: 'red'}}>*</span>
                    </div>
                  </div>
                  {disableButton && checked && (
                    <div style={{color: 'red', marginTop: '3px'}}>
                      {intl.formatMessage({id: 'YOU_MUST_ACCEPT_THE_TERMS_AND_CONDITIONS'})}
                    </div>
                  )}
                </div>
              )}
            </Container>
          ) : (
            <UploadUspsClaims reloadTable={() => reloadTable()} handleClose={() => handleClose()} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-end'>
            <Button
              className='btn btn-secondary me-2'
              label={intl.formatMessage({id: 'CANCEL'})}
              loadingText={intl.formatMessage({id: 'CANCEL'})}
              event={handleCloseModal}
            />
            {showModalUploadUsps ? (
              !signature ? (
                <Button
                  className='btn btn-primary'
                  label={intl.formatMessage({id: 'SUBMIT'})}
                  loadingText={intl.formatMessage({id: 'SUBMIT'})}
                  disabled={loading}
                  loading={loading}
                  event={() => {
                    if (!checkSignature() || formik.values[`option_signature`] !== 'drawing') {
                      formik.submitForm()
                    }
                  }}
                />
              ) : (
                <Button
                  className='btn btn-primary'
                  label={intl.formatMessage({id: 'SUBMIT'})}
                  loadingText={intl.formatMessage({id: 'SUBMIT'})}
                  disabled={disableButton}
                  event={() => {
                    setShowModalUploadUsps(false)
                  }}
                />
              )
            ) : (
              ''
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {UploadUspsClaimsAgreementModal}
