import {isEmpty} from 'lodash'
import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useAuth} from '../../../app/modules/auth'
import CarrierService from '../../../app/modules/common/carriers/core/_requests'
import SettingsService from '../../../app/modules/settings/core/_requests'
import {DEFAULT_PAGE, PAGE_SIZE_MAX} from '../../constants'
import TableService from '../../partials/widgets/tables/core/_requests'
import {StorageHelpers} from '../StorageHelpers'

const STATES_GLOBAL_STORAGE_KEY =
  process.env.REACT_APP_STATES_GLOBAL_STORAGE_KEY || 'STATES_GLOBAL_STORAGE_KEY'

export interface StatesGlobalContextModel {
  statesGlobal: any
  getAddress: any
  getPackages: any
  getPresets: any
  setHazmat: any
  getManageColumns: any
}

const initStatesGlobalContextPropsStates = {
  statesGlobal: StorageHelpers.getItemLocalStorage(STATES_GLOBAL_STORAGE_KEY),
  getAddress: () => {},
  getPackages: () => {},
  getPresets: () => {},
  setHazmat: () => {},
  getManageColumns: () => {},
}

const StatesGlobalContext = React.createContext<StatesGlobalContextModel>(
  initStatesGlobalContextPropsStates
)

const StatesGlobalProvider: React.FC = ({children}) => {
  const {currentUser} = useAuth()
  let [statesGlobal, setStateGlobal] = useState(
    StorageHelpers.getItemLocalStorage(STATES_GLOBAL_STORAGE_KEY)
  )
  const [carriers, setCarriers] = useState<Object>(statesGlobal?.carriers)
  const [carriersPackage, setCarriersPackage] = useState<Object>(statesGlobal?.carriersPackage)
  const [address, setAddress] = useState<Object>(statesGlobal?.address)
  const [packages, setPackages] = useState<Object>(statesGlobal?.packages)
  const [presets, setPresets] = useState<Object>(statesGlobal?.presets)
  const [manageColumns, setManageColumns] = useState<Object>(statesGlobal?.manageColumns)
  const [hazmat, setHazmat] = useState<boolean>(
    statesGlobal?.hazmat || (currentUser?.show_reminder ?? true)
  )

  // begin: state global carriers
  const getCarriers = useCallback(async () => {
    try {
      const response = await CarrierService.getAll()
      if (response) {
        setCarriers(response.carriers)
        setCarriersPackage(response.package_types)
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (currentUser && (isEmpty(carriers) || isEmpty(carriersPackage))) {
      getCarriers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCarriers, currentUser])
  // end: state global carriers

  // begin: state global address
  const getAddress = useCallback(async (nameAddress = 'from') => {
    try {
      const {shipping_addresses}: any = await SettingsService.getAddress({
        params: {page: DEFAULT_PAGE, page_size: PAGE_SIZE_MAX, type: nameAddress},
      })

      if (shipping_addresses) {
        setAddress((prev) => ({...prev, [nameAddress]: shipping_addresses}))
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (currentUser && isEmpty(address)) {
      getAddress('from')
      getAddress('to')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAddress, currentUser])
  // end: state global address

  // begin: state global packages
  const getPackages = useCallback(async () => {
    try {
      const config = {
        params: {
          page: DEFAULT_PAGE,
          page_size: PAGE_SIZE_MAX,
        },
      }
      const {packages} = await SettingsService.getPackages(config)
      if (packages) {
        setPackages(packages)
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (currentUser && isEmpty(packages)) {
      getPackages()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPackages, currentUser])
  // end: state global packages

  // begin: state global presets
  const getPresets = useCallback(async () => {
    try {
      const config = {
        params: {
          page: DEFAULT_PAGE,
          page_size: PAGE_SIZE_MAX,
        },
      }

      const {shipping_presets} = await SettingsService.getShippingPresets(config)
      if (shipping_presets) {
        setPresets(shipping_presets)
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (currentUser && isEmpty(presets)) {
      getPresets()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPresets, currentUser])
  // end: state global presets

  // begin: state global custom column
  const getManageColumns = useCallback(async () => {
    const config = {
      params: {
        filter_key: 'custom_columns',
      },
    }
    try {
      const {filters} = await TableService.getCustomColumns(config)
      if (filters.length > 0) {
        setManageColumns(
          filters?.reduce((allDataColumn, currentColumn) => {
            allDataColumn[currentColumn?.filter_name] = currentColumn?.filter_payload
            return allDataColumn
          }, {})
        )
      } else {
        setManageColumns({})
      }
    } catch (error) {
      console.error(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (currentUser && isEmpty(manageColumns)) {
      getManageColumns()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getManageColumns, currentUser])
  // end: state global users

  // Update global state when making the first API call.
  useEffect(() => {
    StorageHelpers.setItemLocalStorage(STATES_GLOBAL_STORAGE_KEY, {
      carriers: carriers,
      carriersPackage: carriersPackage,
      address: address,
      packages: packages,
      presets: presets,
      hazmat: hazmat,
      manageColumns: manageColumns,
    })

    setStateGlobal(StorageHelpers.getItemLocalStorage(STATES_GLOBAL_STORAGE_KEY))
  }, [address, carriers, packages, presets, hazmat, manageColumns, carriersPackage])

  return (
    <StatesGlobalContext.Provider
      value={{
        statesGlobal,
        getAddress,
        getPackages,
        getPresets,
        setHazmat,
        getManageColumns,
      }}
    >
      {children}
    </StatesGlobalContext.Provider>
  )
}

function useStatesGlobal() {
  return useContext(StatesGlobalContext)
}

export {StatesGlobalProvider, useStatesGlobal}
