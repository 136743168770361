import {AxiosRequestConfig} from 'axios'
import RequestBase from '../../../api/RequestBase'

const BASE_URL = '/agreements'

const get = (id: string, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${BASE_URL}/${id}`, config)
}

const storeMNDA = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${BASE_URL}/mnda`, payload, config)
}

const updateMNDA = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${BASE_URL}/mnda`, payload, config)
}

const getClaim = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${BASE_URL}/claim`, config)
}

const storeClaim = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${BASE_URL}/claim`, payload, config)
}

const verify = (password?: string, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${BASE_URL}/verify/${password}`, config)
}

const updateHazmat = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${BASE_URL}/hazmat`, payload, config)
}

const AgreementService = {
  get,

  //MNDA
  storeMNDA,
  updateMNDA,

  //Claim
  getClaim,
  storeClaim,
  verify,

  //Hazmat
  updateHazmat,
}

export default AgreementService
