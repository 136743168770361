import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {CSSTransition} from 'react-transition-group'
import {KTSVG, convertCurrency} from '../../../../../_gori/helpers'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import {Button, ButtonBack, ValidationErrorModal} from '../../../../../_gori/partials/widgets'
import {ButtonLabelCreation} from '../../../../../_gori/partials/widgets/form/ButtonLabelCreation'
import {ClaimAlertModal, LowBalanceAlertModal} from '../../../onboard'
import {STEPS} from '../../core/_const'
import OnboardService from '../../core/_requests'
import UseRoutesPermission from '../../../../../_gori/hooks/UseRoutesPermission'
import {ConfigGeneral} from '../../../settings/core/_const'

type Props = {
  setActiveStep: any
  onboard?: any
  setOnboard?: any
  setErrorValidate?: any
}

const General: React.FC<Props> = ({setActiveStep, onboard, setOnboard, setErrorValidate}) => {
  const intl = useIntl()
  const {routes} = UseRoutesPermission()
  const [showModal, setShowModal] = useState<{
    time_zone: boolean
    low_balance_alerts: boolean
    claim_alerts: boolean
  }>({
    time_zone: false,
    low_balance_alerts: false,
    claim_alerts: false,
  })
  const [dataGeneral, setDataGeneral] = useState<{meter: any; claims: any}>({
    meter: undefined,
    claims: undefined,
  })
  const [validationErrors, setValidationErrors] = useState<any>()
  const {newCancelToken, isCancel} = useCancelToken()
  const [loading, setLoading] = useState<{save_and_exit: boolean; continue: boolean}>({
    save_and_exit: false,
    continue: false,
  })
  const navigate = useNavigate()

  const initValue = useMemo(() => {
    return [
      {
        category: ConfigGeneral.NOTIFICATION_CATEGORY.METER,
        type: ConfigGeneral.NOTIFICATION_TYPE.EMAIL,
        status: false,
        receiver: null,
        value: {
          threshold: '',
        },
      },
      {
        category: ConfigGeneral.NOTIFICATION_CATEGORY.CLAIMS,
        type: ConfigGeneral.NOTIFICATION_TYPE.EMAIL,
        status: false,
        receiver: null,
      },
    ]
  }, [])

  useEffect(() => {
    let _dataGeneral = (onboard?.ob_general?.notifications ?? initValue)?.reduce(
      (total, current: any) => {
        total[current?.category] = current
        return total
      },
      {}
    )
    setDataGeneral(_dataGeneral)
  }, [initValue, onboard])

  const notificationHtml = useCallback(
    (config) => {
      return (
        <div className='d-flex justify-content-center text-gray-700'>
          <div className='w-75 border border-secondary rounded-2 py-6 px-8'>
            <div className='d-flex justify-content-between fw-bolder'>
              <span className='fs-3'>{intl.formatMessage({id: config.title})}</span>
              <span
                className='fs-5 text-primary text-decoration-underline cursor-pointer'
                onClick={() => setShowModal((prev) => ({...prev, [config.name]: true}))}
              >
                {intl.formatMessage({id: 'EDIT'})}
              </span>
            </div>
            {config?.subtitle && (
              <div className='mt-8 fw-bold text-gray-500'>{config?.subtitle}</div>
            )}
            <div className='row fs-5 mt-8'>{config.children}</div>
          </div>
        </div>
      )
    },
    [intl]
  )

  const configHtml = useMemo(() => {
    const dataMeter = dataGeneral.meter
    const dataClaims = dataGeneral.claims

    let _obj: any = {}
    _obj = {
      ..._obj,
      LOW_BALANCE_ALERTS: {
        title: 'LOW_BALANCE_ALERTS',
        name: 'low_balance_alerts',
        subtitle: intl.formatMessage(
          {id: 'LOW_BALANCE_TEXT'},
          {brand: intl.formatMessage({id: 'BRAND_NAME'})}
        ),
        children: (
          <>
            {dataMeter?.status ? (
              <>
                <div className='row fs-5 mt-8'>
                  <div className='col-4 fw-bold'>{intl.formatMessage({id: 'THRESHOLD'})}</div>
                  <div className='col-8 fw-bolder'>
                    {convertCurrency(dataMeter.value.threshold)}
                  </div>
                </div>
                <div className='row fs-5 mt-8'>
                  <div className='col-4 fw-bold'>{intl.formatMessage({id: 'EMAIL_TO'})}</div>
                  <div className='col-8 fw-bolder'>
                    {dataMeter?.receiver?.email?.map((email, index) => (
                      <div className='my-2' key={index}>
                        {email}
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <div className='row fs-5 mt-8'>
                <div className='col-4 fw-bold'>{intl.formatMessage({id: 'ALERT'})}</div>
                <div className='col-8 fw-bolder'>
                  {intl.formatMessage({id: dataMeter?.status ? 'ON' : 'OFF'}).toUpperCase()}
                </div>
              </div>
            )}
          </>
        ),
      },
    }

    _obj = {
      ..._obj,
      CLAIM_ALERTS: {
        title: 'CLAIM_ALERTS',
        name: 'claim_alerts',
        children: (
          <div className='row fs-5 mt-8'>
            {dataClaims?.status ? (
              <>
                <div className='col-4 fw-bold'>{intl.formatMessage({id: 'EMAIL_TO'})}</div>
                <div className='col-8 fw-bolder'>
                  {dataClaims?.receiver?.email?.map((email, index) => (
                    <div className='my-2' key={index}>
                      {email}
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                <div className='col-4 fw-bold'>{intl.formatMessage({id: 'ALERT'})}</div>
                <div className='col-8 fw-bolder'>
                  {intl.formatMessage({id: 'OFF'}).toUpperCase()}
                </div>
              </>
            )}
          </div>
        ),
      },
    }

    return _obj
  }, [dataGeneral.claims, dataGeneral.meter, intl, routes])

  const nextStep = () => {
    setActiveStep(STEPS.add_fund)
  }

  const backStep = () => {
    setActiveStep(STEPS.shipping)
  }

  const handleSubmit = async (name: 'save_and_exit' | 'continue') => {
    const isSaveAndExit = name === 'save_and_exit'
    const config = {cancelToken: newCancelToken()}

    let payload = {
      step: 'ob_general',
      data: {
        notifications: [dataGeneral.meter, dataGeneral.claims],
      },
      save_and_exit: isSaveAndExit,
    }

    setLoading((prev) => ({...prev, [name]: true}))

    try {
      const res = await OnboardService.store(payload, config)
      if (res) {
        setOnboard((prev) => ({...prev, ob_general: payload.data}))
        toast.success(intl.formatMessage({id: 'UPDATE_GENERAL_SUCCESSFULLY'}))
        nextStep()
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setLoading((prev) => ({...prev, [name]: false}))
      if (isSaveAndExit) {
        navigate('/logout')
      }
    }
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      {showModal.low_balance_alerts && (
        <LowBalanceAlertModal
          show={showModal.low_balance_alerts}
          handleClose={() => {
            setShowModal((prev) => ({...prev, low_balance_alerts: false}))
          }}
          data={dataGeneral.meter}
          handleSave={(values) => setDataGeneral((prev) => ({...prev, meter: values}))}
        />
      )}
      {showModal.claim_alerts && (
        <ClaimAlertModal
          show={showModal.claim_alerts}
          handleClose={() => {
            setShowModal((prev) => ({...prev, claim_alerts: false}))
          }}
          data={dataGeneral.claims}
          handleSave={(values) => setDataGeneral((prev) => ({...prev, claims: values}))}
        />
      )}
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className='account-all'>
          <div className='account-step '>
            <div className='account-timezone'>{/*<TimeZoneAccount formik={formik} />*/}</div>
            <div className='account-notification bg-white rounded-2 p-10 shadow'>
              {Object.entries(configHtml).map(([key, config]) => {
                return <div className='mt-5'>{notificationHtml(config)}</div>
              })}
            </div>
            <div className='col-12 text-center mt-15 save-button'>
              <ButtonBack
                event={() => backStep()}
                className='back onboard-form'
                label={intl.formatMessage({id: 'BACK'})}
                disabled={loading.continue}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'CONTINUE'})}
                loadingText={intl.formatMessage({id: 'CONTINUE'})}
                loading={loading.continue}
                event={() => handleSubmit('continue')}
              />
            </div>
          </div>
          <div className='col-12 mt-5 save-and-exit'>
            <ButtonLabelCreation
              className='btn btn-light'
              label={intl.formatMessage({id: 'SAVE_AND_EXIT'})}
              loadingText={intl.formatMessage({id: 'SAVE_AND_EXIT'})}
              event={() => handleSubmit('save_and_exit')}
              loading={loading.save_and_exit}
              disabled={loading.continue}
            >
              <KTSVG path='/media/gori/orders/delete.svg' className='m-0' svgClassName='mh-10px' />
            </ButtonLabelCreation>
          </div>
        </div>
      </CSSTransition>
    </>
  )
}

export {General}
