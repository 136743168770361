import clsx from 'clsx'
import {cloneDeep, find, isEmpty, isEqual} from 'lodash'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {TABLE_KEY_COLUMN} from '../../../constants'
import {KTSVG} from '../../../helpers'
import {useStatesGlobal} from '../../../helpers/components/StatesGlobalProvider'
import {Button} from '../form/Button'
import TableService from './core/_requests'

type Props = {
  show: boolean
  handleClose: () => void
  name: string
  initColumns?: any
  columns: any
}

const CustomsColumnTable: React.FC<Props> = ({show, name, initColumns, columns, handleClose}) => {
  const intl = useIntl()
  let draggedItem = useRef()
  const [loading, setLoading] = useState<boolean>(false)
  const {statesGlobal, getManageColumns} = useStatesGlobal()
  const dataCustomColumns = statesGlobal?.manageColumns?.[name]
  const [idxDragging, setIdxDragging] = useState<number | undefined>(undefined)
  const [itemsDrop, setItemsDrop] = useState<any>([])

  useEffect(() => {
    setItemsDrop(() => {
      if (isEmpty(initColumns) && isEmpty(columns)) return []
      return (initColumns || columns).reduce((colInit, col) => {
        colInit.push({
          id: col?.id,
          header: col?.Header,
          show: col?.show,
        })
        return colInit
      }, [])
    })
  }, [columns, initColumns])

  const hasCanCheck = useMemo(() => {
    return find(itemsDrop, {id: TABLE_KEY_COLUMN.CAN_CHECK})
  }, [itemsDrop])

  const onDragStart = useCallback(
    (index) => {
      draggedItem.current = cloneDeep(
        itemsDrop.filter(
          ({id}) =>
            id !== TABLE_KEY_COLUMN.CAN_CHECK &&
            id !== TABLE_KEY_COLUMN.SUB_ACTIONS &&
            id !== TABLE_KEY_COLUMN.ACTIONS
        )
      )[index]
      setIdxDragging(index)
    },
    [itemsDrop]
  )

  const onDragOver = useCallback(
    (e, index) => {
      e.preventDefault()
      if (!itemsDrop || !draggedItem.current) return
      const _itemsDrop = cloneDeep(itemsDrop)

      if (draggedItem.current === cloneDeep(_itemsDrop[index])) {
        return
      }
      setIdxDragging(index)
      let updatedItems = cloneDeep(_itemsDrop).filter((item) => !isEqual(item, draggedItem.current))
      updatedItems.splice(hasCanCheck ? index + 1 : index, 0, draggedItem.current)
      setItemsDrop(updatedItems)
    },
    [hasCanCheck, itemsDrop]
  )

  const onDragEnd = useCallback(() => {
    draggedItem.current = undefined
    setIdxDragging(undefined)
  }, [])

  const handleChangeCheckbox = useCallback(
    (itemCheck) => {
      const newItems = cloneDeep(itemsDrop).map((item) => {
        if (isEqual(item, itemCheck)) {
          item.show = !item.show
        }
        return item
      })
      setItemsDrop(newItems)
    },
    [itemsDrop]
  )

  const handleSaveColumns = async () => {
    try {
      setLoading(true)
      let res
      if (dataCustomColumns) {
        res = await TableService.updateCustomColumns({
          filter_name: name,
          filter_key: 'custom_columns',
          filter_payload: itemsDrop,
        })
      } else {
        res = await TableService.saveCustomColumns({
          filter_name: name,
          filter_key: 'custom_columns',
          filter_payload: itemsDrop,
        })
      }
      if (res) {
        await getManageColumns()
        await handleClose()
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      id='gori_table_modal'
      tabIndex={-1}
      aria-hidden='true'
      centered
      show={show}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <Modal.Header closeButton>
          <Modal.Title bsPrefix={'fw-bolder fs-1'}>
            {intl.formatMessage({id: 'MANAGE_COLUMNS'})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='vh-75 scroll-y'>
          <div className='px-4'>
            {cloneDeep(itemsDrop)
              ?.filter(
                ({id}) =>
                  id !== TABLE_KEY_COLUMN.CAN_CHECK &&
                  id !== TABLE_KEY_COLUMN.SUB_ACTIONS &&
                  id !== TABLE_KEY_COLUMN.ACTIONS
              )
              ?.map((item, idx) => {
                if (!item.header) return null
                return (
                  <div
                    className='d-flex my-2'
                    key={idx}
                    onDragOver={(e) => onDragOver(e, idx)}
                    draggable={false}
                  >
                    <div
                      className={clsx(
                        'd-flex align-items-center flex-fill justify-content-between bg-white cursor-default p-2 px-4 rounded-2 border',
                        {
                          'border-primary bg-light shadow': idxDragging === idx,
                          'border-secondary': idxDragging !== idx,
                        }
                      )}
                    >
                      <div className='d-flex align-items-center'>
                        <label className='form-check form-check-custom form-check-white'>
                          <input
                            type='checkbox'
                            className='form-check-input cursor-pointer'
                            checked={item?.show || false}
                            onChange={() => handleChangeCheckbox(item)}
                          />
                        </label>
                        <span className='ms-4 fs-5 fw-bold'>{item.header}</span>
                      </div>
                      <div
                        className='cursor-move'
                        onDragStart={() => onDragStart(idx)}
                        onDragEnd={onDragEnd}
                        onDragOver={(e) => onDragOver(e, idx)}
                        draggable
                      >
                        <KTSVG
                          path={`/media/gori/tables/move-col.svg`}
                          className={clsx('svg-icon svg-icon-3', {
                            'text-primary': idxDragging === idx,
                          })}
                          small={false}
                        />
                      </div>
                      {/* {displayConfig.items.table.pin && (
                      <div onClick={() => handleChangePin(item)} className='cursor-pointer'>
                        {item.pin ? (
                          <div className='pin'>
                            <div className='pinned'>{intl.formatMessage({id: 'PINNED'})}</div>
                            <div className='unpin'>{intl.formatMessage({id: 'UNPIN'})}</div>
                          </div>
                        ) : (
                          <div>{intl.formatMessage({id: 'PIN'})}</div>
                        )}
                      </div>
                    )} */}
                    </div>
                  </div>
                )
              })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-end'>
            <Button
              className='btn btn-secondary me-3'
              label={intl.formatMessage({id: 'CANCEL'})}
              event={() => handleClose()}
            />
            <Button
              className='btn btn-primary'
              label={intl.formatMessage({id: 'SAVE_COLUMNS'})}
              loadingText={intl.formatMessage({id: 'SAVE_COLUMNS'})}
              event={handleSaveColumns}
              loading={loading}
            />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  )
}

export {CustomsColumnTable}
