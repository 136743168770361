export const OrderStatus = {
  OPEN: 'open',
  SHIPPED: 'shipped',
  AWAITING_PAYMENT: 'awaiting_payment',
  ON_HOLD: 'on_hold',
  CANCELLED: 'cancelled',
  ERROR: 'error',
}

export const OrdersConfig = {
  PAGE_SIZE_ADDRESS: 200,
  CREATE_ALL_LABEL: 10,
  GET_CHEAPEST_SERVICE: 100,
  GET_ESTIMATED_SERVICE: 100,
  SHIPPING: {
    COMPANY: {label: 'COMPANY', value: 'company', required: true},
    FIRST_NAME: {label: 'FIRST_NAME', value: 'first_name', required: true},
    LAST_NAME: {label: 'LAST_NAME', value: 'last_name'},
    EMAIL: {label: 'EMAIL', value: 'email'},
    PHONE: {label: 'PHONE', value: 'phone'},
    ADDRESS: {label: 'ADDRESS', value: 'street1', required: true},
    ADDRESS_2: {label: 'ADDRESS_2', value: 'street2'},
    CITY: {label: 'CITY', value: 'city', required: true},
    STATE: {label: 'STATE', value: 'state', required: true},
    COUNTRY: {label: 'COUNTRY', value: 'country'},
    ZIP_CODE: {label: 'POSTAL_CODE', value: 'zip', required: true},
    RESIDENTIAL: {label: 'RESIDENTIAL', value: 'is_residential'},
    VERIFIED: {label: 'VERIFIED', value: 'is_verified'},
    SAVE_ADDRESS: {label: 'SAVE_ADDRESS', value: 'type'},
  },
  VALIDATE_ADDRESS: ['city', 'country', 'state', 'street1', 'street2', 'zip'],
  PARCEL: ['length', 'width', 'height', 'weight', 'dimension_unit', 'weight_unit'],
  ITEMS: {
    TITLE: {label: 'Name', value: 'title', type: 'text'},
    SKU: {label: 'SKU', value: 'sku', type: 'text'},
    QUANTITY: {label: 'Quantity', value: 'quantity', type: 'number'},
    PRICE: {label: 'Price', value: 'unit_price', type: 'number'},
  },
  CUSTOMS: {
    CONTENT: {label: 'CONTENT', value: 'content', type: 'text'},
    SIGNER: {label: 'SIGNER', value: 'signer', type: 'text'},
    DESCRIPTION: {label: 'DESCRIPTION', value: 'description', type: 'text'},
    ITEMS: {
      DESCRIPTION: {label: 'DESCRIPTION', value: 'description', type: 'text', required: true},
      HS_TARIFF_NUMBER: {
        label: 'HS_TARIFF_NUMBER',
        value: 'hs_tariff_number',
        type: 'text',
        required: false,
      },
      COUNTRY_OF_ORIGIN: {
        label: 'COUNTRY_OF_ORIGIN',
        value: 'country',
        type: 'text',
        required: false,
      },
      CODE: {label: 'CODE', value: 'code', type: 'text', required: false},
      QUANTITY: {label: 'QUANTITY', value: 'quantity', type: 'number', required: true},
      VALUE: {label: 'VALUE', value: 'value', type: 'number', required: true},
      WEIGHT: {label: 'WEIGHT', value: 'weight', type: 'number', required: true},
    },
  },
  US_TERRITORIES: ['AS', 'FM', 'GU', 'MH', 'MP', 'PW', 'PR', 'VI'],
  US_MILITARIES: ['APO', 'FPO', 'DPO'],
  SERVICE_FILTER: {
    TERRITORIES: ['usps_ground_advantage', 'usps_priority', 'ups_ground'],
    MILITARIES: ['usps_ground_advantage', 'usps_priority'],
  },
  MASS_UPDATE: [
    {label: 'PRESETS', value: 'preset'},
    {label: 'SHIP_FROM', value: 'from'},
    {label: 'SHIP_TO', value: 'to'},
    {label: 'SERVICE', value: 'service'},
    {label: 'PACKAGE', value: 'package_type'},
    {label: 'WEIGHT', value: 'weight'},
    {label: 'SIZE', value: 'parcels'},
    {label: 'SHIP_BY', value: 'ship_by_date'},
  ],
}

export const OPTION_INSURANCE_TYPE = [
  {label: 'NONE', value: null},
  {label: 'CARRIER', value: 'carrier'},
]

export const OPTION_DIMENSION_UNIT = [
  {label: 'in', value: 'in'},
  {label: 'cm', value: 'cm'},
  {label: 'mm', value: 'mm'},
]

export const OPTION_WEIGHT_UNIT = [
  {label: 'oz', value: 'oz'},
  {label: 'lb', value: 'lb'},
  {label: 'gm', value: 'gm'},
  {label: 'kg', value: 'kg'},
]

export const UNIT_VALUES = {
  WEIGHT_CARRIER: {
    oz: {max: 1120},
    lb: {max: 70},
    gm: {max: 31751},
    kg: {max: 31.5},
  },
  WEIGHT: {
    oz: {max: 10000},
    lb: {max: 625},
    gm: {max: 283495},
    kg: {max: 283.5},
  },
  DIMENSION: {
    in: {max: 100},
    cm: {max: 254},
    mm: {max: 2540},
  },
}

export const OPTIONS_CONTENT_TYPE_UPS = [
  {label: 'GIFT', value: 'gift'},
  {label: 'DOCUMENTS', value: 'documents'},
  {label: 'SAMPLE', value: 'sample'},
  {label: 'OTHER', value: 'other'},
]

export const OPTIONS_CONTENT_TYPE = [
  {label: 'MERCHANDISE', value: 'merchandise'},
  {label: 'GIFT', value: 'gift'},
  {label: 'DOCUMENTS', value: 'documents'},
  {label: 'SAMPLE', value: 'sample'},
  {label: 'RETURN', value: 'return'},
  {label: 'HUMANITARIAN', value: 'humanitarian'},
  {label: 'DANGEROUS_GOODS', value: 'dangerousgoods'},
  {label: 'OTHER', value: 'other'},
]

export const OPTION_PACKAGE_TYPE = {
  INPUT_DIMENSION: {label: 'INPUT_DIMENSION', value: 'input_dimension'},
  CUSTOM_PACKAGE: {label: 'CUSTOM_PACKAGE', value: 'custom_package'},
  CARRIER_STANDARD_PACKAGE: {label: 'CARRIER_STANDARD_PACKAGE', value: 'carrier_standard_package'},
}

export const PARAMS_ORDER_FILTER = [
  'date_from',
  'date_to',
  'batch',
  'ship_by_date_from',
  'ship_by_date_to',
  'customer',
  'order_ref',
  'service',
  'package_info',
  'preset_id',
  'assigned_id',
  'item_name',
  'item_sku',
  'tag_id',
]

export const SORT_KEYS_ORDER_VALIDATE = [
  'from',
  'to',
  'parcels',
  'check_weight',
  'ship_by_date',
  'international_option',
  'custom_items',
  'reference1',
  'order_items',
  'signature_confirmation',
  'insurance_option',
]

export const OrderType = {
  SHIPBAE: 'shipbae',
  SHIPSTATION: 'shipstation',
  SHOPIFY: 'shopify',
  WALMART: 'walmart',
  TIKTOK: 'tiktok',
  EBAY: 'ebay',
}

export const HELP_CENTER_WIDGET_PARAMS = {
  ORDER: {
    label: 'QUESTION_TOOLTIP_AT_ORDER_PAGE',
    href: '/help-center/create-shipment/manual-order',
  },
  BATCHES: {
    label: 'QUESTION_TOOLTIP_AT_BATCHES_PAGE',
    href: '/help-center/create-shipment/batch-order',
  },
  DEPOSITS: {
    label: 'QUESTION_TOOLTIP_AT_DEPOSITS',
    href: '/help-center/payment',
  },
}

export const COLORS = [
  {name: 'RED_ORANGE', color: '#ef462d'},
  {name: 'PINK_RED', color: '#e42165'},
  {name: 'PURPLE', color: '#9920b7'},
  {name: 'DARK_PURPLE', color: '#6535be'},
  {name: 'BLUE_PURPLE', color: '#404ebc'},
  {name: 'GREEN', color: '#55b03e'},
  {name: 'TEAL', color: '#209688'},
  {name: 'LIGHT_BLUE', color: '#2abbd7'},
  {name: 'BRIGHT_BLUE', color: '#25a7fb'},
  {name: 'MEDIUM_BLUE', color: '#2f93fa'},
  {name: 'LIME_GREEN', color: '#8ec429'},
  {name: 'YELLOW_GREEN', color: '#cede00'},
  {name: 'BRIGHT_YELLOW', color: '#ffed00'},
  {name: 'GOLD', color: '#fdc300'},
  {name: 'ORANGE', color: '#fc9a00'},
  {name: 'BLACK', color: '#000000'},
  {name: 'GRAY_BLUE', color: '#617d8c'},
  {name: 'LIGHT_GRAY', color: '#9e9e9e'},
  {name: 'BROWN', color: '#785646'},
  {name: 'DARK_ORANGE', color: '#fa5a01'},
]
