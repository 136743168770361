import {IntegrationsPageLayout} from '../../../_gori/layout/components/IntegrationsPagesLayout'

export function EBayIntegration() {
  return (
    <>
      <IntegrationsPageLayout
        pageClass='ebay-integration'
        heading='EBAY_HEADER'
        headingSubtext='EBAY_SUBTEXT'
        integrationLogo='/media/gori/integrations/ebay/ebay-logo.svg'
        integrationLogoAlt='ebay-logo'
        integrateImg='/media/gori/integrations/ebay/integrate.webp'
        card1Copy='EBAY_CARD_1'
        card2Copy='EBAY_CARD_2'
        card3Copy='EBAY_CARD_3'
        card4Copy='EBAY_CARD_4'
      />
    </>
  )
}
