import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {CSSTransition} from 'react-transition-group'
import * as Yup from 'yup'
import {DEFAULT_PAGE, DEFAULT_PAGE_SIZE, TABLE_KEY_COLUMN} from '../../../../../../_gori/constants'
import {KTSVG, setLoadingActionTable, useDisabled} from '../../../../../../_gori/helpers'
import {TableSkeleton} from '../../../../../../_gori/helpers/components'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import UseRoutesPermission from '../../../../../../_gori/hooks/UseRoutesPermission'
import UseYupValidate from '../../../../../../_gori/hooks/UseYupValidate'
import {
  ConfirmActionSwal,
  DropdownButton,
  Table,
  TableBody,
  TableWrapper,
} from '../../../../../../_gori/partials/widgets'
import {useAuth} from '../../../../auth'
import {FromOrToModal} from '../../../../orders'
import {OrdersConfig} from '../../../../orders/core/_const'
import SettingsService from '../../../core/_requests'
import {ShippingAddressFilter} from './ShippingAddressFilter'

const ShippingAddressPage: React.FC = () => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const {stringYup, orderYup, regexYup, infoYup} = UseYupValidate()
  const {setPageDisabled} = useDisabled()
  const {loadingSwitch} = useAuth()
  const [loadingFirst, setLoadingFirst] = useState<Boolean>(true)
  const [selectedListId, setSelectedListId] = useState<any>([])
  const [tableData, setTableData] = useState<any>([])
  const [pagination, setPagination] = useState<any>({})
  const [showModal, setShowModal] = useState<{fromTo: boolean; confirmDelete: boolean}>({
    fromTo: false,
    confirmDelete: false,
  })
  const {routes} = UseRoutesPermission()
  const [idEdit, setIdEdit] = useState<number | undefined>(undefined)
  const [idDelete, setIdDelete] = useState<number | undefined>(undefined)

  useEffect(() => {
    const type = searchParams.get('type')
    if (!type) return
    if (!['to', 'from', 'return_to'].includes(type)) {
      return navigate('/settings/shipping-addresses')
    }
  }, [navigate, searchParams])

  const nameTab: {name: 'to' | 'from'; hasReturn: boolean} = useMemo(() => {
    const type = searchParams.get('type') || 'from'
    const typeMapping = {
      to: {name: 'to', hasReturn: false},
      return_to: {name: 'to', hasReturn: true},
      from: {name: 'from', hasReturn: false},
    }
    return typeMapping[type]
  }, [searchParams])

  const initFilterValue = useMemo(() => {
    return {type: 'to'}
  }, [])

  const setTable = useCallback(async () => {
    setPageDisabled(true)
    const config = {
      params: {
        page: searchParams.get('page') || DEFAULT_PAGE,
        page_size: searchParams.get('page_size') || DEFAULT_PAGE_SIZE,
        type: (nameTab.hasReturn ? `return_${nameTab.name}` : nameTab.name) || initFilterValue.type,
      },
      cancelToken: newCancelToken(),
    }

    await SettingsService.getAddress(config)
      .then((response: any) => {
        let newOrder = Array.from(response.shipping_addresses, (order: any) => {
          return {
            ...order,
            canCheck: true,
          }
        })
        setTableData(newOrder)
        setPagination(response.pagination)
      })
      .catch((error) => {
        if (isCancel(error)) return
      })
      .finally(() => {
        setPageDisabled(false)
        setLoadingFirst(false)
      })
  }, [
    initFilterValue.type,
    isCancel,
    nameTab.hasReturn,
    nameTab.name,
    newCancelToken,
    searchParams,
    setPageDisabled,
  ])

  useEffect(() => {
    if (loadingSwitch) return
    setTable()

    return () => {}
  }, [searchParams, loadingSwitch, setTable])

  const validateAddress = useCallback(
    (name) => {
      return {
        [`${name}_company`]: Yup.string().when([`${name}_first_name`], {
          is: (first_name) => !first_name || first_name.trim() === '',
          then: orderYup.companyFirstName('COMPANY'),
          otherwise: stringYup(255, 'COMPANY', false),
        }),
        [`${name}_first_name`]: Yup.string().when(`${name}_company`, {
          is: (company) => !company || company.trim() === '',
          then: orderYup.companyFirstName('FIRST_NAME'),
          otherwise: stringYup(255, 'FIRST_NAME', false),
        }),
        [`${name}_street1`]: stringYup(255, 'ADDRESS'),
        [`${name}_city`]: stringYup(255, 'CITY'),
        [`${name}_state`]: Yup.string().when(`${name}_country`, {
          is: (val) => val === 'US',
          then: regexYup.state(),
          otherwise: stringYup(255, 'STATE'),
        }),
        [`${name}_country`]: regexYup.country,
        [`${name}_zip`]: Yup.string().when(`${name}_country`, {
          is: (val) => val === 'US',
          then: regexYup.zipCode(),
          otherwise: stringYup(255, 'POSTAL_CODE'),
        }),
        [`${name}_last_name`]: stringYup(255, 'LAST_NAME', false),
        [`${name}_street2`]: stringYup(255, 'ADDRESS_2', false),
        [`${name}_phone`]: infoYup.phone('PHONE', false),
        [`${name}_email`]: infoYup.email('EMAIL', false),
      }
    },
    [infoYup, orderYup, regexYup, stringYup]
  )

  const initialValues = useMemo(() => {
    const init: any = Object.entries(OrdersConfig.SHIPPING).reduce((initValue, [key, value]) => {
      switch (value.value) {
        case OrdersConfig.SHIPPING.VERIFIED.value:
        case OrdersConfig.SHIPPING.SAVE_ADDRESS.value:
          initValue[`${nameTab.name}_${value.value}`] = false
          break
        case OrdersConfig.SHIPPING.RESIDENTIAL.value:
          initValue[`${nameTab.name}_${value.value}`] = nameTab.name === 'to'
          break

        default:
          initValue[`${nameTab.name}_${value.value}`] = ''
          break
      }
      return initValue
    }, {})

    return init
  }, [nameTab.name])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async () => {},
  })

  const handleEditItem = useCallback(
    (data) => {
      setShowModal((prev) => ({...prev, fromTo: true}))
      const _data: any = Object.entries(OrdersConfig.SHIPPING).reduce((initValue, [key, value]) => {
        switch (value.value) {
          case OrdersConfig.SHIPPING.VERIFIED.value:
          case OrdersConfig.SHIPPING.RESIDENTIAL.value:
            initValue[`${nameTab.name}_${value.value}`] = data[value.value] || false
            break
          case OrdersConfig.SHIPPING.SAVE_ADDRESS.value:
            initValue[`${nameTab.name}_${value.value}`] = !!data[value.value]
            break
          default:
            initValue[`${nameTab.name}_${value.value}`] = data[value.value] || ''
            break
        }
        return initValue
      }, {})
      formik.setValues(_data)
      setIdEdit(data.id)
    },
    [formik, nameTab.name]
  )

  const handleEditItemSuccess = useCallback(() => {
    setShowModal((prev) => ({...prev, fromTo: false}))
    setIdEdit(undefined)
    formik.resetForm()
    setTable()
  }, [formik, setTable])

  const handleDeleteItem = useCallback(async () => {
    if (!idDelete) return
    setTableData(setLoadingActionTable(tableData, [idDelete], true))

    const payload = {
      ids: [idDelete],
    }

    try {
      const res = await SettingsService.deleteAddress(payload, {cancelToken: newCancelToken()})
      if (res.status) {
        setTable()
        setIdDelete(undefined)
        toast.success(intl.formatMessage({id: 'DELETED_SUCCESSFULLY'}))
      }
    } catch (error) {
      if (isCancel(error)) return
      toast.error(intl.formatMessage({id: 'DELETED_FAILED'}))
    } finally {
      setTableData(setLoadingActionTable(tableData, [idDelete], false))
    }
  }, [idDelete, intl, isCancel, newCancelToken, setTable, tableData])

  const columns = useMemo(
    () => [
      {
        Header: intl.formatMessage({id: 'ID'}),
        accessor: 'canCheck',
      },
      {
        Header: intl.formatMessage({id: 'COMPANY'}),
        headerClassName: 'min-w-125px',
        cellClassName: 'text-dark',
        Cell: ({row}: {row: any}) => {
          return <div>{row.original.company}</div>
        },
      },
      {
        Header: intl.formatMessage({id: 'NAME'}),
        headerClassName: 'min-w-150px',
        Cell: ({row}: {row: any}) => {
          return (
            <div>
              {row.original.first_name} {row.original.last_name}
            </div>
          )
        },
      },
      {
        Header: intl.formatMessage({id: 'EMAIL'}),
        headerClassName: 'min-w-100px',
        Cell: ({row}: {row: any}) => {
          return <div>{row.original?.email}</div>
        },
      },
      {
        Header: intl.formatMessage({id: 'PHONE'}),
        headerClassName: 'min-w-100px',
        Cell: ({row}: {row: any}) => {
          return <div>{row.original.phone}</div>
        },
      },
      {
        Header: intl.formatMessage({id: 'ADDRESS'}),
        headerClassName: 'min-w-150px',
        Cell: ({row}: {row: any}) => <div>{row.original.street1}</div>,
      },
      {
        Header: intl.formatMessage({id: 'CITY'}),
        headerClassName: 'min-w-150px',
        Cell: ({row}: {row: any}) => {
          return <div>{row.original.city}</div>
        },
      },
      {
        Header: intl.formatMessage({id: 'STATE'}),
        headerClassName: 'min-w-100px text-center',
        cellClassName: 'text-center',
        Cell: ({row}: {row: any}) => {
          return <div>{row.original.state}</div>
        },
      },
      {
        Header: intl.formatMessage({id: 'POSTAL_CODE'}),
        headerClassName: 'min-w-150px text-center',
        cellClassName: 'text-center',
        Cell: ({row}: {row: any}) => {
          return <div>{row.original.zip}</div>
        },
      },
      {
        Header: intl.formatMessage({id: 'COUNTRY'}),
        headerClassName: 'min-w-100px text-center',
        cellClassName: 'text-center',
        Cell: ({row}: {row: any}) => {
          return <div>{row.original.country}</div>
        },
      },
      {
        Header: intl.formatMessage({id: 'VERIFIED_ADDRESS'}),
        headerClassName: 'min-w-150px text-center',
        cellClassName: 'text-center',
        Cell: ({row}: {row: any}) => {
          return row.original.is_verified ? (
            <KTSVG path='/media/gori/orders/success.svg' small={false} />
          ) : (
            <KTSVG path='/media/gori/orders/error.svg' small={false} />
          )
        },
      },
      {
        id: TABLE_KEY_COLUMN.SUB_ACTIONS,
        cellClassName: 'fixed-column',
        Cell: ({row}: {row: any}) => (
          <DropdownButton
            loading={row.original.isLoading}
            list={[
              {
                label: intl.formatMessage({id: 'EDIT'}),
                action: () => {
                  handleEditItem(row.original)
                },
                className: 'cursor-pointer',
                hidden: !routes.SETTINGS_ADDRESSES.hasPermission,
              },
              {
                label: intl.formatMessage({id: 'DELETE'}),
                action: () => {
                  setIdDelete(row.original.id)
                  setShowModal((prev) => ({...prev, confirmDelete: true}))
                },
                className: 'cursor-pointer',
                hidden: !routes.SETTINGS_ADDRESSES.hasPermission,
              },
            ]}
          />
        ),
      },
    ],
    [handleEditItem, intl, routes]
  )

  return (
    <>
      {showModal.confirmDelete && (
        <ConfirmActionSwal
          show={showModal.confirmDelete}
          title={intl.formatMessage({id: 'DELETE_ADDRESS'})}
          message={intl.formatMessage({id: 'ARE_YOU_SURE'})}
          messageCancel={intl.formatMessage({id: 'NO'})}
          handleCallBack={handleDeleteItem}
          handleClose={() => setShowModal((prev) => ({...prev, confirmDelete: false}))}
        />
      )}
      {showModal.fromTo && (
        <FromOrToModal
          data={{
            show: showModal.fromTo,
            name: nameTab.name,
            data: formik.values,
            validate: validateAddress(nameTab.name),
          }}
          handleSave={handleEditItemSuccess}
          handleClose={() => {
            formik.resetForm()
            setShowModal((prev) => ({...prev, fromTo: false}))
          }}
          idEdit={idEdit}
          hasReturn={nameTab.hasReturn}
          autoSave
        />
      )}
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        {loadingFirst ? (
          <>
            <TableWrapper>
              <TableBody>
                <div className='mt-6 d-flex  align-item-center justify-content-between'>
                  <div className='col-3'>
                    <span className='fs-1 col-12 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
                  </div>
                  <div className='col-2 d-flex flex-row-reverse'>
                    <span className='btn col-5 placeholder placeholder-lg rounded-2 bg-secondary' />
                  </div>
                </div>
                <hr className='mb-8' />
                <TableSkeleton countRow={8} />
              </TableBody>
            </TableWrapper>
          </>
        ) : (
          <>
            <TableWrapper>
              <div className='mb-4'>
                <div className='card-header card-header-stretch d-flex h-55px'>
                  <div className='card-title'>
                    <h3>{intl.formatMessage({id: 'SHIPPING_ADDRESSES'})}</h3>
                  </div>
                  <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                    <li className='nav-item'>
                      <Link
                        to={'/settings/shipping-addresses?type=from'}
                        className={clsx('nav-link text-active-primary me-6 ', {
                          active: nameTab.name === 'from' && !nameTab.hasReturn,
                        })}
                      >
                        {intl.formatMessage({id: 'SHIP_FROM'})}
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link
                        to={'/settings/shipping-addresses?type=to'}
                        className={clsx('nav-link text-active-primary me-6 ', {
                          active: nameTab.name === 'to' && !nameTab.hasReturn,
                        })}
                      >
                        {intl.formatMessage({id: 'SHIP_TO'})}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <TableBody>
                <ShippingAddressFilter
                  selectedListId={selectedListId}
                  reloadTable={() => {
                    setTable()
                  }}
                  dataInit={{
                    data: formik.values,
                    nameTab: nameTab,
                    validate: validateAddress(nameTab.name),
                  }}
                />
                <Table
                  columns={columns}
                  data={tableData}
                  pagination={pagination}
                  tbodyClass='text-gray-600 fw-bold'
                  usePagination
                  useCheckBox
                  callBackSetData={(selected) => {
                    setSelectedListId(selected)
                  }}
                />
              </TableBody>
            </TableWrapper>
          </>
        )}
      </CSSTransition>
    </>
  )
}

export {ShippingAddressPage}
