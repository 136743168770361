import React, {useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {
  Button,
  ButtonBack,
  InputTextFormik,
  SelectFormik,
  ValidationErrorModal,
} from '../../../../../_gori/partials/widgets'
import * as Yup from 'yup'
import UseYupValidate from '../../../../../_gori/hooks/UseYupValidate'
import {OPTION_COUNTRIES} from '../../../../../_gori/constants'
import {find, includes, isEmpty} from 'lodash'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import OnboardService from '../../core/_requests'
import {toast} from 'react-toastify'
import {KTSVG} from '../../../../../_gori/helpers'
import {CSSTransition} from 'react-transition-group'
import {OPTIONS_METHODS, OPTIONS_SERVICES, STEPS} from '../../core/_const'
import {useAuth} from '../../../auth'
import {ButtonLabelCreation} from '../../../../../_gori/partials/widgets/form/ButtonLabelCreation'
import {useNavigate} from 'react-router-dom'
import {AutocompleteAddress} from '../../../common'
import {InputCheckboxFormik} from '../../../../../_gori/partials/widgets/form/InputCheckboxFormik'
import UserService from '../../../users/core/_requests'

type Props = {
  setActiveStep: any
  onboard?: any
  setOnboard?: any
  setErrorValidate?: any
}

const Shipping: React.FC<Props> = ({setActiveStep, onboard, setOnboard, setErrorValidate}) => {
  const intl = useIntl()
  const {stringYup, regexYup} = UseYupValidate()
  const [validationErrors, setValidationErrors] = useState<any>()
  const {newCancelToken, isCancel} = useCancelToken()
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false)
  const {currentUser, setCurrentUser} = useAuth()
  const [isLoadingSaveAndExit, setIsLoadingSaveAndExit] = useState<boolean>(false)
  const [saveAndExit, setSaveAndExit] = useState<boolean>(false)
  const [sameAddress, setSameAddress] = useState<boolean>(false)
  const [sameAddressUpsFedex, setSameAddressUpsFedex] = useState<boolean>(false)
  const navigate = useNavigate()
  const OPTIONS_SERVICES_CUSTOM = useMemo(() => {
    return [...OPTIONS_SERVICES, {label: intl.formatMessage({id: 'NOT_SURE'}), value: 'not_sure'}]
  }, [intl])
  const OPTIONS_METHODS_CUSTOM = useMemo(() => {
    return [...OPTIONS_METHODS, {label: intl.formatMessage({id: 'NOT_SURE'}), value: 'not_sure'}]
  }, [intl])

  const [usps, setUsps] = useState<boolean>(false)
  const [uspsDisable, setUspsDisable] = useState<boolean>(false)

  const [ups, setUps] = useState<boolean>(false)
  const [upsDisable, setUpsDisable] = useState<boolean>(false)

  const [fedex, setFedex] = useState<boolean>(false)
  const [fedexDisable, setFedexDisable] = useState<boolean>(false)

  const [dhl, setDhl] = useState<boolean>(false)
  const [dhlDisable, setDhlDisable] = useState<boolean>(false)

  const [serviceNotSure, setServiceNotSure] = useState<boolean>(false)
  const [serviceNotSureDisable, setServiceNotSureDisable] = useState<boolean>(false)

  const [methodShipbae, setMethodShipbae] = useState<boolean>(false)
  const [methodShipbaeDisable, setMethodShipbaeDisable] = useState<boolean>(false)

  const [methodGori, setMethodGori] = useState<boolean>(false)
  const [methodGoriDisable, setMethodGoriDisable] = useState<boolean>(false)

  const [methodNotSure, setMethodNotSure] = useState<boolean>(false)
  const [methodNotSureDisable, setMethodNotSureDisable] = useState<boolean>(false)

  const [isRequiredService, setIsRequiredService] = useState<boolean>(false)
  const [isRequiredMethod, setIsRequiredMethod] = useState<boolean>(false)

  const [isFormUpsFedex, setIsFormUpsFedex] = useState<boolean>(false)

  useEffect(() => {
    if (ups || fedex) {
      setIsFormUpsFedex(true)
    } else {
      setIsFormUpsFedex(false)
    }
  }, [fedex, ups])

  const validationSchema = useMemo(() => {
    let userStep = STEPS[`${currentUser?.onboard_step}`] ?? 0
    let currentStep = STEPS[`shipping`]
    let required = true
    if (currentStep >= userStep && saveAndExit) {
      required = false
    }
    return {
      country: stringYup(255, 'COUNTRY', required),
      street1: stringYup(255, 'ADDRESS', required),
      street2: stringYup(255, 'ADDRESS_2', false),
      city: stringYup(255, 'CITY', required),
      zip: Yup.string().when(`country`, {
        is: (val) => val === 'US',
        then: regexYup.zipCode('POSTAL_CODE', required),
        otherwise: stringYup(255, 'POSTAL_CODE', required),
      }),
      state: Yup.string().when(`country`, {
        is: (val) => val === 'US',
        then: regexYup.state('STATE', required),
        otherwise: stringYup(255, 'STATE', required),
      }),
      ups_fedex_country: stringYup(255, 'COUNTRY', isFormUpsFedex),
      ups_fedex_street1: stringYup(255, 'ADDRESS', isFormUpsFedex),
      ups_fedex_street2: stringYup(255, 'ADDRESS_2', false),
      ups_fedex_city: stringYup(255, 'CITY', isFormUpsFedex),
      ups_fedex_zip: Yup.string().when(`ups_fedex_country`, {
        is: (val) => val === 'US',
        then: regexYup.zipCode('POSTAL_CODE', isFormUpsFedex),
        otherwise: stringYup(255, 'POSTAL_CODE', isFormUpsFedex),
      }),
      ups_fedex_state: Yup.string().when(`ups_fedex_country`, {
        is: (val) => val === 'US',
        then: regexYup.state('STATE', isFormUpsFedex),
        otherwise: stringYup(255, 'STATE', isFormUpsFedex),
      }),
      enabled: Yup.boolean(),
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveAndExit, isFormUpsFedex])

  const checkSameAddress = async (input) => {
    const shipping = onboard?.profile
    let isSame = true
    if (!isEmpty(input) && !isEmpty(shipping) && sameAddress) {
      if ((input['country'] ?? '') !== (shipping['country'] ?? '')) {
        isSame = false
      }

      if ((input['street1'] ?? '') !== (shipping['street1'] ?? '')) {
        isSame = false
      }

      if ((input['street2'] ?? '') !== (shipping['street2'] ?? '')) {
        isSame = false
      }
      if ((input['city'] ?? '') !== (shipping['city'] ?? '')) {
        isSame = false
      }
      if ((input['state'] ?? '') !== (shipping['state'] ?? '')) {
        isSame = false
      }
      if ((input['zip'] ?? '') !== (shipping['zip'] ?? '')) {
        isSame = false
      }
      setSameAddress(isSame)
    }
  }
  const checkSameAddressUpsFedex = async (input) => {
    const profile = formik.values
    let isSameUpsFedex = true

    if (!isEmpty(input) && !isEmpty(profile) && sameAddressUpsFedex) {
      if ((input['ups_fedex_country'] ?? '') !== (profile['country'] ?? '')) {
        isSameUpsFedex = false
      }

      if ((input['ups_fedex_street1'] ?? '') !== (profile['street1'] ?? '')) {
        isSameUpsFedex = false
      }

      if ((input['ups_fedex_street2'] ?? '') !== (profile['street2'] ?? '')) {
        isSameUpsFedex = false
      }
      if ((input['ups_fedex_city'] ?? '') !== (profile['city'] ?? '')) {
        isSameUpsFedex = false
      }
      if ((input['ups_fedex_state'] ?? '') !== (profile['state'] ?? '')) {
        isSameUpsFedex = false
      }

      if ((input['ups_fedex_zip'] ?? '') !== (profile['zip'] ?? '')) {
        isSameUpsFedex = false
      }

      setSameAddressUpsFedex(isSameUpsFedex)
    }
  }

  useEffect(() => {
    let dataService = onboard?.shipping?.interested_services ?? []
    dataService.map((item: string) => {
      if (item === 'ups') {
        setUps(true)
      }
      if (item === 'usps') {
        setUsps(true)
      }
      if (item === 'fedex') {
        setFedex(true)
      }
      if (item === 'dhl') {
        setDhl(true)
      }
      if (item === 'not_sure') {
        setServiceNotSure(true)
      }
    })

    let dataMethod = onboard?.shipping?.using_methods ?? []

    dataMethod.map((item: string) => {
      if (item === 'shipbae') {
        setMethodShipbae(true)
      }
      if (item === 'gori_api') {
        setMethodGori(true)
      }
      if (item === 'not_sure') {
        setMethodNotSure(true)
      }
    })
  }, [onboard?.shipping?.interested_services, onboard?.shipping?.using_methods])

  const initialValues = useMemo(() => {
    let dataShippingDefault = {
      country: onboard?.shipping?.country,
      street1: onboard?.shipping?.street1,
      street2: onboard?.shipping?.street2,
      city: onboard?.shipping?.city,
      zip: onboard?.shipping?.zip,
      state: onboard?.shipping?.state,
      is_same_address: onboard?.shipping?.is_same_address,
    }

    let dataShippingUspFedex = {
      country: onboard?.shipping?.ups_fedex_country,
      street1: onboard?.shipping?.ups_fedex_street1,
      street2: onboard?.shipping?.ups_fedex_street2,
      city: onboard?.shipping?.ups_fedex_city,
      zip: onboard?.shipping?.ups_fedex_zip,
      state: onboard?.shipping?.ups_fedex_state,
      is_same_address: onboard?.shipping?.ups_fedex_is_same_address,
    }

    setSameAddress(dataShippingDefault.is_same_address)
    setSameAddressUpsFedex(dataShippingUspFedex.is_same_address)

    let arrInterestedServices = onboard?.shipping?.interested_services ?? []
    const checkEditsUps = arrInterestedServices.includes('ups')
    const checkEditsFedex = arrInterestedServices.includes('fedex')
    if (checkEditsUps || checkEditsFedex) {
      setIsFormUpsFedex(true)
    } else {
      setIsFormUpsFedex(false)
    }

    return {
      interested_services:
        OPTIONS_SERVICES_CUSTOM.filter((option) =>
          onboard?.shipping?.interested_services?.includes(option.value)
        ) ?? [],
      using_methods:
        OPTIONS_METHODS_CUSTOM.filter((option) =>
          onboard?.shipping?.using_methods?.includes(option.value)
        ) ?? [],
      country: dataShippingDefault?.country ?? '',
      street1: dataShippingDefault?.street1 ?? '',
      street2: dataShippingDefault?.street2 ?? '',
      city: dataShippingDefault?.city ?? '',
      zip: dataShippingDefault?.zip ?? '',
      state: dataShippingDefault?.state ?? '',
      is_same_address: checkEditsUps || checkEditsFedex ? dataShippingDefault?.is_same_address : '',
      ups_fedex_country: checkEditsUps || checkEditsFedex ? dataShippingUspFedex?.country : '',
      ups_fedex_street1: checkEditsUps || checkEditsFedex ? dataShippingUspFedex?.street1 : '',
      ups_fedex_street2: checkEditsUps || checkEditsFedex ? dataShippingUspFedex?.street2 : '',
      ups_fedex_city: checkEditsUps || checkEditsFedex ? dataShippingUspFedex?.city : '',
      ups_fedex_zip: checkEditsUps || checkEditsFedex ? dataShippingUspFedex?.zip : '',
      ups_fedex_state: checkEditsUps || checkEditsFedex ? dataShippingUspFedex?.state : '',
      ups_fedex_is_same_address:
        checkEditsUps || checkEditsFedex ? dataShippingUspFedex?.is_same_address : false,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboard])

  const nextStep = () => {
    setActiveStep(STEPS.ob_general)
  }

  const getInterestedService = () => {
    let data: any = []
    if (serviceNotSure) {
      data = ['not_sure']
    } else {
      if (ups) {
        data.push('ups')
      }
      if (usps) {
        data.push('usps')
      }
      if (fedex) {
        data.push('fedex')
      }
      if (dhl) {
        data.push('dhl')
      }
    }

    return data
  }
  const getMethodService = () => {
    let data: any = []
    if (methodNotSure) {
      data = ['not_sure']
    } else {
      if (methodShipbae) {
        data.push('shipbae')
      }

      if (methodGori) {
        data.push('gori_api')
      }
    }

    return data
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(validationSchema),
    onSubmit: async (values, {setSubmitting}) => {
      if (!saveAndExit) {
        setSubmitting(true)
        setIsLoadingForm(true)
      } else {
        setIsLoadingSaveAndExit(true)
      }
      let payload = {
        step: 'shipping',
        data: {
          ...values,
          is_same_address: sameAddress,
          ups_fedex_is_same_address: sameAddressUpsFedex,
          interested_services: getInterestedService(),
          using_methods: getMethodService(),
        },
        save_and_exit: saveAndExit,
      }

      if (!isFormUpsFedex) {
        payload.data.ups_fedex_city = ''
        payload.data.ups_fedex_country = ''
        payload.data.ups_fedex_is_same_address = false
        payload.data.ups_fedex_state = ''
        payload.data.ups_fedex_street1 = ''
        payload.data.ups_fedex_street2 = ''
        payload.data.ups_fedex_zip = ''
      }
      try {
        const config = {cancelToken: newCancelToken()}
        const res = await OnboardService.store(payload, config)
        if (res) {
          setCurrentUser(await UserService.getUser(config))
          let newOnboard = {
            shipping: payload.data,
            hazmat: onboard.hazmat,
            profile: onboard.profile,
            ob_general: onboard.ob_general,
          }
          setOnboard(newOnboard)

          setSameAddress(payload.data.is_same_address)
          setSameAddressUpsFedex(payload.data.ups_fedex_is_same_address)

          let interested_services = payload.data.interested_services

          interested_services.map((item) => {
            if (item === 'not_sure') {
              setUspsDisable(false)
              setUpsDisable(false)
              setFedexDisable(false)
              setDhlDisable(false)
            } else {
              setUspsDisable(true)
              setUpsDisable(true)
              setFedexDisable(true)
              setDhlDisable(true)
            }
          })

          toast.success(intl.formatMessage({id: 'UPDATE_SHIPPING_SUCCESSFULLY'}))
          nextStep()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        let errorFields = error?.response?.data?.error?.fields
        if (errorFields.interested_services || errorFields.using_methods) {
          if (errorFields.interested_services) {
            setIsRequiredService(true)
          }
          if (errorFields.using_methods) {
            setIsRequiredMethod(true)
          }
        } else {
          setValidationErrors(error?.response)
        }
      } finally {
        if (!saveAndExit) {
          setSubmitting(false)
          setIsLoadingForm(false)
        } else {
          setIsLoadingSaveAndExit(false)
          navigate('/logout')
        }
      }
    },
  })

  const backStep = () => {
    setActiveStep(STEPS.profile)
  }

  const checkHasSaved = () => {
    let userStep = STEPS[`${currentUser?.onboard_step}`]
    let currentStep = STEPS[`shipping`]
    return userStep > currentStep
  }

  useEffect(() => {
    if (!serviceNotSure) {
      setUspsDisable(false)
      setUpsDisable(false)
      setFedexDisable(false)
      setDhlDisable(false)
    } else {
      setUspsDisable(true)
      setUpsDisable(true)
      setFedexDisable(true)
      setDhlDisable(true)
    }

    if (!methodNotSure) {
      setMethodGoriDisable(false)
      setMethodShipbaeDisable(false)
    } else {
      setMethodGoriDisable(true)
      setMethodShipbaeDisable(true)
    }
  }, [serviceNotSure, methodNotSure])

  useEffect(() => {
    checkSameAddress(formik.values)
    checkSameAddressUpsFedex(formik.values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.city,
    formik.values.country,
    formik.values.street1,
    formik.values.street2,
    formik.values.state,
    formik.values.zip,
    formik.values.ups_fedex_country,
    formik.values.ups_fedex_city,
    formik.values.ups_fedex_street1,
    formik.values.ups_fedex_street2,
    formik.values.ups_fedex_state,
    formik.values.ups_fedex_zip,
  ])

  const setSameAddressInput = async (isSame: boolean | ((prevState: boolean) => boolean)) => {
    const address = onboard.profile
    let shipping = onboard.shipping

    formik.setFieldValue('country', isSame ? address.country : shipping?.country ?? '').then(() => {
      formik.setFieldError('country', undefined)
    })
    formik.setFieldValue('street1', isSame ? address.street1 : shipping?.street1 ?? '').then(() => {
      formik.setFieldError('street1', undefined)
    })

    formik
      .setFieldValue('street2', isSame ? address?.street2 ?? '' : shipping?.street2 ?? '')
      .then(() => {
        formik.setFieldError('street2', undefined)
      })
    formik.setFieldValue('city', isSame ? address.city : shipping?.city ?? '').then(() => {
      formik.setFieldError('city', undefined)
    })
    formik.setFieldValue('zip', isSame ? address.zip : shipping?.zip ?? '').then(() => {
      formik.setFieldError('zip', undefined)
    })
    formik.setFieldValue('state', isSame ? address.state : shipping?.state ?? '').then(() => {
      formik.setFieldError('state', undefined)
    })
    setSameAddress(isSame)
  }

  const setSameAddressInputUpsFedex = async (
    isSameUpsFedex: boolean | ((prevState: boolean) => boolean)
  ) => {
    let profile = {
      country: onboard?.shipping?.ups_fedex_country ?? '',
      street1: onboard?.shipping?.ups_fedex_street1 ?? '',
      street2: onboard?.shipping?.ups_fedex_street2 ?? '',
      city: onboard?.shipping?.ups_fedex_city ?? '',
      zip: onboard?.shipping?.ups_fedex_zip ?? '',
      state: onboard?.shipping?.ups_fedex_state ?? '',
    }

    formik
      .setFieldValue(
        'ups_fedex_country',
        isSameUpsFedex ? formik.values.country : profile?.country ?? ''
      )
      .then(() => {
        formik.setFieldError('ups_fedex_country', undefined)
      })
    formik
      .setFieldValue(
        'ups_fedex_street1',
        isSameUpsFedex ? formik.values.street1 : profile?.street1 ?? ''
      )
      .then(() => {
        formik.setFieldError('ups_fedex_street1', undefined)
      })

    formik
      .setFieldValue(
        'ups_fedex_street2',
        isSameUpsFedex ? formik.values.street2 : profile?.street2 ?? ''
      )
      .then(() => {
        formik.setFieldError('ups_fedex_street2', undefined)
      })
    formik
      .setFieldValue('ups_fedex_city', isSameUpsFedex ? formik.values.city : profile?.city ?? '')
      .then(() => {
        formik.setFieldError('ups_fedex_city', undefined)
      })
    formik
      .setFieldValue('ups_fedex_zip', isSameUpsFedex ? formik.values.zip : profile?.zip ?? '')
      .then(() => {
        formik.setFieldError('ups_fedex_zip', undefined)
      })
    formik
      .setFieldValue('ups_fedex_state', isSameUpsFedex ? formik.values.state : profile?.state ?? '')
      .then(() => {
        formik.setFieldError('ups_fedex_state', undefined)
      })

    setSameAddressUpsFedex(isSameUpsFedex)
  }

  useEffect(() => {
    setErrorValidate(!isEmpty(formik.errors) && checkHasSaved())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors])

  const selectedPlace = (selected) => {
    let city = find(selected.address_components, (item) => {
      return includes(item?.types, 'locality')
    })
    formik.setFieldValue(formik.getFieldProps(`city`).name, city?.long_name ?? '')
    let state = find(selected.address_components, (item) => {
      return includes(item?.types, 'administrative_area_level_1')
    })
    formik.setFieldValue(formik.getFieldProps(`state`).name, state?.short_name ?? '')
    let country = find(selected.address_components, (item) => {
      return includes(item?.types, 'country')
    })
    formik.setFieldValue(formik.getFieldProps(`country`).name, country?.short_name ?? '')
    let zipCode = find(selected.address_components, (item) => {
      return includes(item?.types, 'postal_code')
    })
    let suffix = find(selected.address_components, (item) => {
      return includes(item?.types, 'postal_code_suffix')
    })
    if (zipCode?.long_name) {
      zipCode = zipCode?.long_name + (suffix?.long_name ? `-${suffix?.long_name}` : '')
    } else {
      zipCode = ''
    }
    formik.setFieldValue('zip', zipCode)
  }

  const selectedPlaceUpsFedex = (selected) => {
    let ups_fedex_city = find(selected.address_components, (item) => {
      return includes(item?.types, 'locality')
    })
    formik.setFieldValue(
      formik.getFieldProps(`ups_fedex_city`).name,
      ups_fedex_city?.long_name ?? ''
    )
    let ups_fedex_state = find(selected.address_components, (item) => {
      return includes(item?.types, 'administrative_area_level_1')
    })
    formik.setFieldValue(
      formik.getFieldProps(`ups_fedex_state`).name,
      ups_fedex_state?.short_name ?? ''
    )
    let ups_fedex_country = find(selected.address_components, (item) => {
      return includes(item?.types, 'country')
    })
    formik.setFieldValue(
      formik.getFieldProps(`ups_fedex_country`).name,
      ups_fedex_country?.short_name ?? ''
    )
    let ups_fedex_zip_code = find(selected.address_components, (item) => {
      return includes(item?.types, 'postal_code')
    })
    let suffix = find(selected.address_components, (item) => {
      return includes(item?.types, 'postal_code_suffix')
    })
    if (ups_fedex_zip_code?.long_name) {
      ups_fedex_zip_code =
        ups_fedex_zip_code?.long_name + (suffix?.long_name ? `-${suffix?.long_name}` : '')
    } else {
      ups_fedex_zip_code = ''
    }
    formik.setFieldValue('ups_fedex_zip', ups_fedex_zip_code)
  }

  const handleEventServiceNotSure = () => {
    setServiceNotSure(!serviceNotSure)
    if (!serviceNotSure) {
      setUspsDisable(true)
      setUpsDisable(true)
      setFedexDisable(true)
      setDhlDisable(true)
    } else {
      setUspsDisable(false)
      setUpsDisable(false)
      setFedexDisable(false)
      setDhlDisable(false)
    }

    if (!serviceNotSure || fedex || usps || dhl || ups) {
      setIsRequiredService(false)
    } else {
      setIsRequiredService(true)
    }
  }

  useEffect(() => {
    if (usps || ups || fedex || dhl) {
      setServiceNotSureDisable(true)
    } else {
      setServiceNotSureDisable(false)
    }
  }, [usps, ups, fedex, dhl])
  const handleEventMethodNotSure = () => {
    setMethodNotSure(!methodNotSure)
    if (!methodNotSure) {
      setMethodShipbaeDisable(true)
      setMethodGoriDisable(true)
    } else {
      setMethodShipbaeDisable(false)
      setMethodGoriDisable(false)
    }
    if (!methodNotSure || methodGori || methodShipbae) {
      setIsRequiredMethod(false)
    } else {
      setIsRequiredMethod(true)
    }
  }

  useEffect(() => {
    if (methodShipbae || methodGori) {
      setMethodNotSureDisable(true)
    } else {
      setMethodNotSureDisable(false)
    }
  }, [methodShipbae, methodGori])

  const getClassShippingPreference = (value, isDisable) => {
    return clsx('btn-sm btn-none border rounded-2 mb-2 me-2 fs-6', {
      'opacity-50 cursor-no-drop': isDisable,
      'border-hover-primary text-hover-primary  bg-white': !value && !isDisable,
      'btn-primary border-primary text-white': value,
      'btn-light border-dark': !value,
    })
  }

  const setUspsAndCheckShippingService = (value) => {
    setUsps(value)
    if (value || fedex || ups || dhl || serviceNotSure) {
      setIsRequiredService(false)
    } else {
      setIsRequiredService(true)
    }
  }
  const setUpsAndCheckShippingService = (value) => {
    setUps(value)
    if (value || fedex || usps || dhl || serviceNotSure) {
      setIsRequiredService(false)
    } else {
      setIsRequiredService(true)
    }
  }
  const setFedexAndCheckShippingService = (value) => {
    setFedex(value)
    if (value || ups || usps || dhl || serviceNotSure) {
      setIsRequiredService(false)
    } else {
      setIsRequiredService(true)
    }
  }

  const setDhlAndCheckShippingService = (value) => {
    setDhl(value)
    if (value || ups || usps || fedex || serviceNotSure) {
      setIsRequiredService(false)
    } else {
      setIsRequiredService(true)
    }
  }
  const setShipBaeAndCheckMethod = (value) => {
    setMethodShipbae(value)
    if (value || serviceNotSure || methodGori) {
      setIsRequiredMethod(false)
    } else {
      setIsRequiredMethod(true)
    }
  }
  const setGoriApiAndCheckMethod = (value) => {
    setMethodGori(value)
    if (value || serviceNotSure || methodShipbae) {
      setIsRequiredMethod(false)
    } else {
      setIsRequiredMethod(true)
    }
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <>
          <div className='d-flex justify-content-center py-12'>
            <div className='w-75'>
              <div className='row bg-white m-0 p-10 rounded-2 shadow-sm'>
                <div className='col-md-4'>
                  <div className='text-start'>
                    <p className='fs-3 fw-bolder'>
                      {intl.formatMessage({id: 'SHIPPING_PREFERENCE'})}
                    </p>
                    <div className='row'>
                      <div className={clsx('my-2')}>
                        <label className='form-label fw-bolder required'>
                          {intl.formatMessage({id: 'WHAT_SHIPPING_SERVICES_ARE_YOU_INTERESTED_IN'})}
                        </label>
                        <br />
                        <Button
                          className={getClassShippingPreference(usps, uspsDisable)}
                          event={() => setUspsAndCheckShippingService(!usps)}
                          disabled={uspsDisable}
                        >
                          {intl.formatMessage({id: 'USPS'})}
                        </Button>
                        <Button
                          className={getClassShippingPreference(ups, upsDisable)}
                          event={() => setUpsAndCheckShippingService(!ups)}
                          disabled={upsDisable}
                        >
                          {intl.formatMessage({id: 'UPS'})}
                        </Button>
                        <Button
                          className={getClassShippingPreference(fedex, fedexDisable)}
                          event={() => setFedexAndCheckShippingService(!fedex)}
                          disabled={fedexDisable}
                        >
                          {intl.formatMessage({id: 'FEDEX'})}
                        </Button>
                        <Button
                          className={getClassShippingPreference(dhl, dhlDisable)}
                          event={() => setDhlAndCheckShippingService(!dhl)}
                          disabled={dhlDisable}
                        >
                          {intl.formatMessage({id: 'DHL'})}
                        </Button>
                        <Button
                          className={getClassShippingPreference(
                            serviceNotSure,
                            serviceNotSureDisable
                          )}
                          event={() => handleEventServiceNotSure()}
                          disabled={serviceNotSureDisable}
                        >
                          {intl.formatMessage({id: 'NOT_SURE'})}
                        </Button>
                        <br />
                        <div className='fv-help-block text-danger'>
                          <span role='alert'>
                            {isRequiredService
                              ? intl.formatMessage({
                                  id: 'PLEASE_SELECT_SERVICE_SERVICE',
                                })
                              : ''}
                          </span>
                        </div>
                      </div>

                      <div className={clsx('my-2')}>
                        <label className='form-label fw-bolder required'>
                          {intl.formatMessage({
                            id: 'WHICH_METHOD_ARE_YOU_USING_TO_CREATE_YOUR_LABELS',
                          })}
                        </label>{' '}
                        <br />
                        <Button
                          className={getClassShippingPreference(
                            methodShipbae,
                            methodShipbaeDisable
                          )}
                          event={() => setShipBaeAndCheckMethod(!methodShipbae)}
                          disabled={methodShipbaeDisable}
                        >
                          {intl.formatMessage({id: 'SHIPBAE'})}
                        </Button>
                        <Button
                          className={getClassShippingPreference(methodGori, methodGoriDisable)}
                          event={() => setGoriApiAndCheckMethod(!methodGori)}
                          disabled={methodGoriDisable}
                        >
                          {intl.formatMessage({id: 'GORI_API'})}
                        </Button>
                        <Button
                          className={getClassShippingPreference(
                            methodNotSure,
                            methodNotSureDisable
                          )}
                          event={() => handleEventMethodNotSure()}
                          disabled={methodNotSureDisable}
                        >
                          {intl.formatMessage({id: 'NOT_SURE'})}
                        </Button>
                        <br />
                        <div className='fv-help-block text-danger'>
                          <span role='alert'>
                            {isRequiredMethod
                              ? intl.formatMessage({
                                  id: 'PLEASE_SELECT_SHIPPING_METHOD',
                                })
                              : ''}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-8'>
                  <div className='text-start'>
                    <p className='fs-3 fw-bolder'>
                      {intl.formatMessage({id: 'SHIP_FROM_ADDRESS'})}
                    </p>
                    <div className='row bg-light rounded-2 p-4'>
                      <div className={clsx('my-2')}>
                        <div className='d-flex justify-content-between'>
                          <div className='form-label fw-bolder'>
                            <span style={{fontSize: '1.35rem'}}>
                              {intl.formatMessage({id: 'DEFAULT'})}{' '}
                            </span>
                            {intl.formatMessage({id: 'SHIPPING_FROM_ADDRESS_DEFAULT'})}
                          </div>
                          <InputCheckboxFormik
                            formik={formik}
                            type='checkbox'
                            checked={sameAddress}
                            label={intl.formatMessage({id: 'SAME_AS_BUSINESS_ADDRESS'})}
                            onClick={() => setSameAddressInput(!sameAddress)}
                            name={'is_same_address'}
                          />
                        </div>
                      </div>
                      <div className={`my-2 col-3`}>
                        <label className='form-label fw-bolder required'>
                          {intl.formatMessage({id: 'COUNTRY'})}
                        </label>
                        <SelectFormik
                          checkFormik
                          placeholder={''}
                          options={OPTION_COUNTRIES}
                          formik={formik}
                          name={'country'}
                          required
                        />
                      </div>
                      <div className={`my-2 col-6 address-complete`}>
                        <label className={'form-label fw-bolder required'}>
                          {intl.formatMessage({id: 'ADDRESS'})}
                        </label>
                        <AutocompleteAddress
                          formik={formik}
                          name={'street1'}
                          label=''
                          onSelected={(selected) => {
                            selectedPlace(selected)
                          }}
                        />
                      </div>

                      <div className={`my-2 col-3`}>
                        <InputTextFormik
                          formik={formik}
                          name={'street2'}
                          disabled={formik.isSubmitting}
                          label={intl.formatMessage({id: 'ADDRESS_2_DEFAULT'})}
                          labelClassName={'fw-bolder'}
                        />
                      </div>
                      <div className={`my-2 col-3`}>
                        <InputTextFormik
                          formik={formik}
                          name={'city'}
                          disabled={formik.isSubmitting}
                          label={intl.formatMessage({id: 'CITY'})}
                          labelClassName={'fw-bolder required'}
                        />
                      </div>
                      <div className={`my-2 col-3`}>
                        <InputTextFormik
                          formik={formik}
                          name={'zip'}
                          disabled={formik.isSubmitting}
                          label={intl.formatMessage({id: 'POSTAL_CODE'})}
                          labelClassName={'fw-bolder required'}
                        />
                      </div>
                      <div className={`my-2 col-3`}>
                        <InputTextFormik
                          formik={formik}
                          name={'state'}
                          disabled={formik.isSubmitting}
                          label={intl.formatMessage({id: 'STATE'})}
                          labelClassName={'fw-bolder required'}
                        />
                      </div>
                    </div>
                    <div
                      className='row bg-light rounded-2 p-4 mt-4'
                      style={{display: !isFormUpsFedex ? 'none' : ''}}
                    >
                      <div className={clsx('my-2')}>
                        <div className='d-flex justify-content-between'>
                          <label className='form-label fw-bolder'>
                            <h3>{intl.formatMessage({id: 'SHIPPING_FROM_ADDRESS_UPS_FEDEX'})}</h3>
                          </label>
                          <InputCheckboxFormik
                            formik={formik}
                            type='checkbox'
                            checked={sameAddressUpsFedex}
                            label={intl.formatMessage({id: 'SAME_AS_DEFAULT'})}
                            onClick={() => setSameAddressInputUpsFedex(!sameAddressUpsFedex)}
                            name={'ups_fedex_is_same_address'}
                          />
                        </div>
                      </div>
                      <div className={`my-2 col-3`}>
                        <label className='form-label fw-bolder required'>
                          {intl.formatMessage({id: 'COUNTRY'})}
                        </label>
                        <SelectFormik
                          checkFormik
                          placeholder={''}
                          options={OPTION_COUNTRIES}
                          formik={formik}
                          name={'ups_fedex_country'}
                        />
                      </div>
                      <div className={`my-2 col-6 address-complete`}>
                        <label className={'form-label fw-bolder required'}>
                          {intl.formatMessage({id: 'ADDRESS'})}
                        </label>
                        <AutocompleteAddress
                          formik={formik}
                          name={'ups_fedex_street1'}
                          label=''
                          onSelected={(selected) => {
                            selectedPlaceUpsFedex(selected)
                          }}
                        />
                      </div>
                      <div className={`my-2 col-3`}>
                        <InputTextFormik
                          formik={formik}
                          name={'ups_fedex_street2'}
                          disabled={formik.isSubmitting}
                          label={intl.formatMessage({id: 'ADDRESS_2_DEFAULT'})}
                          labelClassName={'fw-bolder'}
                        />
                      </div>
                      <div className={`my-2 col-3`}>
                        <InputTextFormik
                          formik={formik}
                          name={'ups_fedex_city'}
                          disabled={formik.isSubmitting}
                          label={intl.formatMessage({id: 'CITY'})}
                          labelClassName={'fw-bolder required'}
                        />
                      </div>
                      <div className={`my-2 col-3`}>
                        <InputTextFormik
                          formik={formik}
                          name={'ups_fedex_zip'}
                          disabled={formik.isSubmitting}
                          label={intl.formatMessage({id: 'POSTAL_CODE'})}
                          labelClassName={'fw-bolder required'}
                        />
                      </div>
                      <div className={`my-2 col-3`}>
                        <InputTextFormik
                          formik={formik}
                          name={'ups_fedex_state'}
                          disabled={formik.isSubmitting}
                          label={intl.formatMessage({id: 'STATE'})}
                          labelClassName={'fw-bolder required'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mt-10 d-flex justify-content-between'>
                <ButtonBack
                  event={() => backStep()}
                  className='back onboard-form'
                  label={intl.formatMessage({id: 'BACK'})}
                  disabled={
                    (checkHasSaved() && !isEmpty(formik.errors)) ||
                    isLoadingForm ||
                    !isEmpty(formik.errors)
                  }
                />
                <Button
                  className='btn btn-primary'
                  label={intl.formatMessage({id: 'CONTINUE'})}
                  loadingText={intl.formatMessage({id: 'CONTINUE'})}
                  loading={formik.isSubmitting || isLoadingForm}
                  event={async () => {
                    await setSaveAndExit(false)
                    formik.handleSubmit()
                  }}
                  disabled={
                    formik.isSubmitting ||
                    !isEmpty(formik.errors) ||
                    isRequiredMethod ||
                    isRequiredService
                  }
                />
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-end'>
            <ButtonLabelCreation
              className='btn btn-light'
              label={intl.formatMessage({id: 'SAVE_AND_EXIT'})}
              loadingText={intl.formatMessage({id: 'SAVE_AND_EXIT'})}
              event={async () => {
                await setSaveAndExit(true)
                formik.submitForm()
              }}
              loading={isLoadingSaveAndExit}
              disabled={
                (checkHasSaved() && !isEmpty(formik.errors)) ||
                isLoadingForm ||
                isRequiredMethod ||
                isRequiredService ||
                !isEmpty(formik.errors)
              }
            >
              <KTSVG path='/media/gori/orders/delete.svg' className='m-0' svgClassName='mh-10px' />
            </ButtonLabelCreation>
          </div>
        </>
      </CSSTransition>
    </>
  )
}

export {Shipping}
