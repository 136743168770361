import clsx from 'clsx'
import {getIn} from 'formik'
import {ChangeEvent, FC, useEffect, useRef, useState} from 'react'
import {Spinner} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {KTSVG, formatBytes, toAbsoluteUrl} from '../../../../_gori/helpers'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import {VALIDATE_FILE} from '../core/_const'
import CommonService from '../../common/core/_requests'

type Props = {
  className?: string
  label?: string | null
  labelClassName?: string | null
  onSubmit: any
  required?: boolean
  formik: any
  name: string
  direction?: boolean // false: horizontal | true: vertical
  payloads?: object
  disabled?: boolean
}

const UploadMultipleFile: FC<Props> = ({
  onSubmit,
  className,
  labelClassName,
  label,
  formik,
  name = '',
  required = false,
  direction = false,
  payloads,
  disabled = false,
}) => {
  const intl = useIntl()
  let key = useRef<number>(0)
  const {newCancelToken, isCancel} = useCancelToken()
  const fileInit = formik.getFieldProps(name).value
  let formikErrors = getIn(formik.errors, name)
  let formikTouched = getIn(formik.touched, name)

  const [selectFiles, setSelectFiles] = useState<Array<object>>([])
  const [previewList, setPreviewList] = useState<Array<object>>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setPreviewList(fileInit)
  }, [fileInit])

  const isValidFileUploaded = (file) => {
    const validExtensions = VALIDATE_FILE.EXTENSION_ALLOW
    const fileExtension = file.type.split('/')[1]
    const filNameExtension = file.name.split('.')
    return (
      validExtensions.includes(fileExtension) &&
      validExtensions.includes(filNameExtension[filNameExtension.length - 1])
    )
  }

  const isOtherImageFile = (file) => {
    const validExtensions = VALIDATE_FILE.EXTENSION_OTHER_IMAGE
    const fileExtension = file.type.split('/')[1]
    const filNameExtension = file.name.split('.')
    return (
      validExtensions.includes(fileExtension) &&
      validExtensions.includes(filNameExtension[filNameExtension.length - 1])
    )
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return
    }

    const files = Array.from(e.target.files)
    e.target.value = '' // reset file upload

    if (files.length + previewList.length > 10) {
      return toast.warning(intl.formatMessage({id: 'UPLOAD_UP_TO_10_FILE'}))
    }

    files.forEach(async (file) => {
      if (isValidFileUploaded(file)) {
        if (file.size > VALIDATE_FILE.SIZE) {
          return toast.warning(
            intl.formatMessage(
              {id: 'FILE_INPUT_ERROR_UPLOADING_PLEASE_UPLOAD_FILES_SMALLER_THAN_8MB'},
              {input: file.name}
            )
          )
        }

        setIsLoading(true)
        const formData = new FormData()
        formData.append('file', file)
        if (payloads) {
          Object.entries(payloads).forEach(([key, value]) => {
            formData.append(key, value)
          })
        }
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
          cancelToken: newCancelToken(),
        }

        try {
          const upload = await CommonService.uploadFile(formData, config)
          if (isOtherImageFile(file)) {
            setSelectFiles((prev) => [
              ...prev,
              {
                key: key.current,
                name: file.name,
                size: file.size,
                ...upload,
                url_show: toAbsoluteUrl('/media/gori/claims/folder.svg'),
              },
            ])
          } else {
            setSelectFiles((prev) => [
              ...prev,
              {key: key.current, name: file.name, size: file.size, url_show: upload.url, ...upload},
            ])
          }

          key.current += 1
        } catch (error) {
          if (isCancel(error)) return
          console.error(error)
          toast.error(
            intl.formatMessage(
              {id: 'FILE_INPUT_ERROR_UPLOADING_PLEASE_TRY_AGAIN'},
              {input: file.name}
            )
          )
        } finally {
          setIsLoading(false)
        }
      } else {
        toast.warning(
          intl.formatMessage(
            {id: 'FILE_INPUT_HAS_INVALID_EXTENSION_ONLY_FILES_ARE_ALLOWED'},
            {input: file.name}
          )
        )
      }
    })
  }

  const handleDelete = (key) => {
    const arr = selectFiles.filter((item: any) => item.key !== key)
    setSelectFiles(arr)
  }

  useEffect(() => {
    if (onSubmit) {
      onSubmit(selectFiles)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFiles])

  return (
    <>
      {label && (
        <label className={`form-label ${labelClassName} ${required ? 'required' : ''}`}>
          {label}
        </label>
      )}
      <div className={clsx({'col-md-7': !direction})}>
        <div className={`dropzone ${className} `}>
          <div className='d-flex justify-content-center'>
            <KTSVG
              path='/media/files/drop-file.svg'
              className='svg-icon-3hx svg-icon-primary me-1'
            />
            <div className='ms-4'>
              <h3 className='fs-4 fw-bold text-gray-700 mb-1'>
                {intl.formatMessage({id: 'DRAG_AND_DROP_HERE_OR'})}
                <span className='ms-2 text-primary'>
                  {intl.formatMessage({id: 'BROWSE_FILES'})}
                </span>
              </h3>
              <span className='fw-semibold fs-4 text-muted'>
                {intl.formatMessage({id: 'UPLOAD_UP_TO_10_FILES'})}
              </span>
            </div>
            <input
              type='file'
              id='file-upload'
              name='file-img'
              className={clsx('dropzone__upload', {
                'cursor-no-drop': isLoading,
                'cursor-pointer': !isLoading,
              })}
              onChange={handleFileChange}
              multiple
              disabled={isLoading || disabled}
            />
          </div>
        </div>
        {formikTouched && formikErrors && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block text-danger'>
              <span role='alert'>{formikErrors}</span>
            </div>
          </div>
        )}
      </div>
      {isLoading ? (
        <div
          className={clsx('d-flex align-items-center justify-content-center', {
            'col-md-6': !direction,
            'mt-5': direction,
          })}
        >
          <Spinner
            animation='border'
            role='status'
            style={{width: '4rem', height: '4rem'}}
          ></Spinner>
        </div>
      ) : (
        <div className={clsx('drop-zone', {'col-md-6': !direction, 'mt-5': direction})}>
          {previewList.map((item: any) => {
            return (
              <div className='d-flex align-items-center mb-2' key={item.key}>
                <div className='d-flex flex-fill justify-content-between preview'>
                  <div className='preview__left'>
                    <img src={item?.url_show} alt='img-file' />
                    <span className='preview__left__name text-truncate'>{item.name}</span>
                  </div>
                  <div>{formatBytes(item.size)}</div>
                </div>

                <div className='d-flex justify-content-center align-content-center'>
                  <div className='preview__delete' onClick={() => handleDelete(item.key)}>
                    <KTSVG path='/media/gori/claims/trash.svg' />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

export {UploadMultipleFile}
