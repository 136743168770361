import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {STEPS} from '../core/_const'
import {useAuth} from '../../auth'

type Props = {
  activeStep: number
  setActiveStep: any
  errorValidate: boolean
}

const Stepper: React.FC<Props> = ({activeStep, setActiveStep, errorValidate}) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [processStep, setProcessStep] = useState<number>(
    STEPS[`${currentUser?.onboard_step}`] ?? STEPS.welcome
  )
  useEffect(() => {
    if (activeStep >= processStep) {
      setProcessStep(activeStep)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep])

  const steps = [
    {title: intl.formatMessage({id: 'PROFILE'})},
    {title: intl.formatMessage({id: 'SHIPPING'})},
    {title: intl.formatMessage({id: 'GENERAL'})},
    {title: intl.formatMessage({id: 'ADD_FUND'})},
    {title: intl.formatMessage({id: 'LABEL_CREATION'})},
  ]
  return (
    <div className='steps-container'>
      {steps.map((step, index) => {
        index = index + 1
        return (
          <div className={`step-box ${activeStep === index ? 'active' : ''}`} key={index}>
            <div
              className={`step-block ${
                processStep >= index && !errorValidate ? 'cursor-pointer' : 'cursor-no-drop'
              }`}
              onClick={() => {
                if (processStep >= index && !errorValidate) {
                  setActiveStep(index)
                }
              }}
            >
              <div className='step-title'>
                <span>{`${index}`}</span>
                {step.title}
              </div>
            </div>
            <div className='step-border'></div>
          </div>
        )
      })}
    </div>
  )
}

export {Stepper}
