import {useFormik} from 'formik'
import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import UseYupValidate from '../../../../../_gori/hooks/UseYupValidate'
import {Button, InputTextFormik} from '../../../../../_gori/partials/widgets'
import PaymentsService from '../../../deposits/core/_requests'

type Props = {
  show: boolean
  handleCloseModal: () => void
  nextStep: any
}

const CreditCardModal: React.FC<Props> = ({show, handleCloseModal, nextStep}) => {
  const intl = useIntl()
  const {numberYup} = UseYupValidate()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const openLightBox = (token) => {
    const paymentFields = {
      ssl_txn_auth_token: token,
    }

    const callback = {
      onError: (error) => {
        showResult('error', error)
      },
      onCancelled: () => {
        showResult('cancelled', '')
      },
      onDeclined: (response) => {
        showResult('declined', JSON.stringify(response, null, '\t'))
      },
      onApproval: (response) => {
        showResult('approval', JSON.stringify(response, null, '\t'))
      },
    }
    if (typeof window !== 'undefined') {
      // @ts-ignore
      window.PayWithConverge.open(paymentFields, callback)
    }
  }

  const handleSendApprovalElavon = async (data) => {
    setIsLoading(true)
    try {
      formik.resetForm()
      const res = await PaymentsService.approvalElavon({...data})
      if (res) {
        toast.success(intl.formatMessage({id: 'PAYMENT_SUCCESS'}))
        await nextStep()
      }
    } catch (error: any) {
      toast.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const showResult = (status, response) => {
    if (status === 'approval') {
      handleSendApprovalElavon(JSON.parse(response)).then(() => {})
    } else if (status === 'cancelled') {
      toast.warning(intl.formatMessage({id: 'PAYMENT_CANCELLED'}))
    } else {
      toast.error(intl.formatMessage({id: 'PAYMENT_ERROR'}))
    }
  }

  const validateSchema = {
    amount: numberYup.amount('FUNDING_AMOUNT'),
  }
  const validationSchema = Yup.object().shape(validateSchema)
  const formik = useFormik({
    initialValues: {
      amount: '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true)
      try {
        const res: any = await PaymentsService.getElavonToken(values)
        if (res) {
          const {token} = res
          openLightBox(token)
        }
      } catch (error: any) {
        toast.error(intl.formatMessage({id: error?.message || 'ERRORS'}))
      } finally {
        setIsLoading(false)
      }
    },
  })

  const handleClose = () => {
    formik.resetForm()
    handleCloseModal()
  }

  return (
    <Modal
      id='gori_modal_credit_card'
      tabIndex={-1}
      aria-hidden='true'
      centered
      show={show}
      backdrop='static'
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title bsPrefix={'fw-bolder fs-1'}>
          {intl.formatMessage({id: 'CREDIT_DEBIT_CARD'})}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex flex-column'>
          <div className='d-flex flex-column'>
            <InputTextFormik
              type='number'
              placeholder='0'
              min={0}
              className='my-3'
              labelClassName='form-label'
              required
              label={intl.formatMessage({id: 'FUNDING_AMOUNT'})}
              formik={formik}
              name='amount'
              configAppend={{
                name: '$',
                position: 'left',
                classInput: 'ps-8',
              }}
            />
            <p className='fw-bold'>
              {intl.formatMessage({id: 'PLEASE_NOTE_CREDIT_CARD_FUNDING_AMOUNT'})}
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='d-flex justify-content-end'>
          <Button
            className='btn btn-sm btn-light me-2'
            label={intl.formatMessage({id: 'CANCEL'})}
            event={handleClose}
          />
          <Button
            className='btn btn-sm btn-primary align-self-end'
            label={intl.formatMessage({id: 'PROCEED'})}
            loadingText={intl.formatMessage({id: 'SUBMIT'})}
            disabled={isLoading}
            event={formik.submitForm}
            loading={isLoading}
          />
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export {CreditCardModal}
