export const CLAIMS_STATUS = {
  UNSUBMITTED: 'unsubmitted',
  DENIED: 'denied',
}

export const VALIDATE_FILE = {
  SIZE: 8388608,
  EXTENSION_ALLOW: ['jpg', 'jpeg', 'png', 'pdf'],
  EXTENSION_OTHER_IMAGE: ['pdf'],
}

export const OPTION_CLAIMS_REASON = [
  {label: 'LOST', value: 'lost'},
  {label: 'DAMAGE', value: 'damage'},
]

export const OPTION_CLAIMS_REASON_FEDEX = [
  {label: 'DELIVERED_INCORRECT_ADDRESS', value: 'delivered_incorrect_address'},
  {label: 'MISSING_CONTENTS', value: 'missing_contents'},
]

export const OPTION_CLAIMS_APPEAL_REASON = [
  {label: 'ADDITIONAL_INFORMATION', value: 'evidence'},
  {label: 'INSUFFICIENT_PAYMENT', value: 'insufficient'},
  {label: 'OTHER', value: 'other'},
]

export const OPTION_ITEM_TYPE = [
  {label: 'CASH_CERTIFICATE', value: 'cash_certificate'},
  {label: 'JEWELRY', value: 'jewelry'},
  {label: 'CLOTHING_HOME_PRODUCTS', value: 'clothing_home_products'},
  {label: 'ARTS_CRAFTS', value: 'arts_crafts'},
  {label: 'MEDIA_MUSIC_VIDEO', value: 'media_music_video'},
  {label: 'ELECTRONICS', value: 'electronics'},
  {label: 'COMPUTERS', value: 'computers'},
  {label: 'COLLECTIBLES', value: 'collectibles'},
  {label: 'SPORTS_EQUIPMENT', value: 'sports_equipment'},
  {label: 'LIQUOR_WINE', value: 'liquor_wine'},
  {label: 'ANIMALS', value: 'animals'},
  {label: 'DOCUMENT_RECONSTRUCTION', value: 'document_reconstruction'},
  {label: 'HAZARDOUS_MATERIAL', value: 'hazardous_material'},
  {label: 'EVENT_TICKETS', value: 'event_tickets'},
]

export const ClaimsConfig = {
  SHOW_ADD_NEW_ITEM: 5,
  SHOW_DELETE_ITEM: 1,
  ITEM_TYPE_OTHER: 'other',
  CARRIER: 'usps',
  MAX_UPLOAD: 1000,
}
