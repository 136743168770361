import clsx from 'clsx'
import React from 'react'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router'
import {CSSTransition} from 'react-transition-group'
import {
  KTSVG,
  checkIsActive,
  checkIsActiveSubRoute,
  allowTestOnly,
  isFeatureEnabled,
} from '../../../../_gori/helpers'
import {Link} from 'react-router-dom'
import {useAuth} from '../../auth'
import UseRoutesPermission from '../../../../_gori/hooks/UseRoutesPermission'
import {FEATURES} from '../../../../_gori/constants/_features'

const URL = '/settings'

const SettingsMenu: React.FC = () => {
  const intl = useIntl()
  const {pathname} = useLocation()
  const {currentUser} = useAuth()
  const {routes} = UseRoutesPermission()

  let menuTab = [
    {
      LABEL: 'ACCOUNT',
      SUB_ROUTE: [`${URL}/profile`, `${URL}/general`, `${URL}/users`, `${URL}/api-keys`],
      SUB_MENU: [
        {LABEL: 'PROFILE', PATH: `${URL}/profile`},
        ...(routes.ADMINS.hasPermission ? [{LABEL: 'GENERAL', PATH: `${URL}/general`}] : []),
        ...(routes.USERS_VIEW.hasPermission && isFeatureEnabled(FEATURES.USER_MANAGEMENT)
          ? [{LABEL: 'USER_MANAGEMENT', PATH: `${URL}/users?status=active`}]
          : []),
        ...(isFeatureEnabled(FEATURES.API_KEYS) && routes.ADMINS.hasPermission
          ? [{LABEL: 'API_KEYS', PATH: `${URL}/api-keys`}]
          : []),
      ],
    },
  ]

  const shippingSubMenu = [
    ...(routes.SETTINGS_ADDRESSES.hasPermission
      ? [{LABEL: 'SHIPPING_ADDRESSES', PATH: `${URL}/shipping-addresses`}]
      : []),
    ...(routes.SETTINGS_PACKAGES.hasPermission
      ? [{LABEL: 'PACKAGES', PATH: `${URL}/packages`}]
      : []),
    ...(routes.SETTINGS_PRESETS.hasPermission ? [{LABEL: 'PRESETS', PATH: `${URL}/presets`}] : []),
    ...(routes.SETTINGS_LABELS_SLIPS.hasPermission && isFeatureEnabled(FEATURES.LABELS_SLIPS)
      ? [{LABEL: 'LABELS_AND_PACKING_SLIPS', PATH: `${URL}/labels-slips`}]
      : []),
  ]

  if (shippingSubMenu.length > 0) {
    menuTab.push({
      LABEL: 'SHIPPING',
      SUB_ROUTE: shippingSubMenu.map((item) => item.PATH),
      SUB_MENU: shippingSubMenu,
    })
  }

  const storeSetUpSubMenu = [
    ...(isFeatureEnabled(FEATURES.MARKETPLACE) && routes.ADMINS.hasPermission
      ? [{LABEL: 'STORE_CONNECTIONS', PATH: `${URL}/connections`}]
      : []),
    ...(allowTestOnly(currentUser) ? [{LABEL: 'WEBHOOK_HANDLE', PATH: `${URL}/webhook`}] : []),
  ]

  if (isFeatureEnabled(FEATURES.WEBHOOKS) && storeSetUpSubMenu.length > 0) {
    menuTab.push({
      LABEL: 'STORE_SETUP',
      SUB_ROUTE: [`${URL}/connections`, `${URL}/webhook`],
      SUB_MENU: storeSetUpSubMenu,
    })
  }

  return (
    <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
      <div className='d-flex flex-column'>
        {Object.entries(menuTab).map(([key, value], index) => {
          return (
            <div key={index} className='text-gray-700 mb-3'>
              <div
                className={clsx(
                  'rounded-2 px-5 py-2 fw-bolder fs-6 bg-light-secondary text-active-primary',
                  {
                    'active bg-light-primary': checkIsActiveSubRoute(value.SUB_ROUTE, pathname),
                  }
                )}
              >
                {intl.formatMessage({id: value.LABEL})}
              </div>
              <div className='d-flex flex-column'>
                {value.SUB_MENU.map((route, index) => {
                  return (
                    <Link
                      key={index}
                      to={route.PATH}
                      className={clsx(
                        'px-5 py-2 text-gray-700 fs-7 fw-bolder text-active-primary ',
                        {
                          active: checkIsActive(pathname, route.PATH),
                          'bg-hover-white text-hover-gray-900': !checkIsActive(
                            pathname,
                            route.PATH
                          ),
                        }
                      )}
                    >
                      <KTSVG
                        path='/media/gori/arrows/arrow_right.svg'
                        className={clsx('me-2', {
                          'text-gray-700': !checkIsActive(pathname, route.PATH),
                          'text-primary': checkIsActive(pathname, route.PATH),
                        })}
                      />
                      {intl.formatMessage({id: route.LABEL})}
                    </Link>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    </CSSTransition>
  )
}

export {SettingsMenu}
