import clsx from 'clsx'
import {useFormik} from 'formik'
import {isUndefined} from 'lodash'
import {useCallback, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useNavigate} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import * as Yup from 'yup'
import {useAuth} from '..'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../_gori/hooks/UseYupValidate'
import {Button} from '../../../../_gori/partials/widgets'
import {InputPassword} from '../../../../_gori/partials/widgets/form/InputPassword'
import UserService from '../../users/core/_requests'
import AuthService from '../core/_requests'
import axios from 'axios'
import {isFeatureEnabled} from '../../../../_gori/helpers'
import {FEATURES} from '../../../../_gori/constants/_features'

export function Login() {
  const intl = useIntl()
  const SIGNATURE_ACCESS_KEY = process.env.REACT_APP_SIGNATURE_ACCESS_KEY
  const {newCancelToken, isCancel} = useCancelToken()
  const {stringYup, authYup} = UseYupValidate()
  const {saveAuth, handleSaveCurrentUser} = useAuth()
  const navigate = useNavigate()

  const passwordRef = useRef<HTMLInputElement | null>(null)
  const [loading, setLoading] = useState<{
    login: boolean
    resentEmail: boolean
  }>({
    login: false,
    resentEmail: false,
  })
  const [checkErrorLogin, setCheckErrorLogin] = useState(false)
  const [statusForm, setStatusForm] = useState<
    {status: 'success' | 'error'; message: string} | undefined
  >(undefined)

  const loginSchema = Yup.object().shape({
    username: stringYup(100, 'EMAIL'),
    password: authYup.password(),
  })

  const initialValues = {
    username: '',
    password: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setStatusForm(undefined)
      setCheckErrorLogin(false)
      try {
        setLoading((prev) => ({...prev, login: true}))

        const config = {cancelToken: newCancelToken()}
        const login = await AuthService.login(values, config)
        await saveAuth(login)
        if (login) {
          delete axios.defaults.headers.common[`${SIGNATURE_ACCESS_KEY}`]
          const user = await UserService.getUser(config)
          handleSaveCurrentUser(user)

          if (user.onboard_step === 'done') {
            navigate('/dashboard')
          }
          navigate('/your-info')
        }
      } catch (error: any) {
        if (isCancel(error)) return
        saveAuth(undefined)
        let errorMessage = error?.response?.data?.message
        if (errorMessage) {
          setStatusForm({
            status: 'error',
            message: intl.formatMessage({id: errorMessage}),
          })
        }
        if (errorMessage === 'UNVERIFIED') {
          setCheckErrorLogin(true)
        }
      } finally {
        setLoading((prev) => ({...prev, login: false}))
      }
    },
  })

  const resendVerificationEmail = async () => {
    setStatusForm(undefined)

    try {
      setLoading((prev) => ({...prev, resentEmail: true}))
      const config = {cancelToken: newCancelToken()}

      let payload = {
        identifier: formik.values?.username,
      }
      const response = await AuthService.resendVerificationEmail(payload, config)
      if (response) {
        setStatusForm({
          status: 'success',
          message: intl.formatMessage({id: 'ENTER_VERIFICATION_ACCOUNT_SENT'}),
        })
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setStatusForm({
        status: 'error',
        message: intl.formatMessage({id: 'SEND_VERIFICATION_ACCOUNT_ERROR'}),
      })
    } finally {
      setLoading((prev) => ({...prev, resentEmail: false}))
    }
  }

  const getVersion = useCallback(async () => {
    try {
      const response = await AuthService.getVersion()
      if (String(response?.app_version) !== String(process.env.REACT_APP_VERSION)) {
        // Clearing localStorage
        localStorage.clear()

        // Clearing cookies
        document.cookie.split(';').forEach((cookie) => {
          const [name] = cookie.split('=')
          document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
        })

        // Reloading the page
        // window.location.href = window.location.href + '?version=' + Date.now()
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getVersion()
  }, [getVersion])

  return (
    <>
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <div className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
            <div className='text-center mb-10'>
              <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'SIGN_IN'})}</h1>
              {isFeatureEnabled(FEATURES.REGISTER) && (
                <div className='text-gray-400 fw-bold fs-4'>
                  {intl.formatMessage({id: 'NEW_HERE'})}?{' '}
                  <Link to='/auth/registration' className='link-primary fw-bolder'>
                    {intl.formatMessage({id: 'CREATE_AN_ACCOUNT'})}
                  </Link>
                </div>
              )}
            </div>
            {!isUndefined(statusForm) && (
              <div
                className={clsx('mb-lg-15 alert', {
                  'bg-light-primary': statusForm.status === 'success',
                  'alert-danger': statusForm.status === 'error',
                })}
              >
                <div className='alert-text font-weight-bold'>{statusForm.message}</div>
              </div>
            )}
            <div className='fv-row mb-10 fv-plugins-icon-container'>
              <label className={`form-label form-label fw-bolder text-dark fs-6 required`}>
                {intl.formatMessage({id: 'EMAIL'})}
              </label>
              <div>
                <div className='input-group'>
                  <input
                    autoFocus
                    type='text'
                    tabIndex={1}
                    autoComplete='off'
                    className={clsx(
                      `form-control form-control-lg`,
                      {
                        'is-invalid': formik.touched.username && formik.errors.username,
                      },
                      {
                        'is-valid':
                          formik.touched.username &&
                          !formik.errors.username &&
                          formik.values.username?.trim(),
                      }
                    )}
                    {...formik.getFieldProps('username')}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        if (
                          formik.values.username?.trim() &&
                          !formik.values.password?.trim() &&
                          passwordRef.current
                        ) {
                          passwordRef.current.focus()
                        } else if (
                          formik.values.username?.trim() &&
                          formik.values.password?.trim()
                        ) {
                          formik.handleSubmit()
                        }
                      }
                    }}
                  />
                </div>
                {formik.touched.username && formik.errors.username && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>
                      <span role='alert'>{formik.errors.username}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='fv-row mb-10 fv-plugins-icon-container'>
              <div className='d-flex flex-stack mb-2'>
                <label className='form-label fw-bolder text-dark fs-6 mb-0 required'>
                  {intl.formatMessage({id: 'PASSWORD'})}
                </label>
                <Link
                  to={'/auth/forgot-password'}
                  tabIndex={1000}
                  className='link-primary fs-6 fw-bolder'
                >
                  {intl.formatMessage({id: 'FORGOT_PASSWORD'})} ?
                </Link>
              </div>
              <InputPassword
                ref={passwordRef}
                tabIndex={2}
                size='lg'
                openHighlight={false}
                formik={formik}
                name={'password'}
                required
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    formik.handleSubmit()
                  }
                }}
              />
            </div>
            <div className='text-center'>
              <Button
                className='btn btn-lg btn-primary w-100 mb-5'
                label={intl.formatMessage({id: 'SUBMIT'})}
                loadingText={intl.formatMessage({id: 'LOGGING_IN'})}
                loading={loading.login}
                disabled={loading.login}
                event={formik.submitForm}
              />
            </div>
            {checkErrorLogin && (
              <div
                onClick={() => {
                  if (!loading.resentEmail) {
                    resendVerificationEmail()
                  }
                }}
                tabIndex={1000}
                className='d-flex link-danger fs-6 fw-bolder text-decoration-underline cursor-pointer'
              >
                {intl.formatMessage({id: 'RESEND_VERIFICATION_EMAIL'})}
                {loading.resentEmail && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <span className='spinner-border spinner-border-sm align-middle ms-2' />
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      </CSSTransition>
    </>
  )
}
