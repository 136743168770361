import clsx from 'clsx'
import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import {KTSVG, toAbsoluteUrl} from '../../../../../_gori/helpers'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import {ButtonBack} from '../../../../../_gori/partials/widgets'
import {ButtonLabelCreation} from '../../../../../_gori/partials/widgets/form/ButtonLabelCreation'
import {ACHModal, CreditCardModal, PayPalModal, WireTransferModal} from '../../../onboard'
import {STEPS} from '../../core/_const'
import OnboardService from '../../core/_requests'

type Props = {
  setActiveStep: any
}
const fundingMethods = [
  {
    label: 'ACH',
    value: 'ach',
    image: '/media/gori/account/ach.png',
  },
  {
    label: 'WIRE_TRANSFER',
    value: 'wire_transfer',
    image: '/media/gori/account/wire.png',
  },
  {
    label: 'CREDIT_DEBIT_CARD',
    value: 'credit_debit_card',
    image: '/media/gori/account/card.png',
  },
  {
    label: 'PAYPAL',
    value: 'paypal',
    image: '/media/gori/account/paypal_no_title.png',
  },
]

const AddFund: React.FC<Props> = ({setActiveStep}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [selectedMethod, setSelectedMethod] = useState<
    'ach' | 'wire_transfer' | 'credit_debit_card' | 'paypal' | null
  >(null)
  const {newCancelToken} = useCancelToken()

  const nextStep = async () => {
    let payload = {
      step: 'add_fund',
      data: {funding_option: selectedMethod},
      funding_option: selectedMethod,
    }
    const res = await OnboardService.store(payload, {cancelToken: newCancelToken()})
    if (res) {
      setActiveStep(STEPS.label_creation)
    }
  }

  const backStep = () => {
    setActiveStep(STEPS.ob_general)
  }

  return (
    <>
      {selectedMethod && (
        <>
          <ACHModal
            show={selectedMethod === 'ach'}
            handleCloseModal={() => setSelectedMethod(null)}
            setActiveStep={setActiveStep}
          />
          <WireTransferModal
            show={selectedMethod === 'wire_transfer'}
            handleCloseModal={() => setSelectedMethod(null)}
            nextStep={nextStep}
          />
          <CreditCardModal
            show={selectedMethod === 'credit_debit_card'}
            handleCloseModal={() => setSelectedMethod(null)}
            nextStep={nextStep}
          />
          <PayPalModal
            show={selectedMethod === 'paypal'}
            handleCloseModal={() => setSelectedMethod(null)}
            nextStep={nextStep}
          />
        </>
      )}
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className='add-fund text-center fade-appear-done fade-enter-done'>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div
              className='add-fund-text'
              dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'ADD_FUND_TEXT'})}}
            ></div>

            <div className='w-75'>
              <div className='row justify-content-center d-flex '>
                {fundingMethods.map((item: any, index) => {
                  return (
                    <div
                      className={clsx('col-md-3')}
                      key={index}
                      onClick={() => {
                        setSelectedMethod(item.value)
                      }}
                    >
                      <div
                        className={`choose-box shadow ${
                          selectedMethod === item.value ? 'active' : ''
                        }`}
                      >
                        <div>
                          <img
                            alt={intl.formatMessage({id: item.label})}
                            src={toAbsoluteUrl(item.image)}
                            className='h-100px m-1'
                          />
                        </div>
                        <div className='choose-text'>
                          <span> {intl.formatMessage({id: item.label})}</span>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className='row justify-content-between'>
                <div className='col-md-3 mt-5'>
                  <ButtonBack
                    event={() => backStep()}
                    className='back onboard-form'
                    label={intl.formatMessage({id: 'BACK'})}
                  />
                </div>
                <div className='col-md-3 skip' onClick={() => setActiveStep(STEPS.label_creation)}>
                  {intl.formatMessage({id: 'SKIP'})}
                  <KTSVG path='/media/gori/arrows/arrow_right.svg' className='svg-icon-3 m-0' />
                </div>
              </div>
            </div>
          </div>

          <div className='col-12 mt-10 save-and-exit'>
            <ButtonLabelCreation
              className='btn btn-light'
              label={intl.formatMessage({id: 'SAVE_AND_EXIT'})}
              loadingText={intl.formatMessage({id: 'SAVE_AND_EXIT'})}
              event={() => {
                navigate('/logout')
              }}
            >
              <KTSVG path='/media/gori/orders/delete.svg' className='m-0' svgClassName='mh-10px' />
            </ButtonLabelCreation>
          </div>
        </div>
      </CSSTransition>
    </>
  )
}

export {AddFund}
