import {IntegrationsPageLayout} from '../../../_gori/layout/components/IntegrationsPagesLayout'

export function TikTokIntegration() {
  return (
    <>
      <IntegrationsPageLayout
        pageClass='tiktok-integration'
        heading='TIKTOK_HEADER'
        headingSubtext='TIKTOK_SUBTEXT'
        integrationLogo='/media/gori/integrations/tiktok/tiktok-logo.svg'
        integrationLogoAlt='tiktok-logo'
        integrateImg='/media/gori/integrations/tiktok/integrate.webp'
        card1Copy='TIKTOK_CARD_1'
        card2Copy='TIKTOK_CARD_2'
        card3Copy='TIKTOK_CARD_3'
        card4Copy='TIKTOK_CARD_4'
      />
    </>
  )
}
