import clsx from 'clsx'
import {cloneDeep, isEmpty, map, uniqBy, uniqueId} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {CSSTransition} from 'react-transition-group'
import {BatchRenameModal, BatchesDetailFilter} from '..'
import {
  CUSTOM_COLUMN_NAME,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE_BATCH,
  TABLE_KEY_COLUMN,
} from '../../../../_gori/constants'
import {
  KTSVG,
  convertUserTimeZone,
  getShipmentFees,
  setLoadingActionTable,
  useDisabled,
  formatOmitLongText,
  isFeatureEnabled,
} from '../../../../_gori/helpers'
import {blockPopUp} from '../../../../_gori/helpers/BrowserHelpers'
import {TableSkeleton} from '../../../../_gori/helpers/components'
import {useBatchesProvider} from '../../../../_gori/helpers/components/BatchesProvider'
import {useMeter} from '../../../../_gori/helpers/components/MeterProvider'
import {useStatesGlobal} from '../../../../_gori/helpers/components/StatesGlobalProvider'
import {TooltipModal} from '../../../../_gori/helpers/components/TooltipModal'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import UseRoutesPermission from '../../../../_gori/hooks/UseRoutesPermission'
import useWindowDimensions from '../../../../_gori/hooks/useWindowDimensions'
import {PageTitle} from '../../../../_gori/layout/core'
import {
  Button,
  ButtonBack,
  CarrierBadge,
  ConfirmActionSwal,
  DropdownButton,
  Table,
  TableBody,
  TableTabs,
  TableWrapper,
  ValidationErrorModal,
} from '../../../../_gori/partials/widgets'
import {ParcelBadge} from '../../../../_gori/partials/widgets/mixed/ParcelBadge'
import {showArrCustomColumns} from '../../../../_gori/utils/common'
import {useAuth} from '../../auth'
import {
  FromToEditModal,
  NoticePrintPackingSlips,
  OrderChildModal,
  PackagesTableModal,
  RatesModal,
} from '../../orders'
import {HELP_CENTER_WIDGET_PARAMS, OrderStatus, OrdersConfig} from '../../orders/core/_const'
import OrderHelper from '../../orders/core/_helpers'
import OrderService from '../../orders/core/_requests'
import SettingsService from '../../settings/core/_requests'
import {ShipmentRefund} from '../../shipments/core/_const'
import ShipmentService from '../../shipments/core/_requests'
import {BatchesStatus} from '../core/_const'
import BatchService from '../core/_requests'
import {BatchesDetailLabels} from './BatchesDetailLabels'
import {HelpCenterWidget} from '../../common/components/HelpCenterWidget'
import {FEATURES} from '../../../../_gori/constants/_features'

const BatchesDetailPage: React.FC = () => {
  const intl = useIntl()
  const {id} = useParams()
  const navigate = useNavigate()
  const orderHelper = OrderHelper()
  const {routes} = UseRoutesPermission()
  const {setMeterChange} = useMeter()
  const [searchParams] = useSearchParams()
  const {setPageDisabled} = useDisabled()
  const {statesGlobal} = useStatesGlobal()
  const {height, width} = useWindowDimensions()
  const carriers = statesGlobal.carriers
  const columnsShow =
    statesGlobal?.manageColumns?.[
      CUSTOM_COLUMN_NAME?.BATCHES_DETAIL_PAGE?.[searchParams.get('status')?.toUpperCase() || 'ALL']
    ]
  const {currentUser, loadingSwitch, currentCompany} = useAuth()
  const {batch, setBatch} = useBatchesProvider()
  const {newCancelToken, isCancel} = useCancelToken()
  const [selectedListId, setSelectedListId] = useState<any>([])
  const [selectedMaster, setSelectedMaster] = useState<boolean>(false)
  const [tableData, setTableData] = useState<any>([])
  const [pagination, setPagination] = useState<any>({})
  const [statistics, setStatistics] = useState([])
  const [validationErrors, setValidationErrors] = useState<any>()
  const [settings, setSettings] = useState<any>([])

  const [loading, setLoading] = useState<any>({
    first: true,
    archive_batch: false,
    remove_batch: false,
    download_file: false,
  })
  const [showModal, setShowModal] = useState<any>({
    fromToEdit: false,
    packageTable: false,
    rateLabel: false,
    createLabel: false,
    outdated: false,
    editOrder: false,
    rename: false,
    noticePrintPackingSlip: false,
  })
  const [dataModal, setDataModal] = useState<any>({
    fromToEdit: {},
    packageTable: {},
    rateLabel: {},
    createLabel: {},
    editOrder: {},
    noticePrintPackingSlip: {},
  })

  const [showSwal, setShowSwal] = useState<any>({
    restore: {callback: undefined, status: false},
    remove_hold: {callback: undefined, status: false},
    cancel: {callback: undefined, status: false},
    remove_batch: {callback: undefined, status: false},
    archive_batch: {callback: undefined, status: false},
    remove_orders: {callback: undefined, status: false},
    create_and_print: {callback: undefined, status: false},
  })

  const convertDataTable = (dataOrders) =>
    Array.from(dataOrders.orders, (order: any) => {
      return {
        ...order,
        canCheck: true,
      }
    })

  const setTable = useCallback(async () => {
    try {
      setPageDisabled(true)
      const config = {
        params: {
          page: searchParams.get('page') || DEFAULT_PAGE,
          page_size: searchParams.get('page_size') || DEFAULT_PAGE_SIZE_BATCH,
          status: searchParams.get('status') || null,
          sort_by: searchParams.get('sort_by') || null,
          sort_direction: searchParams.get('sort_direction') || null,
        },
        cancelToken: newCancelToken(),
      }
      const response = await BatchService.getBatchOrders(id, config)
      if (response) {
        setTableData(convertDataTable(response))
        setPagination(response.pagination)
        setStatistics(response.statistics)
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setPageDisabled(false)
      setLoading((prev) => ({...prev, first: false}))
    }
  }, [id, isCancel, newCancelToken, searchParams, setPageDisabled])

  const getBatch = useCallback(async () => {
    try {
      const response = await BatchService.get(id, {cancelToken: newCancelToken()})
      if (response) {
        setBatch(response.batch)
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    }
  }, [id, isCancel, newCancelToken, setBatch])

  const getSettings = useCallback(async () => {
    const settings = await SettingsService.settings({cancelToken: newCancelToken()})
    setSettings(settings)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCancelToken])

  useEffect(() => {
    if (loadingSwitch) return

    setTable()

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSwitch, setTable])

  useEffect(() => {
    if (loadingSwitch) return

    if (!batch?.id) {
      getBatch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batch?.id, getBatch, loadingSwitch])

  useEffect(() => {
    if (loadingSwitch) return

    getSettings()

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSwitch, getSettings])

  const handlePrintLabel = useCallback(
    async (dataOrder: any) => {
      const shipment = dataOrder?.latest_shipment
      const isRefunded =
        shipment.refund?.status === ShipmentRefund.SUBMITTED ||
        shipment.refund?.status === ShipmentRefund.REFUNDED
      if (isRefunded) {
        toast.warning(
          intl.formatMessage({
            id: 'PRINT_LABEL_REFUNDED',
          })
        )
        return
      }
      setTableData(setLoadingActionTable(tableData, [dataOrder.id], true))
      const config = {cancelToken: newCancelToken()}
      const data = {
        order_ids: [dataOrder.id],
      }
      await OrderService.print(data, config)
        .then((response) => {
          if (blockPopUp(response.label_url)) {
            toast.warning(
              intl.formatMessage({
                id: 'POP_UP_IS_BLOCKED',
              })
            )
          }
        })
        .catch((error: any) => {
          if (isCancel(error)) return
          setValidationErrors(error?.response)
        })
      setTableData(setLoadingActionTable(tableData, [dataOrder.id], false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCancel, newCancelToken, intl, tableData]
  )

  const handlePrintPackingSlip = useCallback(
    async (dataOrder: any) => {
      const {
        packing_slip: {disable},
      } = settings
      if (disable) {
        const configNoticePackingSlip = {
          title: 'PRINT_PACKING_SLIP',
          content: 'DISABLE_PACKING_SLIPS_MESSAGE',
        }
        setDataModal((prev) => ({...prev, noticePrintPackingSlip: configNoticePackingSlip}))
        setShowModal((prev) => ({...prev, noticePrintPackingSlip: true}))
        return
      }

      setTableData(setLoadingActionTable(tableData, [dataOrder.id], true))
      try {
        let payload = {
          order_id: dataOrder.id,
        }
        const res = await ShipmentService.storePackingSlip(payload, {
          cancelToken: newCancelToken(),
        })
        if (res) {
          if (blockPopUp(res.packing_slip_url)) {
            toast.warning(intl.formatMessage({id: 'POP_UP_IS_BLOCKED'}))
          }
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setTableData(setLoadingActionTable(tableData, [dataOrder.id], false))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCancel, newCancelToken, intl, tableData]
  )

  const handleViewOrder = useCallback(
    (orderId: any) => {
      navigate('/orders/' + orderId)
    },
    [navigate]
  )

  const handleModalSuccess = useCallback(
    (key: 'createLabel' | 'fromToEdit' | 'packageTable' | 'rateLabel' | 'editOrder') => {
      switch (key) {
        case 'createLabel':
          setMeterChange(true)
          toast.success(intl.formatMessage({id: 'CREATE_LABEL_SUCCESSFULLY'}))
          break
        default:
          toast.success(intl.formatMessage({id: 'UPDATED_SUCCESSFULLY'}))
          break
      }
      setShowModal((prev) => ({...prev, [key]: false}))
      setTable()
    },
    [intl, setMeterChange, setTable]
  )

  const handleEditModalRate = useCallback((data) => {
    const _valueOrder = Object.entries(cloneDeep(data)).reduce(
      (initValue, [key, value]: [any, any]) => {
        switch (true) {
          case key.includes('international_option'):
            initValue.international_option = value
            break
          case key.includes('insurance_option'):
            initValue.insurance_option = value
            break
          case key.includes('signature_confirmation'):
            initValue.signature_confirmation = value
            break
          case key.includes('from_'):
            initValue.from[key] = value
            break
          case key.includes('to_'):
            initValue.to[key] = value
            break
          case key === 'parcels':
            initValue.parcels = value[0]
            break
          case key === 'service':
            initValue.service = `${data.carrier}_${data.service}`
            break
          case key === 'id':
            initValue.id = value
            break
          case key === 'preset_id':
            initValue.preset_id = data.preset_id
            break
          default:
            break
        }

        return initValue
      },
      {
        international_option: {},
        insurance_option: {},
        signature_confirmation: null,
        from: {},
        to: {},
        parcels: {},
        service: '',
        id: '',
        preset_id: null,
      }
    )
    setDataModal((prev) => ({...prev, rateLabel: _valueOrder}))
    setShowModal((prev) => ({...prev, rateLabel: true}))
  }, [])

  const handleGetRate = useCallback(
    async (data) => {
      const dataParcels = orderHelper.buildRateParcels(
        `${data.carrier}_${data.service}`,
        data.parcels
      )
      try {
        setTableData(setLoadingActionTable(tableData, [data.id], true, 'isLoadingRate'))
        const res = await OrderService.estimateRate(
          {
            order_id: data?.id,
            shipment: {
              carrier: data?.carrier ?? null,
              service: data?.service ?? null,
              from_address: Object.entries(OrdersConfig.SHIPPING).reduce(
                (fromData, [key, value]) => {
                  fromData[value.value] = data?.[`from_${value.value}`]
                  return fromData
                },
                {}
              ),
              to_address: Object.entries(OrdersConfig.SHIPPING).reduce((toData, [key, value]) => {
                toData[value.value] = data?.[`to_${value.value}`]
                return toData
              }, {}),
              parcel: dataParcels,
            },
          },
          {cancelToken: newCancelToken()}
        )
        if (res) {
          setTable()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setTableData(setLoadingActionTable(tableData, [data.id], false, 'isLoadingRate'))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCancel, newCancelToken, orderHelper, setTable, tableData]
  )

  const handleCopy = useCallback(
    async (id) => {
      if (!id) return
      setTableData(setLoadingActionTable(tableData, [id], true))
      try {
        const {order} = await OrderService.get(id, {cancelToken: newCancelToken()})
        if (order) {
          navigate('/orders/create', {state: {dataCopy: order}})
        }
      } catch (error) {
        if (isCancel(error)) return
      } finally {
        setTableData(setLoadingActionTable(tableData, [id], false))
      }
    },
    [isCancel, navigate, newCancelToken, tableData]
  )

  const handleRemoveHoldOrder = useCallback(
    async (orderId: any) => {
      setTableData(setLoadingActionTable(tableData, [orderId], true))

      let payload = {
        order_ids: [orderId],
      }

      try {
        const res = await OrderService.removeHold(payload, {cancelToken: newCancelToken()})
        if (res) {
          toast.success(intl.formatMessage({id: 'REMOVED_HOLD_ORDER_SUCCESSFULLY'}))
          setTable()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setTableData(setLoadingActionTable(tableData, [orderId], false))
      }
    },
    [intl, isCancel, newCancelToken, setTable, tableData]
  )

  const handleRestoreOrder = useCallback(
    async (orderId: any) => {
      setTableData(setLoadingActionTable(tableData, [orderId], true))
      let payload = {
        order_ids: [orderId],
      }

      try {
        const res = await OrderService.restore(payload, {cancelToken: newCancelToken()})
        if (res) {
          toast.success(intl.formatMessage({id: 'RESTORED_ORDER_SUCCESSFULLY'}))
          setTable()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setTableData(setLoadingActionTable(tableData, [orderId], false))
      }
    },
    [intl, isCancel, newCancelToken, setTable, tableData]
  )

  const columns = useMemo(() => {
    let colShipDate: any = {
      id: 'shipped_date',
      Header: intl.formatMessage({id: 'SHIPPED_DATE'}),
      accessor: 'shipped_date',
      headerClassName: 'min-w-100px',
      Cell: ({row}: {row: any}) => {
        return (
          row.original?.latest_shipment &&
          convertUserTimeZone(
            row.original?.latest_shipment?.created_at,
            currentUser,
            process.env.REACT_APP_DATE_FORMAT
          )
        )
      },
    }
    let colHoldByDate: any = {
      id: 'hold_until_date',
      Header: intl.formatMessage({id: 'HOLD_BY_DATE'}),
      accessor: 'hold_until_date',
      headerClassName: 'min-w-150px',
      cellClassName: 'text-wrap',
      isVisible: false,
      Cell: ({row}: {row: any}) => {
        return (
          !isEmpty(row.original?.hold_until_date) &&
          convertUserTimeZone(
            row.original?.hold_until_date,
            currentUser,
            process.env.REACT_APP_DATE_FORMAT
          )
        )
      },
    }
    let colCancelledDate: any = {
      id: 'cancelled_at',
      Header: intl.formatMessage({id: 'CANCELLED_DATE'}),
      accessor: 'cancelled_at',
      headerClassName: 'min-w-150px',
      cellClassName: 'text-wrap',
      Cell: ({row}: {row: any}) => {
        return (
          !isEmpty(row.original?.cancelled_at) &&
          convertUserTimeZone(
            row.original?.cancelled_at,
            currentUser,
            process.env.REACT_APP_DATE_FORMAT
          )
        )
      },
    }

    let colShipBy: any = {
      id: 'ship_by_date',
      Header: intl.formatMessage({id: 'SHIP_BY'}),
      accessor: 'ship_by_date',
      headerClassName: 'min-w-100px',
      cellClassName: 'text-wrap',
      Cell: ({row}: {row: any}) => {
        return (
          !isEmpty(row.original?.ship_by_date) &&
          convertUserTimeZone(
            row.original?.ship_by_date,
            currentUser,
            process.env.REACT_APP_DATE_FORMAT
          )
        )
      },
    }
    let colStatus: any = {
      id: 'status',
      Header: intl.formatMessage({id: 'STATUS'}),
      accessor: 'status',
      headerClassName: 'min-w-175px text-center',
      cellClassName: 'text-center',
      Cell: ({row}: {row: any}) => {
        return (
          <span
            className={`order-status-badge-${row.original?.status?.replaceAll(
              '_',
              '-'
            )} px-4 py-2 rounded-pill`}
          >
            {intl.formatMessage({id: row.original.status.toUpperCase()})}
          </span>
        )
      },
    }

    switch (searchParams.get('status')) {
      case 'open':
        colShipDate = null
        colHoldByDate = null
        colCancelledDate = null
        colStatus = null
        break
      case 'shipped':
        colHoldByDate = null
        colCancelledDate = null
        colShipBy = null
        colStatus = null
        break
      case 'on_hold':
        colShipDate = null
        colCancelledDate = null
        colShipBy = null
        colStatus = null
        break
      case 'cancelled':
        colShipDate = null
        colHoldByDate = null
        colShipBy = null
        colStatus = null
        break
    }

    const _columns = [
      {
        id: TABLE_KEY_COLUMN.CAN_CHECK,
        Header: intl.formatMessage({id: 'ID'}),
        accessor: 'canCheck',
      },
      {
        id: 'created_at',
        Header: intl.formatMessage({id: 'ORDER_DATE'}),
        accessor: 'created_at',
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) => {
          return convertUserTimeZone(
            row.original?.created_at,
            currentUser,
            process.env.REACT_APP_DATE_FORMAT
          )
        },
      },
      {
        id: 'code',
        Header: 'BAE ID',
        accessor: 'code',
        headerClassName: 'min-w-150px',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) => {
          return (
            <div
              className={clsx(
                'text-gray-800 mb-1 cursor-pointer d-inline',
                routes.ORDERS_UPDATE.hasPermission
                  ? 'cursor-pointer text-hover-primary'
                  : 'cursor-no-drop'
              )}
              onClick={() =>
                routes.ORDERS_UPDATE.hasPermission ? handleViewOrder(row.original.id) : false
              }
            >
              {`${currentCompany?.sb_code}-${row.original.code}`}
              <span className='m-1 rounded-pill badge badge-pill badge-light'>
                {row.original.children.length > 0 && intl.formatMessage({id: 'COMBINED'})}
                {!isEmpty(row.original.split_from) &&
                  row.original.children.length === 0 &&
                  intl.formatMessage({id: 'SPLIT'})}
              </span>
            </div>
          )
        },
      },
      colShipDate,
      colHoldByDate,
      colCancelledDate,
      colShipBy,
      {
        id: 'customer',
        Header: intl.formatMessage({id: 'CUSTOMER'}),
        accessor: 'customer',
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap mw-150px',
        Cell: (data) => {
          const row = data.row
          return row.original.status === OrderStatus.OPEN && routes.ORDERS_UPDATE.hasPermission ? (
            <TooltipModal
              className={clsx('d-flex flex-column', {
                'tooltip-modal--top': data.rows.length - 1 === row.index,
                'tooltip-modal--bottom': data.rows.length - 1 !== row.index,
              })}
              classNameTooltip={clsx('text-dark text-hover-primary ', {
                'tooltip-modal__text--top': data.rows.length - 1 === row.index,
                'tooltip-modal__text--bottom': data.rows.length - 1 !== row.index,
              })}
              children={
                <>
                  <div className='d-flex flex-column'>
                    <span className='text-gray-800'>
                      {isEmpty(row.original?.to_company)
                        ? row.original?.to_first_name +
                          (row.original?.to_last_name ? ' ' + row.original?.to_last_name : '')
                        : row.original?.to_company}
                    </span>
                    <span>{row.original?.to_city + ', ' + row.original?.to_state}</span>
                  </div>
                </>
              }
              textTooltip={intl.formatMessage({id: 'EDIT_ADDRESS'})}
              handleEvent={() => {
                setDataModal((prev) => ({...prev, fromToEdit: row.original}))
                setShowModal((prev) => ({...prev, fromToEdit: true}))
              }}
            />
          ) : (
            <>
              {row.original?.latest_shipment ? (
                <div className='d-flex flex-column'>
                  <span className='text-gray-800'>
                    {isEmpty(row.original?.latest_shipment?.to_company)
                      ? row.original?.latest_shipment?.to_first_name +
                        (row.original?.to_last_name ? ' ' + row.original?.to_last_name : '')
                      : row.original?.latest_shipment?.to_company}
                  </span>
                  <span>
                    {row.original?.latest_shipment?.to_city +
                      ', ' +
                      row.original?.latest_shipment?.to_state}
                  </span>
                </div>
              ) : (
                <div className='d-flex flex-column'>
                  <span className='text-gray-800'>
                    {isEmpty(row.original?.to_company)
                      ? row.original?.to_first_name +
                        (row.original?.to_last_name ? ' ' + row.original?.to_last_name : '')
                      : row.original?.to_company}
                  </span>
                  <span>{row.original?.to_city + ', ' + row.original?.to_state}</span>
                </div>
              )}{' '}
            </>
          )
        },
      },
      colStatus,
      {
        id: 'package_info',
        Header: intl.formatMessage({id: 'PACKAGE_INFO'}),
        headerClassName: 'min-w-100px text-center',
        cellClassName: 'text-center',
        Cell: (data) => {
          const row = data.row
          return row.original.status === OrderStatus.OPEN && routes.ORDERS_UPDATE.hasPermission ? (
            <TooltipModal
              className={clsx({
                'tooltip-modal--top': data.rows.length - 1 === row.index,
                'tooltip-modal--bottom': data.rows.length - 1 !== row.index,
              })}
              classNameTooltip={clsx('text-dark text-hover-primary w-150px', {
                'tooltip-modal__text--top': data.rows.length - 1 === row.index,
                'tooltip-modal__text--bottom': data.rows.length - 1 !== row.index,
              })}
              children={
                <>
                  {row.original.parcels.length > 0
                    ? row.original.parcels.map((parcel: any) => (
                        <ParcelBadge key={uniqueId('parcel')} values={parcel} />
                      ))
                    : row.original.status === OrderStatus.OPEN && (
                        <div className='flex-shrink-0'>
                          <Button className='btn border-active btn-white btn-sm text-gray-700'>
                            <i className='fas fa-plus' />
                            {intl.formatMessage({id: 'ADD_PACKAGE_INFO'})}
                          </Button>
                        </div>
                      )}
                </>
              }
              textTooltip={intl.formatMessage({id: 'EDIT_PACKAGE_INFO'})}
              handleEvent={() => {
                setDataModal((prev) => ({...prev, packageTable: row.original}))
                setShowModal((prev) => ({...prev, packageTable: true}))
              }}
            />
          ) : (
            <>
              {row.original?.latest_shipment
                ? row.original?.latest_shipment?.parcels?.map((parcel: any) => (
                    <ParcelBadge key={uniqueId('parcel')} values={parcel} />
                  ))
                : row.original.parcels.length > 0
                ? row.original.parcels.map((parcel: any) => (
                    <ParcelBadge key={uniqueId('parcel')} values={parcel} />
                  ))
                : row.original.status === OrderStatus.OPEN &&
                  routes.ORDERS_UPDATE.hasPermission && (
                    <div className='flex-shrink-0'>
                      <Button className='btn border-active btn-white btn-sm text-gray-700'>
                        <i className='fas fa-plus' />
                        {intl.formatMessage({id: 'ADD_PACKAGE_INFO'})}
                      </Button>
                    </div>
                  )}
            </>
          )
        },
      },
      {
        id: 'rates',
        Header: intl.formatMessage({id: 'RATES'}),
        headerClassName: 'min-w-50px text-center',
        cellClassName: 'text-center',
        Cell: (data: any) => {
          const row = data.row
          let sum = 0
          let service = carriers[row.original.carrier + '_' + row.original.service] ?? []
          if (row.original?.latest_shipment) {
            sum = getShipmentFees(row.original?.latest_shipment?.fees?.items)
            service =
              carriers?.[
                row.original?.latest_shipment?.carrier +
                  '_' +
                  row.original?.latest_shipment?.service
              ] ?? []
          } else if (row.original?.estimated_rate) {
            sum = row.original?.estimated_rate
          }
          const isCheapest = row.original?.is_cheapest_rate ?? false

          return row.original.status === OrderStatus.OPEN && routes.ORDERS_UPDATE.hasPermission ? (
            row.original.carrier ? (
              <TooltipModal
                className={clsx({
                  'tooltip-modal--top': data.rows.length - 1 === row.index,
                  'tooltip-modal--bottom': data.rows.length - 1 !== row.index,
                })}
                classNameTooltip={clsx('text-dark text-hover-primary w-150px', {
                  'tooltip-modal__text--top': data.rows.length - 1 === row.index,
                  'tooltip-modal__text--bottom': data.rows.length - 1 !== row.index,
                })}
                children={
                  <>
                    <CarrierBadge
                      handleGetRate={() => handleGetRate(row.original)}
                      loadingRate={row.original.isLoadingRate}
                      service={service}
                      isCheapest={isCheapest}
                      {...((row.original?.latest_shipment || row.original?.estimated_rate) && {
                        cost: sum,
                      })}
                    />
                  </>
                }
                textTooltip={intl.formatMessage({id: 'EDIT_RATE'})}
                handleEvent={() => {
                  handleEditModalRate(row.original)
                }}
              />
            ) : (
              <div className='flex-shrink-0'>
                <Button
                  className='btn border-active btn-white btn-sm text-dark text-hover-primary'
                  event={() => {
                    handleEditModalRate(row.original)
                  }}
                >
                  <i className='fas fa-sync' />
                  {intl.formatMessage({id: 'GET_RATES'})}
                </Button>
              </div>
            )
          ) : (
            row.original?.carrier && (
              <CarrierBadge
                service={service}
                isCheapest={isCheapest}
                {...((row.original?.latest_shipment || row.original?.estimated_rate) && {
                  cost: sum,
                })}
              />
            )
          )
        },
      },
      {
        id: 'tracking_number',
        Header: intl.formatMessage({id: 'TRACKING_NUMBER'}),
        headerClassName: 'min-w-200px',
        cellClassName: 'text-wrap mw-200px',
        Cell: ({row}: {row: any}) => {
          let service = carriers[row.original.carrier + '_' + row.original.service] ?? []

          return (
            <>
              <span
                className='text-primary cursor-pointer'
                onClick={() => {
                  if (
                    blockPopUp(
                      service?.visuals?.tracking_url +
                        row.original?.latest_shipment?.tracking_number
                    )
                  ) {
                    toast.warning(
                      intl.formatMessage({
                        id: 'POP_UP_IS_BLOCKED',
                      })
                    )
                  }
                }}
              >
                {row.original?.latest_shipment?.tracking_number ?? null}
              </span>
              {!isEmpty(row.original.error) && (
                <div className='row'>
                  <span className='text-danger'>{row.original?.error?.message}</span>
                  <span className='text-danger'>{row.original?.error?.Description}</span>
                  {row.original?.error?.fields &&
                    Object.entries(row.original.error.fields).map(([key, value]: [any, any]) => {
                      return (
                        <span key={key} className='text-danger'>
                          - {key} {value}
                        </span>
                      )
                    })}
                </div>
              )}
            </>
          )
        },
      },
      {
        id: 'reference1',
        Header: intl.formatMessage({id: 'ORDER_REF'}),
        accessor: 'reference1',
        headerClassName: 'text-wrap min-w-125px',
        Cell: ({row}: {row: any}) => {
          return <>{row.original.reference1}</>
        },
      },
      {
        id: 'item_name',
        Header: intl.formatMessage({id: 'ITEM_NAME'}),
        accessor: 'item_name',
        headerClassName: 'text-wrap min-w-125px',
        Cell: ({row}: {row: any}) => {
          const arr = map(row.original.order_items, 'title')
          return (
            <div>
              {arr.map((item, index) => (
                <div key={index}>{formatOmitLongText(item)}</div>
              ))}
            </div>
          )
        },
      },
      {
        id: 'item_sku',
        Header: intl.formatMessage({id: 'ITEM_SKU'}),
        accessor: 'item_sku',
        headerClassName: 'text-wrap min-w-125px',
        Cell: ({row}: {row: any}) => {
          const arr = map(uniqBy(row.original.order_items, 'sku'), 'sku')

          return (
            <div>
              {arr.map((item, index) => (
                <div key={index}>{formatOmitLongText(item)}</div>
              ))}
            </div>
          )
        },
      },
      {
        id: 'assigned_name',
        Header: intl.formatMessage({id: 'ASSIGNED'}),
        headerClassName: 'min-w-50px text-center',
        cellClassName: 'text-center',
        accessor: 'assigned_name',
        Cell: ({row}: {row: any}) => {
          if (!isEmpty(row.original.assigned_name)) {
            return (
              <span className={`assigned-badge- px-4 py-2 rounded-pill`}>
                {row.original.assigned_name}
              </span>
            )
          } else {
            return <span className={`assigned-badge px-4 py-2 rounded-pill`}></span>
          }
        },
      },
      {
        id: 'store_name',
        Header: intl.formatMessage({id: 'STORE'}),
        accessor: 'store_name',
        headerClassName: 'text-wrap min-w-125px',
        Cell: ({row}: {row: any}) => {
          return !isEmpty(row.original?.store?.store_name)
            ? row.original?.store?.store_name
            : intl.formatMessage({id: 'SHIPBAE'})
        },
      },
      {
        id: TABLE_KEY_COLUMN.ACTIONS,
        Header: intl.formatMessage({id: 'ACTIONS'}),
        headerClassName: 'min-w-150px text-center fixed-action',
        cellClassName: 'text-center fixed-action',
        Cell: ({row}: {row: any}) => {
          return (
            <div>
              {
                <>
                  <div
                    className={clsx({
                      'cursor-no-drop': row.original.isLoading,
                    })}
                    id={'group' + row.index}
                  >
                    <Button
                      className={clsx('btn btn-primary btn-sm', {
                        'pe-none': row.original.isLoading,
                      })}
                      event={() => {
                        if (
                          row.original.status === OrderStatus.OPEN &&
                          routes.SHIPMENTS_CREATE.hasPermission
                        ) {
                          setDataModal((prev) => ({...prev, createLabel: row.original.id}))
                          setShowModal((prev) => ({...prev, createLabel: true}))
                        } else if (
                          row.original.status === OrderStatus.CANCELLED &&
                          routes.ORDERS_CANCEL.hasPermission
                        ) {
                          setShowSwal((prev) => ({
                            ...prev,
                            restore: {
                              status: true,
                              callback: () => handleRestoreOrder(row.original.id),
                            },
                          }))
                        } else if (
                          row.original.status === OrderStatus.ON_HOLD &&
                          routes.ORDERS_HOLD.hasPermission
                        ) {
                          setShowSwal((prev) => ({
                            ...prev,
                            remove_hold: {
                              status: true,
                              callback: () => handleRemoveHoldOrder(row.original.id),
                            },
                          }))
                        } else {
                          handleViewOrder(row.original.id)
                        }
                      }}
                    >
                      {row.original.status === OrderStatus.OPEN &&
                      routes.SHIPMENTS_CREATE.hasPermission
                        ? intl.formatMessage({id: 'CREATE_LABEL'})
                        : row.original.status === OrderStatus.CANCELLED &&
                          routes.ORDERS_CANCEL.hasPermission
                        ? intl.formatMessage({id: 'RESTORE'})
                        : row.original.status === OrderStatus.ON_HOLD &&
                          routes.ORDERS_HOLD.hasPermission
                        ? intl.formatMessage({id: 'REMOVE_HOLD'})
                        : intl.formatMessage({id: 'VIEW'})}
                    </Button>
                  </div>
                </>
              }
            </div>
          )
        },
      },
      {
        id: TABLE_KEY_COLUMN.SUB_ACTIONS,
        cellClassName: 'fixed-column',
        Cell: ({row}: {row: any}) => {
          const isEditable = [OrderStatus.OPEN, OrderStatus.ON_HOLD].includes(row.original.status)

          const listDropDownBtn = [
            {
              label: intl.formatMessage({id: 'EDIT_ORDER'}),
              action: () => {
                setDataModal((prev) => ({...prev, editOrder: row.original.id}))
                setShowModal((prev) => ({...prev, editOrder: true}))
              },
              className: 'cursor-pointer',
              hidden: !isEditable || !routes.ORDERS_UPDATE.hasPermission,
            },
            {
              label: intl.formatMessage({id: 'RESHIP'}),
              action: () => {
                setDataModal((prev) => ({...prev, createLabel: row.original.id}))
                setShowModal((prev) => ({...prev, createLabel: true}))
              },
              className: 'cursor-pointer',
              hidden:
                row.original.status !== OrderStatus.SHIPPED ||
                !routes.SHIPMENTS_CREATE.hasPermission,
            },
            {
              label: intl.formatMessage({id: 'PRINT_LABEL'}),
              action: () => {
                handlePrintLabel(row.original)
              },
              className: 'cursor-pointer',
              hidden: row.original.status !== OrderStatus.SHIPPED,
            },
            {
              label: intl.formatMessage({id: 'PRINT_PACKING_SLIP'}),
              action: () => {
                handlePrintPackingSlip(row.original)
              },
              className: 'cursor-pointer',
              hidden:
                !routes.SHIPMENTS_PACKING_SLIP.hasPermission || !isEmpty(row.original?.refund),
            },
            {
              label: intl.formatMessage({id: 'COPY'}),
              action: () => {
                handleCopy(row.original.id)
              },
              className: 'cursor-pointer',
              hidden: !routes.ORDERS_CREATE.hasPermission,
            },
          ]

          return (
            <DropdownButton
              loading={row.original.isLoading}
              list={listDropDownBtn}
              className='d-flex justify-content-center'
            />
          )
        },
      },
    ]
    return _columns.filter((col) => col)
  }, [
    intl,
    currentUser,
    routes,
    currentCompany,
    handleViewOrder,
    carriers,
    handleGetRate,
    handleEditModalRate,
    handleRestoreOrder,
    handleRemoveHoldOrder,
    handlePrintLabel,
    handlePrintPackingSlip,
    handleCopy,
  ])

  const customColumns = useMemo(() => {
    if (columnsShow) {
      return showArrCustomColumns(columns, columnsShow)
    } else {
      return cloneDeep(columns)
        .filter((item) => item)
        .map((item: any) => {
          item.show = true
          return item
        })
    }
  }, [columns, columnsShow])

  const handleDownloadFile = async () => {
    try {
      setPageDisabled(true)
      setLoading((prev) => ({...prev, download_file: true}))
      const response = await BatchService.download(batch.id, {
        cancelToken: newCancelToken(),
      })
      if (response) {
        const blob = new Blob([response.content], {type: response.type})
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = response.file_name
        link.click()
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setPageDisabled(false)
      setLoading((prev) => ({...prev, download_file: false}))
    }
  }

  const handleRemoveBatch = async () => {
    try {
      setLoading((prev) => ({...prev, remove_batch: true}))
      setPageDisabled(true)
      await BatchService.removeBatch(batch.id)
      toast.success(intl.formatMessage({id: 'DELETED_BATCH_SUCCESSFULLY'}))
      navigate('/batches')
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setPageDisabled(false)
      setLoading((prev) => ({...prev, remove_batch: false}))
    }
  }

  const handleArchiveBatch = async () => {
    try {
      setLoading((prev) => ({...prev, archive_batch: true}))
      setPageDisabled(true)
      await BatchService.archiveBatch(batch.id)
      toast.success(intl.formatMessage({id: 'ARCHIVED_BATCH_SUCCESSFULLY'}))
      navigate('/batches')
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setPageDisabled(false)
      setLoading((prev) => ({...prev, archive_batch: false}))
    }
  }

  const detailMenu = () => {
    return (
      <>
        <div className='ms-1 batch-detail-menu'>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-overflow='false'
            data-kt-menu-placement='bottom-start'
          >
            <KTSVG path='/media/gori/tables/custom-column-default.svg' />
          </div>
          <div
            className='px-3 menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-200px'
            data-kt-menu='true'
          >
            <div className='menu-item'>
              <div className='menu-link' onClick={() => handleDownloadFile()}>
                {intl.formatMessage({id: 'EXPORT_AS_CSV'})}
              </div>
            </div>

            {routes.BATCHES_UPDATE.hasPermission && (
              <div className='menu-item'>
                <div
                  className={clsx('menu-link', {
                    'menu-disabled': batch?.status === BatchesStatus.ARCHIVED,
                  })}
                  onClick={() => {
                    if (batch?.status !== BatchesStatus.ARCHIVED) {
                      setShowModal((prev) => ({...prev, rename: true}))
                    }
                  }}
                >
                  {intl.formatMessage({id: 'RENAME_BATCH'})}
                </div>
              </div>
            )}
            {routes.BATCHES_DELETE.hasPermission && (
              <div className='menu-item'>
                <div
                  className={clsx('menu-link', {
                    'menu-disabled': batch?.status === BatchesStatus.ARCHIVED,
                  })}
                  onClick={() => {
                    if (batch?.status !== BatchesStatus.ARCHIVED) {
                      setShowSwal((prev) => ({
                        ...prev,
                        remove_batch: {status: true, callback: () => handleRemoveBatch()},
                      }))
                    }
                  }}
                >
                  {intl.formatMessage({id: 'REMOVE_BATCH'})}
                </div>
              </div>
            )}
            {routes.BATCHES_UPDATE.hasPermission && (
              <div className='menu-item'>
                <div
                  className={clsx('menu-link', {
                    'menu-disabled': batch?.status !== BatchesStatus.PROCESSED,
                  })}
                  onClick={() => {
                    if (batch?.status === BatchesStatus.PROCESSED) {
                      setShowSwal((prev) => ({
                        ...prev,
                        archive_batch: {status: true, callback: () => handleArchiveBatch()},
                      }))
                    }
                  }}
                >
                  {intl.formatMessage({id: 'ARCHIVE'})}
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    )
  }
  return loading.first ? (
    <>
      <div className='d-flex col-6 placeholder-wave mb-5'>
        <span className='col-5 placeholder placeholder-lg rounded-2 bg-secondary' />
        <span className='col-3 placeholder placeholder-lg rounded-2 bg-secondary mx-2' />
        <span className='btn col-2 placeholder placeholder-lg rounded-2 bg-secondary' />
      </div>
      <TableWrapper>
        <TableBody>
          <div className='d-flex col-4 placeholder-wave my-5'>
            <span className='btn col-3 placeholder placeholder-lg rounded-2 bg-secondary' />
            <span className='btn col-5 placeholder placeholder-lg rounded-2 bg-secondary mx-2' />
            <span className='btn col-4 placeholder placeholder-lg rounded-2 bg-secondary' />
          </div>
          <TableSkeleton />
        </TableBody>
      </TableWrapper>
    </>
  ) : (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      {showSwal &&
        Object.entries(showSwal).map(([key, value]: [any, any]) => {
          return (
            <ConfirmActionSwal
              show={value.status}
              title={intl.formatMessage({id: 'RESTORE'})}
              message={intl.formatMessage({id: 'ARE_YOU_SURE'})}
              messageCancel={intl.formatMessage({id: 'NO'})}
              handleCallBack={value.callback}
              handleClose={() =>
                setShowSwal((prev) => ({...prev, [key]: {...prev[key], status: false}}))
              }
            />
          )
        })}
      {showModal.fromToEdit && (
        <FromToEditModal
          show={showModal.fromToEdit}
          data={dataModal.fromToEdit}
          handleClose={() => setShowModal((prev) => ({...prev, fromToEdit: false}))}
          handleToEditSuccess={() => handleModalSuccess('fromToEdit')}
        />
      )}
      {showModal.packageTable && (
        <PackagesTableModal
          show={showModal.packageTable}
          data={dataModal.packageTable}
          handleClose={() => setShowModal((prev) => ({...prev, packageTable: false}))}
          handlePackagesTableEditSuccess={() => handleModalSuccess('packageTable')}
        />
      )}
      {showModal.rateLabel && (
        <RatesModal
          show={showModal.rateLabel}
          data={dataModal.rateLabel}
          handleClose={() => setShowModal((prev) => ({...prev, rateLabel: false}))}
          handleEditRateLabelSuccess={() => handleModalSuccess('rateLabel')}
        />
      )}
      {showModal.createLabel && (
        <OrderChildModal
          show={showModal.createLabel}
          handleClose={() => {
            setTable()
            setShowModal((prev) => ({...prev, createLabel: false}))
            setDataModal((prev) => ({...prev, createLabel: null}))
          }}
          handleSuccess={(url) => {
            if (blockPopUp(url)) {
              toast.warning(
                intl.formatMessage({
                  id: 'POP_UP_IS_BLOCKED',
                })
              )
            }
            handleModalSuccess('createLabel')
          }}
          name='createLabel'
          parentId={dataModal.createLabel}
        />
      )}
      {showModal.editOrder && (
        <OrderChildModal
          show={showModal.editOrder}
          handleClose={() => {
            setTable()
            setShowModal((prev) => ({...prev, editOrder: false}))
            setDataModal((prev) => ({...prev, editOrder: null}))
          }}
          handleSuccess={() => {
            handleModalSuccess('editOrder')
          }}
          name='editOrder'
          parentId={dataModal.editOrder}
        />
      )}
      {showModal.rename && (
        <BatchRenameModal
          show={showModal.rename}
          handleClose={() => setShowModal((prev) => ({...prev, rename: false}))}
        />
      )}
      {showModal.noticePrintPackingSlip && (
        <NoticePrintPackingSlips
          show={showModal.noticePrintPackingSlip}
          handleClose={() => setShowModal((prev) => ({...prev, noticePrintPackingSlip: false}))}
          configModal={dataModal.noticePrintPackingSlip}
        />
      )}
      <PageTitle>
        <div className='d-flex align-items-center'>
          <div className='d-flex align-items-center'>
            <ButtonBack
              label={intl.formatMessage({id: 'BACK'})}
              className='bg-white'
              event={() => navigate('/batches?status=open')}
            />
          </div>
          {batch?.created_at && (
            <div className='d-flex text-dark fw-bolder fs-3 align-items-center justify-content-between'>
              <div>
                <div className='d-flex'>
                  <span className='text-gray-700 fs-3'>{batch?.name}</span>
                </div>
                <div className='fs-7 fw-bold'>
                  <span className='fw-bolder me-1'>
                    {convertUserTimeZone(
                      batch.created_at,
                      currentUser,
                      process.env.REACT_APP_DATE_FORMAT
                    )}
                  </span>{' '}
                  <span className='text-gray-600'>
                    {convertUserTimeZone(
                      batch.created_at,
                      currentUser,
                      process.env.REACT_APP_TIME_FORMAT
                    )}
                  </span>
                </div>
              </div>
            </div>
          )}
          {detailMenu()}
        </div>
      </PageTitle>
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <TableTabs
          dataTabs={statistics}
          keyCheckActive='status'
          classNameChildTabs={clsx('col-auto', {
            'mb-3 mt-5': !(width >= 1600 || height >= 1600),
          })}
          childrenTab={
            <BatchesDetailLabels
              selectedList={{
                selected: selectedListId,
                selectedMaster: selectedMaster,
                total: pagination?.total,
              }}
              statistics={statistics}
              reloadTable={() => {
                setMeterChange(true)
                setTable()
                getBatch()
              }}
            />
          }
          children={
            <TableWrapper className='rounded-top-left-0-dt'>
              <BatchesDetailFilter
                selectedList={{
                  selected: selectedListId,
                  selectedMaster: selectedMaster,
                  total: pagination?.total,
                }}
                statistics={statistics}
                reloadTable={() => {
                  setMeterChange(true)
                  setTable()
                  getBatch()
                }}
                dataSettings={settings}
              />
              <TableBody>
                <Table
                  columns={customColumns}
                  data={tableData}
                  pagination={pagination}
                  usePagination
                  tbodyClass='text-gray-600 fw-bold'
                  useCheckBox={!isEmpty(tableData)}
                  callBackSetData={(selected) => {
                    setSelectedMaster(selected?.length === pagination?.page_size)
                    setSelectedListId(selected)
                  }}
                  customColumn={{
                    show: true,
                    name: CUSTOM_COLUMN_NAME?.BATCHES_DETAIL_PAGE?.[
                      searchParams.get('status')?.toUpperCase() || 'ALL'
                    ],
                  }}
                />
              </TableBody>
              {isFeatureEnabled(FEATURES.HELP_CENTER_WIDGET) && (
                <HelpCenterWidget data={HELP_CENTER_WIDGET_PARAMS.BATCHES} />
              )}
            </TableWrapper>
          }
        />
      </CSSTransition>
    </>
  )
}

export {BatchesDetailPage}
