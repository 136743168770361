import {isEmpty, startCase} from 'lodash'
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import HttpStatusCode from '../../../../app/api/HttpStatusCode'

type Props = {
  handleClose?: () => void
  response?: any
}

const ValidationErrorModal: React.FC<Props> = ({handleClose, response}) => {
  const intl = useIntl()
  const [title, setTitle] = useState<string>()
  const [arrErrorsOrder, setArrErrorsOrder] = useState<any>([])
  const errors = response?.data?.error

  useEffect(() => {
    if (!errors) return
    setTitle(errors?.message)

    if (response?.status !== HttpStatusCode.UNPROCESSABLE_ENTITY) return
    let arrErrors = [{}]
    errors.fields &&
      Object.entries(errors.fields).forEach(([name, value]: [any, any]) => {
        if (!name.includes('.')) {
          value.forEach((err) => {
            let arrErrorField = err.split('|')
            if (arrErrorField.length === 1) {
              arrErrors.push({id: arrErrorField[0]})
            } else if (arrErrorField.length === 2) {
              arrErrors.push({
                input: startCase(arrErrorField[0]),
                id: arrErrorField[1],
              })
            } else {
              arrErrors.push({
                input: startCase(arrErrorField[0]),
                id: arrErrorField[1],
                max: arrErrorField[2],
              })
            }
          })
        } else {
          value.forEach((err) => {
            arrErrors.push({noIntl: err})
          })
        }
      })

    setArrErrorsOrder(arrErrors.filter((item) => !isEmpty(item)))

    return () => {
      arrErrors = [{}]
      setArrErrorsOrder(undefined)
    }
  }, [errors, response?.status])

  return (
    <Modal
      id='gori_order_modal_error'
      tabIndex={-1}
      aria-hidden='true'
      centered
      show={!isEmpty(response)}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <Modal.Header closeButton>
          <Modal.Title bsPrefix={'fw-bolder fs-1'}></Modal.Title>
        </Modal.Header>
        <Modal.Body className='vh-75 scroll-y'>
          {title && (
            <div className='d-flex justify-content-center fw-bolder fs-3 text-danger mb-5'>
              {intl.formatMessage({id: title})}
              <br />
              {!!errors?.log_id ? intl.formatMessage({id: 'LOG_ID'}, {log_id: errors?.log_id}) : ''}
            </div>
          )}
          {arrErrorsOrder?.length > 0 &&
            arrErrorsOrder.map((err, index) => (
              <div key={index} className='d-flex'>
                <div className='fw-bolder fs-3 text-danger'>
                  -{' '}
                  {err?.max
                    ? intl.formatMessage(
                        {id: err.id},
                        {
                          input: err.input,
                          max: err.max,
                        }
                      )
                    : err?.input
                    ? intl.formatMessage(
                        {id: err.id},
                        {
                          input: err.input,
                        }
                      )
                    : err?.id
                    ? intl.formatMessage({id: err.id})
                    : err.noIntl}
                </div>
              </div>
            ))}
        </Modal.Body>
      </div>
    </Modal>
  )
}

export {ValidationErrorModal}
