import clsx from 'clsx'
import {cloneDeep, isEmpty, isUndefined} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSearchParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {DEFAULT_PAGE, PAGE_SIZE_MAX} from '../../../../_gori/constants'
import {KTSVG} from '../../../../_gori/helpers'
import {blockPopUp} from '../../../../_gori/helpers/BrowserHelpers'
import {DatePicker} from '../../../../_gori/helpers/components/DatePicker'
import {useStatesGlobal} from '../../../../_gori/helpers/components/StatesGlobalProvider'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import useDebouncedValue from '../../../../_gori/hooks/useDebouncedValue'
import UseRoutesPermission from '../../../../_gori/hooks/UseRoutesPermission'
import {
  Button,
  ConfirmActionSwal,
  InputSearch,
  SelectMultiDropdown,
  SelectNoFormik,
  TableHeader,
} from '../../../../_gori/partials/widgets'
import {InputFilter} from '../../../../_gori/partials/widgets/form/InputFilter'
import displayConfig from '../../../../displayconfig.json'
import {BatchCreationModal} from '../../batches'
import {ActionAllModal} from '../../common'
import {SET_UP_MODAL_ALL} from '../../common/core/_const'
import {
  AssignUserModal,
  CancelOrderModal,
  CheapestServiceModal,
  CombineOrderModal,
  HoldOrderModal,
  ManageTagModal,
  MassUpdateModal,
  NoticePrintPackingSlips,
  PresetModal,
  ProcessCheapestServiceModal,
  ProcessEstimatedServiceModal,
  RemoveHoldOrderModal,
  RestoreOrderModal,
  SaveFilterButton,
  SplitOrderModal,
  WarningTagModal,
} from '../../orders'
import {ShipmentRefund} from '../../shipments/core/_const'
import {OPTION_PACKAGE_TYPE, OrdersConfig, OrderStatus, PARAMS_ORDER_FILTER} from '../core/_const'
import OrderService from '../core/_requests'
import UserService from '../../users/core/_requests'
import {useAuth} from '../../auth'

type Props = {
  selectedList?: any
  reloadTable: any
  statistics: any
  dataSettings: any
}

const OrderFilter: React.FC<Props> = ({statistics, selectedList, reloadTable, dataSettings}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const {routes} = UseRoutesPermission()
  const [searchParams, setSearchParams] = useSearchParams()
  const {statesGlobal, getPackages} = useStatesGlobal()
  const carriers = statesGlobal.carriers
  const packages = statesGlobal.packages
  const [users, setUsers] = useState<any>()
  const {currentAccount} = useAuth()
  const [loading, setLoading] = useState<{getTags: boolean; printLabel: boolean}>({
    getTags: false,
    printLabel: false,
  })
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [clickFilter, setClickFilter] = useState<boolean>(false)
  const [dataModal, setDataModal] = useState<{
    mass_update: any
    print_packing_slip: any
    notice_print_packing_slip: any
    action_swal: any
    manage_tag: any
    warning_tags: any
  }>({
    mass_update: {ids: [], name: undefined},
    print_packing_slip: [],
    notice_print_packing_slip: {},
    action_swal: null,
    manage_tag: [],
    warning_tags: [],
  })
  const [showModal, setShowModal] = useState<{
    create_label: boolean
    preset: boolean
    mass_update: boolean
    add_to_batch: boolean
    split_shipments: boolean
    combine_shipments: boolean
    hold: boolean
    remove_hold: boolean
    cancel: boolean
    restore: boolean
    assign_to: boolean
    save_current_filter: boolean
    cheapest_service: boolean
    process_cheapest_service: boolean
    estimated_service: boolean
    print_packing_slip: boolean
    notice_print_packing_slip: boolean
    request_refund: boolean
    manage_tag: boolean
    warning_tags: boolean
  }>({
    create_label: false,
    preset: false,
    mass_update: false,
    add_to_batch: false,
    split_shipments: false,
    combine_shipments: false,
    hold: false,
    remove_hold: false,
    cancel: false,
    restore: false,
    assign_to: false,
    save_current_filter: false,
    cheapest_service: false,
    process_cheapest_service: false,
    estimated_service: false,
    print_packing_slip: false,
    notice_print_packing_slip: false,
    request_refund: false,
    manage_tag: false,
    warning_tags: false,
  })

  const initFilterOrder = useMemo(() => {
    return PARAMS_ORDER_FILTER.reduce((acc, param) => {
      acc[param] = searchParams.get(param) || undefined
      return acc
    }, {})
  }, [searchParams])

  const hasFilter = useMemo(() => {
    return Object.values(initFilterOrder).some((filter) => !isUndefined(filter))
  }, [initFilterOrder])

  const [dataFilters, setDataFilters] = useState<{[key: string]: any}>(initFilterOrder)

  useEffect(() => {
    setDataFilters(initFilterOrder)
  }, [initFilterOrder])

  // BEGIN: Service
  const optionsService = useMemo(() => {
    if (isEmpty(carriers)) return
    const serviceEnable = Object.values(cloneDeep(carriers)).filter((value: any) => value?.status)

    const _optionsService = serviceEnable.map((value: any) => ({
      label: value.visuals?.display_name,
      value: `${value.carrier}_${value.service}`,
    }))

    return [{label: intl.formatMessage({id: 'NONE'}), value: 'none'}, ..._optionsService]
  }, [carriers, intl])
  // END: Service

  // BEGIN: Package Info
  const optionsStandardPackage = useMemo(() => {
    const _options: any = []
    Object.values(carriers).forEach((carrier: any) => {
      if (carrier.status && carrier.package_types) {
        const arr = carrier.package_types.map((item) => ({label: item.label, value: item.value}))
        _options.push(...arr)
      }
    })
    return _options
  }, [carriers])

  useEffect(() => {
    getPackages()

    return () => {}
  }, [getPackages])

  const optionPackages = useMemo(() => {
    if (isEmpty(packages)) return
    return (
      packages &&
      packages.map((item: any) => ({
        label: `${item.name} ${item.length}*${item.width}*${item.height}`,
        value: item.id.toString(),
      }))
    )
  }, [packages])

  const optionPackageInfo = useMemo(() => {
    return [
      {
        label: OPTION_PACKAGE_TYPE.CARRIER_STANDARD_PACKAGE.label,
        value: OPTION_PACKAGE_TYPE.CARRIER_STANDARD_PACKAGE.value,
        children: optionsStandardPackage,
      },
      {
        label: OPTION_PACKAGE_TYPE.CUSTOM_PACKAGE.label,
        value: OPTION_PACKAGE_TYPE.CUSTOM_PACKAGE.value,
        children: optionPackages,
      },
    ]
  }, [optionPackages, optionsStandardPackage])
  // END: Package Info

  // BEGIN: User

  const getUsers = useCallback(async () => {
    const config = {
      params: {
        page: DEFAULT_PAGE,
        page_size: PAGE_SIZE_MAX,
        status: 'active',
      },
    }
    try {
      if (!currentAccount) return
      const response = await UserService.getListUserGlobal(currentAccount.encrypt_key, config)
      setUsers(response.users)
    } catch (error) {
      console.error(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getUsers()

    return () => {}
  }, [getUsers])

  const optionsUsersSearch = useMemo(() => {
    if (isEmpty(users)) return
    const _optionUser = (users &&
      users?.length > 0 &&
      Object.entries(users).map(([key, value]: [any, any]) => ({
        label: value?.first_name + ' ' + value?.last_name,
        value: value?.id.toString(),
      }))) || [{}]
    return [{label: intl.formatMessage({id: 'NONE'}), value: 'none'}, ..._optionUser]
  }, [intl, users])
  // END: User

  // BEGIN: Tag
  const optionsTag = useMemo(() => {
    const _optionsTag = dataModal.manage_tag.map((item: any) => ({
      label: (
        <div className='text-truncate'>
          <KTSVG
            path='/media/gori/orders/tag.svg'
            className='svg-icon-2 me-2'
            style={{color: item.color}}
          />
          <span>{item.name}</span>
        </div>
      ),
      labelSearch: item.name,
      value: item.id.toString(),
    }))

    return [{label: intl.formatMessage({id: 'NONE'}), value: 'none'}, ..._optionsTag]
  }, [dataModal.manage_tag, intl])

  const getOptionsTag = useCallback(async () => {
    try {
      setLoading((prev) => ({...prev, getTags: true}))
      const config = {cancelToken: newCancelToken()}
      const {tags} = await OrderService.getTags(config)
      setDataModal((prev) => ({...prev, manage_tag: tags}))
    } catch (error) {
      if (isCancel(error)) return
    } finally {
      setLoading((prev) => ({...prev, getTags: false}))
    }
  }, [isCancel, newCancelToken])

  useEffect(() => {
    getOptionsTag()

    return () => {}
  }, [getOptionsTag])
  // END: Tag

  useEffect(() => {
    if (!selectedList.selectedMaster) {
      setSelectAll(false)
    }
  }, [selectedList.selected, selectedList.selectedMaster])

  const getIdsByStatus = useCallback(
    (nameStatus) => {
      if (isEmpty(statistics)) return []
      const status: any = cloneDeep(statistics).find((item: any) => item.value === nameStatus)

      let orders: any
      if (!status) {
        orders = cloneDeep(statistics)
          .flatMap((item: any) => item.orders || [])
          .map((order: any) => ({original: order}))
      } else {
        orders = (status?.orders || []).map((order: any) => ({original: order}))
      }

      return orders
    },
    [statistics]
  )

  const filterOrdersByStatus = useCallback(
    (statusList) => {
      if (selectAll) {
        const currentStatus = searchParams.get('status')
        if (!currentStatus) {
          return statusList.flatMap((status) => getIdsByStatus(status))
        } else if (statusList.includes(currentStatus)) {
          return getIdsByStatus(currentStatus)
        }
      } else {
        return cloneDeep(selectedList.selected).flatMap((row) =>
          statusList.includes(row.original.status) ? [row] : []
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getIdsByStatus, searchParams, selectAll, selectedList.selected]
  )

  const orderIds = useMemo(() => {
    const convertDataRefund = (data) => {
      const dataRefund = data?.map((row) => ({
        ...row,
        original: {...row.original, id: row.original.latest_shipment?.gori_shipment_id},
      }))
      return dataRefund || []
    }

    return {
      create_label: filterOrdersByStatus([OrderStatus.OPEN]),
      print_label: filterOrdersByStatus([OrderStatus.SHIPPED]),
      print_return_label: filterOrdersByStatus([OrderStatus.SHIPPED]),
      print_packing_slip: selectedList.selected ?? [],
      mass_update: filterOrdersByStatus([OrderStatus.OPEN, OrderStatus.ON_HOLD]),
      // BEGIN: Other Action
      add_to_batch: selectedList.selected ?? [],
      split_combine: filterOrdersByStatus([OrderStatus.OPEN]),
      hold: filterOrdersByStatus([OrderStatus.OPEN]),
      remove_hold: filterOrdersByStatus([OrderStatus.ON_HOLD]),
      cancel: filterOrdersByStatus([OrderStatus.OPEN, OrderStatus.ON_HOLD]),
      restore: filterOrdersByStatus([OrderStatus.CANCELLED]),
      assign_to: selectedList.selected ?? [],
      request_refund: convertDataRefund(filterOrdersByStatus([OrderStatus.SHIPPED])),
      // END: Other Action
      cheapest_service: filterOrdersByStatus([OrderStatus.OPEN]),
      estimated_service: filterOrdersByStatus([OrderStatus.OPEN]),
      tag: filterOrdersByStatus([OrderStatus.OPEN]),
    }
  }, [filterOrdersByStatus, selectedList.selected])

  const otherActionsOptions: any = useMemo(() => {
    let otherActions: any = []
    otherActions.push(
      {value: 'print_packing_slip', label: intl.formatMessage({id: 'PRINT_PACKING_SLIPS'})},
      {value: 'add_to_batch', label: intl.formatMessage({id: 'ADD_TO_BATCH'})},
      {value: 'hold', label: intl.formatMessage({id: 'HOLD'})},
      {value: 'remove_hold', label: intl.formatMessage({id: 'REMOVE_HOLD'})},
      {value: 'cancel', label: intl.formatMessage({id: 'CANCEL'})},
      {value: 'restore', label: intl.formatMessage({id: 'RESTORE'})},
      {value: 'request_refund', label: intl.formatMessage({id: 'LABEL_REQUEST_REFUND'})},
      {value: 'assign_to', label: intl.formatMessage({id: 'ASSIGN_TO'})}
    )

    if (displayConfig.items.orders.splitCombine) {
      otherActions.push(
        {value: 'split_shipments', label: intl.formatMessage({id: 'SPLIT_SHIPMENTS'})},
        {value: 'combine_shipments', label: intl.formatMessage({id: 'COMBINE_SHIPMENTS'})}
      )
    }

    const status = selectedList.selected.filter((row) => row.original.status !== OrderStatus.OPEN)
    const split = selectedList.selected.filter((row) => row.original.split_from !== null)
    const combined = selectedList.selected.filter((row) => row.original.children.length > 0)
    otherActions = otherActions.map((item: any) => {
      switch (item.value) {
        case 'print_packing_slip':
          item.disabled = isEmpty(orderIds.print_packing_slip) || selectAll
          if (!routes.SHIPMENTS_PACKING_SLIP.hasPermission) {
            return null
          }
          break
        case 'add_to_batch':
          item.disabled = selectAll
          if (!routes.BATCHES_UPDATE.hasPermission) {
            return null
          }
          break
        case 'split_shipments':
          item.disabled =
            selectedList.selected.length > 1 ||
            (selectedList.selected.length === 1 &&
              selectedList.selected?.at(0)?.original?.order_items.length === 0) ||
            (selectedList.selected.length === 1 &&
              selectedList.selected?.at(0)?.original?.order_items.length === 1 &&
              selectedList.selected?.at(0)?.original?.order_items?.at(0)?.quantity === 1) ||
            status.length !== 0 ||
            split.length !== 0 ||
            combined.length !== 0 ||
            selectAll
          if (!routes.ORDERS_UPDATE.hasPermission) {
            return null
          }
          break
        case 'combine_shipments':
          item.disabled =
            selectedList.selected.length < 2 ||
            status.length !== 0 ||
            combined.length !== 0 ||
            split.length !== 0 ||
            selectAll
          if (!routes.ORDERS_UPDATE.hasPermission) {
            return null
          }
          break
        case 'hold':
          item.disabled = isEmpty(orderIds.hold) || selectAll
          if (!routes.ORDERS_HOLD.hasPermission) {
            return null
          }
          break
        case 'remove_hold':
          item.disabled = isEmpty(orderIds.remove_hold) || selectAll
          if (!routes.ORDERS_HOLD.hasPermission) {
            return null
          }
          break
        case 'cancel':
          item.disabled = isEmpty(orderIds.cancel) || selectAll
          if (!routes.ORDERS_CANCEL.hasPermission) {
            return null
          }
          break
        case 'restore':
          item.disabled = isEmpty(orderIds.restore) || selectAll
          if (!routes.ORDERS_CANCEL.hasPermission) {
            return null
          }
          break
        case 'request_refund':
          item.disabled = isEmpty(orderIds.request_refund) || selectAll
          if (!routes.SHIPMENTS_REFUND.hasPermission) {
            return null
          }
          break
        case 'assign_to':
          item.disabled = selectAll
          if (!routes.ORDERS_ASSIGN.hasPermission) {
            return null
          }
          break
        default:
          return null
      }

      return item
    })

    return otherActions.filter((item) => item !== null)
  }, [intl, orderIds, routes, selectAll, selectedList.selected])

  const debouncedOrderTagIdsSelected = useDebouncedValue(orderIds.tag, 1000)
  const debouncedDataModal = useDebouncedValue(dataModal, 1000)
  const optionsSelectedTag = useMemo(() => {
    const orderSelected = debouncedOrderTagIdsSelected?.reduce((acc, item) => {
      const idsTagForOrder = isEmpty(item.original.tags)
        ? []
        : item.original.tags.map((tag) => tag.id)

      acc[item.original.id] = idsTagForOrder
      return acc
    }, {})

    const manageTag = debouncedDataModal.manage_tag.map((tag) => {
      const id = tag.id
      let allChecked = true
      let noneChecked = true
      if (isEmpty(debouncedOrderTagIdsSelected)) {
        tag.checked = 'none'
        return tag
      }

      for (const key in orderSelected) {
        if (orderSelected[key].includes(id)) {
          noneChecked = false
        } else {
          allChecked = false
        }
      }

      tag.checked = allChecked ? 'all' : noneChecked ? 'none' : 'some'
      return tag
    })

    const handleClickTag = (tag) => {
      const checked = tag.checked === 'all' || tag.checked === 'some'

      setDataModal((prev) => ({
        ...prev,
        warning_tags: {
          status: checked ? 'remove' : 'add',
          title: checked ? 'REMOVE_TAG' : 'APPLY_TAG',
          data: {
            tag: tag,
            selected: debouncedOrderTagIdsSelected,
          },
        },
      }))
      setShowModal((prev) => ({...prev, warning_tags: true}))
    }

    const _optionsSelectedTag = manageTag.map((item) => ({
      label: (
        <div
          className={clsx('d-flex', {'cursor-no-drop opacity-50': selectAll})}
          onClick={() => !selectAll && handleClickTag(item)}
        >
          <div className='form-check'>
            <input
              aria-label='check'
              className='form-check-input cursor-pointer'
              type={item?.checked === 'all' || item.checked === 'none' ? 'checkbox' : 'radio'}
              checked={item?.checked === 'all' || item?.checked === 'some'}
            />
          </div>
          <KTSVG
            path='/media/gori/orders/tag.svg'
            className='svg-icon-2 me-2'
            style={{color: item.color}}
          />
          <span className='text-truncate'>{item.name}</span>
        </div>
      ),
      value: item.id.toString(),
    }))

    return [
      ..._optionsSelectedTag,
      {
        label: (
          <div className='mt-0'>
            {!isEmpty(_optionsSelectedTag) && (
              <hr className='mt-n2 text-gray-600 me-n3 ms-n3 overflow-auto' />
            )}
            <span className='fw-bold fs-5'>{intl.formatMessage({id: 'MANAGE_TAGS'})}</span>
          </div>
        ),
        value: 'manage_tag',
      },
    ]
  }, [debouncedOrderTagIdsSelected, debouncedDataModal.manage_tag, intl, selectAll])

  const handlePrintBatchLabel = useCallback(
    async (selected: any) => {
      let orderIds: any = []
      let refundOrderIds: any = []
      selected.forEach((row: any) => {
        if (
          row.original?.latest_shipment &&
          (row.original.latest_shipment.refund?.status === ShipmentRefund.SUBMITTED ||
            row.original.latest_shipment.refund?.status === ShipmentRefund.REFUNDED)
        ) {
          refundOrderIds.push(row.original.id)
        }
        orderIds.push(row.original.id)
      })
      if (orderIds.length === refundOrderIds.length) {
        toast.warning(
          intl.formatMessage({
            id: 'PRINT_LABELS_REFUNDED',
          })
        )
        return
      }
      if (orderIds.length > 0) {
        const config = {cancelToken: newCancelToken()}
        const data = {
          order_ids: orderIds,
        }
        setLoading((prev) => ({...prev, printLabel: true}))
        await OrderService.print(data, config)
          .then((response) => {
            if (blockPopUp(response.label_url)) {
              toast.warning(
                intl.formatMessage({
                  id: 'POP_UP_IS_BLOCKED',
                })
              )
            }
          })
          .catch((error) => {
            if (isCancel(error)) return
          })
          .finally(() => {
            setLoading((prev) => ({...prev, printLabel: false}))
          })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl, isCancel, newCancelToken]
  )

  const handleMassUpdate = useCallback(
    (name) => {
      if (name === 'preset') {
        setShowModal((prev) => ({...prev, preset: true}))
      } else {
        setShowModal((prev) => ({...prev, mass_update: true}))
        setDataModal((prev) => ({
          ...prev,
          mass_update: {
            ids: orderIds.mass_update.map((item: any) => {
              return item.original.id
            }),
            name: name,
          },
        }))
      }
    },
    [orderIds.mass_update]
  )

  const handleFiltersOrder = (key: string, value: any) => {
    value = value.toString()
    value.trim() ? searchParams.set(key, value.trim()) : searchParams.delete(key)
    searchParams.set('page', DEFAULT_PAGE)
    setSearchParams(searchParams)
  }

  const handleClearAll = () => {
    PARAMS_ORDER_FILTER.forEach((param) => searchParams.delete(param))
    setSearchParams(searchParams)
    setClickFilter(false)
  }

  const handleRequestRefundAll = useCallback(() => {
    if (isEmpty(orderIds.request_refund)) return
    setShowModal((prev) => ({...prev, request_refund: true}))
  }, [orderIds.request_refund])

  const handlePrintPackingSlips = () => {
    const {
      packing_slip: {disable},
    } = dataSettings
    if (disable) {
      const configNoticePackingSlip = {
        title: 'PRINT_PACKING_SLIPS',
        content: 'DISABLE_PACKING_SLIPS_MESSAGE',
      }
      setDataModal((prev) => ({
        ...prev,
        notice_print_packing_slip: configNoticePackingSlip,
      }))
      setShowModal((prev) => ({...prev, notice_print_packing_slip: true}))
      return
    }

    setDataModal((prev) => ({
      ...prev,
      print_packing_slip: orderIds.print_packing_slip,
    }))
    setShowModal((prev) => ({...prev, print_packing_slip: true}))
  }

  return (
    <>
      {dataModal.action_swal &&
        Object.entries(dataModal.action_swal).map(([key, value]: [any, any], index) => {
          return (
            <ConfirmActionSwal
              key={index}
              show={value.status}
              title={intl.formatMessage({id: 'REFUND_REQUEST'})}
              message={intl.formatMessage({id: 'ARE_YOU_SURE'})}
              messageCancel={intl.formatMessage({id: 'NO'})}
              handleCallBack={value.callback}
              handleClose={() =>
                setDataModal((prev) => ({
                  ...prev,
                  action_swal: {
                    ...prev.action_swal,
                    [key]: {...prev.action_swal[key], status: false},
                  },
                }))
              }
            />
          )
        })}
      {showModal.create_label && (
        <ActionAllModal
          show={showModal.create_label}
          data={orderIds.create_label}
          setUpModal={SET_UP_MODAL_ALL.CREATE_LABELS}
          handleClose={() => {
            reloadTable()
            setShowModal((prev) => ({...prev, create_label: false}))
          }}
        />
      )}

      {showModal.print_packing_slip && (
        <ActionAllModal
          show={showModal.print_packing_slip}
          data={dataModal.print_packing_slip}
          setUpModal={SET_UP_MODAL_ALL.PACKING_SLIPS}
          handleClose={() => {
            setShowModal((prev) => ({...prev, print_packing_slip: false}))
          }}
          isSelectAll={selectAll}
        />
      )}
      {showModal.preset && (
        <PresetModal
          show={showModal.preset}
          handleClose={() => setShowModal((prev) => ({...prev, preset: false}))}
          orders={orderIds.mass_update}
          reloadTable={reloadTable}
        />
      )}
      {showModal.mass_update && (
        <MassUpdateModal
          show={showModal.mass_update}
          handleClose={() => setShowModal((prev) => ({...prev, mass_update: false}))}
          data={dataModal.mass_update}
          reloadTable={reloadTable}
        />
      )}
      {showModal.split_shipments && (
        <SplitOrderModal
          show={showModal.split_shipments}
          handleClose={() => setShowModal((prev) => ({...prev, split_shipments: false}))}
          orders={orderIds.split_combine}
          reloadTable={reloadTable}
        />
      )}
      {showModal.combine_shipments && (
        <CombineOrderModal
          show={showModal.combine_shipments}
          handleClose={() => setShowModal((prev) => ({...prev, combine_shipments: false}))}
          orders={orderIds.split_combine}
          reloadTable={reloadTable}
        />
      )}
      {showModal.add_to_batch && (
        <BatchCreationModal
          show={showModal.add_to_batch}
          handleClose={() => {
            setShowModal((prev) => ({...prev, add_to_batch: false}))
          }}
          orders={orderIds.add_to_batch}
          reloadTable={reloadTable}
        />
      )}
      {showModal.assign_to && (
        <AssignUserModal
          show={showModal.assign_to}
          handleClose={() => setShowModal((prev) => ({...prev, assign_to: false}))}
          orders={orderIds.assign_to}
          reloadTable={reloadTable}
        />
      )}
      {showModal.remove_hold && (
        <RemoveHoldOrderModal
          show={showModal.remove_hold}
          handleClose={() => setShowModal((prev) => ({...prev, remove_hold: false}))}
          orders={orderIds.remove_hold}
          reloadTable={reloadTable}
        />
      )}
      {showModal.restore && (
        <RestoreOrderModal
          show={showModal.restore}
          handleClose={() => setShowModal((prev) => ({...prev, restore: false}))}
          orders={orderIds.restore}
          reloadTable={reloadTable}
        />
      )}
      {showModal.hold && (
        <HoldOrderModal
          show={showModal.hold}
          handleClose={() => setShowModal((prev) => ({...prev, hold: false}))}
          orders={orderIds.hold}
          reloadTable={reloadTable}
        />
      )}
      {showModal.cancel && routes.ORDERS_CANCEL.hasPermission && (
        <CancelOrderModal
          show={showModal.cancel}
          handleClose={() => setShowModal((prev) => ({...prev, cancel: false}))}
          orders={orderIds.cancel}
          reloadTable={reloadTable}
        />
      )}
      {showModal.cheapest_service && (
        <CheapestServiceModal
          show={showModal.cheapest_service}
          handleClose={() => setShowModal((prev) => ({...prev, cheapest_service: false}))}
          orders={orderIds.cheapest_service}
          confirm={() => setShowModal((prev) => ({...prev, process_cheapest_service: true}))}
        />
      )}
      {showModal.process_cheapest_service && (
        <ProcessCheapestServiceModal
          isSelectAll={selectAll}
          show={showModal.process_cheapest_service}
          data={orderIds.cheapest_service}
          handleClose={() => {
            reloadTable()
            setShowModal((prev) => ({...prev, process_cheapest_service: false}))
          }}
        />
      )}
      {showModal.estimated_service && (
        <ProcessEstimatedServiceModal
          isSelectAll={selectAll}
          show={showModal.estimated_service}
          data={orderIds.estimated_service}
          handleClose={() => {
            reloadTable()
            setShowModal((prev) => ({...prev, estimated_service: false}))
          }}
        />
      )}
      {showModal.request_refund && (
        <ActionAllModal
          show={showModal.request_refund}
          data={orderIds.request_refund}
          setUpModal={SET_UP_MODAL_ALL.REFUND_REQUEST}
          handleClose={() => {
            reloadTable()
            setShowModal((prev) => ({...prev, request_refund: false}))
          }}
        />
      )}
      {showModal.notice_print_packing_slip && (
        <NoticePrintPackingSlips
          show={showModal.notice_print_packing_slip}
          handleClose={() => setShowModal((prev) => ({...prev, notice_print_packing_slip: false}))}
          configModal={dataModal.notice_print_packing_slip}
        />
      )}
      {showModal.manage_tag && (
        <ManageTagModal
          show={showModal.manage_tag}
          dataInit={dataModal.manage_tag}
          handleClose={() => setShowModal((prev) => ({...prev, manage_tag: false}))}
          reloadOptionsTag={(newData) => {
            setDataModal((prev) => ({
              ...prev,
              manage_tag: newData,
            }))
            reloadTable()
          }}
        />
      )}
      {showModal.warning_tags && (
        <WarningTagModal
          show={showModal.warning_tags}
          dataModal={dataModal.warning_tags}
          handleClose={() => {
            setShowModal((prev) => ({...prev, warning_tags: false}))
            setDataModal((prev) => ({...prev, warning_tags: {}}))
          }}
          handleSuccess={() => reloadTable()}
        />
      )}
      <TableHeader className='min-h-1px my-5 mb-8 d-flex flex-column'>
        <div className='d-flex flex-wrap flex-fill justify-content-between'>
          <div className='d-flex align-items-center position-relative my-1'>
            <InputSearch placeholder={intl.formatMessage({id: 'SEARCH_ORDERS'})} />
          </div>
          <div className='d-flex flex-wrap'>
            <DatePicker
              dateFrom='date_from'
              dateTo='date_to'
              name={intl.formatMessage({id: 'ORDER_DATE'})}
              className='m-2 bg-input-dark'
            />
            <div className='d-flex justify-content-center align-items-center'>
              <div
                className={clsx(
                  'btn btn-icon border border-2 border-light btn-active-light-primary m-2',
                  {
                    'border border-2 border-primary rounded-2 active': clickFilter,
                    'bg-primary text-hover-primary': hasFilter && !clickFilter,
                  }
                )}
                onClick={() => setClickFilter(!clickFilter)}
              >
                <KTSVG
                  path='/media/gori/orders/filter.svg'
                  className={clsx('svg-icon svg-icon-1', {
                    'text-white': hasFilter && !clickFilter,
                  })}
                  small={false}
                />
              </div>
            </div>
            <SaveFilterButton
              setClickFilter={setClickFilter}
              showModalSaveCurrentFilter={showModal.save_current_filter}
              setShowModalSaveCurrentFilter={setShowModal}
              dataFilters={dataFilters}
              handleClearAll={handleClearAll}
              className='m-2 bg-input-dark'
            />
          </div>
        </div>
        {clickFilter && (
          <div className='d-flex flex-wrap justify-content-end'>
            <span
              className='text-gray-600 text-hover-danger m-2 d-flex align-items-center cursor-pointer'
              onClick={handleClearAll}
            >
              <span className='fw-bolder me-2'>{intl.formatMessage({id: 'CLEAR_ALL'})}</span> X
            </span>
            {hasFilter && (
              <span
                className='text-primary bg-hover-light px-2 rounded-2 m-2 d-flex align-items-center cursor-pointer'
                onClick={() => setShowModal((prev) => ({...prev, save_current_filter: true}))}
              >
                <span className='fw-bolder me-2'>
                  {intl.formatMessage({id: 'SAVE_CURRENT_FILTER'})}
                </span>
              </span>
            )}

            <InputFilter
              className='m-2 bg-input-dark'
              name={intl.formatMessage({id: 'BATCH'})}
              valueInput={dataFilters.batch}
              setValueInput={(value) => {
                handleFiltersOrder('batch', value)
              }}
            />
            <DatePicker
              dateFrom='ship_by_date_from'
              dateTo='ship_by_date_to'
              name={intl.formatMessage({id: 'SHIP_BY'})}
              className='m-2 bg-input-dark'
              alwaysShowLabel={false}
            />
            <InputFilter
              className='m-2 bg-input-dark'
              name={intl.formatMessage({id: 'CUSTOMER'})}
              valueInput={dataFilters.customer}
              setValueInput={(value) => {
                handleFiltersOrder('customer', value)
              }}
            />
            <InputFilter
              className='m-2 bg-input-dark'
              name={intl.formatMessage({id: 'ORDER_REF'})}
              valueInput={dataFilters.order_ref}
              setValueInput={(value) => {
                handleFiltersOrder('order_ref', value)
              }}
            />
            <InputFilter
              className='m-2 bg-input-dark'
              name={intl.formatMessage({id: 'ITEM_SKU'})}
              valueInput={dataFilters.item_sku}
              setValueInput={(value) => {
                handleFiltersOrder('item_sku', value)
              }}
            />
            <InputFilter
              className='m-2 bg-input-dark'
              name={intl.formatMessage({id: 'ITEM_NAME'})}
              valueInput={dataFilters.item_name}
              setValueInput={(value) => {
                handleFiltersOrder('item_name', value)
              }}
            />
            <SelectMultiDropdown
              className='m-2 bg-input-dark'
              name={intl.formatMessage({id: 'SERVICE'})}
              dataOptions={optionsService}
              valueOptions={dataFilters.service}
              handleSetValueOptions={(service) => handleFiltersOrder('service', service)}
              placeholder={intl.formatMessage({id: 'SEARCH'})}
              isSearch
              hasClearValue
              firstOption
            />
            <SelectMultiDropdown
              className='m-2 bg-input-dark'
              name={intl.formatMessage({id: 'PACKAGE_INFO'})}
              dataOptions={optionPackageInfo}
              valueOptions={dataFilters.package_info}
              handleSetValueOptions={(value) => handleFiltersOrder('package_info', value)}
              hasClearValue
            />
            <SelectMultiDropdown
              name={intl.formatMessage({id: 'ASSIGNED'})}
              className='m-2 bg-input-dark'
              placeholder={intl.formatMessage({id: 'SEARCH'})}
              dataOptions={optionsUsersSearch}
              valueOptions={dataFilters.assigned_id}
              isSearch
              handleSetValueOptions={(value) => {
                handleFiltersOrder('assigned_id', value)
              }}
              hasClearValue
              firstOption
            />
            <SelectMultiDropdown
              name={intl.formatMessage({id: 'CUSTOM_TAG'})}
              className='m-2 bg-input-dark'
              placeholder={intl.formatMessage({id: 'SEARCH'})}
              dataOptions={optionsTag}
              valueOptions={dataFilters.tag_id}
              isSearch
              handleSetValueOptions={(value) => {
                handleFiltersOrder('tag_id', value)
              }}
              hasClearValue
              firstOption
            />
          </div>
        )}
        <div className='d-flex justify-content-between align-items-center'>
          <div
            className={clsx('d-flex justify-content-end align-items-center flex-wrap gap-3', {
              'd-none': selectedList.selected.length <= 0,
            })}
          >
            <div className='fw-bolder me-5'>
              <span className='me-2'>
                {selectAll ? selectedList.total : selectedList.selected.length}
              </span>
              {intl.formatMessage({id: 'SELECTED'})}
            </div>
            {!selectAll && selectedList.selectedMaster && (
              <div
                onClick={() => setSelectAll(true)}
                className='fw-bolder me-5 link-blue text-decoration-underline cursor-pointer'
              >
                {intl.formatMessage({id: 'SELECT_ALL'})} {selectedList.total}
              </div>
            )}
            {selectAll && (
              <div
                onClick={() => setSelectAll(false)}
                className='fw-bolder me-5 link-blue text-decoration-underline cursor-pointer'
              >
                {intl.formatMessage({id: 'CLEAR_SELECTION'})}
              </div>
            )}
            {!isEmpty(orderIds.create_label) && routes.SHIPMENTS_CREATE.hasPermission && (
              <Button
                className='btn btn-light-primary border border-primary'
                label={intl.formatMessage({id: 'CREATE_LABEL'})}
                loadingText={intl.formatMessage({id: 'CREATE_LABEL'})}
                event={() => setShowModal((prev) => ({...prev, create_label: true}))}
              />
            )}
            {!isEmpty(orderIds.print_label) && (
              <Button
                className='btn btn-light-blue border border-blue'
                label={intl.formatMessage({id: 'PRINT_LABELS'})}
                loading={loading.printLabel}
                loadingText={intl.formatMessage({id: 'PRINT_LABELS'})}
                event={() => handlePrintBatchLabel(orderIds.print_label)}
                disabled={loading.printLabel}
              />
            )}
            {!isEmpty(orderIds.mass_update) && routes.ORDERS_UPDATE.hasPermission && (
              <SelectNoFormik
                emptyDefault={false}
                className={clsx('w-200px fw-bold fs-5')}
                value={{label: intl.formatMessage({id: 'MASS_UPDATE'}), value: ''}}
                options={OrdersConfig.MASS_UPDATE}
                onChange={(e) => handleMassUpdate(e.value)}
                hasUseIntl={true}
              />
            )}
            {!isEmpty(orderIds.tag) && routes.ORDERS_UPDATE.hasPermission && (
              <SelectNoFormik
                emptyDefault={false}
                className={clsx('w-200px fw-bold fs-5')}
                value={{label: intl.formatMessage({id: 'TAG'}), value: ''}}
                options={optionsSelectedTag}
                onChange={(e) => {
                  if (e.value === 'manage_tag') {
                    setShowModal((prev) => ({...prev, manage_tag: true}))
                  }
                }}
                disabled={loading.getTags}
              />
            )}
            {!isEmpty(otherActionsOptions) && (
              <SelectNoFormik
                emptyDefault={false}
                className={clsx('w-200px fw-bold fs-5')}
                value={{label: intl.formatMessage({id: 'OTHER_ACTIONS'}), value: ''}}
                options={otherActionsOptions}
                onChange={(e) => {
                  switch (e.value) {
                    case 'print_packing_slip':
                      handlePrintPackingSlips()
                      break
                    case 'request_refund':
                      setDataModal((prev) => ({
                        ...prev,
                        action_swal: {
                          ...prev.action_swal,
                          [e.value]: {
                            status: true,
                            message:
                              'A_REFUND_WILL_BE_REQUESTED_FOR_THE_LATEST_SHIPMENT_IN_THE_ORDER_WOULD_YOU_LIKE_TO_PROCEED',
                            callback: () => handleRequestRefundAll(),
                          },
                        },
                      }))
                      break
                    default:
                      setShowModal((prev) => ({...prev, [e.value]: true}))
                      break
                  }
                }}
              />
            )}
            {!isEmpty(orderIds.cheapest_service) && routes.ORDERS_UPDATE.hasPermission && (
              <Button
                className='btn btn-light-warning'
                label={intl.formatMessage({id: 'CHEAPEST_SERVICE'})}
                loadingText={intl.formatMessage({id: 'CHEAPEST_SERVICE'})}
                event={() => setShowModal((prev) => ({...prev, cheapest_service: true}))}
              />
            )}
            {!isEmpty(orderIds.estimated_service) && routes.ORDERS_UPDATE.hasPermission && (
              <Button
                className='btn btn-light-info'
                label={intl.formatMessage({id: 'ESTIMATE_SERVICE'})}
                loadingText={intl.formatMessage({id: 'ESTIMATE_SERVICE'})}
                event={() => setShowModal((prev) => ({...prev, estimated_service: true}))}
              />
            )}
          </div>
        </div>
      </TableHeader>
    </>
  )
}

export {OrderFilter}
