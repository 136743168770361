import _ from 'lodash'
import React from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'

type Props = {
  handleClose?: () => void
  message?: any
}

const ErrorModal: React.FC<Props> = ({handleClose, message}) => {
  return (
    <Modal
      id='gori_order_modal_error'
      tabIndex={-1}
      aria-hidden='true'
      centered
      show={!_.isEmpty(message)}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <Modal.Header closeButton>
          <Modal.Title bsPrefix={'fw-bolder fs-1'}></Modal.Title>
        </Modal.Header>
        <Modal.Body className='vh-75 scroll-y'>
          <div className='fw-bolder fs-3 text-danger'>{message}</div>
        </Modal.Body>
      </div>
    </Modal>
  )
}

export {ErrorModal}
