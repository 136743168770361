import {IntegrationsPageLayout} from '../../../_gori/layout/components/IntegrationsPagesLayout'

export function AmazonIntegration() {
  return (
    <>
      <IntegrationsPageLayout
        pageClass='amazon-integration'
        heading='AMAZON_HEADER'
        headingSubtext='AMAZON_SUBTEXT'
        integrationLogo='/media/gori/integrations/amazon/amazon-logo.svg'
        integrationLogoAlt='amazon-logo'
        integrateImg='/media/gori/integrations/amazon/integrate.webp'
        card1Copy='AMAZON_CARD_1'
        card2Copy='AMAZON_CARD_2'
        card3Copy='AMAZON_CARD_3'
        card4Copy='AMAZON_CARD_4'
      />
    </>
  )
}
