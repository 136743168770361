import React, {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import {useAuth} from '../../../../auth'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import SettingsService from '../../../core/_requests'
import {toAbsoluteUrl} from '../../../../../../_gori/helpers'
import {Button} from '../../../../../../_gori/partials/widgets'
import {toast} from 'react-toastify'
import {OrderType} from '../../../../orders/core/_const'

const ConnectionsPage: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {loadingSwitch} = useAuth()
  const {newCancelToken, isCancel} = useCancelToken()
  const [connections, setConnections] = useState<any>()
  const [isLoadingConnections, setIsLoadingConnections] = useState<boolean>(false)

  const getConnections = useCallback(async () => {
    const config = {
      cancelToken: newCancelToken(),
    }
    setIsLoadingConnections(true)
    await SettingsService.connections(config)
      .then((response) => {
        setConnections(response.connections)
      })
      .catch((error) => {
        if (isCancel(error)) return
      })
      .finally(() => {
        setIsLoadingConnections(false)
      })
  }, [isCancel, newCancelToken])

  useEffect(() => {
    if (loadingSwitch) return

    getConnections()

    return () => {
      setConnections(undefined)
    }
  }, [loadingSwitch, getConnections])

  const handleDisconnect = useCallback(
    async (store_type) => {
      const config = {
        params: {
          store_type: store_type,
        },
        cancelToken: newCancelToken(),
      }
      setIsLoadingConnections(true)

      await SettingsService.disconnect(config)
        .then(async () => {
          await getConnections()
          navigate('/settings/connections')
          toast.success(intl.formatMessage({id: 'DISCONNECTED_SUCCESSFULLY'}))
        })
        .catch((error) => {
          if (isCancel(error)) return
        })
        .finally(() => {
          setIsLoadingConnections(false)
        })
    },
    [getConnections, intl, isCancel, navigate, newCancelToken]
  )

  const connectToShipStation = () => {
    navigate('/settings/connections/shipstation')
  }

  const connectToShopify = () => {
    navigate('/settings/connections/shopify')
  }

  const connectToWalmart = () => {
    navigate('/settings/connections/walmart')
  }

  const connectToTikTok = () => {
    navigate('/settings/connections/tiktok')
  }

  const connectToEbay = () => {
    navigate('/settings/connections/ebay')
  }

  return (
    <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
      <div className='card h-100'>
        <div className='card-header card-header-stretch'>
          <div className='card-title'>
            <h3>{intl.formatMessage({id: 'STORE_CONNECTIONS'})}</h3>
          </div>
        </div>
        <div className='card-body'>
          <div className='d-flex justify-content-between pb-5'>
            <div className={'d-flex flex-row align-items-center gap-2'}>
              <div className='w-30px text-center'>
                <img
                  alt='shipstation'
                  src={toAbsoluteUrl('/media/gori/settings/shipstation.png')}
                  className={'h-15px'}
                />
              </div>
              <span className={'fw-bolder fs-4'}>ShipStation</span>
            </div>
            <div>
              <Button
                className={`btn btn-sm fs-sm-5 ${
                  connections?.shipstation?.length > 0 ? 'btn-danger' : 'btn-primary'
                }`}
                label={intl.formatMessage({
                  id: connections?.shipstation?.length > 0 ? 'DISCONNECT' : 'CONNECT',
                })}
                loadingText={intl.formatMessage({
                  id: connections?.shipstation?.length > 0 ? 'DISCONNECT' : 'CONNECT',
                })}
                loading={isLoadingConnections}
                disabled={isLoadingConnections}
                event={
                  connections?.shipstation?.length > 0
                    ? () => handleDisconnect(OrderType.SHIPSTATION)
                    : connectToShipStation
                }
              />
            </div>
          </div>
          <div className='d-flex justify-content-between pb-5'>
            <div className={'d-flex flex-row align-items-center gap-2'}>
              <div className='w-30px text-center'>
                <img
                  alt='shopify'
                  src={toAbsoluteUrl('/media/gori/settings/shopify.png')}
                  className={'h-15px'}
                />
              </div>
              <span className={'fw-bolder fs-4'}>Shopify</span>
            </div>
            <div>
              <Button
                className={`btn btn-sm btn-primary fs-sm-5`}
                label={intl.formatMessage({id: 'MANAGE_CONNECTIONS'})}
                loadingText={intl.formatMessage({id: 'MANAGE_CONNECTIONS'})}
                loading={isLoadingConnections}
                disabled={isLoadingConnections}
                event={connectToShopify}
              />
            </div>
          </div>
          <div className='d-flex justify-content-between pb-5'>
            <div className={'d-flex flex-row align-items-center gap-2'}>
              <div className='w-30px text-center'>
                <img
                  alt='walmart'
                  src={toAbsoluteUrl('/media/gori/settings/walmart.png')}
                  className={'h-15px'}
                />
              </div>
              <span className={'fw-bolder fs-4'}>Walmart</span>
            </div>
            <div>
              <Button
                className={`btn btn-sm btn-primary fs-sm-5`}
                label={intl.formatMessage({id: 'MANAGE_CONNECTIONS'})}
                loadingText={intl.formatMessage({id: 'MANAGE_CONNECTIONS'})}
                loading={isLoadingConnections}
                disabled={isLoadingConnections}
                event={connectToWalmart}
              />
            </div>
          </div>
          <div className='d-flex justify-content-between pb-5'>
            <div className={'d-flex flex-row align-items-center gap-2'}>
              <div className='w-30px text-center'>
                <img
                  alt='shopify'
                  src={toAbsoluteUrl('/media/gori/settings/tiktok.png')}
                  className={'h-15px'}
                />
              </div>
              <span className={'fw-bolder fs-4'}>TikTok</span>
            </div>
            <div>
              <Button
                className={`btn btn-sm btn-primary fs-5`}
                label={intl.formatMessage({id: 'MANAGE_CONNECTIONS'})}
                loadingText={intl.formatMessage({id: 'MANAGE_CONNECTIONS'})}
                loading={isLoadingConnections}
                disabled={isLoadingConnections}
                event={connectToTikTok}
              />
            </div>
          </div>
          <div className='d-flex justify-content-between pb-5'>
            <div className={'d-flex flex-row align-items-center gap-2'}>
              <div className='w-30px text-center'>
                <img
                  alt='ebay'
                  src={toAbsoluteUrl('/media/gori/settings/ebay-logo.png')}
                  className={'h-15px'}
                />
              </div>
              <span className={'fw-bolder fs-4'}>eBay</span>
            </div>
            <div>
              <Button
                className={`btn btn-sm btn-primary fs-5`}
                label={intl.formatMessage({id: 'MANAGE_CONNECTIONS'})}
                loadingText={intl.formatMessage({id: 'MANAGE_CONNECTIONS'})}
                loading={isLoadingConnections}
                disabled={isLoadingConnections}
                event={connectToEbay}
              />
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export {ConnectionsPage}
