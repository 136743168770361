import React, {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import SettingsService from '../../../core/_requests'
import {LoadingPage} from '../../../../../../_gori/helpers/components/LoadingPage'
import {useAuth} from '../../../../auth'
import {StorageHelpers} from '../../../../../../_gori/helpers'
import {OrderType} from '../../../../orders/core/_const'

const VerifyShopify: React.FC = () => {
  const intl = useIntl()
  const [queryParams] = useSearchParams()
  const store = queryParams.get('shop')
  const accessToken = queryParams.get('access_token')
  const scope = queryParams.get('scope')
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {currentUser} = useAuth()

  const connectShopify = useCallback(async () => {
    setIsLoading(true)
    try {
      const res = await SettingsService.connect({
        store: store,
        access_token: accessToken,
        scope: scope,
        store_type: OrderType.SHOPIFY,
      })
      if (res) {
        toast.success(intl.formatMessage({id: 'CONNECTIONS_SUCCESSFUL'}))
      }
    } catch (error) {
      toast.error(intl.formatMessage({id: 'CONNECTIONS_FAILED'}))
    } finally {
      setIsLoading(false)
      StorageHelpers.setItemLocalStorage('IS_LOADING', false)
      if (currentUser?.onboard_step === 'done') {
        navigate('/settings/connections/shopify')
      } else {
        StorageHelpers.setItemLocalStorage('CONNECT_SHOPIFY', true)
        navigate('/your-info')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, intl, navigate, scope, store, currentUser])

  useEffect(() => {
    connectShopify()
  }, [connectShopify])

  return isLoading ? <LoadingPage className='start-0' /> : <></>
}

export {VerifyShopify}
