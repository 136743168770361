import {CSSTransition} from 'react-transition-group'
import {toAbsoluteUrl} from '../../../_gori/helpers'
import {useEffect} from 'react'
import {Header} from '../../../app/pages/landing/components/Header'
import {HowItWorks} from '../../../app/pages/landing/components/HowItWorks'
import {StartFreeToday} from '../../../app/pages/landing/components/StartFreeToday'
import {Footer} from '../../../app/pages/landing/components/Footer'
import clsx from 'clsx'
import {CalculateLabels} from '../../../app/pages/landing/components/CalculateLabels'
import {Discounts} from '../../../app/pages/landing/components/Discounts'
import {Security} from '../../../app/pages/landing/components/Security'
import {WhatIsShipbae} from '../../../app/pages/landing/components/WhatIsShipbae'
import {useIntl} from 'react-intl'

type Props = {
  pageClass?: string
  heading?: string
  headingSubtext?: string
  integrationLogo?: string
  integrationLogoAlt?: string
  integrateImg?: string
  card1Copy?: string
  card2Copy?: string
  card3Copy?: string
  card4Copy?: string
}

export function IntegrationsPageLayout({
  pageClass,
  heading,
  headingSubtext,
  integrationLogo,
  integrationLogoAlt,
  integrateImg,
  card1Copy,
  card2Copy,
  card3Copy,
  card4Copy,
}: Props) {
  const intl = useIntl()

  useEffect(() => {
    window.scrollTo({top: 0})
  }, [])

  return (
    <>
      <Header />

      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className={clsx('bg-white', pageClass)}>
          <section className='gradient-bg'>
            <div className='hero container'>
              <div className='logo-container'>
                {integrationLogo && (
                  <img src={toAbsoluteUrl(integrationLogo)} alt={integrationLogoAlt} />
                )}
              </div>

              <div className='integration-x-container d-flex flex-row justify-content-center align-items-center'>
                {integrationLogo && (
                  <img src={toAbsoluteUrl(integrationLogo)} alt={integrationLogoAlt} />
                )}
                <img src={toAbsoluteUrl('/media/gori/integrations/x.svg')} alt='x' />
                <img
                  src={toAbsoluteUrl('/media/gori/integrations/shipbae-logo.svg')}
                  alt='shipbae'
                />
              </div>

              <h2 className='text-primary h2 text-center px-6'>
                {intl.formatMessage({id: heading})}
              </h2>
              <p className='text-center px-6 mb-20'>{intl.formatMessage({id: headingSubtext})}</p>

              <Discounts />
            </div>

            <WhatIsShipbae />

            <div className='what-we-offer container'>
              <h2 className='text-orange h2 text-left'>What We Offer</h2>

              <ul className='d-flex flex-column justify-content-center align-items-center w-100'>
                {[
                  {img: integrateImg, copy: card1Copy, alt: 'integrate graphic'},
                  {
                    img: '/media/gori/integrations/shop-rates.webp',
                    copy: card2Copy,
                    alt: 'shop rates graphic',
                  },
                  {
                    img: '/media/gori/integrations/at-a-glance.webp',
                    copy: card3Copy,
                    alt: 'at a glance graphic',
                  },
                  {
                    img: '/media/gori/integrations/tools.webp',
                    copy: card4Copy,
                    alt: 'tools graphic',
                  },
                ].map((item, index) => (
                  <li
                    key={index}
                    className='w-100 d-flex flex-column flex-md-row justify-content-center align-items-center'
                  >
                    {item.img && (
                      <img className='d-block' src={toAbsoluteUrl(item.img)} alt={item.alt} />
                    )}
                    <div>
                      <p>{intl.formatMessage({id: item.copy})}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </section>

          <HowItWorks />

          <CalculateLabels />

          <Security />

          <StartFreeToday />
        </div>
      </CSSTransition>

      <Footer />
    </>
  )
}
