import React, {useState, useEffect} from 'react'
import {CSSTransition} from 'react-transition-group'
import {useIntl} from 'react-intl'
import {StorageHelpers, toAbsoluteUrl} from '../../../../../../_gori/helpers'
import {ShipStation} from './ShipStation'
import {Shopify} from './Shopify'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import SettingsService from '../../../../settings/core/_requests'
import Card from '../common/Card'

type Props = {
  setPayloadOption: any
}
const Store: React.FC<Props> = ({setPayloadOption}) => {
  const intl = useIntl()
  const [active, setActive] = useState<string | null>(null)
  const [isConnection, setIsConnection] = useState<any>({})
  const connectShopify = StorageHelpers.getItemLocalStorage('CONNECT_SHOPIFY')
  const {newCancelToken, isCancel} = useCancelToken()

  const StoreOption = [
    {
      value: 'shopify',
      label: 'Shopify',
      image: '../media/gori/onboard/label/v2/grey_out/shopify_grey.png',
      image_active: '../media/gori/onboard/store/shopify.png',
    },
    {
      value: 'shipstation',
      label: 'ShipStation',
      image: '../media/gori/onboard/label/v2/grey_out/shipst.png',
      image_active: '../media/gori/onboard/store/ship-station.png',
    },
  ]

  useEffect(() => {
    try {
      const fetchConnections = async () => {
        const res = await SettingsService.connections({cancelToken: newCancelToken()})
        if (res) {
          setIsConnection({
            shopify: res?.connections?.shopify.length > 0,
            shipStation: res?.connections?.shipstation.length > 0,
          })
        }
      }
      fetchConnections()
    } catch (error: any) {
      if (isCancel(error)) return
    }
  }, [])

  useEffect(() => {
    if (connectShopify) {
      setActive(StoreOption[0]?.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectShopify])

  const content = (
    <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
      <div className='choose-platform'>
        <p className='fs-3 fw-boldest text-start'>
          {intl.formatMessage({id: 'SELECT_YOUR_STORE_PLATFORM'})}
        </p>
        <div className='d-flex justify-content-between'>
          {StoreOption.map((item, index) => {
            const imgSrc =
              active === item.value ? toAbsoluteUrl(item.image_active) : toAbsoluteUrl(item.image)
            return (
              <div
                key={index}
                onClick={() => {
                  setActive(item.value)
                  setPayloadOption(item.value)
                }}
                className='w-225px'
              >
                <div className={`choose-box label shadow ${active === item.value ? 'active' : ''}`}>
                  <div className='icon-store'>
                    <img src={imgSrc} alt={item.value} className='w-75px' />
                  </div>
                  <div className='choose-text-label'>
                    <span>{item.label}</span>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div>
          {active === 'shopify' ? <Shopify isConnection={isConnection.shopify} /> : ''}
          {active === 'shipstation' ? <ShipStation isConnection={isConnection.shipStation} /> : ''}
        </div>
      </div>
    </CSSTransition>
  )

  const learnMoreUrl =
    active === 'shopify'
      ? '/help-center/store-connection/shopify'
      : '/help-center/store-connection/shipstation'
  return (
    <Card
      title={intl.formatMessage({
        id: 'INTEGRATE_YOUR_SHOPIFY_OR_SHIPSTATION_STORES_INTO_SHIPBAE_TO_CREATE_LABELS_FOR_THE_ORDERS',
      })}
      titleClass='text-start'
      learnMoreUrl={learnMoreUrl}
    >
      {content}
    </Card>
  )
}

export {Store}
