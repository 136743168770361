import {useFormik} from 'formik'
import {cloneDeep, isEmpty, omitBy, size} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Container, Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {KTSVG} from '../../../../_gori/helpers'
import {useStatesGlobal} from '../../../../_gori/helpers/components/StatesGlobalProvider'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import {
  Button,
  InputDate,
  InputTextFormik,
  SelectFormik,
  SelectMultiLevelFormik,
  SelectNoFormik,
  ValidationErrorModal,
} from '../../../../_gori/partials/widgets'
import {OPTION_DIMENSION_UNIT, OPTION_WEIGHT_UNIT, OrdersConfig} from '../core/_const'
import OrderService from '../core/_requests'
import UseYupValidate from '../../../../_gori/hooks/UseYupValidate'
import clsx from 'clsx'
import moment from 'moment/moment'

type Props = {
  show: boolean
  handleClose: () => void
  data: {ids: Array<number>; name: string | undefined}
  reloadTable: () => {}
}

const MassUpdateModal: React.FC<Props> = ({show, handleClose, data, reloadTable}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const {numberYup, stringYup} = UseYupValidate()
  const {statesGlobal, getPackages, getAddress} = useStatesGlobal()
  const address = statesGlobal.address
  const packages = statesGlobal.packages
  const carriers = statesGlobal.carriers
  const [messageErrors, setMessageErrors] = useState<string | undefined>(undefined)
  const [validationErrors, setValidationErrors] = useState<any>()
  const [loading, setLoading] = useState<{update: boolean}>({update: false})
  const [optionsCustomPackage, setOptionsCustomPackage] = useState<any>([])
  const [optionsAddress, setOptionsAddress] = useState<any>([])
  const [selectedAddress, setSelectedAddress] = useState()

  const handleCloseModal = () => {
    handleClose()
    formik.resetForm()
  }

  const {orderYup} = UseYupValidate()
  const initValidateSchema = useMemo(() => {
    return {
      weight: Yup.object().shape(
        {
          weight: numberYup.parcel('weight', false),
          weight_unit: Yup.string().when('weight', {
            is: (value) => value,
            then: stringYup(50, 'UNIT'),
            otherwise: stringYup(50, 'UNIT', false),
          }),
        },
        [['weight', 'weight_unit']]
      ),
      parcels: Yup.object().shape(
        {
          length: numberYup.parcel('length', false),
          width: numberYup.parcel('width', false),
          height: numberYup.parcel('height', false),
          dimension_unit: Yup.string().when(['length', 'width', 'height'], {
            is: (length, width, height) => length || width || height,
            then: stringYup(50, 'UNIT'),
            otherwise: stringYup(50, 'UNIT', false),
          }),
        },
        [
          ['length', 'dimension_unit'],
          ['width', 'dimension_unit'],
          ['height', 'dimension_unit'],
        ]
      ),
      ship_by_date: orderYup.shipByDate,
    }
  }, [numberYup, orderYup.shipByDate, stringYup])

  const formik = useFormik({
    initialValues: {
      from: '',
      to: '',
      service: '',
      package_type: '',
      weight: {
        weight: '',
        weight_unit: '',
      },
      parcels: {
        package_id: null,
        length: '',
        width: '',
        height: '',
        dimension_unit: '',
      },
      ship_by_date: undefined,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape(initValidateSchema),
    onSubmit: async (values) => {
      const _values: any = showFromPreset.reduce((_payload, item) => {
        switch (item.key) {
          case 'package_type':
            _payload['parcels'] = values?.[item.key]
            break
          default:
            _payload[item.key] = values?.[item.key]
            break
        }
        return _payload
      }, {})

      let payload: any = {}
      Object.entries(_values).forEach(([key, value]: [any, any]) => {
        switch (key) {
          case 'parcels':
            payload['type'] = 'package'
            const {length, width, height, dimension_unit} = _values.parcels || {}
            if ((length && width && height) || (!length && !width && !height && dimension_unit)) {
              payload['fields'] = {
                ...omitBy(cloneDeep(value), (item) => item === ''),
                preset_id: null,
              }
            } else {
              payload['fields'] = null
            }
            break
          case 'from':
            payload['type'] = 'address'
            payload['fields'] = value ? {...value, preset_id: null} : null
            break
          case 'to':
            payload['type'] = 'address'
            payload['fields'] = value ? {...value, preset_id: null} : null
            break
          case 'service':
            payload['type'] = 'service'
            payload['fields'] = !isEmpty(omitBy(cloneDeep(_values), (item) => item === ''))
              ? {
                  ...omitBy(cloneDeep(_values), (item) => item === ''),
                  preset_id: null,
                }
              : null
            break
          case 'weight':
            payload['type'] = 'weight'
            payload['fields'] = _values.weight?.weight_unit
              ? {...omitBy(cloneDeep(_values.weight), (item) => item === ''), preset_id: null}
              : null
            break
          case 'ship_by_date':
            payload['type'] = 'ship_by_date'
            payload['fields'] = value ? {...value, ship_by_date: moment(value)} : null
            break
        }
        payload['order_ids'] = data.ids
      })

      if (size(payload.fields) === 0) {
        return setMessageErrors('PLEASE_ENTER_THE_VALUE')
      } else {
        setMessageErrors(undefined)
      }

      try {
        setLoading((prev) => ({...prev, update: true}))
        const {message} = await OrderService.patch(payload, {
          cancelToken: newCancelToken(),
        })
        toast.success(intl.formatMessage({id: message}))
        handleClose()
        reloadTable()
      } catch (error) {
        if (isCancel(error)) return
      } finally {
        setLoading((prev) => ({...prev, update: false}))
      }
    },
  })

  // BEGIN: Options address
  useEffect(() => {
    if (data?.name !== 'from' && data?.name !== 'to') return
    getAddress(data?.name)

    return () => {}
  }, [data?.name, getAddress])

  useEffect(() => {
    if (data?.name !== 'from' && data?.name !== 'to') return

    if (!address) {
      setOptionsAddress([])
    } else {
      setOptionsAddress(
        address[data.name].map((item) => ({
          label: (
            <div>
              <span>{item.company}</span>
              <span className='d-block fw-bold'>
                {`${item.street1} ${item.city} ${item.state} ${item.zip}`}
              </span>
            </div>
          ),
          value: item,
        }))
      )
    }

    return () => {
      setOptionsAddress([])
      setSelectedAddress(undefined)
    }
  }, [address, data.name])

  const handleFilterAddress = useCallback(
    (name, storeValue) => {
      setSelectedAddress(storeValue)

      const value: any = Object.entries(OrdersConfig.SHIPPING).reduce((initValue, [key, value]) => {
        switch (value.value) {
          case OrdersConfig.SHIPPING.VERIFIED.value:
          case OrdersConfig.SHIPPING.RESIDENTIAL.value:
            initValue[`${name}_${value?.value}`] = storeValue?.value[value.value] || false
            break
          case OrdersConfig.SHIPPING.SAVE_ADDRESS.value:
            initValue[`${name}_${value?.value}`] = !!storeValue?.value[value.value]
            break
          default:
            initValue[`${name}_${value?.value}`] = storeValue?.value[value.value] || ''
            break
        }
        return initValue
      }, {})

      formik.setFieldValue(name, value)
    },
    [formik]
  )
  // END: Options address

  // BEGIN: Options service and package
  useEffect(() => {
    if (data?.name !== 'package_type') return
    getPackages()

    return () => {}
  }, [data?.name, getPackages])

  useEffect(() => {
    if (data?.name !== 'package_type') return
    if (!packages) {
      setOptionsCustomPackage([{label: intl.formatMessage({id: 'CUSTOM_PACKAGE'}), options: []}])
    } else {
      const _optionPackages = packages.map((item) => {
        let objOption = {label: '', value: {}}
        objOption.label = item.name
        objOption.value = {
          package_id: item.id,
          length: item.length,
          width: item.width,
          height: item.height,
          dimension_unit: item.dimension_unit,
        }
        return objOption
      })
      setOptionsCustomPackage([
        {label: intl.formatMessage({id: 'CUSTOM_PACKAGE'}), options: _optionPackages},
      ])
    }

    return () => {}
  }, [data?.name, intl, packages])

  const optionsService = useMemo(() => {
    if (isEmpty(carriers)) return []
    const _optionsService = Object.values(carriers).map((value: any) => {
      if (value.status) {
        return {label: value.visuals?.display_name, value: `${value.carrier}_${value.service}`}
      } else {
        return null
      }
    })
    return _optionsService.filter((item) => item)
  }, [carriers])

  useMemo(() => {
    if (typeof formik.values?.package_type === 'object') {
      formik.setFieldValue('parcels', formik.values?.package_type)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.package_type])
  // END: Options service and package

  const showFromPreset = useMemo(() => {
    setMessageErrors(undefined)
    const formInit = Object.entries(formik?.values).map(([key, value]) => ({
      show: false,
      key: key,
      value: value,
    }))

    const updateData = formInit.map((item) => {
      if (item.key === data.name) {
        item.show = true
      }
      return item
    })

    return updateData.filter((item) => item.show)
  }, [data.name, formik?.values])

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <Modal
        id='gori_modal_mass_update_order'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-600px h-auto'
        show={show}
        backdrop='static'
        onHide={() => {
          handleClose()
        }}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'MASS_UPDATE'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div className='d-flex justify-content-between align-items-center'>
                <span className='fs-2 fw-bolder'>
                  {intl.formatMessage({
                    id: OrdersConfig.MASS_UPDATE.filter((item) => item?.value === data.name)[0]
                      ?.label,
                  })}
                </span>
                <span className='fw-bold text-primary'>
                  {intl.formatMessage(
                    {id: 'THIS_WILL_APPLY_TO_INPUT_SELECTED_SHIPMENTS'},
                    {input: data.ids.length}
                  )}
                </span>
              </div>
              {showFromPreset.map((item) => {
                switch (item.key) {
                  case 'from':
                    return (
                      <div className='row my-4' key={item.key}>
                        <div className='d-flex align-items-center'>
                          <SelectNoFormik
                            className={'flex-fill border border-secondary rounded-2'}
                            options={optionsAddress}
                            value={selectedAddress}
                            onChange={(value) => handleFilterAddress('from', value)}
                            placeholder={intl.formatMessage({id: 'PLEASE_CHOOSE'})}
                            isSearchable={false}
                          />
                        </div>
                      </div>
                    )
                  case 'to':
                    return (
                      <div className='row my-4' key={item.key}>
                        <div className='d-flex align-items-center'>
                          <SelectNoFormik
                            className={'flex-fill border border-secondary rounded-2'}
                            options={optionsAddress}
                            value={selectedAddress}
                            onChange={(value) => handleFilterAddress('to', value)}
                            placeholder={intl.formatMessage({id: 'PLEASE_CHOOSE'})}
                            isSearchable={false}
                          />
                        </div>
                      </div>
                    )
                  case 'service':
                    return (
                      <div className='row my-4' key={item.key}>
                        <div className='d-flex align-items-center'>
                          <SelectFormik
                            className='flex-fill'
                            placeholder={intl.formatMessage({id: 'PLEASE_CHOOSE'})}
                            formik={formik}
                            name='service'
                            options={optionsService || []}
                            checkFormik={false}
                          />
                        </div>
                      </div>
                    )
                  case 'package_type':
                    return (
                      <div className='row my-4' key={item.key}>
                        <div className='d-flex align-items-center'>
                          <SelectMultiLevelFormik
                            emptyDefault={false}
                            className='d-flex flex-fill'
                            classNameSelect='flex-fill'
                            formik={formik}
                            name='package_type'
                            options={optionsCustomPackage}
                            placeholder={intl.formatMessage({id: 'PLEASE_CHOOSE'})}
                          />
                        </div>
                        {(formik.values.parcels.length ||
                          formik.values.parcels.width ||
                          formik.values.parcels.height) && (
                          <span className='text-muted mt-1'>
                            {intl.formatMessage({id: 'LENGTH'})}: {formik.values.parcels?.length}{' '}
                            {intl.formatMessage({id: 'WIDTH'})}: {formik.values.parcels?.width}{' '}
                            {intl.formatMessage({id: 'HEIGHT'})}: {formik.values.parcels?.height} (
                            {formik.values.parcels?.dimension_unit})
                          </span>
                        )}
                      </div>
                    )
                  case 'weight':
                    return (
                      <div className='row my-4' key={item.key}>
                        <div className='d-flex justify-content-between'>
                          <div className={clsx('d-flex col-6')}>
                            <InputTextFormik
                              type='number'
                              min={0}
                              className='flex-fill me-2'
                              labelClassName='col-3 col-form-label text-gray-600 fw-bolder'
                              label={intl.formatMessage({id: 'WEIGHT'})}
                              formik={formik}
                              name={`weight.${item.key}`}
                            />
                          </div>
                          <div className='d-flex col-6'>
                            <SelectFormik
                              label={intl.formatMessage({id: 'UNIT'})}
                              labelClassName={'col-3 col-form-label text-gray-600 fw-bolder'}
                              className='flex-fill'
                              options={OPTION_WEIGHT_UNIT}
                              formik={formik}
                              name={'weight.weight_unit'}
                              checkFormik={false}
                              placeholder=''
                            />
                          </div>
                        </div>
                        <div className='text-danger text-center mt-4'>
                          {typeof formik.errors.weight === 'string' && formik.errors.weight}
                        </div>
                      </div>
                    )
                  case 'parcels':
                    return (
                      <div className='row my-4' key={item.key}>
                        <div className='d-flex align-items-start'>
                          {Object.keys(formik.values.parcels).map((subSize, index) => {
                            switch (subSize) {
                              case 'dimension_unit':
                                return (
                                  <div className='col-3'>
                                    <SelectFormik
                                      label={intl.formatMessage({id: 'UNIT'})}
                                      labelClassName={'col-form-label text-gray-600 fw-bolder'}
                                      options={OPTION_DIMENSION_UNIT}
                                      name={'parcels.dimension_unit'}
                                      formik={formik}
                                      checkFormik={false}
                                      placeholder=''
                                    />
                                  </div>
                                )
                              case 'length':
                              case 'width':
                              case 'height':
                                return (
                                  <div className={clsx('col-3')} key={index}>
                                    <InputTextFormik
                                      key={index}
                                      type='number'
                                      min={0}
                                      className='flex-fill me-2'
                                      labelClassName='col-form-label text-gray-600 fw-bolder'
                                      label={intl.formatMessage({id: subSize.toUpperCase()})}
                                      formik={formik}
                                      name={`parcels.${subSize}`}
                                    />
                                  </div>
                                )
                              default:
                            }
                            return ''
                          })}
                        </div>
                      </div>
                    )
                  case 'ship_by_date':
                    return (
                      <div className='row my-4' key={item.key}>
                        <div className='d-flex justify-content-between'>
                          <InputDate
                            labelClassName='fw-bolder'
                            formik={formik}
                            name={'ship_by_date'}
                            required={true}
                            min={moment()}
                            className='flex-fill'
                            disabled={false}
                          />
                        </div>
                      </div>
                    )
                  default:
                    return null
                }
              })}
              {!isEmpty(messageErrors) && (
                <div className='bg-light-danger mt-4 py-2 px-4 text-dark d-flex align-items-center'>
                  <KTSVG path='/media/gori/settings/errors.svg' small={false} />
                  <span className='fs-5 mx-3 fw-bold'>{intl.formatMessage({id: 'ERROR'})}</span>
                  {intl.formatMessage({id: messageErrors})}
                </div>
              )}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
                disabled={loading.update}
              />
              <Button
                className={'btn btn-primary'}
                label={intl.formatMessage({id: 'UPDATE'})}
                loadingText={intl.formatMessage({id: 'UPDATE'})}
                event={formik.handleSubmit}
                disabled={loading.update}
                loading={loading.update}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {MassUpdateModal}
