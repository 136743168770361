import {PayPalButtons, PayPalScriptProvider} from '@paypal/react-paypal-js'
import {useFormik} from 'formik'
import {useCallback, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import UseYupValidate from '../../../../../_gori/hooks/UseYupValidate'
import {Button, InputTextFormik} from '../../../../../_gori/partials/widgets'
import PaymentsService from '../../../deposits/core/_requests'

type Props = {
  show: boolean
  handleCloseModal: () => void
  nextStep: any
}

const PayPalModal: React.FC<Props> = ({show, handleCloseModal, nextStep}) => {
  const intl = useIntl()
  const {numberYup} = UseYupValidate()
  const [isLoading, setIsLoading] = useState<{btn: boolean; page: boolean}>({
    btn: false,
    page: false,
  })
  const [initialOptions, setInitialOptions] = useState<
    {clientId: string; intent: 'capture'} | undefined
  >(undefined)

  const handleGetClientId = useCallback(async () => {
    setIsLoading((prev) => ({...prev, page: true}))
    const config = {
      params: {
        type: 'paypal',
      },
    }

    try {
      const {configurations}: any = await PaymentsService.getConfigPayment(config)
      setInitialOptions({
        intent: 'capture',
        clientId: configurations?.PAYPAL_CLIENT_ID,
      })
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading((prev) => ({...prev, page: false}))
    }
  }, [])

  useEffect(() => {
    handleGetClientId()
    return () => {}
  }, [handleGetClientId])

  const validateSchema = {
    amount: numberYup.amount('FUNDING_AMOUNT'),
  }
  const validationSchema = Yup.object().shape(validateSchema)
  const formik = useFormik({
    initialValues: {
      amount: '',
      current_code: 'USD',
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: () => {},
  })

  useEffect(() => {
    setIsLoading((prev) => ({...prev, btn: true}))
    const timeout = setTimeout(() => {
      setIsLoading((prev) => ({...prev, btn: false}))
    }, 1000)

    return () => {
      clearTimeout(timeout)
      setIsLoading((prev) => ({...prev, btn: false}))
    }
  }, [formik.values.amount])

  const createOrder = useCallback(async () => {
    setIsLoading((prev) => ({...prev, page: true}))

    try {
      const res: any = await PaymentsService.createPaypalOrder({
        amount: formik.values.amount,
        current_code: formik.values.current_code,
      })
      return res.paypal_order_id
    } catch (error: any) {
      toast.error(error)
    } finally {
      setIsLoading((prev) => ({...prev, page: false}))
    }
  }, [formik.values.amount, formik.values.current_code])

  const onApprove = useCallback(
    async (data) => {
      try {
        const response: any = await PaymentsService.approvalPaypalOrder({
          paypal_order_id: data.orderID,
        })
        if (response.toast === 'success') {
          toast.success(response.message)
          formik.resetForm()
          await nextStep()
        }
      } catch (error: any) {
        const toastStatus = error.fields?.toast
        switch (toastStatus) {
          case 'error':
            toast.error(error?.message)
            break
          case 'warning':
            toast.warning(error?.message)
            await nextStep()
            break
        }
      }
    },
    [formik, nextStep]
  )

  const handleClose = () => {
    formik.resetForm()
    handleCloseModal()
  }

  return (
    <Modal
      id='gori_modal_paypal'
      tabIndex={-1}
      aria-hidden='true'
      centered
      show={show}
      backdrop='static'
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title bsPrefix={'fw-bolder fs-1'}>{intl.formatMessage({id: 'PAYPAL'})}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex flex-column'>
          <InputTextFormik
            type='number'
            placeholder='0'
            min={0}
            className='my-3'
            labelClassName='form-label'
            required
            label={intl.formatMessage({id: 'FUNDING_AMOUNT'})}
            formik={formik}
            name='amount'
            configAppend={{
              name: '$',
              position: 'left',
              classInput: 'ps-8',
            }}
          />
          <p className='fw-bold'>{intl.formatMessage({id: 'PLEASE_NOTE_PAYPAL_FUNDING_AMOUNT'})}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='d-flex justify-content-end'>
          <Button
            className='btn btn-sm btn-light me-2'
            label={intl.formatMessage({id: 'CANCEL'})}
            event={handleClose}
          />
          {isLoading.btn || !initialOptions ? (
            <div className='w-200px h-35px btn btn-warning pe-none rounded-1 d-flex justify-content-center align-items-center'>
              <span className='spinner-border spinner-border-sm align-middle' />
            </div>
          ) : (
            <PayPalScriptProvider options={initialOptions}>
              <PayPalButtons
                onClick={(data, actions) => {
                  if (!!formik.errors.amount || !formik.values.amount) {
                    formik.setFieldTouched('amount', true)
                    return actions.reject()
                  } else {
                    return actions.resolve()
                  }
                }}
                disabled={!!formik.errors.amount || !formik.values.amount}
                forceReRender={[formik.values.amount]}
                className='d-flex align-items-center bg-warning h-35px w-200px rounded-1'
                fundingSource='paypal'
                createOrder={createOrder}
                onApprove={onApprove}
                onCancel={() => {
                  toast.warning(intl.formatMessage({id: 'PAYMENT_CANCELLED'}))
                  handleClose()
                }}
                onError={() => {
                  toast.error(intl.formatMessage({id: 'PAYMENT_ERROR'}))
                  handleClose()
                }}
              />
            </PayPalScriptProvider>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export {PayPalModal}
