import {useFormik} from 'formik'
import {cloneDeep, isEmpty, snakeCase, startCase} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {CSSTransition} from 'react-transition-group'
import * as Yup from 'yup'
import {KEY_PERMISSIONS} from '../../../../../../_gori/constants'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../../../_gori/hooks/UseYupValidate'
import {
  Button,
  InputTextFormik,
  ValidationErrorModal,
} from '../../../../../../_gori/partials/widgets'
import {InputCheckboxFormik} from '../../../../../../_gori/partials/widgets/form/InputCheckboxFormik'
import displayConfig from '../../../../../../displayconfig.json'
import {useAuth} from '../../../../auth'
import UserService from '../../../../users/core/_requests'

type Props = {
  show: boolean
  handleClose: () => void
  handleUpdateUserSuccess: any
  userId: any
}

const UpdateUserModal: React.FC<Props> = ({show, handleClose, handleUpdateUserSuccess, userId}) => {
  const intl = useIntl()
  const {infoYup, regexYup} = UseYupValidate()
  const {newCancelToken, isCancel} = useCancelToken()
  const {loadingSwitch} = useAuth()
  const [dataPermissions, setDataPermissions] = useState<any>([])
  const [groupByNames, setGroupByNames] = useState<any>([])
  const [user, setUser] = useState<any>({})
  const [loading, setLoading] = useState<{first: boolean; update: boolean}>({
    first: true,
    update: false,
  })
  const [validationErrors, setValidationErrors] = useState<any>()

  const items = useMemo(() => {
    const {first_name, last_name, email, status, permissions} = user

    const permissionsObject = cloneDeep(dataPermissions).reduce((acc, permission) => {
      acc[`${permission.parent_id}::${permission.id}::${permission.name}`] =
        permissions?.indexOf(permission.name) > -1 ?? false
      if (permission.children && permission.children.length > 0) {
        const childrenObject = permission.children.reduce((childAcc, childPermission) => {
          childAcc[`${childPermission.parent_id}::${childPermission.id}::${childPermission.name}`] =
            permissions?.indexOf(childPermission.name) > -1 ?? false
          return childAcc
        }, {})
        acc = {...acc, ...childrenObject}
      }

      Object.keys(cloneDeep(acc)).forEach((level1) => {
        const [, childLevel1] = level1.split('::')
        const arrChildLevel2 = Object.keys(cloneDeep(acc)).filter((level2) => {
          const [parentLevel2] = level2.split('::')
          return childLevel1 === parentLevel2
        })
        if (arrChildLevel2.length > 0) {
          acc[level1] = arrChildLevel2.every((per) => acc[per])
        }
      })
      return acc
    }, {})

    return {
      first_name: first_name ?? null,
      last_name: last_name ?? null,
      email: email ?? null,
      status: status ?? false,
      checkHasPermission: permissionsObject.length > 0 ?? false,
      permissions: permissionsObject,
    }
  }, [dataPermissions, user])

  const initValidateSchema = useMemo(() => {
    let validate: any = {
      first_name: regexYup.inputText('FIRST_NAME', true, 50),
      last_name: regexYup.inputText('LAST_NAME', true, 50),
      email: infoYup.email('EMAIL'),
    }

    if (displayConfig.items.settings.permissions.show) {
      validate = {
        ...validate,
        checkHasPermission: Yup.bool().oneOf(
          [true],
          intl.formatMessage(
            {id: 'INPUT_IS_REQUIRED'},
            {input: intl.formatMessage({id: 'PERMISSIONS'})}
          )
        ),
      }
    }
    return validate
  }, [infoYup, intl, regexYup])

  const validationSchema = Yup.object().shape(initValidateSchema)

  const formik = useFormik({
    initialValues: items || {},
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading((prev) => ({...prev, update: true}))
        const config = {cancelToken: newCancelToken()}
        const payload = {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          status: values.status,
          permissions: displayConfig.items.settings.permissions.show
            ? Object.keys(values.permissions)
                .filter((key) => values.permissions[key])
                .map((item) => {
                  const [parentField] = item.split('::')
                  return parentField !== '0' ? item.split('::')[2] : null
                })
                .filter((item) => item !== null)
            : [KEY_PERMISSIONS.ADMIN],
        }

        const res = await UserService.update(userId, payload, config)
        if (res) {
          toast.success(intl.formatMessage({id: 'UPDATED_SUCCESSFULLY'}))
          handleUpdateUserSuccess()
          handleCloseModal()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, update: false}))
      }
    },
  })

  const getDataForm = useCallback(async () => {
    try {
      const config = {cancelToken: newCancelToken()}
      const [{user}, permissions] = await Promise.all([
        UserService.showUser(userId, config),
        UserService.getPermissions(),
      ])

      if (user && permissions) {
        setDataPermissions(permissions)
        setUser(user)
      }
    } catch (error) {
      if (isCancel(error)) return
    } finally {
      setLoading((prev) => ({...prev, first: false}))
    }
  }, [isCancel, newCancelToken, userId])

  useEffect(() => {
    if (loadingSwitch) return
    getDataForm()

    return () => {}
  }, [getDataForm, loadingSwitch])

  const handleCloseModal = () => {
    formik.resetForm()
    handleClose()
  }

  const handleChangeCheckbox = (field) => {
    const {permissions} = formik.values
    const valuePrev = permissions?.[field]
    let data = cloneDeep(permissions)
    let arrChildAdmin: any = []

    const [, childField, nameField] = field.split('::')
    data[field] = !valuePrev

    const isFieldAdmin = nameField.toLowerCase() === KEY_PERMISSIONS.ADMIN

    if (isFieldAdmin) {
      Object.keys(cloneDeep(formik.values?.permissions)).forEach((per) => {
        data[per] = !valuePrev
      })
    } else {
      const arrChild = Object.keys(cloneDeep(data)).filter((per) => {
        const [parentPer] = per.split('::')
        return childField === parentPer
      })
      arrChild.forEach((child) => (data[child] = !valuePrev))

      Object.keys(cloneDeep(data)).forEach((level1) => {
        const [parentLevel1, childLevel1] = level1.split('::')
        if (parentLevel1 === '0') {
          arrChildAdmin.push(level1)
        }
        const arrChildLevel2 = Object.keys(cloneDeep(formik.values?.permissions)).filter(
          (level2) => {
            const [parentLevel2] = level2.split('::')
            return childLevel1 === parentLevel2
          }
        )
        if (arrChildLevel2.length > 0) {
          const checkAll = arrChildLevel2.every((per) => data[per])
          data[level1] = checkAll
        }
      })

      const arrAdmin = Object.keys(cloneDeep(data)).filter((per) => {
        const [, , namePer] = per.split('::')
        return namePer.toLowerCase() === KEY_PERMISSIONS.ADMIN
      })
      const checkAll = arrChildAdmin
        .filter((per) => {
          const [, , namePer] = per.split('::')
          return namePer.toLowerCase() !== KEY_PERMISSIONS.ADMIN
        })
        .every((per) => data[per])
      arrAdmin.forEach((admin) => (data[admin] = checkAll))
    }

    formik.setFieldValue('permissions', data)
  }

  useEffect(() => {
    const hasValue = Object.values(formik.values.permissions).every((item) => !item)
    formik.setFieldValue('checkHasPermission', !hasValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.permissions])

  const skeletonPermission = useMemo(() => {
    return (
      <>
        <div className='row bg-light rounded mb-5  px-5 py-5 m-0'>
          <div className='d-flex justify-content-between mb-2'>
            <h1 className='mb-3'>{intl.formatMessage({id: 'INFORMATION'})}</h1>
          </div>
          {Array.from({length: 2}).map((_, index) => (
            <div className='col-md-6 mb-1' key={index}>
              {Array.from({length: 2}).map((_, index) => (
                <div className='d-flex my-3' key={index}>
                  <span className='fs-1 col-3 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
                  <span className='btn col-6 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
                </div>
              ))}
            </div>
          ))}
        </div>
        {displayConfig.items.settings.permissions.show && (
          <div className='row bg-light rounded mb-5  px-5 py-5 m-0'>
            <div className='d-flex justify-content-between mb-2'>
              <h1 className='mb-3'>{intl.formatMessage({id: 'PERMISSIONS'})}</h1>
            </div>
            <div className='row'>
              {Array.from({length: 3}).map((_, index) => (
                <div className='d-flex flex-column col-4' key={index}>
                  {Array.from({length: 2}).map((_, index) => (
                    <div className='d-flex my-2' key={index}>
                      <span className='btn col-3 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
                      <span className='btn col-6 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    )
  }, [intl])

  const htmlPermission = (permission, index) => {
    return (
      <div className='mb-4'>
        <div
          key={index}
          className='form-check form-switch form-check-custom form-check-solid px-5 align-items-center'
        >
          <InputCheckboxFormik
            formik={formik}
            className='form-check-input'
            type='checkbox'
            name={`permissions.${permission.parent_id}::${permission.id}::${permission.name}`}
            onClick={() =>
              handleChangeCheckbox(`${permission.parent_id}::${permission.id}::${permission.name}`)
            }
            disabled={loading.update}
          />
          <label htmlFor={permission.name} className='ms-2'>
            {intl.formatMessage({id: snakeCase(permission.name).toUpperCase()})}
          </label>
        </div>
        {displayConfig.items.settings.permissions.showPermissionChild &&
          permission?.children?.map((childPermission, childIndex) => {
            return (
              <div
                key={childIndex}
                className='my-2 form-check form-switch form-check-custom form-check-solid px-5 align-items-center'
              >
                <InputCheckboxFormik
                  formik={formik}
                  className='form-check-input ms-9'
                  type='checkbox'
                  name={`permissions.${childPermission.parent_id}::${childPermission.id}::${childPermission.name}`}
                  onClick={() =>
                    handleChangeCheckbox(
                      `${childPermission.parent_id}::${childPermission.id}::${childPermission.name}`
                    )
                  }
                  disabled={loading.update}
                />
                <label htmlFor={childPermission.name} className='ms-2'>
                  {startCase(childPermission.name)}
                </label>
              </div>
            )
          })}
      </div>
    )
  }

  useEffect(() => {
    const data: any = {
      column1: [],
      column2: [],
      column3: [],
    }
    dataPermissions.forEach((permission, index) => {
      if (index <= 1) {
        data['column1'].push(permission)
      } else if (index <= 4) {
        data['column2'].push(permission)
      } else {
        data['column3'].push(permission)
      }
    })
    setGroupByNames(data)
  }, [dataPermissions])

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <Modal
        id='gori_modal_update_user'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-1000px h-auto'
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
          <form id='form_user_1' noValidate>
            <Modal.Header closeButton>
              <Modal.Title bsPrefix={'fw-bolder fs-1'}>
                {intl.formatMessage({id: 'EDIT_USER'})}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className='mh-650px scroll-y'>
              <div className={'card bg-white rounded px-15'}>
                {loading.first ? (
                  skeletonPermission
                ) : (
                  <>
                    <div className='row bg-light rounded mb-5  px-5 py-5 m-0'>
                      <div className='d-flex justify-content-between mb-2'>
                        <h1 className='mb-3'>{intl.formatMessage({id: 'INFORMATION'})}</h1>
                      </div>
                      <div className='col-md-6 mb-1'>
                        <div className='d-flex mb-7'>
                          <InputTextFormik
                            name='first_name'
                            label={intl.formatMessage({id: 'FIRST_NAME'})}
                            required
                            formik={formik}
                            type='text'
                            className='col-8'
                            labelClassName='col-4 col-form-label'
                          />
                        </div>
                        <div className='d-flex'>
                          <InputTextFormik
                            name='last_name'
                            label={intl.formatMessage({id: 'LAST_NAME'})}
                            required
                            formik={formik}
                            type='text'
                            className='col-8'
                            labelClassName='col-4 col-form-label'
                          />
                        </div>
                      </div>
                      <div className='col-6'>
                        <div className='d-flex mb-7'>
                          <InputTextFormik
                            name='email'
                            label={intl.formatMessage({id: 'EMAIL'})}
                            required
                            formik={formik}
                            type='text'
                            className='col-8'
                            labelClassName='col-4 col-form-label'
                            disabled
                          />
                        </div>
                      </div>
                      <div className='col-6'>
                        <div className='d-flex align-items-center mt-5'>
                          <label className='form-check form-switch form-check-custom form-check-solid px-5 align-items-center'>
                            <InputCheckboxFormik
                              formik={formik}
                              className='form-check-input'
                              type='checkbox'
                              name='status'
                            />
                          </label>
                          <span className='fs-6 fw-semibold text-gray-900 d-block'>
                            {intl.formatMessage({id: 'ALLOW_THIS_USER_TO_LOG_IN'})}
                          </span>
                        </div>
                      </div>
                    </div>
                    {displayConfig.items.settings.permissions.show && dataPermissions.length > 0 && (
                      <div className='row bg-light rounded mb-5  px-5 py-5 m-0'>
                        <div className='d-flex mb-3'>
                          <h1>{intl.formatMessage({id: 'PERMISSIONS'})}</h1>
                          {formik.errors.checkHasPermission &&
                            !isEmpty(formik.touched.permissions) && (
                              <span className='fs-5 ms-5 mt-1 text-danger d-block fw-bold'>
                                {formik.errors.checkHasPermission}
                              </span>
                            )}
                        </div>
                        <div className='row d-flex'>
                          <div className='col-md-4'>
                            {groupByNames &&
                              groupByNames.column1?.map((permission, index) => {
                                return htmlPermission(permission, index)
                              })}
                          </div>
                          <div className='col-md-4'>
                            {groupByNames &&
                              groupByNames.column2?.map((permission, index) => {
                                return htmlPermission(permission, index)
                              })}
                          </div>
                          <div className='col-md-4'>
                            {groupByNames &&
                              groupByNames.column3?.map((permission, index) => {
                                return htmlPermission(permission, index)
                              })}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className='d-flex justify-content-end'>
                <Button
                  className='btn btn-secondary me-2'
                  label={intl.formatMessage({id: 'CANCEL'})}
                  loadingText={intl.formatMessage({id: 'CANCEL'})}
                  event={handleCloseModal}
                />
                <Button
                  className='btn btn-primary'
                  label={intl.formatMessage({id: 'UPDATE'})}
                  loadingText={intl.formatMessage({id: 'UPDATE'})}
                  event={formik.submitForm}
                  disabled={loading.update}
                  loading={loading.update}
                />
              </div>
            </Modal.Footer>
          </form>
        </CSSTransition>
      </Modal>
    </>
  )
}

export {UpdateUserModal}
