import {AxiosRequestConfig} from 'axios'
import RequestBase from '../../../api/RequestBase'

const getFilterStatus = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get('/claims/filter-status', config)
}

const getClaimsStatistics = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get('/claims/statistics', config)
}

const getClaims = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get('/claims', config)
}

const getClaimDetail = (id?: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`/claims/detail/${id}`, config)
}

const getClaimHistory = (id?: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`/claims/histories/${id}?page_size=50`, config)
}

const createClaims = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post('/claims', payload, config)
}

const editClaims = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put('/claims', payload, config)
}

const exportCsv = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get('/claims/download-csv', config)
}

const syncClaimsStatus = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post('/claims/sync-fotform-force', payload, config)
}

const deleteClaims = (id, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.remove(`/claims/${id}`, config)
}

const postClaimsAppeal = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post('/claims/appeal', payload, config)
}

const uploadBatch = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post('/claims/upload', payload, config)
}

const ClaimService = {
  getFilterStatus,
  getClaimsStatistics,
  getClaims,
  deleteClaims,
  getClaimDetail,
  getClaimHistory,
  createClaims,
  editClaims,
  exportCsv,
  syncClaimsStatus,
  postClaimsAppeal,
  uploadBatch,
}

export default ClaimService
