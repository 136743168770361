import {useState, useEffect, useRef} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_gori/helpers'
import {Link} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import {useIntl} from 'react-intl'

export function Security() {
  const intl = useIntl()
  const [showModal, setShowModal] = useState(false)
  const [modalContent, setModalContent] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [isOverflowing, setIsOverflowing] = useState<boolean[]>([])

  const cardData = [
    {
      title: intl.formatMessage({id: 'SECURITY_CARD_TITLE_1'}),
      description: intl.formatMessage({id: 'SECURITY_CARD_DESCRIPTION_1'}),
      imgSrc: '/media/gori/landing/auth-icon.svg',
    },
    {
      title: intl.formatMessage({id: 'SECURITY_CARD_TITLE_2'}),
      description: intl.formatMessage({id: 'SECURITY_CARD_DESCRIPTION_2'}),
      imgSrc: '/media/gori/landing/input-validation-icon.svg',
    },
    {
      title: intl.formatMessage({id: 'SECURITY_CARD_TITLE_3'}),
      description: intl.formatMessage({id: 'SECURITY_CARD_DESCRIPTION_3'}),
      imgSrc: '/media/gori/landing/encryption-icon.svg',
    },
    {
      title: intl.formatMessage({id: 'SECURITY_CARD_TITLE_4'}),
      description: intl.formatMessage({id: 'SECURITY_CARD_DESCRIPTION_4'}),
      imgSrc: '/media/gori/landing/session-management-icon.svg',
    },
    {
      title: intl.formatMessage({id: 'SECURITY_CARD_TITLE_5'}),
      description: intl.formatMessage({id: 'SECURITY_CARD_DESCRIPTION_5'}),
      imgSrc: '/media/gori/landing/error-handling-and-logging-icon.svg',
    },
    {
      title: intl.formatMessage({id: 'SECURITY_CARD_TITLE_6'}),
      description: intl.formatMessage({id: 'SECURITY_CARD_DESCRIPTION_6'}),
      imgSrc: '/media/gori/landing/data-protection-icon.svg',
    },
    {
      title: intl.formatMessage({id: 'SECURITY_CARD_TITLE_7'}),
      description: intl.formatMessage({id: 'SECURITY_CARD_DESCRIPTION_7'}),
      imgSrc: '/media/gori/landing/compliance-icon.svg',
    },
  ]

  const handleShowModal = (title: string, content: string) => {
    setModalTitle(title)
    setModalContent(content)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const checkOverflow = (element: HTMLElement) => {
    return element.scrollHeight > element.clientHeight
  }

  const descriptionRefs = useRef<(HTMLParagraphElement | null)[]>([])

  useEffect(() => {
    const updateOverflowState = () => {
      const overflowStates = descriptionRefs.current.map((ref) =>
        ref ? checkOverflow(ref) : false
      )
      setIsOverflowing(overflowStates)
    }

    updateOverflowState()

    const handleResize = () => {
      updateOverflowState()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <section className='security overflow-x-hidden bg-white'>
      <div className='container'>
        <h2 className='mb-12 text-left lh-sm'>{intl.formatMessage({id: 'SECURITY_H2'})}</h2>
        <div className='row'>
          <div className='cell col-md-4 d-flex align-items-stretch pt-9 pb-12 px-12'>
            <div className='card'>
              <div className='card-body p-0'>
                <h3 className='card-title text-capitalize'>
                  {intl.formatMessage({id: 'SECURITY_SUBTITLE'})}
                </h3>
              </div>
            </div>
          </div>

          {cardData.map((card, index) => (
            <div key={index} className='cell col-md-4 d-flex align-items-stretch pt-9 pb-12 px-12'>
              <div className='card'>
                <img className='mb-3' src={toAbsoluteUrl(card.imgSrc)} alt='' />
                <div className='card-body p-0'>
                  <h5 className='card-title'>{card.title}</h5>
                  <p
                    ref={(el) => (descriptionRefs.current[index] = el)}
                    className='card-text description mb-0'
                  >
                    {card.description}
                  </p>

                  {isOverflowing[index] && (
                    <button
                      className='btn btn-link text-decoration-underline pt-3 pb-0'
                      onClick={() => handleShowModal(card.title, card.description)}
                    >
                      {intl.formatMessage({id: 'SECURITY_LEARN_MORE'})}
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}

          <div className='cell col-md-4 d-flex align-items-stretch pt-9 pb-12 px-12'>
            <div className='card'>
              <div className='d-none d-md-block spacer mb-3'></div>
              <div className='card-body p-0'>
                <h5 className='card-title'>
                  <Link
                    className='text-decoration-underline'
                    style={{color: '#0f1a2a'}}
                    to='/privacy-policy'
                    target='_blank'
                  >
                    {intl.formatMessage({id: 'SECURITY_PRIV_POL'})}
                    <KTSVG
                      path='/media/gori/landing/new-tab-icon.svg'
                      className='ms-4 d-inline-block h-100'
                      svgClassName='d-block h-100'
                    />
                  </Link>
                </h5>
                <p className='card-text description mb-0'>
                  {intl.formatMessage({id: 'SECURITY_LEARN_MORE_ABOUT'})}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} size='lg' centered animation>
        <Modal.Header closeButton className='px-9 py-5'>
          <Modal.Title className='fs-1'>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='fs-6 fw-bold px-9 py-12' style={{color: '#64748B'}}>
          {modalContent}
        </Modal.Body>
      </Modal>
    </section>
  )
}
