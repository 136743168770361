import {IntegrationsPageLayout} from '../../../_gori/layout/components/IntegrationsPagesLayout'

export function WalmartIntegration() {
  return (
    <>
      <IntegrationsPageLayout
        pageClass='walmart-integration'
        heading='WALMART_HEADER'
        headingSubtext='WALMART_SUBTEXT'
        integrationLogo='/media/gori/integrations/walmart/walmart-logo.svg'
        integrationLogoAlt='walmart-logo'
        integrateImg='/media/gori/integrations/walmart/integrate.webp'
        card1Copy='WALMART_CARD_1'
        card2Copy='WALMART_CARD_2'
        card3Copy='WALMART_CARD_3'
        card4Copy='WALMART_CARD_4'
      />
    </>
  )
}
