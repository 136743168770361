import clsx from 'clsx'
import {isEmpty} from 'lodash'
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_gori/helpers'
import {InputTextNoFormik} from '../../../../../_gori/partials/widgets'
import {useAuth} from '../../../auth'

type Props = {
  childItems: any
  disabled?: boolean
}

const OrderItems: React.FC<Props> = ({childItems, disabled = false}) => {
  const intl = useIntl()
  const {currentCompany} = useAuth()

  return (
    <div>
      {!isEmpty(childItems) &&
        childItems.map((value, index) => {
          return (
            !isEmpty(value.order_items) && (
              <div
                className={clsx('bg-white rounded mt-5', {'cursor-no-drop bg-light': disabled})}
                key={value.id + '_' + index}
              >
                <div className='p-8'>
                  <div className='d-flex justify-content-between mt-5'>
                    <h3>{intl.formatMessage({id: 'ITEM'})}</h3>
                    <h3>
                      <KTSVG path='/media/gori/arrows/arrow_thin_right.svg' />{' '}
                      {intl.formatMessage({id: 'COMBINED'})} {currentCompany?.sb_code}-{value?.code}
                    </h3>
                  </div>
                  <div>
                    {value.order_items.map((value, index) => {
                      return (
                        <div className='d-flex align-items-center' key={value.id + '_' + index}>
                          <div className='d-flex flex-fill'>
                            <div className='col-md-3'>
                              <div className={'mb-8 mx-2'}>
                                <InputTextNoFormik
                                  labelClassName='col-form-label text-nowrap text-muted'
                                  label={intl.formatMessage({id: 'SKU'})}
                                  value={value?.sku}
                                  disabled={disabled}
                                />
                              </div>
                            </div>
                            <div className='col-md-3'>
                              <div className={'mb-8 mx-2'}>
                                <InputTextNoFormik
                                  labelClassName='col-form-label text-nowrap text-muted'
                                  label={intl.formatMessage({id: 'NAME'})}
                                  value={value?.title}
                                  disabled={disabled}
                                />
                              </div>
                            </div>
                            <div className='col-md-3'>
                              <div className={'mb-8 mx-2'}>
                                <InputTextNoFormik
                                  labelClassName='col-form-label text-nowrap text-muted'
                                  label={intl.formatMessage({id: 'QUANTITY'})}
                                  value={value?.quantity}
                                  disabled={disabled}
                                />
                              </div>
                            </div>
                            <div className='col-md-3'>
                              <div className={'mb-8 mx-2'}>
                                <InputTextNoFormik
                                  labelClassName='col-form-label text-nowrap text-muted'
                                  label={intl.formatMessage({id: 'PRICE'})}
                                  value={value?.unit_price}
                                  disabled={disabled}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='col-1'>
                            <div
                              className={clsx(
                                'd-flex justify-content-center text-hover-danger cursor-pointer',
                                {'cursor-no-drop': disabled}
                              )}
                            >
                              <KTSVG path='/media/gori/orders/empty.svg' small={false} />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )
          )
        })}
    </div>
  )
}

export {OrderItems}
