import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useAuth} from '../../../app/modules/auth'
import {MeterModel} from '../../../app/modules/users/core/_models'
import UserService from '../../../app/modules/users/core/_requests'
import {round} from '../FormatHelpers'
import {isFeatureEnabled} from '../CommonHelpers'
import {FEATURES} from '../../constants/_features'
import {ValidationErrorModal} from '../../partials/widgets'

export interface MeterContextModel {
  isMeterChange?: Boolean
  meter: MeterModel
  setMeterChange: (_isMeterChange: boolean) => void
}

const MeterContext = React.createContext<MeterContextModel>({
  meter: {balance: 0, recharge_amount: 0, recharge_threshold: 0, is_enabled: false, name: ''},
  setMeterChange: (_isMeterChange: boolean) => {},
})

const MeterProvider: React.FC = ({children}) => {
  const {currentAccount} = useAuth()
  const [isMeterChange, setMeterChange] = useState(true)
  const [meter, setMeter] = useState<MeterModel>({
    balance: 0,
    recharge_amount: 0,
    recharge_threshold: 0,
    is_enabled: false,
    name: '',
  })
  const {currentUser, loadingSwitch} = useAuth()
  const [validationErrors, setValidationErrors] = useState<any>()
  const value: MeterContextModel = {
    isMeterChange,
    meter,
    setMeterChange,
  }

  const loadMeter = useCallback(async () => {
    if (!currentAccount) return
    return await UserService.getMeter(currentAccount?.encrypt_key)
  }, [currentAccount])

  useEffect(() => {
    if (loadingSwitch) return
    if (isMeterChange && currentUser && isFeatureEnabled(FEATURES.METER)) {
      loadMeter()
        .then((response) => {
          response.balance = round(Number(response.balance))
          setMeter(response)
        })
        .catch((error: any) => {
          setValidationErrors(error?.response)
        })
        .finally(() => {
          setMeterChange(false)
        })
    }
  }, [currentUser, isMeterChange, loadMeter, loadingSwitch])

  return (
    <MeterContext.Provider value={value}>
      {children}
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
    </MeterContext.Provider>
  )
}

function useMeter() {
  return useContext(MeterContext)
}

export {MeterProvider, useMeter}
