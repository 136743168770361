import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSearchParams} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import {DEFAULT_PAGE, DEFAULT_PAGE_SIZE, TABLE_KEY_COLUMN} from '../../../../../../_gori/constants'
import {convertUserTimeZone, useDisabled} from '../../../../../../_gori/helpers'
import {TableSkeleton} from '../../../../../../_gori/helpers/components'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import UseRoutesPermission from '../../../../../../_gori/hooks/UseRoutesPermission'
import {
  Button,
  ConfirmActionSwal,
  DropdownButton,
  Table,
  TableBody,
  TableWrapper,
} from '../../../../../../_gori/partials/widgets'
import {TableTabsSetting} from '../../../../../../_gori/partials/widgets/tables/TableTabsSetting'
import {useAuth} from '../../../../auth'
import {UpdateUserModal, UsersFilter} from '../../../../settings'
import UserService from '../../../../users/core/_requests'
import {toast} from 'react-toastify'

const UsersPage: React.FC = () => {
  const intl = useIntl()
  const [responseData, setResponseData] = useState<any>({})
  const [loadingFirst, setLoadingFirst] = useState<Boolean>(true)
  const [statistics, setStatistics] = useState([])
  const [showModalEditUser, setShowModalEditUser] = useState<boolean>(false)
  const [showModalRemoveUser, setShowModalRemoveUser] = useState<boolean>(false)
  const [userId, setUserId] = useState<any>('')
  const {loadingSwitch, currentUser} = useAuth()
  const {setPageDisabled} = useDisabled()
  const [searchParams] = useSearchParams()
  const {newCancelToken, isCancel} = useCancelToken()
  const {routes} = UseRoutesPermission()

  const setTable = useCallback(async () => {
    setPageDisabled(true)
    const config = {
      params: {
        page: searchParams.get('page') || DEFAULT_PAGE,
        page_size: searchParams.get('page_size') || DEFAULT_PAGE_SIZE,
        search_text: searchParams.get('search_text') || null,
        status: searchParams.get('status') || null,
      },
      cancelToken: newCancelToken(),
    }
    try {
      const res = await UserService.getListUser(config)
      if (res) {
        setResponseData(res)
        setStatistics(res.statistics)
      }
    } catch (error) {
      if (isCancel(error)) return
    } finally {
      setPageDisabled(false)
      setLoadingFirst(false)
    }
  }, [setPageDisabled, searchParams, newCancelToken, isCancel])

  const reload = useCallback(() => {
    setTable()
  }, [setTable])

  const convertNameStatus = (item) => {
    if (!item.verified) {
      return intl.formatMessage({id: 'PENDING_INVITATION'})
    } else if (item.status) {
      return intl.formatMessage({id: 'ACTIVE'})
    }
    return intl.formatMessage({id: 'INACTIVE'})
  }

  const handleResendInvitation = useCallback(
    async (userId) => {
      setPageDisabled(true)
      await UserService.resend(
        {
          user_id: userId,
        },
        {cancelToken: newCancelToken()}
      )
        .then(() => {
          toast.success(intl.formatMessage({id: 'RESEND_INVITATION_SUCCESSFULLY'}))
          reload()
        })
        .catch((error) => {
          if (isCancel(error)) return
          toast.error(intl.formatMessage({id: 'RESEND_INVITATION_FAILED'}))
        })
        .finally(() => {
          setPageDisabled(false)
          setLoadingFirst(false)
        })
    },
    [setPageDisabled, isCancel, intl, reload, newCancelToken]
  )

  const handleCancelInvitation = useCallback(
    async (userId) => {
      setPageDisabled(true)
      await UserService.cancel(
        {
          user_id: userId,
        },
        {cancelToken: newCancelToken()}
      )
        .then(() => {
          toast.success(intl.formatMessage({id: 'CANCEL_INVITATION_SUCCESSFULLY'}))
          reload()
        })
        .catch((error) => {
          if (isCancel(error)) return
          toast.error(intl.formatMessage({id: 'CANCEL_INVITATION_FAILED'}))
        })
        .finally(() => {
          setPageDisabled(false)
          setLoadingFirst(false)
        })
    },
    [setPageDisabled, isCancel, intl, reload, newCancelToken]
  )

  const columns = useMemo(() => {
    return [
      {
        Header: intl.formatMessage({id: 'FULL_NAME'}),
        headerClassName: 'min-w-150px',
        cellClassName: 'text-dark',
        Cell: ({row}: {row: any}) => {
          return (row.original.first_name || '') + ' ' + (row.original.last_name || '')
        },
      },
      {
        Header: intl.formatMessage({id: 'EMAIL'}),
        headerClassName: 'min-w-80px',
        Cell: ({row}: {row: any}) => {
          return row.original.email
        },
      },
      {
        Header: intl.formatMessage({id: 'STATUS'}),
        headerClassName: 'min-w-125px text-center',
        cellClassName: 'text-center',
        Cell: ({row}: {row: any}) => {
          return (
            <span
              className={`d-flex justify-content-center px-4 py-1 rounded-pill status-user-${convertNameStatus(
                row.original
              )} rounded-pill`}
            >
              {convertNameStatus(row.original)}
            </span>
          )
        },
      },
      {
        Header: intl.formatMessage({id: 'MEMBER_SINCE'}),
        headerClassName: 'min-w-125px',
        Cell: ({row}: {row: any}) => {
          return convertUserTimeZone(
            row.original?.created_at,
            currentUser,
            process.env.REACT_APP_DATE_FORMAT
          )
        },
      },
      {
        id: TABLE_KEY_COLUMN.ACTIONS,
        Header: intl.formatMessage({id: 'ACTIONS'}),
        headerClassName: 'text-center fixed-action',
        cellClassName: 'text-center fixed-action',
        Cell: ({row}: {row: any}) =>
          routes.USERS_UPDATE.hasPermission && (
            <Button
              disabled={!row.original.verified}
              className='btn text-white text-hover-primary bg-primary bg-hover-light-primary btn-sm'
              event={() => {
                setUserId(row.original.id)
                setShowModalEditUser(true)
              }}
            >
              {intl.formatMessage({id: 'EDIT'})}
            </Button>
          ),
      },
      {
        id: TABLE_KEY_COLUMN.SUB_ACTIONS,
        cellClassName: 'fixed-column',
        Cell: ({row}: {row: any}) => {
          let listDropDownBtn = [
            {
              label: intl.formatMessage({id: 'REMOVE'}),
              action: () => {
                setUserId(row.original.id)
                setShowModalRemoveUser(true)
              },
              className: 'cursor-pointer',
              hidden: !routes.USERS_REMOVE.hasPermission,
            },
          ]

          if (!row.original.verified) {
            listDropDownBtn = [
              {
                label: intl.formatMessage({id: 'RESEND_INVITATION'}),
                action: () => {
                  handleResendInvitation(row.original.id)
                },
                className: 'cursor-pointer',
                hidden: !routes.USERS_INVITE.hasPermission,
              },
              {
                label: intl.formatMessage({id: 'CANCEL_INVITATION'}),
                action: () => {
                  handleCancelInvitation(row.original.id)
                },
                className: 'cursor-pointer',
                hidden: !routes.USERS_INVITE.hasPermission,
              },
            ]
          }

          return <DropdownButton loading={row.original.isLoading} list={listDropDownBtn} />
        },
      },
    ]
  }, [currentUser, intl, routes, convertNameStatus, handleCancelInvitation, handleResendInvitation])

  const handleRemoveUser = useCallback(async () => {
    setPageDisabled(true)
    await UserService.removeFromCompany(userId)
      .then(() => {
        toast.success(intl.formatMessage({id: 'REMOVE_SUCCESSFULLY'}))
        reload()
      })
      .catch((error) => {
        if (isCancel(error)) return
        if (error.response.data.error.message) {
          toast.error(error.response.data.error.message)
        } else {
          toast.error(intl.formatMessage({id: 'REMOVE_FAILED'}))
        }
      })
      .finally(() => {
        setPageDisabled(false)
        setLoadingFirst(false)
      })
  }, [setPageDisabled, isCancel, userId, intl, reload])

  useEffect(() => {
    if (loadingSwitch) return
    setTable()

    return () => {}
  }, [setTable, loadingSwitch])

  return (
    <>
      {showModalEditUser && (
        <UpdateUserModal
          show={showModalEditUser}
          handleClose={() => {
            setShowModalEditUser(false)
          }}
          handleUpdateUserSuccess={reload}
          userId={userId}
        />
      )}

      <ConfirmActionSwal
        show={showModalRemoveUser}
        title={intl.formatMessage({id: 'DELETE_USER'})}
        message={intl.formatMessage({id: 'ARE_YOU_SURE'})}
        messageCancel={intl.formatMessage({id: 'NO'})}
        handleCallBack={handleRemoveUser}
        handleClose={() => setShowModalRemoveUser(false)}
      />
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        {loadingFirst ? (
          <>
            <TableWrapper>
              <TableBody>
                <div className='mt-6 d-flex  align-item-center justify-content-between'>
                  <div className='col-3'>
                    <span className='fs-1 col-12 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
                  </div>
                  <div className='col-2 d-flex flex-row-reverse'>
                    <span className='btn col-5 placeholder placeholder-lg rounded-2 bg-secondary' />
                  </div>
                </div>
                <hr className='mb-8' />
                <TableSkeleton countRow={8} />
              </TableBody>
            </TableWrapper>
          </>
        ) : (
          <>
            <TableWrapper className=''>
              <div className='mb-4'>
                <TableTabsSetting
                  dataTabs={statistics}
                  keyCheckActive='status'
                  title={intl.formatMessage({id: 'USER_MANAGEMENT'})}
                />
              </div>
              <TableBody>
                <UsersFilter reloadTable={reload} />
                <Table
                  columns={columns}
                  data={responseData?.users || []}
                  pagination={responseData?.pagination || {}}
                  tbodyClass='text-gray-600 fw-bold'
                  usePagination
                  useCheckBox
                />
              </TableBody>
            </TableWrapper>
          </>
        )}
      </CSSTransition>
    </>
  )
}

export {UsersPage}
