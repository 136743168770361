import React, {ChangeEvent, useEffect, useRef, useState} from 'react'
import {Modal, Spinner} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import {Button} from '../../../../_gori/partials/widgets'
import clsx from 'clsx'
import {KTSVG} from '../../../../_gori/helpers'
import {getIn} from 'formik'
import {SettingsConfig} from '../../settings/core/_const'
import {toast} from 'react-toastify'
import ReactCropper, {ReactCropperElement} from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import {isEmpty} from 'lodash'
import CommonService from '../core/_requests'

type Props = {
  onSubmit: any
  formik: any
  name: string
  size: any
  currentImg: any
}

const ImageCrop: React.FC<Props> = ({onSubmit, formik, name = '', size, currentImg}) => {
  const intl = useIntl()
  const fileInputRef = useRef<any>(null)
  let formikErrors = getIn(formik.errors, name)
  let formikTouched = getIn(formik.touched, name)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [preview, setPreview] = useState<any>({})
  const fileInit = formik.getFieldProps(name).value
  const [show, setShow] = useState<boolean>(false)
  const [image, setImage] = useState<any>()
  const [file, setFile] = useState<any>()
  const cropperRef = useRef<ReactCropperElement>(null)
  const [selectFiles, setSelectFiles] = useState<object>({})
  const {newCancelToken, isCancel} = useCancelToken()

  useEffect(() => {
    if (onSubmit) {
      onSubmit(selectFiles)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFiles])

  useEffect(() => {
    setPreview(!isEmpty(fileInit) ? fileInit : currentImg)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileInit])

  const handleUploadFile = () => {
    fileInputRef.current.click()
  }

  const isValidFileUploaded = (file) => {
    const validExtensions = SettingsConfig.VALIDATE_FILE.EXTENSION_ALLOW
    const fileExtension = file.type.split('/')[1]

    const filNameExtension = file.name

    let isValidFilNameExtension = false
    for (const ext of validExtensions) {
      if (filNameExtension.endsWith(`.${ext}`)) {
        isValidFilNameExtension = true
        break
      }
    }
    return validExtensions.includes(fileExtension) && isValidFilNameExtension
  }
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return
    }
    const files = Array.from(e.target.files)
    e.target.value = '' // reset file upload

    files.forEach(async (file) => {
      if (isValidFileUploaded(file)) {
        if (file.size > SettingsConfig.VALIDATE_FILE.SIZE) {
          return toast.warning(
            intl.formatMessage(
              {id: 'FILE_INPUT_ERROR_UPLOADING_PLEASE_UPLOAD_FILES_SMALLER_THAN_8MB'},
              {input: file.name}
            )
          )
        }
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          setFile(file)
          setImage(reader.result)
        }
        setShow(true)
      } else {
        toast.warning(
          intl.formatMessage(
            {id: 'FILE_INPUT_HAS_INVALID_EXTENSION_ONLY_IMAGES_ARE_ALLOWED'},
            {input: file.name}
          )
        )
      }
    })
  }

  const handleCropImage = async () => {
    const cropper = cropperRef.current?.cropper
    if (typeof cropper !== 'undefined') {
      try {
        setShow(false)
        setIsLoading(true)
        let image = cropper.getCroppedCanvas().toDataURL()
        const config = {
          cancelToken: newCancelToken(),
        }
        let payload = {
          file: image,
        }
        const upload = await CommonService.uploadFileBase64(payload, config)
        if (upload) {
          setSelectFiles({name: file.name, size: file.size, url_show: upload.url, ...upload})
        }
      } catch (error) {
        if (isCancel(error)) return
        console.error(error)
        toast.error(
          intl.formatMessage(
            {id: 'FILE_INPUT_ERROR_UPLOADING_PLEASE_TRY_AGAIN'},
            {input: file.name}
          )
        )
      } finally {
        setIsLoading(false)
      }
    }
  }

  return (
    <>
      {isLoading ? (
        <div className='w-50'>
          <div className={clsx('d-flex align-items-center justify-content-center')}>
            <Spinner
              animation='border'
              role='status'
              style={{width: '4rem', height: '4rem'}}
            ></Spinner>
          </div>
        </div>
      ) : (
        !isEmpty(preview) && (
          <div className='w-50 overflow-hidden'>
            <img
              src={preview?.url_show}
              className='rounded-2 w-100 ps-img-preview'
              alt='img-file'
            />
            <div className='text-truncate mt-2'>{preview?.name}</div>
          </div>
        )
      )}
      <div className='col-3'>
        <input ref={fileInputRef} hidden type='file' onChange={handleFileChange} accept='image/*' />
        {formikTouched && formikErrors && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block text-danger'>
              <span role='alert'>{formikErrors}</span>
            </div>
          </div>
        )}
        <div
          className={clsx('btn btn-primary', {'cursor-no-drop': isLoading})}
          onClick={handleUploadFile}
        >
          <KTSVG path='/media/gori/settings/download.svg' />
          {intl.formatMessage({id: 'UPLOAD_IMAGE'})}
        </div>
      </div>
      <Modal
        id='gori_modal_labels_settings'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-650px h-auto'
        show={show}
        backdrop='static'
        onHide={() => setShow(false)}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'IMAGE_CROP'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div className='crop-image'>
                <ReactCropper
                  src={image}
                  responsive={true}
                  guides={false}
                  aspectRatio={1}
                  cropBoxResizable={false}
                  movable={false}
                  minCropBoxWidth={size.crop.width || 300}
                  minCropBoxHeight={size.crop.height || 300}
                  minContainerWidth={size.container.width || 400}
                  minContainerHeight={size.container.height || 300}
                  ref={cropperRef}
                />
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={() => setShow(false)}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'CROP'})}
                loadingText={intl.formatMessage({id: 'CROP'})}
                event={() => handleCropImage()}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {ImageCrop}
