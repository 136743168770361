import {useNavigate} from 'react-router-dom'
import {useAuth} from './core/Auth'
import {useEffect} from 'react'

export function Logout() {
  const navigate = useNavigate()
  const {logout} = useAuth()
  logout()

  useEffect(() => {
    navigate('/auth/login')
  }, [navigate])
  return null
}
