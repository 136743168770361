import {useFormik} from 'formik'
import {cloneDeep, isEmpty, snakeCase, startCase} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Container, Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {KEY_PERMISSIONS} from '../../../../../../_gori/constants'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../../../_gori/hooks/UseYupValidate'
import {Button, InputTextFormik} from '../../../../../../_gori/partials/widgets'
import {InputCheckboxFormik} from '../../../../../../_gori/partials/widgets/form/InputCheckboxFormik'
import displayConfig from '../../../../../../displayconfig.json'
import UserService from '../../../../users/core/_requests'

type Props = {
  show: boolean
  handleClose: () => void
  reloadTable: any
}

const InviteUserModal: React.FC<Props> = ({show, handleClose, reloadTable}) => {
  const intl = useIntl()
  const {infoYup} = UseYupValidate()
  const {newCancelToken, isCancel} = useCancelToken()
  const [dataPermissions, setDataPermissions] = useState<any>([])
  const [groupByNames, setGroupByNames] = useState<any>([])
  const [loading, setLoading] = useState<{first: boolean; update: boolean}>({
    first: true,
    update: false,
  })

  const initialValues = useMemo(() => {
    const permissionsObject = cloneDeep(dataPermissions).reduce((acc, permission) => {
      acc[`${permission.parent_id}::${permission.id}::${permission.name}`] = false
      if (permission.children && permission.children.length > 0) {
        const childrenObject = permission.children.reduce((childAcc, childPermission) => {
          childAcc[`${childPermission.parent_id}::${childPermission.id}::${childPermission.name}`] =
            false
          return childAcc
        }, {})
        acc = {...acc, ...childrenObject}
      }
      return acc
    }, {})

    return {
      email: null,
      checkHasPermission: false,
      permissions: permissionsObject,
    }
  }, [dataPermissions])

  const initValidateSchema = useMemo(() => {
    let validate: any = {
      email: infoYup.email('EMAIL'),
    }

    if (displayConfig.items.settings.permissions.show) {
      validate = {
        ...validate,
        checkHasPermission: Yup.bool().oneOf(
          [true],
          intl.formatMessage(
            {id: 'INPUT_IS_REQUIRED'},
            {input: intl.formatMessage({id: 'PERMISSIONS'})}
          )
        ),
      }
    }
    return validate
  }, [infoYup, intl])

  const validationSchema = Yup.object().shape(initValidateSchema)
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      setLoading((prev) => ({...prev, update: true}))
      const payload = {
        email: values.email,
        permissions: displayConfig.items.settings.permissions.show
          ? Object.keys(values.permissions)
              .filter((key) => values.permissions[key])
              .map((item) => {
                const [parentField] = item.split('::')
                return parentField !== '0' ? item.split('::')[2] : null
              })
              .filter((item) => item !== null)
          : [KEY_PERMISSIONS.ADMIN],
      }
      try {
        const res = await UserService.invite(payload, {cancelToken: newCancelToken()})
        if (res) {
          toast.success(intl.formatMessage({id: 'INVITED_SUCCESSFULLY'}))
          reloadTable()
          handleCloseModal()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        if (error.response.data.error.message) {
          toast.error(intl.formatMessage({id: error.response.data.error.message}))
        } else {
          toast.error(intl.formatMessage({id: 'INVITED_FAILED'}))
        }
      } finally {
        setLoading((prev) => ({...prev, update: false}))
      }
    },
  })

  const handleCloseModal = () => {
    formik.resetForm()
    handleClose()
  }

  const getDataForm = useCallback(async () => {
    try {
      const config = {cancelToken: newCancelToken()}
      const permissions = await UserService.getPermissions(config)

      if (permissions) {
        setDataPermissions(permissions)
      }
    } catch (error) {
      if (isCancel(error)) return
    } finally {
      setLoading((prev) => ({...prev, first: false}))
    }
  }, [isCancel, newCancelToken])

  useEffect(() => {
    getDataForm()

    return () => {}
  }, [getDataForm])

  const handleChangeCheckbox = (field) => {
    const {permissions} = formik.values
    const valuePrev = permissions?.[field]
    let data = cloneDeep(permissions)
    let arrChildAdmin: any = []

    const [, childField, nameField] = field.split('::')
    data[field] = !valuePrev

    const isFieldAdmin = nameField.toLowerCase() === KEY_PERMISSIONS.ADMIN

    if (isFieldAdmin) {
      Object.keys(cloneDeep(formik.values?.permissions)).forEach((per) => {
        data[per] = !valuePrev
      })
    } else {
      const arrChild = Object.keys(cloneDeep(data)).filter((per) => {
        const [parentPer] = per.split('::')
        return childField === parentPer
      })
      arrChild.forEach((child) => (data[child] = !valuePrev))

      Object.keys(cloneDeep(data)).forEach((level1) => {
        const [parentLevel1, childLevel1] = level1.split('::')
        if (parentLevel1 === '0') {
          arrChildAdmin.push(level1)
        }
        const arrChildLevel2 = Object.keys(cloneDeep(formik.values?.permissions)).filter(
          (level2) => {
            const [parentLevel2] = level2.split('::')
            return childLevel1 === parentLevel2
          }
        )
        if (arrChildLevel2.length > 0) {
          const checkAll = arrChildLevel2.every((per) => data[per])
          data[level1] = checkAll
        }
      })

      const arrAdmin = Object.keys(cloneDeep(data)).filter((per) => {
        const [, , namePer] = per.split('::')
        return namePer.toLowerCase() === KEY_PERMISSIONS.ADMIN
      })
      const checkAll = arrChildAdmin
        .filter((per) => {
          const [, , namePer] = per.split('::')
          return namePer.toLowerCase() !== KEY_PERMISSIONS.ADMIN
        })
        .every((per) => data[per])
      arrAdmin.forEach((admin) => (data[admin] = checkAll))
    }

    formik.setFieldValue('permissions', data)
  }

  useEffect(() => {
    const hasValue = Object.values(formik.values.permissions).every((item) => !item)
    formik.setFieldValue('checkHasPermission', !hasValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.permissions])

  const skeletonPermission = useMemo(() => {
    return (
      <>
        <div className='d-flex flex-column col-md-12 mb-8'>
          <span className='fs-1 col-2 placeholder placeholder-lg rounded-2 bg-secondary mb-4' />
          <span className='btn col-12 placeholder placeholder-lg rounded-2 bg-secondary' />
        </div>
        {displayConfig.items.settings.permissions.show && (
          <div className='row bg-light rounded mb-5  px-5 py-5 m-0'>
            <div className='d-flex justify-content-between mb-2'>
              <h1 className='mb-3'>{intl.formatMessage({id: 'PERMISSIONS'})}</h1>
            </div>
            <div className='row'>
              {Array.from({length: 3}).map((_, index) => (
                <div className='d-flex flex-column col-4' key={index}>
                  {Array.from({length: 2}).map((_, index) => (
                    <div className='d-flex my-2' key={index}>
                      <span className='btn col-3 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
                      <span className='btn col-6 placeholder placeholder-lg rounded-2 bg-secondary me-2' />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    )
  }, [intl])

  const htmlPermission = (permission, index) => {
    return (
      <div className='mb-4'>
        <div
          key={index}
          className='form-check form-switch form-check-custom form-check-solid px-5 align-items-center'
        >
          <InputCheckboxFormik
            formik={formik}
            className='form-check-input'
            type='checkbox'
            name={`permissions.${permission.parent_id}::${permission.id}::${permission.name}`}
            onClick={() =>
              handleChangeCheckbox(`${permission.parent_id}::${permission.id}::${permission.name}`)
            }
            disabled={loading.update}
          />
          <label htmlFor={permission.name} className='ms-2'>
            {intl.formatMessage({id: snakeCase(permission.name).toUpperCase()})}
          </label>
        </div>
        {displayConfig.items.settings.permissions.showPermissionChild &&
          permission?.children?.map((childPermission, childIndex) => {
            return (
              <div
                key={childIndex}
                className='my-2 form-check form-switch form-check-custom form-check-solid px-5 align-items-center'
              >
                <InputCheckboxFormik
                  formik={formik}
                  className='form-check-input ms-9'
                  type='checkbox'
                  name={`permissions.${childPermission.parent_id}::${childPermission.id}::${childPermission.name}`}
                  onClick={() =>
                    handleChangeCheckbox(
                      `${childPermission.parent_id}::${childPermission.id}::${childPermission.name}`
                    )
                  }
                  disabled={loading.update}
                />
                <label htmlFor={childPermission.name} className='ms-2'>
                  {startCase(childPermission.name)}
                </label>
              </div>
            )
          })}
      </div>
    )
  }

  useEffect(() => {
    const data: any = {
      column1: [],
      column2: [],
      column3: [],
    }
    dataPermissions.forEach((permission, index) => {
      if (index <= 1) {
        data['column1'].push(permission)
      } else if (index <= 4) {
        data['column2'].push(permission)
      } else {
        data['column3'].push(permission)
      }
    })
    setGroupByNames(data)
  }, [dataPermissions])

  return (
    <>
      <Modal
        id='gori_modal_invite_user'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-1000px h-auto'
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title bsPrefix={'fw-bolder fs-1'}>
            {intl.formatMessage({id: 'INVITE_USER'})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='mh-650px scroll-y'>
          <Container>
            <>
              {loading.first ? (
                skeletonPermission
              ) : (
                <>
                  <InputTextFormik
                    className='flex-fill mb-8'
                    labelClassName='fw-bolder'
                    required
                    label={intl.formatMessage({id: 'EMAIL'})}
                    formik={formik}
                    name={'email'}
                  />
                  {displayConfig.items.settings.permissions.show && dataPermissions.length > 0 && (
                    <div className='row bg-light rounded mb-5  px-5 py-5 m-0'>
                      <div className='d-flex mb-3'>
                        <h1>{intl.formatMessage({id: 'PERMISSIONS'})}</h1>
                        {formik.errors.checkHasPermission &&
                          !isEmpty(formik.touched.permissions) && (
                            <span className='fs-5 ms-5 mt-1 text-danger d-block fw-bold'>
                              {formik.errors.checkHasPermission}
                            </span>
                          )}
                      </div>
                      <div className='row d-flex'>
                        <div className='col-md-4'>
                          {groupByNames &&
                            groupByNames.column1?.map((permission, index) => {
                              return htmlPermission(permission, index)
                            })}
                        </div>
                        <div className='col-md-4'>
                          {groupByNames &&
                            groupByNames.column2?.map((permission, index) => {
                              return htmlPermission(permission, index)
                            })}
                        </div>
                        <div className='col-md-4'>
                          {groupByNames &&
                            groupByNames.column3?.map((permission, index) => {
                              return htmlPermission(permission, index)
                            })}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-end'>
            <Button
              className='btn btn-secondary me-2'
              label={intl.formatMessage({id: 'CLOSE'})}
              loadingText={intl.formatMessage({id: 'CLOSE'})}
              event={handleCloseModal}
            />
            <Button
              className='btn btn-primary me-2'
              label={intl.formatMessage({id: 'INVITE'})}
              loadingText={intl.formatMessage({id: 'INVITE'})}
              disabled={loading.update}
              loading={loading.update}
              event={formik.handleSubmit}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {InviteUserModal}
