import {CSSTransition} from 'react-transition-group'
import {useCallback, useEffect} from 'react'
import {LoadingPage} from '../../../_gori/helpers/components/LoadingPage'
import {useNavigate, useSearchParams} from 'react-router-dom'
import SettingsService from '../../modules/settings/core/_requests'
import {toast} from 'react-toastify'
import {StorageHelpers} from '../../../_gori/helpers'
import {useIntl} from 'react-intl'
import {useAuth} from '../../modules/auth'
import {OrderType} from '../../modules/orders/core/_const'
import {isEmpty} from 'lodash'

export function EbayVerifyPage() {
  const navigate = useNavigate()
  const intl = useIntl()
  const [searchParams] = useSearchParams()
  const {currentUser} = useAuth()

  const connectEbay = useCallback(async () => {
    try {
      const res = await SettingsService.connect({
        store_type: OrderType.EBAY,
        code: searchParams.get('code'),
      })
      if (res) {
        toast.success(intl.formatMessage({id: 'CONNECTIONS_SUCCESSFUL'}))
      }
    } catch (error) {
      // @ts-ignore
      let message = error?.response?.data?.error?.message ?? ''
      toast.error(isEmpty(message) ? intl.formatMessage({id: 'CONNECTIONS_FAILED'}) : message)
    } finally {
      if (currentUser?.onboard_step === 'done') {
        navigate('/settings/connections/ebay')
      } else {
        StorageHelpers.setItemLocalStorage('CONNECT_SHOPIFY', true)
        navigate('/your-info')
      }
    }
  }, [intl, navigate, searchParams])

  useEffect(() => {
    connectEbay()
  }, [connectEbay])

  return (
    <CSSTransition appear in timeout={100} classNames='fade' unmountOnExit>
      <LoadingPage />
    </CSSTransition>
  )
}
