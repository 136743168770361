import {compact} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {CSSTransition} from 'react-transition-group'
import {convertUserTimeZone, isFeatureEnabled} from '../../../../../../_gori/helpers'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import UseRoutesPermission from '../../../../../../_gori/hooks/UseRoutesPermission'
import {TableWrapper} from '../../../../../../_gori/partials/widgets'
import {useAuth} from '../../../../auth'
import {OPTIONS_METHODS, OPTIONS_SERVICES} from '../../../../onboard/core/_const'
import {MonthlyShipmentVolume} from '../../../core/_const'
import SettingsService from '../../../core/_requests'
import {AccountEditModal} from './AccountEditModal'
import {ChangePasswordModal} from './ChangePasswordModal'
import {CompanyEditModal} from './CompanyEditModal'
import clsx from 'clsx'
import {FEATURES} from '../../../../../../_gori/constants/_features'

const ProfilePage: React.FC = () => {
  const intl = useIntl()
  const {routes} = UseRoutesPermission()
  const {loadingSwitch, currentUser} = useAuth()
  const {newCancelToken, isCancel} = useCancelToken()
  const [company, setCompany] = useState<any>()
  const [showModal, setShowModal] = useState<{
    change_password: boolean
    account_edit: boolean
    connections_edit: boolean
  }>({
    change_password: false,
    account_edit: false,
    connections_edit: false,
  })

  const OPTIONS_SERVICES_CUSTOM = useMemo(() => {
    return [...OPTIONS_SERVICES, {label: intl.formatMessage({id: 'NOT_SURE'}), value: 'not_sure'}]
  }, [intl])
  const OPTIONS_METHODS_CUSTOM = useMemo(() => {
    return [...OPTIONS_METHODS, {label: intl.formatMessage({id: 'NOT_SURE'}), value: 'not_sure'}]
  }, [intl])
  const buildSkeleton = useMemo(() => {
    return (
      <>
        <div className='mt-8'>
          <div className='d-flex justify-content-center'>
            <div className='w-100 w-sm-75 bg-light rounded p-8'>
              <div className='d-flex justify-content-between'>
                <div className='fs-1 col-3 placeholder placeholder-lg rounded-2 bg-secondary' />
                <span className='fs-1 col-1 placeholder rounded-2 bg-secondary' />
              </div>
              <div className='fs-2 col-12 placeholder placeholder-lg rounded-2 bg-secondary mt-6 mb-10' />
              <div className='col-12 d-flex justify-content-between'>
                <div className='fs-2 col-3 placeholder placeholder-lg rounded-2 bg-secondary' />
                <div className='fs-2 col-7 placeholder placeholder-lg rounded-2 bg-secondary' />
              </div>
              <div className='col-12 d-flex justify-content-between mt-6'>
                <div className='fs-2 col-3 placeholder placeholder-lg rounded-2 bg-secondary' />
                <div className='fs-2 col-7 placeholder placeholder-lg rounded-2 bg-secondary' />
              </div>
            </div>
          </div>
        </div>

        <div className='mt-8'>
          <div className='d-flex justify-content-center'>
            <div className='w-100 w-sm-75 bg-light rounded p-8'>
              <div className='d-flex justify-content-between'>
                <div className='fs-1 col-3 placeholder placeholder-lg rounded-2 bg-secondary' />
                <span className='fs-1 col-1 placeholder rounded-2 bg-secondary' />
              </div>
              <div className='fs-2 col-12 placeholder placeholder-lg rounded-2 bg-secondary mt-6 mb-10' />
              <div className='col-12 d-flex justify-content-between'>
                <div className='fs-2 col-3 placeholder placeholder-lg rounded-2 bg-secondary' />
                <div className='fs-2 col-7 placeholder placeholder-lg rounded-2 bg-secondary' />
              </div>
              <div className='col-12 d-flex justify-content-between mt-6'>
                <div className='fs-2 col-3 placeholder placeholder-lg rounded-2 bg-secondary' />
                <div className='fs-2 col-7 placeholder placeholder-lg rounded-2 bg-secondary' />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }, [])

  const getProfileConnection = useCallback(async () => {
    try {
      const company = await SettingsService.getCompany({
        cancelToken: newCancelToken(),
      })

      if (company) {
        setCompany(company)
      }
    } catch (error: any) {
      if (isCancel(error)) return
    }
  }, [isCancel, newCancelToken])

  useEffect(() => {
    if (loadingSwitch) return
    getProfileConnection()

    return () => {}
  }, [getProfileConnection, loadingSwitch])

  const PROFILE_AND_CONNECTIONS = useMemo(() => {
    if (!company)
      return {
        ACCOUNT: [],
        COMPANY: [],
        PRIMARY_CONTACTS: [],
        SHIPPING_PREFERENCE: [],
      }
    const {street1, city, state, zip, accounting, report, interested_services, using_methods} =
      company
    const valuesAddress = compact([street1, city, state])
    const valuesAddressUpsFedex = compact([
      company?.shipping_address_ups_fedex?.street1,
      company?.shipping_address_ups_fedex?.city,
      company?.shipping_address_ups_fedex?.state,
    ])
    const valuesAddressDefault = compact([
      company?.shipping_address_default?.street1,
      company?.shipping_address_default?.city,
      company?.shipping_address_default?.state,
    ])
    const ShowAddress = `${valuesAddress.join(', ')} ${zip}`
    const ShowAddressUPSFedex = `${valuesAddressUpsFedex.join(', ')} ${
      company?.shipping_address_ups_fedex?.zip
    }`
    const ShowAddressDefault = `${valuesAddressDefault.join(', ')} ${
      company?.shipping_address_default?.zip
    }`
    const interestedServices =
      compact(
        OPTIONS_SERVICES_CUSTOM.map((option) => {
          if (interested_services?.includes(option.value)) {
            return option.label
          } else {
            return null
          }
        })
      ) ?? []
    const usingMethods =
      compact(
        OPTIONS_METHODS_CUSTOM.map((option) => {
          if (using_methods?.includes(option.value)) {
            return option.label
          } else {
            return null
          }
        })
      ) ?? []

    return {
      ACCOUNT: [
        {
          label: 'FIRST_NAME',
          value: currentUser?.first_name ?? null,
        },
        {
          label: 'LAST_NAME',
          value: currentUser?.last_name ?? null,
        },
        {
          label: 'EMAIL',
          value: currentUser?.email ?? null,
        },
      ],
      COMPANY: [
        {
          label: 'COMPANY_NAME',
          value: company?.name ?? null,
        },
        {
          label: 'PHONE',
          value: company?.phone ?? null,
        },
        {
          label: 'MONTHLY_SHIPMENT_VOLUME',
          value:
            intl.formatMessage({
              id:
                MonthlyShipmentVolume.find(
                  (option) => company?.monthly_shipment_volume === option.value
                )?.label || intl.formatMessage({id: 'NONE'}),
            }) ?? null,
        },
        {
          label: 'COUNTRY',
          value: company?.country ?? null,
        },
        {
          label: 'BUSINESS_ADDRESS',
          value: ShowAddress ?? null,
        },
        {
          label: 'SALE_REP',
          value: company?.sales_rep ?? null,
        },
      ],
      PRIMARY_CONTACTS: [
        {
          label: 'DATA_REPORT',
          value: report?.name ?? null,
        },
        {
          label: null,
          value: report?.emails?.join(`\n`) ?? null,
        },
        {
          label: 'ACCOUNTING',
          value: accounting?.name ?? null,
        },
        {
          label: null,
          value: accounting?.emails?.join(`\n`) ?? null,
        },
      ],
      SHIPPING_PREFERENCE: [
        {
          label: 'WHICH_METHOD_ARE_YOU_USING_TO_CREATE_YOUR_LABELS',
          value: usingMethods?.join(', ') ?? null,
        },
        {
          label: 'WHAT_SHIPPING_SERVICES_ARE_YOU_INTERESTED_IN',
          value: interestedServices?.join(', ') ?? null,
        },
        {
          label: 'FROM_ADDRESS_DEFAULT',
          value: company?.shipping_address_default != null ? ShowAddressDefault : '',
        },
        {
          label: 'FROM_ADDRESS_FOR_UPS_FEDEX',
          value: company?.shipping_address_ups_fedex?.street1 != null ? ShowAddressUPSFedex : null,
        },
      ],
    }
  }, [OPTIONS_METHODS_CUSTOM, OPTIONS_SERVICES_CUSTOM, company, currentUser, intl])

  return (
    <>
      {showModal.connections_edit && (
        <CompanyEditModal
          show={showModal.connections_edit}
          handleClose={() => {
            setShowModal((prev) => ({...prev, connections_edit: false}))
          }}
          company={company}
        />
      )}
      {showModal.account_edit && (
        <AccountEditModal
          show={showModal.account_edit}
          handleClose={() => {
            setShowModal((prev) => ({...prev, account_edit: false}))
          }}
        />
      )}
      {showModal.change_password && (
        <ChangePasswordModal
          show={showModal.change_password}
          handleClose={() => {
            setShowModal((prev) => ({...prev, change_password: false}))
          }}
        />
      )}

      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <TableWrapper className='h-100'>
          <div className='mb-4'>
            <div className='card-header card-header-stretch d-flex h-55px'>
              <div className='card-title'>
                <h3>{intl.formatMessage({id: 'PROFILE'})}</h3>
              </div>
            </div>

            <div className='card-body'>
              {!company ? (
                <>{buildSkeleton}</>
              ) : (
                <div className='d-flex flex-column align-items-center justify-content-center'>
                  <div className='w-100 w-sm-75 border border-secondary rounded-2 py-6 px-8 mt-8'>
                    <div>
                      <div className='d-flex flex-wrap justify-content-between'>
                        <span className='fs-3 fw-bolder text-gray-700'>
                          {intl.formatMessage({id: 'USER_INFORMATION'})}
                        </span>
                        <div className='fw-bold d-flex'>
                          <div
                            className='fs-5 text-gray-600 text-decoration-underline cursor-pointer fw-bolder'
                            onClick={() =>
                              setShowModal((prev) => ({...prev, change_password: true}))
                            }
                          >
                            {intl.formatMessage({id: 'CHANGE_PASSWORD'})}
                          </div>
                          <div
                            className='fs-5 text-primary text-decoration-underline cursor-pointer fw-bolder ms-4'
                            onClick={() => setShowModal((prev) => ({...prev, account_edit: true}))}
                          >
                            {intl.formatMessage({id: 'EDIT'})}
                          </div>
                        </div>
                      </div>

                      <p className='form-label text-gray-600'>
                        {intl.formatMessage({id: 'USER_CREATED'}) + ': '}
                        {convertUserTimeZone(
                          currentUser?.created_at ?? '',
                          currentUser,
                          process.env.REACT_APP_DATE_FORMAT
                        )}
                      </p>
                    </div>

                    <div className='mt-6'>
                      {PROFILE_AND_CONNECTIONS.ACCOUNT.map((row, index) => (
                        <div className='d-flex text-gray-700 fs-5 mt-8' key={index}>
                          <div className='col-6 col-sm-4 fw-bold'>
                            {row.label ? intl.formatMessage({id: row.label}) : null}
                          </div>
                          <div className='text-wrap fw-bolder'>
                            {row?.value || intl.formatMessage({id: 'NONE'})}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {isFeatureEnabled(FEATURES.ACCOUNT_INFO) && routes.ADMINS.hasPermission && (
                    <div className='w-100 w-sm-75 border border-secondary rounded-2 py-6 px-8 mt-8'>
                      <div>
                        <div className='d-flex justify-content-between'>
                          <span className='fs-3 fw-bolder text-gray-700'>
                            {intl.formatMessage({id: 'ACCOUNT_INFORMATION'})}
                          </span>
                          <div
                            className='fs-5 text-primary text-decoration-underline cursor-pointer fw-bolder'
                            onClick={() =>
                              setShowModal((prev) => ({...prev, connections_edit: true}))
                            }
                          >
                            {intl.formatMessage({id: 'EDIT'})}
                          </div>
                        </div>
                        <div className='mt-6'>
                          {PROFILE_AND_CONNECTIONS?.COMPANY?.map((row, index) => (
                            <div className='d-flex text-gray-700 fs-5 mt-8' key={index}>
                              <div className='col-6 col-sm-4 fw-bold'>
                                {row.label ? intl.formatMessage({id: row.label}) : null}
                              </div>
                              <div className='text-wrap fw-bolder'>
                                {row?.value || intl.formatMessage({id: 'NONE'})}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <hr className='my-8 fw-bold' />
                      <div>
                        <div className='d-flex'>
                          <span className='fs-3 fw-bolder text-gray-700'>
                            {intl.formatMessage({id: 'PRIMARY_CONTACTS'})}
                          </span>
                        </div>
                        <div className='mt-6'>
                          {PROFILE_AND_CONNECTIONS?.PRIMARY_CONTACTS?.map((row, index) => (
                            <div
                              className={clsx('d-flex text-gray-700 fs-5', row.label ? 'mt-8' : '')}
                              key={index}
                            >
                              <div className='col-6 col-sm-4 fw-bold'>
                                {row.label ? intl.formatMessage({id: row.label}) : null}
                              </div>
                              <div
                                className={clsx('text-truncate fw-bolder', row.label ? '' : 'mt-2')}
                                style={{whiteSpace: 'pre'}}
                              >
                                {row?.value || intl.formatMessage({id: 'NONE'})}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <hr className='my-8 fw-bold' />
                      <div>
                        <div className='d-flex'>
                          <span className='fs-3 fw-bolder text-gray-700'>
                            {intl.formatMessage({id: 'SHIPPING_PREFERENCE'})}
                          </span>
                        </div>
                        <div className='mt-6'>
                          {PROFILE_AND_CONNECTIONS?.SHIPPING_PREFERENCE?.map((row, index) => (
                            <div className='d-flex text-gray-700 fs-5 mt-8' key={index}>
                              <div className='col-6 col-sm-4 fw-bold'>
                                {row.label ? intl.formatMessage({id: row.label}) : null}
                              </div>
                              <div className='fw-bolder'>
                                {row?.value || intl.formatMessage({id: 'NONE'})}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </TableWrapper>
      </CSSTransition>
    </>
  )
}

export {ProfilePage}
