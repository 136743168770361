import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {CSSTransition} from 'react-transition-group'
import * as Yup from 'yup'
import SettingsService from '../../../../../settings/core/_requests'
import {Button, InputTextFormik} from '../../../../../../../_gori/partials/widgets'
import UseYupValidate from '../../../../../../../_gori/hooks/UseYupValidate'
import {StorageHelpers} from '../../../../../../../_gori/helpers'
import useCancelToken from '../../../../../../../_gori/hooks/UseCancelToken'
import {useOnboardProvider} from '../../../../../../../_gori/helpers/components/OnboardProvider'

const ConnectShopify: React.FC = () => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {setErrors} = useOnboardProvider()
  const {regexYup} = UseYupValidate()
  const {isCancel} = useCancelToken()
  const initialValues = {
    storeLink: '',
  }

  const validationSchema = Yup.object().shape({
    storeLink: regexYup.storeLink,
  })

  const onSubmit = async (values, {setStatus}) => {
    setIsLoading(true)
    StorageHelpers.setItemLocalStorage('CONNECT_SHOPIFY', true)
    await SettingsService.getShopifyIntegrateLink(values.storeLink)
      .then((res) => {
        setIsLoading(false)
        window.location.href = res
      })
      .catch((error) => {
        if (isCancel(error)) return
        setStatus(error?.response?.data?.error?.message ?? '')
        setIsLoading(false)
      })
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  useEffect(() => {
    setErrors((prev) => ({...prev, label_creation: formik.errors.storeLink}))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors.storeLink])

  return (
    <>
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className='d-flex justify-content-center my-6'>
          <div className='w-75'>
            <div className='text-start'>
              <InputTextFormik
                labelClassName='fw-bolder'
                required
                formik={formik}
                name={'storeLink'}
                label={intl.formatMessage({id: 'SHOPIFY_STORE_DOMAIN'})}
              />
            </div>
            <div
              className={clsx('text-end mt-4', {
                'cursor-no-drop': isLoading,
              })}
            >
              <Button
                className={clsx('btn btn-primary', {
                  'pe-none': isLoading,
                  'cursor-no-drop': isLoading,
                })}
                loading={isLoading}
                loadingText={intl.formatMessage({id: 'CONNECT'})}
                label={intl.formatMessage({id: 'CONNECT'})}
                event={formik.submitForm}
              />
            </div>
            {formik.status && (
              <div className='d-flex align-items-start'>
                <div className='d-flex align-items-center'>
                  <div className='mt-5 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </CSSTransition>
    </>
  )
}

export {ConnectShopify}
