import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {CSSTransition} from 'react-transition-group'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {toast} from 'react-toastify'
import useCancelToken from '../../../../../../../_gori/hooks/UseCancelToken'
import SettingsService from '../../../../../settings/core/_requests'
import {Button, InputTextFormik} from '../../../../../../../_gori/partials/widgets'
import UseYupValidate from '../../../../../../../_gori/hooks/UseYupValidate'
import {useOnboardProvider} from '../../../../../../../_gori/helpers/components/OnboardProvider'
import {OrderType} from '../../../../../orders/core/_const'

type Props = {
  setConnection?: any
}

const ConnectShipStation: React.FC<Props> = ({setConnection}) => {
  const intl = useIntl()
  const {settingsYup} = UseYupValidate()
  const {newCancelToken, isCancel} = useCancelToken()
  const {setErrors} = useOnboardProvider()

  const initialValues = {
    store_type: OrderType.SHIPSTATION,
    api_key: '',
    secret_key: '',
  }

  const validationSchema = Yup.object().shape({
    api_key: settingsYup.apiKey,
    secret_key: settingsYup.secretKey,
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      const config = {cancelToken: newCancelToken()}
      setStatus('')
      await SettingsService.connect(values, config)
        .then(async () => {
          toast.success(intl.formatMessage({id: 'CONNECTIONS_SUCCESSFUL'}))
          setConnection(true)
        })
        .catch((error) => {
          if (isCancel(error)) return
          setStatus(error?.response?.data?.error?.message ?? '')
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  useEffect(() => {
    const error = formik.errors.api_key || formik.errors.secret_key
    setErrors((prev) => ({...prev, label_creation: error}))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors.api_key, formik.errors.secret_key])

  return (
    <>
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className='d-flex justify-content-center my-6'>
          <div className='w-75'>
            {formik.status && (
              <div className='alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}
            <div className='text-start'>
              <InputTextFormik
                labelClassName='fw-bolder'
                required
                label={'ShipStation ' + intl.formatMessage({id: 'API_KEY'})}
                formik={formik}
                name={'api_key'}
              />
            </div>
            <div className='text-start'>
              <InputTextFormik
                labelClassName='fw-bolder mt-3'
                required
                label={'ShipStation ' + intl.formatMessage({id: 'API_SECRET'})}
                formik={formik}
                name={'secret_key'}
              />
            </div>
            <div className='text-end'>
              <Button
                className='btn btn-primary mt-7'
                label={intl.formatMessage({id: 'CONNECT'})}
                loadingText={intl.formatMessage({id: 'CONNECT'})}
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting || !formik.isValid}
                event={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  )
}

export {ConnectShipStation}
