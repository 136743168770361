export const BatchesConfig = {
  COUNT_CREATE_ALL_LABELS: 10,
  COUNT_PRINT_ALL_LABELS: 500,
  PAGE_SIZE_LOAD_MORE: 200,
  MAX_UPLOAD: 5000,
  SUPPORTED_FORMATS: ['text/csv'],
}

export const BatchesStatus = {
  OPEN: 'open',
  PROCESSED: 'processed',
  ARCHIVED: 'archived',
}

export const BATCH_UPLOAD_MAPPING = {
  DEFAULT: [
    {label: 'DO_NOT_MAP', value: '', required: false},
    {label: 'SERVICE', value: 'service', required: false},
    {label: 'PACKAGE_TYPE', value: 'package_type', required: false},
    {label: 'WEIGHT', value: 'weight', required: true},
    {label: 'WEIGHT_UNIT', value: 'weight_unit', required: false},
    {label: 'WIDTH', value: 'width', required: true},
    {label: 'HEIGHT', value: 'height', required: true},
    {label: 'LENGTH', value: 'length', required: true},
    {label: 'DIM_UNIT', value: 'dim_unit', required: false},
    {label: 'TO_COMPANY', value: 'to_company', required: false},
    {label: 'RESIDENTIAL', value: 'residential', required: false},
    {label: 'TO_FIRST_NAME', value: 'to_first_name', required: true},
    {label: 'TO_LAST_NAME', value: 'to_last_name', required: false},
    {label: 'TO_PHONE_NUMBER', value: 'to_phone_number', required: false},
    {label: 'TO_EMAIL_ADDRESS', value: 'to_email_address', required: false},
    {label: 'TO_STREET_1', value: 'to_street_1', required: true},
    {label: 'TO_STREET_2', value: 'to_street_2', required: false},
    {label: 'TO_CITY', value: 'to_city', required: true},
    {label: 'TO_STATE', value: 'to_state', required: true},
    {label: 'TO_ZIP', value: 'to_zip', required: true},
    {label: 'TO_COUNTRY', value: 'to_country', required: false},
    {label: 'FROM_COMPANY', value: 'from_company', required: false},
    {label: 'FROM_FIRST_NAME', value: 'from_first_name', required: true},
    {label: 'FROM_LAST_NAME', value: 'from_last_name', required: false},
    {label: 'FROM_PHONE_NUMBER', value: 'from_phone_number', required: false},
    {label: 'FROM_EMAIL_ADDRESS', value: 'from_email_address', required: false},
    {label: 'FROM_STREET_1', value: 'from_street_1', required: true},
    {label: 'FROM_STREET_2', value: 'from_street_2', required: false},
    {label: 'FROM_CITY', value: 'from_city', required: true},
    {label: 'FROM_STATE', value: 'from_state', required: true},
    {label: 'FROM_ZIP', value: 'from_zip', required: true},
    {label: 'FROM_COUNTRY', value: 'from_country', required: false},
    {label: 'REFERENCE_1', value: 'reference_1', required: false},
    {label: 'REFERENCE_2', value: 'reference_2', required: false},
    {label: 'SHIP_BY', value: 'ship_by', required: false},
    {label: 'ITEM_SKU', value: 'item_sku', required: false},
    {label: 'ITEM_NAME', value: 'item_name', required: false},
    {label: 'ITEM_QUANTITY', value: 'item_quantity', required: false},
    {label: 'ITEM_PRICE', value: 'item_price', required: false},
  ],
  CUSTOM: [
    {label: 'PACKAGE_CONTENT_TYPE', value: 'package_content_type', required: true},
    {label: 'PRODUCT_DESCRIPTION', value: 'product_description', required: true},
    {label: 'TOTAL_VALUE_IN_USD', value: 'total_value_in_usd', required: true},
    {label: 'COUNTRY_OF_ORIGIN', value: 'country_of_origin', required: false},
  ],
}
