import {cloneDeep, find, isEmpty} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSearchParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {CSSTransition} from 'react-transition-group'
import {ButtonConfirmProgress, CreateAllLabelsModal} from '..'
import {blockPopUp} from '../../../../_gori/helpers/BrowserHelpers'
import {useBatchesProvider} from '../../../../_gori/helpers/components/BatchesProvider'
import {useMeter} from '../../../../_gori/helpers/components/MeterProvider'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import UseRoutesPermission from '../../../../_gori/hooks/UseRoutesPermission'
import {Button, ValidationErrorModal} from '../../../../_gori/partials/widgets'
import {ActionAllModal} from '../../common'
import {SET_UP_MODAL_ALL} from '../../common/core/_const'

import {OrderStatus} from '../../orders/core/_const'
import {BatchesStatus} from '../core/_const'
import BatchService from '../core/_requests'

type Props = {
  selectedList?: any
  reloadTable: any
  statistics: any
}
const BatchesDetailLabels: React.FC<Props> = ({statistics, selectedList, reloadTable}) => {
  const intl = useIntl()
  const {routes} = UseRoutesPermission()
  const {setMeterChange} = useMeter()
  const {batch} = useBatchesProvider()
  const {newCancelToken, isCancel} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [searchParams] = useSearchParams()

  const [loading, setLoading] = useState<any>({
    loadingPrintLabel: false,
    print_all: false,
    select_print_ids: false,
  })
  const [showModal, setShowModal] = useState<any>({
    create_label: false,
    create_all: false,
  })
  const [dataModal, setDataModal] = useState<any>({
    create_order_ids: [],
  })

  useEffect(() => {
    if (!selectedList.selectedMaster) {
      setSelectAll(false)
    }
  }, [selectedList.selected, selectedList.selectedMaster])

  const getIdsByStatus = useCallback(
    (nameStatus) => {
      if (isEmpty(statistics)) return []
      const status: any = cloneDeep(statistics).find((item: any) => item.value === nameStatus)

      let orders: any
      if (!status) {
        orders = cloneDeep(statistics)
          .flatMap((item: any) => item.orders || [])
          .map((order: any) => ({original: order}))
      } else {
        orders = (status?.orders || []).map((order: any) => ({original: order}))
      }

      return orders
    },
    [statistics]
  )

  const filterOrdersByStatus = useCallback(
    (statusList) => {
      if (selectAll) {
        const currentStatus = searchParams.get('status')
        if (!currentStatus) {
          return statusList.flatMap((status) => getIdsByStatus(status))
        } else if (statusList.includes(currentStatus)) {
          return getIdsByStatus(currentStatus)
        }
      } else {
        return cloneDeep(selectedList.selected).flatMap((row) =>
          statusList.includes(row.original.status) ? [row] : []
        )
      }
    },
    [getIdsByStatus, searchParams, selectAll, selectedList.selected]
  )

  const orderIds = useMemo(() => {
    return {
      create_label: filterOrdersByStatus([OrderStatus.OPEN]),
      create_all_label:
        find(statistics, {value: OrderStatus.OPEN})?.orders?.map((item) => item.id) ?? [],
      print_all_label:
        find(statistics, {value: OrderStatus.SHIPPED})?.orders?.map((item) => item.id) ?? [],
    }
  }, [filterOrdersByStatus, statistics])

  // BEGIN: Create All Labels
  const handleCreateAllLabels = useCallback(() => {
    setDataModal((prev) => ({...prev, create_order_ids: orderIds.create_all_label}))
    setShowModal((prev) => ({...prev, create_all: true}))
  }, [orderIds.create_all_label])

  const handleCloseCreateAllModal = useCallback(() => {
    setMeterChange(true)
    setShowModal((prev) => ({...prev, create_all: false}))
    reloadTable()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // END: Create All Labels

  const handlePrintAllLabels = useCallback(async () => {
    if (!isEmpty(orderIds.print_all_label)) {
      try {
        setLoading((prev) => ({...prev, print_all: true}))
        const response = await BatchService.print(
          {order_ids: orderIds.print_all_label},
          {
            cancelToken: newCancelToken(),
            params: {
              batch: batch.name,
            },
          }
        )
        if (response) {
          // TODO: Fix print all labels when all refunded
          if (!response.label_url) {
            toast.warning(
              intl.formatMessage({
                id: 'PRINT_LABELS_REFUNDED',
              })
            )
            return
          }
          if (blockPopUp(response.label_url)) {
            toast.warning(
              intl.formatMessage({
                id: 'POP_UP_IS_BLOCKED',
              })
            )
          }
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, print_all: false}))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batch.name, isCancel, newCancelToken, orderIds.print_all_label])

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      {showModal.create_label && (
        <ActionAllModal
          show={showModal.create_label}
          data={orderIds?.create_label}
          setUpModal={SET_UP_MODAL_ALL.CREATE_LABELS}
          handleClose={() => {
            reloadTable()
            setShowModal((prev) => ({...prev, create_label: false}))
          }}
        />
      )}
      {showModal.create_all && (
        <CreateAllLabelsModal
          show={showModal.create_all}
          orderIds={dataModal.create_order_ids}
          handleClose={() => handleCloseCreateAllModal()}
        />
      )}

      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className='d-flex flex-column gap-3'>
          <div className='d-flex'>
            {routes.SHIPMENTS_CREATE.hasPermission && (
              <ButtonConfirmProgress
                className='btn btn-sm text-white bg-image-gori user-select-none me-5'
                label={intl.formatMessage({id: 'CREATE_ALL_LABELS'})}
                disabled={
                  orderIds.create_all_label.length === 0 || batch?.status === BatchesStatus.ARCHIVED
                }
                callBack={handleCreateAllLabels}
              />
            )}
            <div>
              <Button
                className={`btn btn-sm btn-light-blue border border-blue ${
                  isEmpty(orderIds.print_all_label) ? 'disabled' : ''
                }`}
                label={intl.formatMessage({id: 'PRINT_ALL_LABELS'})}
                loadingText={intl.formatMessage({id: 'PRINT_ALL_LABELS'})}
                loading={loading.print_all}
                event={handlePrintAllLabels}
              />
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  )
}

export {BatchesDetailLabels}
