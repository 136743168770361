import {IntegrationsPageLayout} from '../../../_gori/layout/components/IntegrationsPagesLayout'

export function ShopifyIntegration() {
  return (
    <>
      <IntegrationsPageLayout
        pageClass='shopify-integration'
        heading='SHOPIFY_HEADER'
        headingSubtext='SHOPIFY_SUBTEXT'
        integrationLogo='/media/gori/integrations/shopify/shopify-logo.svg'
        integrationLogoAlt='shopify-logo'
        integrateImg='/media/gori/integrations/shopify/integrate.webp'
        card1Copy='SHOPIFY_CARD_1'
        card2Copy='SHOPIFY_CARD_2'
        card3Copy='SHOPIFY_CARD_3'
        card4Copy='SHOPIFY_CARD_4'
      />
    </>
  )
}
