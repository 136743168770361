import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {Table, SelectFormik, Button, InputTextFormik} from '../../../../_gori/partials/widgets'
import {useFormik} from 'formik'
import _ from 'lodash'
import {toast} from 'react-toastify'
import OrderService from '../core/_requests'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import {useAuth} from '../../auth'

type Props = {
  show: boolean
  handleClose: () => void
  orders?: any
  reloadTable: () => {}
}

const SplitOrderModal: React.FC<Props> = ({show, handleClose, orders, reloadTable}) => {
  const intl = useIntl()
  const {currentCompany} = useAuth()
  const [tableData, setTableData] = useState<any>([])
  const [options, setOptions] = useState<any>([])
  const [disabledSplit, setDisabledSplit] = useState<boolean>(false)
  const [disabledApply, setDisabledApply] = useState<boolean>(false)
  const {newCancelToken, isCancel} = useCancelToken()

  const initialValues = useMemo(() => {
    let init = {}
    let key = 0
    if (orders[0]?.original?.order_items.length > 0) {
      _.forEach(orders[0]?.original?.order_items, function (item) {
        init[`shipment_${key}`] = 1
        init[`quantity_${key}`] = item.quantity
        key++
      })
    }
    if (orders[0]?.original?.children.length > 0) {
      _.forEach(orders[0]?.original?.children, (child) => {
        if (!_.isEmpty(child.order_items)) {
          _.forEach(child.order_items, (item) => {
            init[`shipment_${key}`] = 1
            init[`quantity_${key}`] = item.quantity
            key++
          })
        }
      })
    }
    return init
  }, [orders])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values, {setSubmitting}) => {
      let split: any = []
      let onChange = false
      _.forEach(tableData, (item) => {
        let index = values[`shipment_${item.key}`]
        split[index] = !_.isEmpty(split[index]) ? split[index] : []
        split[index].push({
          order_item_id: item.id,
          quantity: values[`quantity_${item.key}`],
        })
        if (index !== 1) {
          onChange = true
        }
      })

      let payload: any = {
        order_id: orders[0]?.original?.id,
        split: split,
      }

      try {
        setSubmitting(true)
        if (onChange) {
          const res = await OrderService.split(payload, {cancelToken: newCancelToken()})
          if (res) {
            toast.success(intl.formatMessage({id: 'SPLIT_ORDER_SUCCESSFULLY'}))
            reloadTable()
          }
        }
      } catch (error: any) {
        if (isCancel(error)) return
      } finally {
        setSubmitting(false)
        handleCloseForm()
      }
    },
  })

  const columns = useMemo(
    () => [
      {
        Header: intl.formatMessage({id: 'ITEM_NAME'}),
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) => {
          return (
            <div className={disabledSplit && !row.original?.split ? 'split-disabled' : ''}>
              <p className='mb-0 fw-bolder'>{row.original.title}</p>
              <small>
                {intl.formatMessage({id: 'SKU'})}: {row.original?.sku}
              </small>
            </div>
          )
        },
      },
      {
        Header: intl.formatMessage({id: 'QUANTITY'}),
        headerClassName: 'min-w-50px w-100px',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) => {
          return row.original?.split ? (
            <div className={disabledSplit && !row.original?.split ? 'split-disabled' : ''}>
              <InputTextFormik
                checkFormik={false}
                className='input-split-quantity'
                name={`quantity_${row.original?.key}`}
                formik={formik}
                type='number'
                min={1}
                disabled={disabledSplit && !row.original?.split}
                onChange={(e) => {
                  changeQuantity(e.target.value, row.original)
                }}
              />
            </div>
          ) : (
            <div
              className={
                disabledSplit && !row.original?.split ? 'split-disabled text-center' : 'text-center'
              }
            >
              {row.original?.quantity}
            </div>
          )
        },
      },
      {
        Header: intl.formatMessage({id: 'SHIPMENT'}),
        headerClassName: 'min-w-150px w-210px',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) => {
          return (
            <div className={disabledSplit && !row.original?.split ? 'split-disabled' : ''}>
              <SelectFormik
                checkFormik={false}
                emptyDefault={false}
                options={options}
                formik={formik}
                name={`shipment_${row.original?.key}`}
                onChange={(e) => {
                  changeShipment(e, row.original?.key)
                }}
                required
                disabled={disabledSplit && !row.original?.split}
                isSearchable={false}
              />
            </div>
          )
        },
      },
      {
        id: 'actions',
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap',
        Cell: ({row}: {row: any}) => {
          return (
            <div className={disabledSplit && !row.original?.split ? 'split-disabled' : ''}>
              {(row.original?.quantity > 1 && row.original?.active) ||
              (row.original?.split && row.original?.active)
                ? splitButton(row.original)
                : ''}
            </div>
          )
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableData, formik, disabledSplit]
  )

  const initSplitModal = useCallback(() => {
    let key = 0
    let dataTable: any[] = []
    if (orders[0]?.original?.order_items.length > 0) {
      _.forEach(orders[0]?.original?.order_items, (item) => {
        dataTable.push({
          id: item.id,
          quantity: item.quantity,
          sku: item.sku,
          title: item.title,
          key: key,
          active: true,
          split: false,
        })
        key++
      })
    }
    if (orders[0]?.original?.children.length > 0) {
      _.forEach(orders[0]?.original?.children, (child) => {
        if (!_.isEmpty(child.order_items)) {
          _.forEach(child.order_items, (item) => {
            dataTable.push({
              id: item.id,
              quantity: item.quantity,
              sku: item.sku,
              title: item.title,
              key: key,
              active: true,
              split: false,
            })
            key++
          })
        }
      })
    }
    setTableData(dataTable)
    setOptions([
      {
        value: 1,
        label: `${intl.formatMessage({id: 'SHIPMENT'})} 1`,
      },
      {
        value: 2,
        label: `${intl.formatMessage({id: 'SHIPMENT'})} 2 (${intl.formatMessage({
          id: 'NEW',
        })})`,
      },
      {
        value: 'ADD_ANOTHER_SHIPMENT',
        label: intl.formatMessage({id: 'ADD_ANOTHER_SHIPMENT'}),
        action: 'link',
      },
    ])
  }, [intl, orders])

  useEffect(() => {
    initSplitModal()
  }, [initSplitModal])

  const changeQuantity = (qty, item) => {
    let maxQty: any = _.filter(tableData, (row) => row.id === item.id && row.split)
    maxQty = _.maxBy(maxQty, 'quantity')
    maxQty = maxQty.quantity ?? 1
    _.forEach(tableData, (row) => {
      if (row.split && row.key !== item.key) {
        let setQty = maxQty - qty + 1
        formik.setFieldValue(
          formik.getFieldProps(`quantity_${row.key}`).name,
          setQty > 0 ? setQty : 1
        )
      }
    })

    if (qty > maxQty) {
      formik.setFieldValue(formik.getFieldProps(`quantity_${item.key}`).name, maxQty)
    }
  }

  const handleCloseForm = () => {
    setDisabledSplit(false)
    setDisabledApply(false)
    initSplitModal()
    formik.resetForm()
    handleClose()
  }
  const changeShipment = (e, itemKey) => {
    if (e.value === 'ADD_ANOTHER_SHIPMENT') {
      options.splice(options.length - 1, 0, {
        value: options.length,
        label: `${intl.formatMessage({id: 'SHIPMENT'})} ${options.length} (${intl.formatMessage({
          id: 'NEW',
        })})`,
      })
      setOptions(options)
      formik.setFieldValue(formik.getFieldProps(`shipment_${itemKey}`).name, options.length - 1)
    }
    let itemSplit = _.find(tableData, (item) => {
      return item.split && item.key !== itemKey
    })
    let shipment = formik.values[`shipment_${itemSplit?.key}`]
    setDisabledApply(shipment === e.value)
  }

  const splitQuantity = (item) => {
    let newItems: any[] = []
    let newKey = tableData.length
    _.forEach(tableData, (value) => {
      if (value.key === item.key && value.quantity > 1) {
        value.quantity--
        value.split = true
        newItems.push(value, {
          id: item.id,
          quantity: 1,
          sku: item.sku,
          title: item.title,
          key: newKey,
          active: false,
          split: true,
        })
        formik.setFieldValue(formik.getFieldProps(`quantity_${value.key}`).name, value.quantity)
      } else {
        newItems.push(value)
      }
    })
    formik.setFieldValue(formik.getFieldProps(`shipment_${newKey}`).name, 2)
    formik.setFieldValue(formik.getFieldProps(`quantity_${newKey}`).name, 1)
    setTableData(newItems)
    setDisabledSplit(true)
    changeShipment({value: 2}, newKey)
  }
  const cancelSplit = () => {
    setDisabledSplit(false)
    let cancelSplitData: any[] = []
    _.forEach(tableData, (item) => {
      if (item.active) {
        if (item.split) {
          item.quantity += 1
        }
        item.split = false
        cancelSplitData.push(item)
      }
    })
    setTableData(cancelSplitData)
  }
  const applySplit = () => {
    setDisabledSplit(false)
    let applySplitData: any[] = []
    _.forEach(tableData, (item) => {
      if (item.split) {
        item.quantity = formik.values[`quantity_${item.key}`]
      }
      item.active = true
      item.split = false
      applySplitData.push(item)
    })
    setTableData(applySplitData)
  }

  const splitButton = (item) => {
    return item.split ? (
      <>
        <div
          className='btn-link cursor-pointer'
          style={{display: 'inline-block'}}
          onClick={() => {
            cancelSplit()
          }}
        >
          {intl.formatMessage({id: 'CANCEL'})}
        </div>
        <div style={{display: 'inline-block', padding: '0 10px'}}>|</div>
        <div
          className={`btn-link cursor-pointer ${disabledApply ? 'disabled' : ''}`}
          style={{display: 'inline-block'}}
          onClick={() => {
            if (!disabledApply) {
              applySplit()
            }
          }}
        >
          {intl.formatMessage({id: 'APPLY'})}
        </div>
      </>
    ) : (
      <div
        className='btn-link cursor-pointer'
        onClick={() => {
          if (!disabledSplit) {
            splitQuantity(item)
          }
        }}
      >
        {intl.formatMessage({id: 'SPLIT'})}
      </div>
    )
  }

  return (
    <>
      <Modal
        id='gori_split_order'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-800px h-auto'
        show={show}
        backdrop='static'
        onHide={() => handleCloseForm()}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'SPLIT_SHIP_ORDER'})}{' '}
              {`${currentCompany?.sb_code}-${orders[0]?.original?.code}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            <div className='row'>
              <p>
                {intl.formatMessage({id: 'TO_MOVE_AN_ITEM_SELECT_THE_SHIPMENT_FROM_THE_DROP_DOWN'})}
              </p>
              <p>
                {intl.formatMessage({
                  id: 'TO_SPLIT_THE_QUANTITY_OF_AN_ITEM_SELECT_THE_ITEM_ROW_AND_CHOOSE_SPLIT',
                })}
              </p>
              <p className='fw-bolder mt-3 fs-3'>{intl.formatMessage({id: 'ORDER_ITEM'})}</p>
              <Table
                columns={columns}
                data={tableData}
                tableClass='table-hover'
                tbodyClass='text-gray-600 fw-bold'
                useSort={false}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='row'>
              <div className='d-flex justify-content-end'>
                <Button
                  className='btn btn-secondary me-3'
                  label={intl.formatMessage({id: 'CANCEL'})}
                  event={() => handleCloseForm()}
                  disabled={formik.isSubmitting}
                />
                <Button
                  className='btn btn-primary'
                  label={intl.formatMessage({id: 'SUBMIT'})}
                  loadingText={intl.formatMessage({id: 'SUBMIT'})}
                  loading={formik.isSubmitting}
                  event={() => {
                    formik.submitForm()
                  }}
                  disabled={disabledSplit}
                />
              </div>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {SplitOrderModal}
